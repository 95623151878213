import axios from '../services/axios';
import { store } from '../ProviderApp';
import { UPDATE_MDPERSON_SUCCESS } from './mdpeople.actions';


/*=========== action names ===========*/

export const GET_REPORT_ATTEMPT = 'GET_REPORT_ATTEMPT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL';

export const GET_REPORT_FIELDS_ATTEMPT = 'GET_REPORT_FIELDS_ATTEMPT';
export const GET_REPORT_FIELDS_SUCCESS = 'GET_REPORT_FIELDS_SUCCESS';
export const GET_REPORT_FIELDS_FAIL = 'GET_REPORT_FIELDS_FAIL';

export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_ATTEMPT = 'UPDATE_REPORT_ATTEMPT';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAIL = 'UPDATE_REPORT_FAIL';

export const GET_META_MEASURES_ATTEMPT = 'GET_META_MEASURES_ATTEMPT';
export const GET_META_MEASURES_SUCCESS = 'GET_META_MEASURES_SUCCESS';
export const GET_META_MEASURES_FAIL = 'GET_META_MEASURES_FAIL';

export const UPDATE_META_MEASURE = 'UPDATE_META_MEASURE';
export const UPDATE_META_MEASURES_ATTEMPT = 'UPDATE_META_MEASURES_ATTEMPT';
export const UPDATE_META_MEASURES_SUCCESS = 'UPDATE_META_MEASURES_SUCCESS';
export const UPDATE_META_MEASURES_FAIL = 'UPDATE_META_MEASURES_FAIL';

/*=========== reducer actions ===========*/

const getReportAttempt = () => {
	return {
		type: GET_REPORT_ATTEMPT,
	};
};

const getReportSuccess = (report) => {
	return {
		type: GET_REPORT_SUCCESS,
		report: report
	};
};

const getReportFail = (err) => {
	return {
		type: GET_REPORT_FAIL,
		err: err
	};
};

const getReportFieldsAttempt = () => {
	return {
		type: GET_REPORT_FIELDS_ATTEMPT,
	};
};

const getReportFieldsSuccess = (fields, emptyValues, fieldsHash) => {
	return {
		type: GET_REPORT_FIELDS_SUCCESS,
		fields: fields,
		emptyValues: emptyValues,
		fieldsHash: fieldsHash,
	};
};
const getReportFieldsFail = (err) => {
	return {
		type: GET_REPORT_FIELDS_FAIL,
		err: err
	};
};


export const updateReport = (key, value) => {
	return {
		type: UPDATE_REPORT,
		key: key,
		value: value,
	};
};

const updateReportAttempt = () => {
	return {
		type: UPDATE_REPORT_ATTEMPT,
	};
};

const updateReportSuccess = (report) => {
	return {
		type: UPDATE_REPORT_SUCCESS,
		report: report
	};
};

const updateReportFail = (err) => {
	return {
		type: UPDATE_REPORT_FAIL,
		err: err
	};
};

const getMetaMeasuresAttempt = () => {
	return {
		type: GET_META_MEASURES_ATTEMPT,
	};
};

const getMetaMeasuresSuccess = (measures) => {
	return {
		type: GET_META_MEASURES_SUCCESS,
		measures: measures,
	};
};

const getMetaMeasuresFail = (err) => {
	return {
		type: GET_META_MEASURES_FAIL,
		err: err,
	};
};

export const updateMetaMeasure = (index, key, value) => {
	return {
		type: UPDATE_META_MEASURE,
		index: index,
		key: key,
		value: value,
	};
};

const updateMetaMeasuresAttempt = () => {
	return {
		type: UPDATE_META_MEASURES_ATTEMPT,
	};
};

const updateMetaMeasuresSuccess = (measures) => {
	return {
		type: UPDATE_META_MEASURES_SUCCESS,
		measures: measures,
	};
};

const updateMetaMeasuresFail = (err) => {
	return {
		type: UPDATE_META_MEASURES_FAIL,
		err: err,
	};
};

/*=========== actions ===========*/

const fetchOneReport = (report_id) => dispatch => {
	dispatch(getReportAttempt());
	axios.get(`/domoreport/uploader/domoreport/${report_id}`)
		.then((res) => {
			dispatch(getReportSuccess(res.data));
		}).catch(err => {
			dispatch(getReportFail(err));
		});
};

export const getOneReport = (report_id) => dispatch => {
	fetchOneReport(report_id)(dispatch);
};

export const updateOneReport = (report_id) => dispatch => {
	updateMetaMeasures(report_id)(dispatch);
	dispatch(updateReportAttempt());
	axios.put(`/domoreport/uploader/domoreport/${report_id}`, store.getState().reports.report)
		.then((res) => {
			dispatch(updateReportSuccess());
			fetchOneReport(report_id)(dispatch);
		}).catch(err => {
			dispatch(updateReportFail(err));
		});
};

const fetcReportFields = (dispatch) => {
	dispatch(getReportFieldsAttempt());
	axios.get(`/domoreport/uploader/domoreport/fields`)
		.then((res) => {
			let emptyValues = {};

			for (let i = 0; i < res.data.length; i++) {
				emptyValues[res.data[i].Field] = null;
			}
			let fieldsHash = {};
			fieldsHash = res.data.reduce((map, obj) => {
				fieldsHash[obj.Field] = obj.Field
				return fieldsHash;
			});
			dispatch(getReportFieldsSuccess(res.data, emptyValues, fieldsHash));
		}).catch(err => {
			dispatch(getReportFieldsFail(err));
		});
};

export const getReportFields = () => dispatch => {
	if (!store.getState().reports.fieldsFetched && !store.getState().reports.fieldsFetching) {
		fetcReportFields(dispatch);
	}
};

export const forceGetReportFields = () => dispatch => {
	fetcReportFields(dispatch);
};

const fetchMetaMeasures = (report_id) => dispatch => {
	dispatch(getMetaMeasuresAttempt());
	axios.get(`/domoreport/uploader/allmetaMeasuresForReport/${report_id}`)
		.then((res) => {
			dispatch(getMetaMeasuresSuccess(res.data.measures));
		}).catch(err => {
			dispatch(getMetaMeasuresFail(err));
		});
};

export const getMetaMeasures = (report_id) => dispatch => {
	fetchMetaMeasures(report_id)(dispatch);
};

const updateMetaMeasures = (report_id) => dispatch => {
	const reportState = store.getState().reports;
	dispatch(updateMetaMeasuresAttempt());
	//check if the new Meta Measure has any values, if yes, create a promise to save it
	let promiseArray = [];
	if (!(reportState.newMetaMeasure.value === "" || reportState.newMetaMeasure.value === null) || !(reportState.newMetaMeasure.measure === "" || reportState.newMetaMeasure.measure === null)) {
		promiseArray = [addNewMetaMeasure()];
	}
	//
	let mappedPromiseArray = reportState.metaMeasures.map(measure => {
		if ((measure.measure === "" || measure.measure === null) && (measure.value === "" || measure.value === null)) {
			return deleteMetaMeasure(measure.meta_measure_id);
		} else {
			return updateOneMetaMeasure({ measure: measure.measure, value: measure.value }, measure.meta_measure_id);
		}
	});

	Promise.all([Promise.all(promiseArray), Promise.all(mappedPromiseArray)])
		.then((answers) => {
			dispatch(updateMetaMeasuresSuccess());
			fetchMetaMeasures(reportState.report.domoreport_log_id)(dispatch);
		})
		.catch(err => {
			dispatch(updateMetaMeasuresFail(err));
		});
};

const addNewMetaMeasure = () => {
	const reportState = store.getState().reports;
	const newMetaMeasure = {
		measure: reportState.newMetaMeasure.measure,
		value: reportState.newMetaMeasure.value,
		majordomo_people_ref_id: reportState.report.majordomo_people_ref_id,
		domoreport_log_id: reportState.report.domoreport_log_id,
	};
	return axios.post(`/domoreport/uploader/metaMeasure`, newMetaMeasure);
};

const updateOneMetaMeasure = (metaMeasure, measure_id) => {
	return axios.put(`/domoreport/uploader/metaMeasure/${measure_id}`, metaMeasure);
};

const deleteMetaMeasure = (measure_id) => {
	return axios.delete(`/domoreport/uploader/metaMeasure/${measure_id}`);
};
import { LOGIN_FAIL, LOGIN_SUCCESS, ATTEMPT_LOGIN } from '../actions'

const initialState = {
	token: "",
};

export function user(state = initialState, action){
	switch (action.type){
		case LOGIN_SUCCESS:
			return {
				...state,
				token: action.token
			}
		default:
			return {...state}
	}
}
import React, { Component } from 'react';
import { Row, Table, ListGroup, Form, Pagination } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import _ from 'lodash'
import moment from 'moment'
import Fuse from 'fuse.js'

export default 
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        allExploreContent: {
            url: `/admin/explore`,

        }
    }) : null;
})
class ExploreComponent extends Component {

    state = {
        searchValue: null,
        currentSort: 'state',
        direction: 'asc',
        activePage: 1
    }

    render() {
        const { allExploreContent } = this.props;

        if (!allExploreContent) {
            return <div>
                <h1>Pending domoreports</h1>
                <Row>
                    <ListGroup>
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                        <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                </Row>
            </div>
        }

        return !allExploreContent.fulfilled ? this._emptyState() : !allExploreContent.value ? this._emptyState() : this._availableState(allExploreContent.value);
    }


    _renderPagination = (length) => {
        const { activePage } = this.state;

        let items = [];
        for (let number = 1; number <= length; number++) {
            items.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => this._changePage(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        return <Pagination style={{ flexWrap: 'wrap' }}>{items}</Pagination>
    }

    _changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber })
    }

    _handleSearch = (text) => {
        if (!text) {
            this.setState({ searchValue: null });
        }
        this.setState({ searchValue: text });
    }

    _sortBy = (column) => {
        if (column === this.state.currentSort) {
            let newDirection = this.state.direction === "asc" ? "desc" : "asc"
            this.setState({ direction: newDirection })
        } else {
            this.setState({ currentSort: column, direction: "asc" })
        }

    }

    _returnCaret = (direction) => {
        return direction === "asc" ? <span>&#9660;</span> : <span>&#9650;</span>
    }


    _availableState = (exploreContent) => {
        const { searchValue, currentSort, direction, activePage } = this.state;

        let options = {
            isCaseSensitive: true,
            keys: [
                {
                    name: 'county',
                    weight: 0.33
                },
                {
                    name: 'state',
                    weight: 0.33
                },
                {
                    name: 'city',
                    weight: 0.33
                },
            ],
            threshold: 0.0,
        }

        let contentFuse = new Fuse(exploreContent, options)
        let searchedExploreContent = contentFuse.list;
        if (searchValue) {
            searchedExploreContent = contentFuse.search(searchValue)
        }

        let skip = activePage === 1 ? 0 : (parseInt(activePage - 1) * 10)

        searchedExploreContent = _.orderBy(searchedExploreContent, [currentSort], [`${direction}`])
        let currentContent = searchedExploreContent.slice(skip, skip + 10)

        return (
            <div className="md-scroll-wrapper">
                <div className="container my-5">
                    <div>
                        <h1>Explore Content</h1>
                        <Link to="/explore-create" className={`btn btn-primary btn-large`}>Create Explore Content</Link>
                        <Form.Group>
                            <Form.Label>Filter Results</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleSearch(e.target.value)} />
                        </Form.Group>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th onClick={() => this._sortBy('title')}>Title {currentSort === "title" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('city')}>City {currentSort === "city" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('county')}>County {currentSort === "county" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('state')}>State {currentSort === "state" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('country')}>Country {currentSort === "country" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('active')}>Active {currentSort === "active" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('verified_by')}>Is Verified? {currentSort === "verified_by" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('date_verified')}>Date Verified {currentSort === "date_verified" ? this._returnCaret(direction) : null}</th>
                                    <th onClick={() => this._sortBy('updatedAt')}>Updated At {currentSort === "updatedAt" ? this._returnCaret(direction) : null}</th>

                                </tr>

                            </thead>
                            <tbody>
                                {_.orderBy(currentContent, [currentSort], [`${direction}`])
                                    .map(content => {
                                        return <tr onClick={() => this.props.history.push(`/explore-content/${content.explore_content_log_id}`)} key={content.explore_content_log_id}>
                                            <td>{content.title}</td>
                                            <td>{content.city}</td>
                                            <td>{content.county}</td>
                                            <td>{content.state}</td>
                                            <td>{content.country}</td>
                                            <td style={content.active === 0 ? { color: 'red' } : { color: 'green' }}>{content.active === 0 ? 'false' : 'true'}</td>
                                            <td>{content.verified_by ? 'Yes' : 'No'}</td>
                                            <td>{content.date_verified ? moment(content.date_verified).format('ll') : null}</td>
                                            <td>{content.updatedAt ? moment(content.updatedAt).format('ll') : null}</td>
                                        </tr>
                                    })}
                            </tbody>
                        </Table>
                        {this._renderPagination(Math.ceil(searchedExploreContent.length / 10))}
                    </div>
                </div>
            </div>
        )
    }

    _emptyState = () => {
        return (
            <div className="md-scroll-wrapper">
                <div className="container my-5">
                    <h2>Explore Content</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Zipcode</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Country</th>
                            </tr>
                        </thead>
                    </Table>
                </div>
            </div>
        )
    }
}
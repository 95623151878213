import React from 'react';
import { Form, Container, Button, Row} from 'react-bootstrap';
import { makeLabel } from '.';

export const ButtonField = function({formName, fieldKey, keysToIgnore, obj, keyAsIndex, onChange, onClick, label, disabled}){
	formName = formName || "";
	fieldKey = fieldKey || "";
	keysToIgnore = keysToIgnore || {};
	keyAsIndex = keyAsIndex || "";
	obj = obj || {};
	onChange = onChange || (() => {});
	return(
		<Container key={keyAsIndex}>
			<Form.Group controlId="fillerControlId" key={keyAsIndex}>
				
				{makeLabel(formName.replace(/_/g,' '), fieldKey, keysToIgnore)}
				<Row>
					<Button onClick={onClick} disabled={disabled}>
						{label}
					</Button>
				</Row>
			</Form.Group>
		</Container>
	)
}
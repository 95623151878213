import React, { Component } from 'react';
import { Container, Button, Row, Col, ListGroup, Table } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withUser from '../../hocs/withUser';
import FormByKey from '../../ui/FormByKey/form';
import { _returnFields } from '../../ui/FormByKey/featureRefFields';
import { keysToIgnore, fieldsHash } from './';
import WarningModal from '../../ui/WarningModal';
import { connect } from 'react-redux'
import { getFeatureRef, updateFeatureRefState, getAvailableFeatures, updateFeatureRef, deleteFeatureRef } from '../../actions';

const mapStateToProps = state => ({
    value: state.featureRef.featureRef,
    sortedFeatures: state.properties.availableFeatures.sections,
    lockFeatureRef: state.featureRef.lockFeatureRef,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, { getFeatureRef, updateFeatureRefState, getAvailableFeatures, updateFeatureRef, deleteFeatureRef })
class EditFeatureRefComponent extends Component {

    state = {
        createWarningOpen: false,
    }

    constructor(props) {
        super(props)
        this._goBack = this._goBack.bind(this);
    }

    componentDidMount() {
        this.props.getFeatureRef(this.props.match.params.id)
        this.props.getAvailableFeatures()
        window.scrollTo(0, 0)
    }

    _handleChange = (key, value) => {
        this.props.updateFeatureRefState(key, value);
    }

    _toggleWarningModal = () => {
        const { createWarningOpen } = this.state;
        this.setState({ createWarningOpen: !createWarningOpen }, () => {
            console.log(this.state);
        });
    }

    _onSubmit = () => {
        this.props.updateFeatureRef(this.props.match.params.id)
    }

    _onDelete = () => {
        const { deleteFeatureRef } = this.props;
        console.log("Gonna delete this, probably")
        deleteFeatureRef(this.props.match.params.id, (success) => {
            if (success) {
                this._goBack();
            }
        });
    }

    _goBack() {
        this.props.history.goBack();
    }

    _sortAreasForDropdown = (secArr) => {
        let dropdown = [];
        for (let i = 0; i < secArr.length; i++) {
            dropdown.push({
                area_name: `-----${secArr[i].section_name}-----`,
                area_ref_id: -1,
                disabled: true,
            })
            for (let j = 0; j < secArr[i].areas.length; j++) {
                dropdown.push({
                    area_name: secArr[i].areas[j].area_name,
                    area_ref_id: secArr[i].areas[j].area_ref_id,
                    disabled: false,
                })
            }
        }

        return dropdown
    }

    render() {
        const { createWarningOpen } = this.state;

        let { value } = this.props

        let dropdownOptions = this._sortAreasForDropdown(this.props.sortedFeatures);

        let fields = []
        for (let key in fieldsHash) {
            if (fieldsHash.hasOwnProperty(key))
                fields.push({ Field: key });
        }

        let buttonDisabled = false
        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                value[key] = (key in this.state ? this.state[key] : value[key])
                if (!(key in keysToIgnore) && key in fieldsHash) {
                    buttonDisabled = buttonDisabled || value[key] === "" || value[key] === "null" || value[key] === null
                }
            }
        }

        return (
            <>
                <div className="md-scroll-wrapper">
                    {(this.props.lockFeatureRef)
                        && <div style={
                            {
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                                zIndex: 999,
                                position: "fixed",
                                left: 0,
                                top: 0,
                                width: "100vw",
                                height: "100vh",
                            }
                        }></div>}
                    <Container>
                        <Row>
                            <Col>
                                <h2>Feature: {value.feature_name}, ID: {value.feature_ref_id}</h2>
                                <Link to="/featureRefs">Go Back</Link>
                                <FormByKey
                                    fields={fields}
                                    obj={{ ...value, areaOptions: dropdownOptions }}
                                    onChange={this._handleChange}
                                    boolChange={this._handleChange}
                                    submit={this._onSubmit}
                                    showDelete={true}
                                    delete={this._onDelete}
                                    returnFields={_returnFields}
                                    keysToIgnore={keysToIgnore}
                                    disabled={buttonDisabled}

                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="9"></Col>
                            <Col sm="3">
                                < Button variant="danger" onClick={this._toggleWarningModal}>DELETE</Button>
                            </Col>
                        </Row>
                        <Row style={{ height: 25 }}></Row>
                        {createWarningOpen && <WarningModal
                            close={this._toggleWarningModal}
                            delete={this._onDelete}
                            titleLable={"I'm begging, please don't do this to me"}
                            bodyLable={"I'm too young to die. I've barely started at this life, I can change..."}
                            buttonLable={"Bang"}
                        />}
                    </Container>
                </div>
            </>
        )
    }
}
import './index.scss'
import React, { Component } from 'react';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';

export default 
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        createInspector: (data) => ({
            createdInspector: {
                url: `/domoreport/uploader/inspector`,
                method: 'POST',

                body: JSON.stringify(data),
                then: (res) => {
                    if (res.inspector_ref_id) {
                        let inspector = {
                            id: res.inspector_ref_id,
                            name: data.inspector_person_name,
                            company: data.inspection_company,
                        }

                        props.handleChange('inspection_company', inspector)
                        props.getInspectors();
                    }
                }
            }
        }),
        updateInspector: (data, id) => ({
            updatedInspector: {
                url: `/domoreport/uploader/inspector/${id}`,
                method: 'PUT',

                body: JSON.stringify(data),
                then: (res) => {
                    if (res.success) {
                        let inspector = {
                            id: id,
                            name: data.inspector_person_name,
                            company: data.inspection_company,
                            email: data.inspection_email,
                            phone: data.inspection_phone,
                        }

                        props.handleChange('inspection_company', inspector)
                        props.getInspectors();
                    }
                }
            }
        }),
        createBroker: (data) => ({
            createdBroker: {
                url: `/domoreport/uploader/broker`,
                method: 'POST',

                body: JSON.stringify(data),
                then: (res) => {
                    if (res.broker_ref_id) {
                        let broker = {
                            id: res.broker_ref_id,
                            name: data.broker_person_name,
                            company: data.brokerage,
                        }

                        props.handleChange('agent', broker)
                        props.getBrokers();
                    }
                }
            }
        }),
        updateBroker: (data, id) => ({
            updatedBroker: {
                url: `/domoreport/uploader/broker/${id}`,
                method: 'PUT',

                body: JSON.stringify(data),
                then: (res) => {
                    if (res.success) {
                        let broker = {
                            id: id,
                            name: data.broker_person_name,
                            company: data.brokerage,
                            email: data.brokerage_email,
                            phone: data.brokerage_phone,
                        }

                        props.handleChange('agent', broker)
                        props.getBrokers();
                    }
                }
            }
        }),
    }) : null;
})
class InspectionDetailsModal extends Component {

    state = {
        name: "",
        company: "",
        email: "",
        phone: "",
    }

    componentDidMount() {
        const { inspectionPerson } = this.props;
        console.log(inspectionPerson)
        if (inspectionPerson) {
            this.setState({
                name: inspectionPerson.name,
                company: inspectionPerson.company,
                email: inspectionPerson.email,
                phone: inspectionPerson.phone,
            })
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, company, email, phone } = this.state;
        const { label, inspectionPerson } = this.props;
        console.log(inspectionPerson)

        if (label === 'inspection_company') {
            let data = {
                inspector_person_name: name,
                inspection_company: company,
                inspection_email: email,
                inspection_phone: phone,
            }

            if (inspectionPerson) {
                this.props.updateInspector(data, inspectionPerson.id)
            } else {
                this.props.createInspector(data)
            }
        } else if (label === 'agent') {
            let data = {
                broker_person_name: name,
                brokerage: company,
                brokerage_email: email,
                brokerage_phone: phone,
            }

            if (inspectionPerson) {
                this.props.updateBroker(data, inspectionPerson.id)
            } else {
                this.props.createBroker(data)
            }
        }

        this.props.toggleModal(e, null);
    }

    render() {
        const { inspectionPerson } = this.props;
        const { name, company, email, phone } = this.state;
        let label = "";

        if (this.props.label === 'inspection_company') {
            label = 'Inspector';
        } else if (this.props.label === 'agent') {
            label = 'Agent'
        }

        return (
            <div className="md-diy-inspector-modal">
                <div className="md-diy-sidebar-form">
                    <h4>{inspectionPerson ? 'Edit' : 'Add New'} {label}</h4>
                    <label>Name</label>
                    <div className="md-diy-input-wrapper">
                        <img src="/assets/icons/Name.svg" className="md-diy-input-icon" />
                        <input
                            type="text"
                            placeholder="Name"
                            onChange={(e) => this.handleChange('name', e.target.value)}
                            value={name || ""}
                        />
                    </div>

                    <label>Company</label>
                    <div className="md-diy-input-wrapper">
                        <img src="/assets/icons/Company.svg" className="md-diy-input-icon" />
                        <input
                            type="text"
                            placeholder="Company Name"
                            onChange={(e) => this.handleChange('company', e.target.value)}
                            value={company || ""}
                        />
                    </div>

                    <label>Email</label>
                    <div className="md-diy-input-wrapper">
                        <img src="/assets/icons/Email.svg" className="md-diy-input-icon" />
                        <input
                            type="text"
                            placeholder="Email Address"
                            onChange={(e) => this.handleChange('email', e.target.value)}
                            value={email || ""}
                        />
                    </div>

                    <label>Phone</label>
                    <div className="md-diy-input-wrapper">
                        <img src="/assets/icons/Phone.svg" className="md-diy-input-icon" />
                        <input
                            type="text"
                            placeholder="Phone Number"
                            onChange={(e) => this.handleChange('phone', e.target.value)}
                            value={phone || ""}
                        />
                    </div>
                </div>

                <div className="md-diy-modal-footer">
                    <a href="#" className="btn btn-cancel" onClick={(e) => this.props.toggleModal(e, null)}>Cancel</a>
                    <a href="#" className="btn btn-primary" onClick={this.handleSubmit}>Save</a>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import withFetch from '../../hocs/withFetch';

export default 
@withFetch((props) => ({
    setPrimaryPhoto: (data) => ({
        primaryPhotoData: {
            method: 'POST',
            url: `/domoreport/documentForDomoreport/${props.reportID}`,
            headers: {
                authorization: props.token,
            },
            body: data
        },
    })
}))
class FeatureImageModal extends Component {

    handleImageUpload = () => {
        const { image } = this.props;
        let formData = new FormData();

        formData.append('doc_file', image);
        formData.append('doc_file_name', image.doc_title)
        formData.append('doc_title', image.doc_title);
        formData.append('doc_description', 'property photo');
        formData.append('property_photo', true);

        this.props.setPrimaryPhoto(formData)
    }

    render() {
        const { image, height } = this.props;

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => this.props.setImage(null, null)}
                className="md-order-modal"
            >
                <Modal.Header closeButton />

                <Modal.Body>
                    <img src={image.location} width="100%" height={height} className="md-feature-image" />
                </Modal.Body>
            </Modal>
        )
    }
}

import '../index.scss'
import React, { useEffect, useState } from 'react';

export default function ExploreList({ exploreData, getExplore, setExploreResource, setResourceTitle, updateStep, userCanEdit }) {
    const [exploreResources, setExploreResources] = useState([]);

    useEffect(() => {
        setResourceTitle(null)
        getExplore();
    }, [])

    useEffect(() => {
        document.getElementsByClassName('md-diy-sidebar')[0].scrollTo(0, 0)

        let resources = [];
        let cities = exploreData.filter(x => x.tags.city && x.tags.city != "null")
        let counties = exploreData.filter(x => x.tags.county && x.tags.county != "null")
        let countries = exploreData.filter(x => x.tags.country && x.tags.country != "null")
        let states = exploreData.filter(x => x.tags.state && x.tags.state != "null")

        resources.push({ header: 'City Resources', data: cities })
        resources.push({ header: 'County Resources', data: counties })
        resources.push({ header: 'State Resources', data: states })
        resources.push({ header: 'USA Resources', data: countries })

        setExploreResources(resources)
    }, [exploreData])

    function handleResourceClick(resource, tag) {
        if (tag.header === 'County Resources') {
            setResourceTitle('county')
        } else if (tag.header === 'City Resources') {
            setResourceTitle('city')
        }

        setExploreResource(resource)
        updateStep(8)
    }

    return (
        <div className="md-diy-sidebar-content">
            <div className="md-diy-sidebar-info">
                <h1 className="md-diy-sidebar-header">Explore Resources</h1>

                {exploreResources && !!exploreResources.length &&
                    <div className="md-diy-explore-wrapper">
                        {exploreResources.map((tag) => {
                            return (
                                <div className="explore-content" key={tag.header}>
                                    <h4>{tag.header}</h4>
                                    <hr />

                                    <div className="explore-resources-wrapper">
                                        {tag.data.map((explore) => {
                                            return (
                                                <div className="explore-resource" key={explore.explore_content_log_id} onClick={() => handleResourceClick(explore, tag)}>
                                                    <p><b>{explore.title}</b> {explore.summary_text && `- ${explore.summary_text}`}</p>
                                                </div>
                                            )
                                        })}

                                        {userCanEdit && tag.header === 'County Resources' &&
                                            <div className="md-diy-sidebar-projects">
                                                <a
                                                    href="#"
                                                    className="btn btn-primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        updateStep(7)
                                                        setResourceTitle('county')
                                                    }}
                                                >+ Add County Resource
                                                </a>
                                            </div>
                                        }

                                        {userCanEdit && tag.header === 'City Resources' &&
                                            <div className="md-diy-sidebar-projects">
                                                <a
                                                    href="#"
                                                    className="btn btn-primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        updateStep(7)
                                                        setResourceTitle('city')
                                                    }}
                                                >+ Add City Resource
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    );
}
import "./index.scss";
import React, { useEffect, useState } from "react";
import v1 from "../../services/axios";
import { sortFeatures } from "../../services/helpers";

export default function CreateFeatureModal({
  project,
  projectFeatureMap,
  property,
  show,
  setProject,
  getProperty,
  hideModal,
}) {
  const [areas, setAreas] = useState([]);
  const [areaRefId, setAreaRefId] = useState(null);
  const [features, setFeatures] = useState([]);
  const [featureRefId, setFeatureRefId] = useState(null);

  useEffect(() => {
    getAvailableFeatures();
  }, [projectFeatureMap]);

  async function getAvailableFeatures() {
    try {
      const { data, status } = await v1.get("/property/categorizedFeatures");
      if (status === 200) {
        let allAreas = [];
        let featuresList = {};
        let areaId = "";
        let featureId = "";

        data.map((section) => {
          section.areas.map((area) => {
            allAreas.push(area);
            featuresList[area.area_ref_id] = area.features.sort(sortFeatures);

            if (projectFeatureMap) {
              area.features.map((feature) => {
                if (
                  feature.feature_ref_id === projectFeatureMap.feature_ref_id
                ) {
                  areaId = area.area_ref_id;
                  featureId = feature.feature_ref_id;
                }
              });
            }
          });
        });

        if (!areaId && areas[0]) {
          setAreaRefId(areas[0].area_ref_id);
        } else {
          setAreaRefId(areaId);
          setFeatureRefId(featureId);
        }

        allAreas.sort(sortFeatures);
        setAreas(allAreas);
        setFeatures(featuresList);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function createFeature() {
    try {
      const area = areas.find(
        (area) => area.area_ref_id === parseInt(areaRefId)
      );
      const feature = area.features.find(
        (feature) => feature.feature_ref_id === parseInt(featureRefId)
      );

      let featuresList = [
        {
          area_ref_id: parseInt(areaRefId),
        },
      ];

      if (!feature && area.features[0] && area.features[0].feature_ref_id) {
        featuresList[0].feature_ref_id = area.features[0].feature_ref_id;
      } else if (feature.feature_ref_id) {
        featuresList[0].feature_ref_id = feature.feature_ref_id;
      }

      const { data, status } = await v1.post(
        `/property/features/${property.property_log_id}`,
        { features: featuresList }
      );

      if (status === 200) {
        const { property_fact_id } = data.successes[0];
        setProject({ ...project, property_fact_id });
        getProperty();
        hideModal();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleClick(e) {
    if (e.target.id && e.target.id === "modalOverlay") {
      hideModal();
    }
  }

  return (
    <div
      id="modalOverlay"
      className={`create-feature-modal-overlay ${!show ? "hidden" : ""}`}
      onClick={handleClick}
    >
      <div className="test-create-feature-modal">
        <div className="modal-header">
          <h4>Create Feature</h4>
        </div>
        <div className="modal-body">
          <label htmlFor="area">Area</label>
          <select
            id="area"
            value={areaRefId || ""}
            onChange={(e) => setAreaRefId(e.target.value)}
          >
            <option key={""} value={""} disabled={true}>
              -----------------------
            </option>
            {areas.map((area) => {
              return (
                <option key={area.area_ref_id} value={area.area_ref_id}>
                  {area.area_name}
                </option>
              );
            })}
          </select>

          <label htmlFor="feature">Feature</label>
          <select
            id="feature"
            value={featureRefId || ""}
            onChange={(e) => setFeatureRefId(e.target.value)}
          >
            <option key={""} value={""} disabled={true}>
              -----------------------
            </option>

            {features[areaRefId] &&
              features[areaRefId].map((feature) => {
                return (
                  <option
                    key={feature.feature_ref_id}
                    value={feature.feature_ref_id}
                  >
                    {feature.feature_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="modal-footer">
          <button className="btn-primary" onClick={createFeature}>
            Create Feature
          </button>
        </div>
      </div>
    </div>
  );
}

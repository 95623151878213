import axios from '../services/axios';
import { store } from '../ProviderApp';

/*=========== action names ===========*/

export const GET_ALL_PFMS_ATTEMPT = 'GET_ALL_PFMS_ATTEMPT';
export const GET_ALL_PFMS_SUCCESS = 'GET_ALL_PFMS_SUCCESS';
export const GET_ALL_PFMS_FAIL = 'GET_ALL_PFMS_FAIL';
export const CHANGE_SORT = "CHANGE_SORT";

export const GET_ONE_PFM_ATTEMPT = 'GET_ONE_PFM_ATTEMPT';
export const GET_ONE_PFM_SUCCESS = 'GET_ONE_PFM_SUCCESS';
export const GET_ONE_PFM_FAIL = 'GET_ONE_PFM_FAIL';

export const CREATE_EMPTY_PFM = 'CREATE_EMPTY_PFM';

export const UPDATE_PFM = "UPDATE_PFM";

export const CREATE_PFM_ATTEMPT = 'CREATE_PFM_ATTEMPT';
export const CREATE_PFM_SUCCESS = 'CREATE_PFM_SUCCESS';
export const CREATE_PFM_FAIL = 'CREATE_PFM_FAIL';

export const UPDATE_PFM_ATTEMPT = 'UPDATE_PFM_ATTEMPT';
export const UPDATE_PFM_SUCCESS = 'UPDATE_PFM_SUCCESS';
export const UPDATE_PFM_FAIL = 'UPDATE_PFM_FAIL';

export const DELETE_PFM_ATTEMPT = 'DELETE_PFM_ATTEMPT';
export const DELETE_PFM_SUCCESS = 'DELETE_PFM_SUCCESS';
export const DELETE_PFM_FAIL = 'DELETE_PFM_FAIL';

export const VERIFY_PFM_ATTEMPT = 'VERIFY_PFM_ATTEMPT';
export const VERIFY_PFM_SUCCESS = 'VERIFY_PFM_SUCCESS';
export const VERIFY_PFM_FAIL = 'VERIFY_PFM_FAIL';

/*=========== reducer actions ===========*/

const getAllPFMsAttempt = (page) => {
	return {
		type: GET_ALL_PFMS_ATTEMPT,
		page: page
	};
};

const getAllPFMsSuccess = (pfms) => {
	return {
		type: GET_ALL_PFMS_SUCCESS,
		pfms: pfms
	};
};
const getAllPFMsFail = (err) => {
	return {
		type: GET_ALL_PFMS_FAIL,
		err: err
	};
};

const changeSort = (sort, asc) => {
	return {
		type: CHANGE_SORT,
		sort: sort,
		asc: asc
	}
}

const getOnePFMAttempt = (id) => {
	return {
		type: GET_ONE_PFM_ATTEMPT,
		id: id
	};
};

const getOnePFMSuccess = (pfm) => {
	return {
		type: GET_ONE_PFM_SUCCESS,
		pfm: pfm
	};
};
const getOnePFMFail = (err) => {
	return {
		type: GET_ONE_PFM_FAIL,
		err: err
	};
};
const createEmptyPFM = () => {
	return {
		type: CREATE_EMPTY_PFM
	};
};
export const updatePFM = (key, value) => {
	return {
		type: UPDATE_PFM,
		key: key,
		value: value,
	}
}

const createPFMAttempt = () => {
	return {
		type: CREATE_PFM_ATTEMPT,
	};
};

const createPFMSuccess = (newId) => {
	return {
		type: CREATE_PFM_SUCCESS,
		newId: newId
	};
};
const createPFMFail = (err) => {
	return {
		type: CREATE_PFM_FAIL,
		err: err
	};
};

const updatePFMAttempt = (id) => {
	return {
		type: UPDATE_PFM_ATTEMPT,
		id: id
	};
};

const updatePFMSuccess = () => {
	return {
		type: UPDATE_PFM_SUCCESS,
	};
};
const updatePFMFail = (err) => {
	return {
		type: UPDATE_PFM_FAIL,
		err: err
	};
};

const deletePFMAttempt = (id) => {
	return {
		type: DELETE_PFM_ATTEMPT,
		id: id
	};
};

const deletePFMSuccess = () => {
	return {
		type: DELETE_PFM_SUCCESS,
	};
};
const deletePFMFail = (err) => {
	return {
		type: DELETE_PFM_FAIL,
		err: err
	};
};

const verifyPFMAttempt = (id) => {
	return {
		type: VERIFY_PFM_ATTEMPT,
		id: id
	};
};

const verifyPFMSuccess = () => {
	return {
		type: VERIFY_PFM_SUCCESS,
	};
};
const verifyPFMFail = (err) => {
	return {
		type: VERIFY_PFM_FAIL,
		err: err
	};
};


/*=========== actions ===========*/

const fetchAllProjectFeatureMaps = (dispatch, page) => {
	const { sort, limit, asc } = store.getState().projectFeatureMap

	dispatch(getAllPFMsAttempt(page));
	axios.get(`/domoreport/uploader/allProjectFeatureMaps?sortBy=${sort}&desc=${!asc}&limit=${limit}&offset=${page * limit}`)
		.then((res) => {
			dispatch(getAllPFMsSuccess(res.data));
		}).catch(err => {
			dispatch(getAllPFMsFail(err));
		});
};

export const getAllProjectFeatureMaps = (page) => dispatch => {
	fetchAllProjectFeatureMaps(dispatch, page);
};

export const forceGetAllProjectFeatureMaps = (page) => dispatch => {
	fetchAllProjectFeatureMaps(dispatch, page);
};

export const changeFetchSort = (newSort) => dispatch => {
	const { sort, asc } = store.getState().projectFeatureMap
	dispatch(changeSort(newSort, ((sort === newSort && !asc) || (sort !== newSort))))
	fetchAllProjectFeatureMaps(dispatch, 0)
}

const fetchOneProjectFeatureMap = (dispatch, id) => {
	dispatch(getOnePFMAttempt(id));
	axios.get(`/domoreport/uploader/projectFeatureMap/${id}`)
		.then((res) => {
			dispatch(getOnePFMSuccess(res.data));
		}).catch(err => {
			dispatch(getOnePFMFail(err));
		});
};

export const getOneProjectFeatureMap = (id) => dispatch => {
	if (id) {
		fetchOneProjectFeatureMap(dispatch, id);
	} else {
		dispatch(createEmptyPFM())
	}

};

export const forceGetOneProjectFeatureMap = (id) => dispatch => {
	fetchOneProjectFeatureMap(dispatch, id);
};

export const createProjectFeatureMap = (id) => dispatch => {
	dispatch(createPFMAttempt(id));
	let saveModel = store.getState().projectFeatureMap.currentPFM
	delete saveModel.id
	axios.post(`/domoreport/uploader/projectFeatureMap/`, saveModel)
		.then((res) => {
			dispatch(createPFMSuccess(res.data.id));
			fetchAllProjectFeatureMaps(dispatch, store.getState().projectFeatureMap.page)
		}).catch(err => {
			dispatch(createPFMFail(err));
		});
};


export const updateProjectFeatureMap = (id) => dispatch => {
	dispatch(updatePFMAttempt(id));
	axios.put(`/domoreport/uploader/projectFeatureMap/${id}`, store.getState().projectFeatureMap.currentPFM)
		.then((res) => {
			dispatch(updatePFMSuccess());
			fetchAllProjectFeatureMaps(dispatch, store.getState().projectFeatureMap.page)
		}).catch(err => {
			dispatch(updatePFMFail(err));
		});
};

export const deleteProjectFeatureMap = (id, callback) => dispatch => {
	dispatch(deletePFMAttempt(id));
	axios.delete(`/domoreport/uploader/projectFeatureMap/${id}`)
		.then((res) => {
			dispatch(deletePFMSuccess());
			fetchAllProjectFeatureMaps(dispatch, store.getState().projectFeatureMap.page)
			callback()
		}).catch(err => {
			dispatch(deletePFMFail(err));
		});
};

export const verifyProjectFeatureMap = (id) => dispatch => {
	dispatch(verifyPFMAttempt(id));
	axios.put(`/domoreport/uploader/approveProjectFeatureMap/${id}`, {})
		.then((res) => {
			dispatch(verifyPFMSuccess());
			fetchOneProjectFeatureMap(dispatch, id);
			fetchAllProjectFeatureMaps(dispatch, store.getState().projectFeatureMap.page);
		}).catch(err => {
			dispatch(verifyPFMFail(err));
		});
}
import React, { Component } from 'react';
import { Modal, Button, Form, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import withUser from '../../hocs/withUser';
import { connect } from 'react-redux';
import { 
	IdNumberField, 
	VarcharTextField, 
	TextQuilField,
	IntDropdownField,
	ButtonField
} from '../../ui/FormFields'
import TradesTypeaheadField from '../../ui/FormFields/trades.typeahead'
import AreaFeatureDropdownField from '../../ui/FormFields/areaFeature.dropdown'
import {
	updatePFM,
	verifyProjectFeatureMap,
} from '../../actions'

const mapStateToProps = state => ({
	pfm: state.projectFeatureMap.currentPFM,
	disableVerifyButton: state.projectFeatureMap.disableVerifyButton
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, {
	updatePFM,
	verifyProjectFeatureMap
})
class ProjectFeatureMapForm extends Component {
	
	render(){
		const actionOptions = [
			{ feature_action: "Maintain", id: 1 },
			{ feature_action: "Repair", id: 2 },
			{ feature_action: "Replace", id: 3 },
		]
		
		let elementIndex = 0
		const obj= this.props.pfm
		return (
			<Container>
				<IdNumberField
					formName={"id"}
					fieldKey={"id"}
					obj={obj}
					keyAsIndex={elementIndex++}
					keysToIgnore={{"id":"id"}}
				/>
				<VarcharTextField 
					formName={"estimate on uuid"}
					fieldKey={"uuid"}
					// keysToIgnore={keysToIgnore}
					obj={obj}
					keyAsIndex={elementIndex++}
					onChange={this.props.updatePFM}
				/>
				<VarcharTextField 
					formName={"Job name"}
					fieldKey={"name"}
					// keysToIgnore={keysToIgnore}
					obj={obj}
					keyAsIndex={elementIndex++}
					onChange={this.props.updatePFM}
				/>
				<TextQuilField
					formName={`Job description`}
					fieldKey={`description`}
					// keysToIgnore={keysToIgnore}
					obj={obj}
					keyAsIndex={elementIndex++}
					onChange={this.props.updatePFM}
				/>
				<VarcharTextField 
					formName={"Unit Of Meassure"}
					fieldKey={"unitOfMeassure"}
					// keysToIgnore={keysToIgnore}
					obj={obj}
					keyAsIndex={elementIndex++}
					onChange={this.props.updatePFM}
				/>
				<AreaFeatureDropdownField 
					value={obj.feature_ref_id}
					onChange={this.props.updatePFM}
				/>
				<TradesTypeaheadField
					// keysToIgnore={keysToIgnore}
					value={obj.trade_id}
					keyAsIndex={elementIndex++}
					onChange={this.props.updatePFM}
				/>
				<IntDropdownField 
					formName={"Action type"}
					fieldKey={"feature_action_ref_id"}
					// keysToIgnore={keysToIgnore}
					obj={obj}
					keyAsIndex={elementIndex++}
					options={actionOptions}
					displayKey="feature_action"
					valueKey="id"
					onChange={this.props.updatePFM}
				/>
				{ obj.adminVerifiedAt && <VarcharTextField 
					formName={"Date verified"}
					fieldKey={"adminVerifiedAt"}
					keysToIgnore={{"adminVerifiedAt":"adminVerifiedAt"}}
					obj={obj}
					keyAsIndex={elementIndex++}
					onChange={this.props.updatePFM}
					disabled={true}
				/> }
				{ !obj.adminVerifiedAt && obj.id &&
					<ButtonField 
						formName={"Date verified"}
						fieldKey={"adminVerifiedAt"}
						keysToIgnore={{"adminVerifiedAt":"adminVerifiedAt"}}
						keyAsIndex={elementIndex++}	
						onClick={() => { this.props.verifyProjectFeatureMap(obj.id) } }
						label={"Veirfy this project has been completed"}
						disabled={this.props.disableVerifyButton}
					/> 
				}
			</Container>
		)
	}
}
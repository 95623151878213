import axios from '../services/axios';
import { store } from '../ProviderApp';

/*=========== action names ===========*/

export const GET_EXPLORE_CONTENT_ATTEMPT = 'GET_EXPLORE_CONTENT_ATTEMPT';
export const GET_EXPLORE_CONTENT_SUCCESS = 'GET_EXPLORE_CONTENT_SUCCESS';
export const GET_EXPLORE_CONTENT_FAIL = 'GET_EXPLORE_CONTENT_FAIL';
export const UPDATE_CONTENT_STATE = 'UPDATE_CONTENT_STATE';

/*=========== reducer actions ===========*/

export const getExploreContentAttempt = () => {
	return {
		type: GET_EXPLORE_CONTENT_ATTEMPT,
	};
};

export const getExploreContentSuccess = (content) => {
	return {
		type: GET_EXPLORE_CONTENT_SUCCESS,
		content: content
	};
};

export const getExploreContentFail = (err) => {
	return {
		type: GET_EXPLORE_CONTENT_FAIL,
		err: err
	};
};

export const updateContentState = (key, value) => {
	return {
		type: UPDATE_CONTENT_STATE,
		key: key,
		value: value,
	};
};


/*=========== actions ===========*/

const fetchExploreContent = (dispatch, id) => {
	dispatch(getExploreContentAttempt());
	axios.get(`/admin/explore/${id}`)
		.then((res) => {
			dispatch(getExploreContentSuccess(res.data));
		}).catch(err => {
			dispatch(getExploreContentFail(err));
		});
};

export const getExploreContent = (id) => dispatch => {
	fetchExploreContent(dispatch, id);
};
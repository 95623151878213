import axios from '../services/axios';
import { store } from '../ProviderApp';

/*=========== action names ===========*/
export const GET_ALL_INSPECTORS_ATTEMPT = 'GET_ALL_INSPECTORS_ATTEMPT';
export const GET_ALL_INSPECTORS_SUCCESS = 'GET_ALL_INSPECTORS_SUCCESS';
export const GET_ALL_INSPECTORS_FAIL = 'GET_ALL_INSPECTORS_FAIL';

/*=========== reducer actions ===========*/
export const getAllInspectorsAttempt = () => {
	return {
		type: GET_ALL_INSPECTORS_ATTEMPT,
	};
};

export const getAllInspectorsSuccess = (inspectors) => {
	return {
		type: GET_ALL_INSPECTORS_SUCCESS,
		inspectors: inspectors
	};
};
export const getAllInspectorsFail = (err) => {
	return {
		type: GET_ALL_INSPECTORS_FAIL,
		err: err
	};
};

/*=========== actions ===========*/
const fetchAllInspectors = (dispatch) => {
	dispatch(getAllInspectorsAttempt());
	axios.get(`/domoreport/uploader/allInspectors`)
		.then((res) => {
			dispatch(getAllInspectorsSuccess(res.data.inspectors));
		}).catch(err => {
			dispatch(getAllInspectorsFail(err));
		});
};

export const getAllInspectors = () => dispatch => {
	if (!store.getState().inspectors.inspectorsFetched && !store.getState().inspectors.inspectorsFetched) {
		fetchAllInspectors(dispatch);
	}
};

export const forceGetAllInspectors = () => dispatch => {
	fetchAllInspectors(dispatch);
};
import {
	GET_PROPERTY_ATTEMPT,
	GET_PROPERTY_FAIL,
	GET_PROPERTY_SUCCESS,

	GET_WIZARD_FEATURES_ATTEMPT,
	GET_WIZARD_FEATURES_FAIL,
	GET_WIZARD_FEATURES_SUCCESS,

	PROPERTY_SORTED_BY_AREA,

	GET_AVAILABLE_FEATURES_ATTEMPT,
	GET_AVAILABLE_FEATURES_SUCCESS,
	GET_AVAILABLE_FEATURES_FAIL,

	TOGGLE_CREATE_FEATURE_MODAL,
	UPDATE_NEW_FEATURE,
	UPDATE_CREATED_FEATURE,
	SET_CURRENT_PROPERTY,

} from '../actions';
import { stat } from 'fs';

const initialState = {
	property: {
		sortedFeatures: [],
		features: [],
		property_log_id: 0,
		fetching: false,
		fetched: false
	},
	wizardFeatures: {
		fetching: false,
		fetched: false
	},
	availableFeatures: {
		sections: [],
		availableFeaturesByArea: {},
		fetching: false,
		fetched: false,
	},
	createFeatureModal: {
		open: false,
		area_ref_id: null,
		area_id: null,
	},
	createdFeature: {},
	newFeature: {},
	sortedRooms: [],
	error: null,
};

export function properties(state = initialState, action) {
	switch (action.type) {
		case GET_PROPERTY_ATTEMPT:
			return {
				...state,
				property: {
					...state.property,
					fetching: true,
				}
			}
		case GET_PROPERTY_SUCCESS:
			return {
				...state,
				property: {
					...state.property,
					...action.property,
					sortedFeatures: action.sortedFeatures,
					fetching: false,
					fetched: true,
				},
			}
		case GET_PROPERTY_FAIL:
			return {
				...state,
				property: {
					...state.property,
					fetching: false,
					fetched: false,
				},
				error: action.err,
			}
		case GET_WIZARD_FEATURES_ATTEMPT:
			return {
				...state,
				wizardFeatures: {
					...state.wizardFeatures,
					fetching: true,
				}
			};
		case GET_WIZARD_FEATURES_SUCCESS:
			return {
				...state,
				wizardFeatures: {
					...state.wizardFeatures,
					...action.wizardFeatures,
					fetching: false,
					fetched: true,
				}
			};
		case GET_WIZARD_FEATURES_FAIL:
			return {
				...state,
				wizardFeatures: {
					...state.wizardFeatures,
					fetching: false,
					fetched: false,
				},
				error: action.err,
			};
		case PROPERTY_SORTED_BY_AREA:
			return {
				...state,
				sortedRooms: action.sortedRooms
			};
		case GET_AVAILABLE_FEATURES_ATTEMPT:
			return {
				...state,
				availableFeatures: {
					...state.availableFeatures,
					fetching: true,
				}
			};
		case GET_AVAILABLE_FEATURES_SUCCESS:
			return {
				...state,
				availableFeatures: {
					...state.availableFeatures,
					sections: action.availableFeatures,
					availableFeaturesByArea: action.availableFeaturesByArea,
					fetching: false,
					fetched: true,
				}
			};
		case GET_AVAILABLE_FEATURES_FAIL:
			return {
				...state,
				availableFeatures: {
					...state.availableFeatures,
					fetching: false,
					fetched: false,
				},
				error: action.err
			};
		case TOGGLE_CREATE_FEATURE_MODAL:
			const tempModal = {
				open: !!action.state,
				area_ref_id: action.area_ref_id || state.createFeatureModal.area_ref_id,
				area_id: action.area_id || state.createFeatureModal.area_id,
			};
			return {
				...state,
				createFeatureModal: {
					...tempModal,
				},
				newFeature: {},
			}
		case UPDATE_NEW_FEATURE:
			return {
				...state,
				newFeature: {
					...state.newFeature,
					[action.key]: action.value,
				}
			}
		case UPDATE_CREATED_FEATURE:
			return {
				...state,
				createdFeature: {
					[action.key]: action.value
				},
			}
		default:
			return {
				...state,
			};
	}
}
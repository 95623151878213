import React, { Component } from 'react';
import { Container, Button, Table } from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import CreateModal from '../../ui/CreateModal/modal';
import { _returnFields } from '../../ui/FormByKey/featureRefFields';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import { connect } from 'react-redux';
import {
    getAvailableFeatures,
    getAllFeatureRefs,
    createFeatureRef,
    sortFeaturesBy,
} from '../../actions'

export const keysToIgnore = {
    "feature_ref_id": "feature_ref_id",
    "score_0_description": "score_0_description",
    "score_5_details": "score_5_details",
    "score_4_details": "score_4_details",
    "score_3_details": "score_3_details",
    "score_2_details": "score_2_details",
    "score_1_details": "score_1_details",
    "score_0_details": "score_0_details",
};

export const fieldsHash = {
    "feature_ref_id": "feature_ref_id",
    "feature_name": "feature_name",
    "area_ref_id": "area_ref_id",
    "feature_importance": "feature_importance",
    "feature_life": "feature_life",
    "feature_priority": "feature_priority",
    "feature_preselect": "feature_preselect",
    "score_5_description": "score_5_description",
    "score_4_description": "score_4_description",
    "score_3_description": "score_3_description",
    "score_2_description": "score_2_description",
    "score_1_description": "score_1_description",
    "score_0_description": "score_0_description",
    "score_5_details": "score_5_details",
    "score_4_details": "score_4_details",
    "score_3_details": "score_3_details",
    "score_2_details": "score_2_details",
    "score_1_details": "score_1_details",
    "score_0_details": "score_0_details",
    "maintain_trade_id": "maintain_trade_id",
    "repair_trade_id": "repair_trade_id",
    "replace_trade_id": "replace_trade_id",
};

const mapStateToProps = state => ({
    sortedFeatures: state.properties.availableFeatures.sections,
    features: state.featureRef.allFeatureRefs,
    lockAllFeatureRefs: state.featureRef.lockAllFeatureRefs,
    sort: state.featureRef.currentSort.sort,
    direction: state.featureRef.currentSort.direction,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, {
    getAvailableFeatures,
    getAllFeatureRefs,
    createFeatureRef,
    sortFeaturesBy,
})
class FeatureRefComponent extends Component {

    state = {
        createModalOpen: false,
        newFeature: {},
        toNewFeature: false,
        newFeatureId: 0,
    }

    componentDidMount() {
        this.props.getAllFeatureRefs()
        this.props.getAvailableFeatures()
    }

    _toggleModal = () => {
        const { createModalOpen } = this.state;
        this.setState({ createModalOpen: !createModalOpen });
    }

    _handleChange = (key, value) => {
        //console.log("Key:",key, "Value:",value)
        let { newFeature } = this.state;
        newFeature[key] = value;
        this.setState({ newFeature }, () => {
            console.log(this.state.newFeature);
        });
    }

    _createNewFeature = () => {
        const { newFeature } = this.state;
        if (newFeature) {
            this.props.createFeatureRef({ ...newFeature }, (success, newFeatureId) => {
                if (success) {
                    this._toggleModal()
                    this.setState({ toNewFeature: true, newFeatureId: newFeatureId }, () => {
                        this.setState({ toNewFeature: false })
                    })
                }
            });
        }
    }

    _createEmptyValues = (fields) => {
        let emptyValues = {}
        for (let i = 0; i < fields.length; i++) {
            emptyValues[fields[i].Field] = null
            if (fields[i].Field === "feature_preselect") {
                emptyValues[fields[i].Field] = false
            }
        }
        return emptyValues;
    }

    _renderFeatureRows = (features) => {
        const { history } = this.props;
        return features.map(feature => {
            return (
                <tr key={feature.feature_ref_id} onClick={() => history.push(`/featureRef/${feature.feature_ref_id}`)}>
                    <td>{feature.feature_ref_id}</td>
                    <td>{feature.feature_name}</td>
                    <td>{feature.feature_life}</td>
                    <td>{feature.feature_importance}</td>
                    <td>{feature.area_name}</td>
                </tr>
            )
        })
    }

    _sortBy = (column) => {
        this.props.sortFeaturesBy(column)
    }

    _returnCaret = (direction) => {
        return direction === "asc" ? <span>&#9660;</span> : <span>&#9650;</span>
    }

    _sortAreasForDropdown = (secArr) => {
        let dropdown = [];
        for (let i = 0; i < secArr.length; i++) {
            dropdown.push({
                area_name: `-----${secArr[i].section_name}-----`,
                area_ref_id: -1,
                disabled: true,
            })
            for (let j = 0; j < secArr[i].areas.length; j++) {
                dropdown.push({
                    area_name: secArr[i].areas[j].area_name,
                    area_ref_id: secArr[i].areas[j].area_ref_id,
                    disabled: false,
                })
            }
        }

        return dropdown
    }


    render() {
        const { createModalOpen, inspectorSubmitting } = this.state;

        let { features } = this.props;


        let dropdownOptions = this._sortAreasForDropdown(this.props.sortedFeatures);

        let fields = []
        for (let key in fieldsHash) {
            if (fieldsHash.hasOwnProperty(key))
                fields.push({ Field: key });
        }
        let emptyValues = this._createEmptyValues(fields);

        const filter = this.props.sort
        let headerType = ''
        let filterName = 'area'

        const compare = (a, b) => {
            let sortA = ''
            let sortB = ''

            if (filter === "ID") {
                sortA = a.feature_ref_id;
                sortB = b.feature_ref_id;
                headerType = "feature_ref_id";
                filterName = "ID";
            } else if (filter === "Name") {
                sortA = a.feature_name;
                sortB = b.feature_name;
                headerType = 'feature_name';
                filterName = "Contractor Type"
            } else if (filter === "Feature_Life") {
                sortA = a.feature_life;
                sortB = b.feature_life;
                headerType = 'feature_life';
                filterName = "Cost Estimate";
            } else if (filter === "Feature_Importance") {
                sortA = b.feature_importance
                sortB = a.feature_importance
                headerType = "feature_importance"
                filterName = "Priority"
            } else {
                sortA = a.area_name;
                sortB = b.area_name;
                headerType = "area_name";
                filterName = "Area";
            }

            let comparison = 0;
            if (sortA > sortB) {
                comparison = 1;
            } else if (sortA < sortB) {
                comparison = -1;
            }
            if (this.props.direction === "asc") {
                comparison = comparison * -1
            }
            return comparison;
        };

        features = features.sort(compare)

        return (
            <>
                <div className="md-scroll-wrapper">
                    {(this.props.lockAllFeatureRefs)
                        && <div style={
                            {
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                                zIndex: 999,
                                position: "fixed",
                                left: 0,
                                top: 0,
                                width: "100vw",
                                height: "100vh",
                            }
                        }></div>}
                    <Container>
                        <h2>Features</h2>
                        <Button onClick={this._toggleModal} >Create a New Feature</Button>
                        {createModalOpen && <CreateModal
                            submitting={inspectorSubmitting}
                            keys={fields}
                            close={this._toggleModal}
                            onChange={this._handleChange}
                            boolChange={this._handleChange}
                            submit={this._createNewFeature}
                            headerTitle={'Create a new Feature Ref'}
                            returnFields={_returnFields}
                            dropdownValues={{ ...emptyValues, areaOptions: dropdownOptions }}
                            state={this.state.newFeature}
                            keysToIgnore={keysToIgnore}
                            obj={{ hidefields: true }}
                        />}
                        {
                            this.state.toNewFeature && <Redirect to={`/featureRef/${this.state.newFeatureId}`} />
                        }
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th onClick={() => this._sortBy('ID')}> ID {this.props.sort === "ID" ? this._returnCaret(this.props.direction) : null}</th>
                                    <th onClick={() => this._sortBy('Name')}> Name {this.props.sort === "Name" ? this._returnCaret(this.props.direction) : null}</th>
                                    <th onClick={() => this._sortBy('Feature_Life')}> Feature Life {this.props.sort === "Feature_Life" ? this._returnCaret(this.props.direction) : null}</th>
                                    <th onClick={() => this._sortBy('Feature_Importance')}> Feature Importance {this.props.sort === "Feature_Importance" ? this._returnCaret(this.props.direction) : null}</th>
                                    <th onClick={() => this._sortBy('Area')}> Area {this.props.sort === "Area" ? this._returnCaret(this.props.direction) : null}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {features.length > 0 && this._renderFeatureRows(features)}
                            </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        )
    }
}
import './index.scss';
import React, { Component } from 'react';
import withFetch from '../../hocs/withFetch';
import { Link } from 'react-router-dom';
import { Alert, Modal, ModalTitle } from 'react-bootstrap';
import '../SharingModal/index';

export default 
@withFetch((props) => ({
    sharingData: {
        url: `/domoreport/${props.reportID}/permissions`,
        headers: {
            authorization: props.token
        },
        force: true,
        refresh: true,
    },
    addSharing: (email, permission) => ({
        addedSharing: {
            method: 'POST',
            url: `/domoreport/${props.reportID}/permissions`,
            headers: {
                authorization: props.token
            },
            force: true,
            refresh: true,
            body: JSON.stringify({
                user_email: email,
                permissionLevel: permission,
            }),
            andThen: res => ({
                sharingData: {
                    url: `/domoreport/${props.reportID}/permissions`,
                    headers: {
                        authorization: props.token
                    },
                    force: true,
                    refresh: true,
                },
            })
        }
    }),
    updateSharing: (userID, permission) => ({
        updatedSharing: {
            method: 'PUT',
            url: `/domoreport/${props.reportID}/permissions/${userID}`,
            headers: {
                authorization: props.token
            },
            force: true,
            refresh: true,
            body: JSON.stringify({ permissionLevel: permission }),
            andThen: res => ({
                sharingData: {
                    url: `/domoreport/${props.reportID}/permissions`,
                    force: true,
                    refresh: true,
                    headers: {
                        authorization: props.token
                    },
                },
            })
        }
    }),
    deleteSharing: (userID) => ({
        deletedSharing: {
            method: 'DELETE',
            url: `/domoreport/${props.reportID}/permissions/${userID}`,
            headers: {
                authorization: props.token
            },
            force: true,
            refresh: true,
            andThen: res => ({
                sharingData: {
                    url: `/domoreport/${props.reportID}/permissions`,
                    force: true,
                    refresh: true,
                    headers: {
                        authorization: props.token
                    },
                },
            })
        }
    })
}))
class SharingModal extends Component {
    state = {
        sharingPermissions: [],
        sharingInputs: [],
        sharingInputsIndex: 0,
        emailInputs: [],
        permissionsInputs: [],
        currentUsers: [],
        addedInputs: 0,
        showMessage: false,
        message: null,
        addedSharingMessage: null,
        updatedSharingMessage: null,
        variant: '',
    }

    addSharingInput = (e, sharingInputs, currentUsers) => {
        e.preventDefault();
        let index = this.state.sharingInputsIndex + sharingInputs.length

        //Add new email and permission input field
        if (e.currentTarget.className === 'btn-add-share') {
            sharingInputs.push(
                <div className="row md-manage-shares-wrapper" key={index}>
                    <div className="col">
                        <input
                            type="text"
                            id={`email_${index}`}
                            data-value={index}
                            placeholder="lance@gmail.com"
                            onChange={(evt) => {
                                this.handleEmailInputs(evt.target.value, evt.target.getAttribute('data-value'))
                            }}
                        />
                    </div>
                    <div className="col">
                        <select id="accessDropdown" data-value={index} onChange={(evt) => this.handlePermissionsInputs(evt.target.value, evt.target.getAttribute('data-value'))}>
                            <option value="owner">Owner</option>
                            <option value="contributor">Contributor</option>
                            <option value="viewer">Viewer</option>
                        </select>
                    </div>
                    <div className="col-2 actions-wrapper">
                        <Link
                            to="#"
                            className="btn-add"
                            onClick={(e) => { e.preventDefault(); this.handleSharingAdd(e.currentTarget.getAttribute('data-value')) }}
                            data-value={index}
                        >Add
                        </Link>
                        <Link
                            to="#"
                            className="btn-remove d-none d-md-block"
                            onClick={(e) => { this.removeSharingInput(e) }}
                            data-value={index}
                        >
                            Remove
                        </Link>
                    </div>
                </div>
            );

            this.setState({
                sharingInputs: sharingInputs,
                addedInputs: this.state.addedInputs + 1,
            })
        }
    }

    removeSharingInput = (e) => {
        e.preventDefault();

        if (e.currentTarget.className === 'btn-remove d-none d-md-block') {
            let sharingInputs = [...this.state.sharingInputs];
            let emailInputs = [...this.state.emailInputs];
            let permissionsInputs = [...this.state.permissionsInputs];

            let inputIndex = sharingInputs.map((input, index) => {
                if (input.key === e.currentTarget.getAttribute('data-value')) {
                    return index;
                } else {
                    return null;
                }
            })

            if (inputIndex) {
                let removeIndex = inputIndex.filter((index) => {
                    return index != null
                })
                sharingInputs.splice(removeIndex[0], 1);
                emailInputs[removeIndex[0]] = null;
                permissionsInputs[removeIndex[0]] = null;

                this.setState({
                    sharingInputs: sharingInputs,
                    emailInputs: emailInputs,
                    addedInputs: this.state.addedInputs - 1,
                })
            }
        }
    }

    // Updates the Email at the index specified
    handleEmailInputs = (email, index) => {
        let emailInputs = [...this.state.emailInputs];
        emailInputs[index] = email;

        this.setState({ emailInputs: emailInputs })
    }

    // Updates the Permission Level at the index specified (for new users only)
    handlePermissionsInputs = (permission, index) => {
        let permissionsInputs = [...this.state.permissionsInputs];
        permissionsInputs[index] = permission;

        this.setState({ permissionsInputs: permissionsInputs })
    }

    // Updates the Permission Level for the user specified (for existing users only)
    changeSharingPermissions = (userID, permission, index) => {
        let sharingValues = [...this.state.sharingPermissions];
        sharingValues[index] = permission;

        this.props.updateSharing(userID, permission.toLowerCase())
    }

    // POSTs new user to database with the specified permission level.  
    handleSharingAdd = (userIndex) => {
        let emailInputs = [...this.state.emailInputs];
        let permissionsInputs = [...this.state.permissionsInputs];

        if (this.state.emailInputs.length) {
            this.state.emailInputs.map((email, index) => {
                if (email && index === userIndex) {
                    if (this.state.permissionsInputs[index] === null) {
                        this.props.addSharing(email, 'owner')
                    } else {
                        this.props.addSharing(email, this.state.permissionsInputs[index])
                    }

                    emailInputs[index] = null;
                    permissionsInputs[index] = null;
                    this.setState({
                        emailInputs: emailInputs,
                        permissionsInputs: permissionsInputs,
                    })
                }
            })
        } else {
            this.setState({
                variant: 'danger',
                message: 'Please enter a valid email address',
                showMessage: true
            })
        }
    }

    // DELETEs specified user (with permission level) from database. 
    handleSharingDelete = (userID, currentUsers, owners, permission) => {
        if (permission != 'OWNER' || owners.length > 1) {
            let inputs = [...this.state.sharingInputs];
            let removeUser = currentUsers.map((user, index) => {
                if (userID === user.user_unique_id) {
                    return index;
                } else {
                    return null;
                }
            })

            if (removeUser) {
                let removeIndex = removeUser.filter((index) => {
                    return index != null;
                })

                inputs.splice(removeIndex[0], 1);
                currentUsers.splice(removeIndex[0], 1);

                this.props.deleteSharing(userID);
                this.setState({
                    currentUsers: currentUsers,
                    sharingInputs: inputs,
                    variant: 'warning',
                    message: 'User removed',
                    showMessage: true,
                });
            }
        } else {
            this.setState({
                variant: 'warning',
                message: 'There must be at least one owner',
                showMessage: true,
            });
        }
    }

    setMessage = (variant, message) => {
        this.setState({
            variant: variant,
            message: message,
            showMessage: true,
        })
    }

    componentWillReceiveProps() {
        const { addedSharing, updatedSharing } = this.props;
        const { addedSharingMessage, updatedSharingMessage } = this.state;

        /*
            If a new user has been POSTed and the response
            does not equal the current message, set the
            current message to the success/error response.
        */
        if (addedSharing && !addedSharing.pending && addedSharing.value) {
            if (addedSharing.value.success && addedSharingMessage != addedSharing.value.success) {
                this.setMessage('success', addedSharing.value.success);
                this.setState({ addedSharingMessage: addedSharing.value.success });
            } else if (addedSharing.value.error && addedSharingMessage != addedSharing.value.error) {
                this.setMessage('danger', addedSharing.value.error);
                this.setState({ addedSharingMessage: addedSharing.value.error });
            }
        }

        /*
            If a user has been updated and the response
            does not equal the current message, set the
            current message to the success/error response.
        */
        if (updatedSharing && !updatedSharing.pending && updatedSharing.value) {
            if (updatedSharing.value.success && updatedSharingMessage != updatedSharing.value.success) {
                this.setMessage('success', updatedSharing.value.success);
                this.setState({ updatedSharingMessage: updatedSharing.value.success });
            } else if (updatedSharing.value.error && updatedSharingMessage != updatedSharing.value.error) {
                this.setMessage('danger', updatedSharing.value.error);
                this.setState({ updatedSharingMessage: updatedSharing.value.error });
            }
        }
    }

    render() {
        const sharingInputs = [...this.state.sharingInputs];
        const { value: sharingData } = this.props.sharingData;

        return (this._renderAvailable(sharingInputs, sharingData))
    }

    _renderAvailable = (sharingInputs, sharingData) => {
        let owners = [];

        if (!this.props.sharingData.pending) {
            // Return list of all users with 'OWNER' status.
            let owners = sharingData.viewers.filter((user) => {
                return user.permissions === 'OWNER'
            });

            //Render list of users who currently have access (regardless of permission level)
            sharingData.viewers.map((user, index) => {
                if (user) {
                    sharingInputs[index] =
                        <div className="row md-manage-shares-wrapper" key={user.user_unique_id}>
                            <div className="col-2">
                                {index === 0 && <label>User ID</label>}
                                <p><Link to={`/customer/${user.user_log_id}`}>{user.user_log_id}</Link></p>
                            </div>
                            <div className="col">
                                {index === 0 && <label>Email</label>}
                                <p>{user.display_email}</p>
                            </div>
                            <div className="col">
                                {index === 0 && <label htmlFor="access">Access</label>}
                                <select id="accessDropdown"
                                    defaultValue={this.state.sharingPermissions[index] ? this.state.sharingPermissions[index] : user.permissions}
                                    onChange={(e) => this.changeSharingPermissions(user.user_unique_id, e.target.value, index, owners)}
                                    disabled={(user.permissions === 'OWNER' && owners.length <= 1) ? true : false}
                                >
                                    <option value="OWNER">Owner</option>
                                    <option value="CONTRIBUTOR">Contributor</option>
                                    <option value="VIEWER">Viewer</option>
                                </select>
                            </div>
                            <div className="col-2 col-md-2 actions-wrapper">
                                {sharingData.viewers.length <= 1 || (user.permissions === 'OWNER' && owners.length <= 1) ?
                                    ''
                                    :
                                    <Link
                                        to="#"
                                        className="btn-remove"
                                        data-value={index}
                                        onClick={(e) => { e.preventDefault(); this.handleSharingDelete(user.user_unique_id, sharingData.viewers, owners, user.permissions) }}
                                    >
                                        Remove
                                    </Link>
                                }
                            </div>
                        </div>
                }
            })
        }

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={this.props.hideModal}
                className="md-sharing-modal"
            >
                <Modal.Header closeButton>
                    <ModalTitle>Sharing</ModalTitle>
                </Modal.Header>

                <Modal.Body>
                    {/* Render success/error messages */}
                    {this.state.showMessage && this._renderAlert(this.state.variant, this.state.message)}

                    <p className="modal-address">{this.props.address}</p>
                    <p className="">This Domoreport has been shared with {sharingInputs.length} users.</p>
                    {!this.props.sharingData.pending && sharingInputs}

                    {!this.props.sharingData.pending &&
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-12">
                                <Link to="#" className="btn-add-share" onClick={(e) => { this.addSharingInput(e, sharingInputs, sharingData.viewers) }}>+ Add Another Share</Link>
                            </div>
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-tertiary" onClick={() => this.props.hideModal()}>Done</button>
                </Modal.Footer>
            </Modal>
        )
    }

    _renderAlert(variant, message) {
        return <Alert variant={variant} onClose={() => { this.setState({ showMessage: false }) }} dismissible><p>{message}</p></Alert>
    }
}

import './index.scss';
import React, { Component } from 'react';
import { Button, Container, Form, Alert } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import moment from 'moment';

export default
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        createCoupon: (data) => ({
            createdCoupon: {
                url: `/domoreport/uploader/coupon/`,
                method: 'POST',
                headers: {
                    authorization: props.user.token,
                },
                body: JSON.stringify(data),
            }
        }),
    }) : null;
})

class CreateCouponComponent extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        body: null,
        coupon_code_string: null,
        discount_total: null,
        coupon_description: null,
        start_date: null,
        end_date: null,
        multi_use_per_user_with_end_date: null,
        max_number_of_uses_per_customer: null,
        error: null,
    }

    render() {
        return this._renderAvailableState();
    }

    _renderAvailableState = () => {
        return (
            <div className="md-scroll-wrapper">
                <Container className="md-create-coupon-page">
                    <Container>
                        <Link className="mt-3" to="/coupons">Go Back</Link>

                        {this.state.error && this._renderAlert('danger', this.state.error)}

                        <h2 className="mt-3">Create Coupon</h2>
                        <hr width="100%" />

                        <h4>Coupon Info</h4>
                        <div className="row">
                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>Coupon Code</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('coupon_code_string', e.target.value)} className="md-coupon-code-input" />
                            </Form.Group>

                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>Coupon Discount</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('discount_total', e.target.value)} />
                            </Form.Group>

                            <Form.Group className={"col-12 mx-auto"}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('coupon_description', e.target.value)} />
                            </Form.Group>

                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('start_date', e.target.value)} placeholder="MM/DD/YYYY" />
                            </Form.Group>

                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('end_date', e.target.value)} placeholder="MM/DD/YYYY" />
                            </Form.Group>

                            <Form.Group className={"col-12 mx-auto"}>
                                <Form.Check
                                    type="checkbox"
                                    className="mx-2"
                                    onChange={(e) => this._handleFormChange('multi_use_per_user_with_end_date', !this.state.multi_use_per_user_with_end_date)}
                                    checked={this.state.multi_use_per_user_with_end_date}
                                    label="Allow multiple uses per customer"
                                />
                            </Form.Group>

                            {this.state.multi_use_per_user_with_end_date ?
                                <Form.Group className={"col-12 mx-auto"}>
                                    <Form.Label># of uses per customer</Form.Label>
                                    <Form.Control type="text" onChange={(e) => this._handleFormChange('max_number_of_uses_per_customer', e.target.value)} />
                                </Form.Group>
                                :
                                <div></div>
                            }

                            <div className="d-flex col">
                                <Button
                                    variant="primary"
                                    size="md"
                                    block
                                    style={{ marginTop: 20 }}
                                    onClick={this._createContent}
                                    className="col-2 mx-1"
                                > Update
                            </Button>
                                <Button
                                    variant="secondary"
                                    size="md"
                                    block
                                    style={{ marginTop: 20 }}
                                    onClick={this._goBack}
                                    className="col-2 mx-1"
                                > Cancel
                            </Button>
                            </div>
                        </div>
                    </Container>
                </Container>
            </div>
        )
    }

    _createContent = () => {
        let { coupon_code_string, discount_total, coupon_description, start_date, end_date, multi_use_per_user_with_end_date, max_number_of_uses_per_customer, } = this.state;
        let start = new Date(start_date);
        let end = new Date(end_date);
        let data = {}

        if ((coupon_code_string, discount_total, start_date, end_date) && start < end) {
            data = {
                coupon_code_string: coupon_code_string.toUpperCase(),
                discount_total: (discount_total.replace('$', '') * 100),
                coupon_description: coupon_description,
                start_date: start.toISOString(),
                end_date: end.toISOString(),
                one_use_per_user_with_end_date: !multi_use_per_user_with_end_date,
                multi_use_per_user_with_end_date: multi_use_per_user_with_end_date || false,
                max_number_of_uses_per_customer: max_number_of_uses_per_customer || 0,
            }

            this._callAlert('create', () => this.props.createCoupon(data));
            this.setState({
                multi_use_per_user_with_end_date: null,
            });
            this._goBack();
        } else {
            this.setState({
                error: 'Coupon code, discount, start date and end date are required',
            })
        }
    }

    _handleFormChange(key, value) {
        this.setState({ [key]: this._validateValue(value) });
    }

    _validateValue = (value) => {
        if (value === '' || value === ' ' || value === 0) {
            return null
        } else {
            return value
        }
    }

    _callAlert = (type, cb) => {
        if (type === "create") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Do you want to create this content?')
            if (res === true) {
                cb()
            }
        }

        if (type === "update") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Are you sure you want to update this content?')
            if (res === true) {
                cb()
            }
        }

        if (type === "delete") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Are you sure you want to delete this content?')
            if (res === true) {
                cb()
            }
        }
    }

    _renderAlert(variant, message) {
        return <Alert variant={variant} onClose={() => { this.setState({ showMessage: false }) }} className="md-alert"><p>{message}</p></Alert>
    }

    _goBack = () => {
        this.props.history.push('/coupons');
    }
}
import React, { Component } from "react";
import { Form, Container, Col, Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import withFetch from "../../hocs/withFetch";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ProjectQualityCard from "../ProjectQualityCard";
import preloader from "../../preloader.gif";
import axios from "../../services/axios";
import {
  getJobList,
  updateNewFeature,
  getFeatureRef,
  getUnitTypes,
} from "../../actions";

const unitOfMeasureMap = require("../../ui/FormFields/unit-of-measure-mappings.json");
const mapStateToProps = (state) => ({
  jobList: state.projects.jobList,
  jobListFetching: state.projects.jobListFetching,
  unitTypes: state.projects.unitTypesMemo,
});
const repairwiseDBKeyMap = {
  description: "cache_job_details",
  name: "xactware_quality",
  lowTotalPrice: "cache_project_cost_low",
  highTotalPrice: "cache_project_cost_high",
  lowMaterialPrice: "cache_material_cost_low",
  highMaterialPrice: "cache_material_cost_high",
  lowLaborPrice: "cache_labor_cost_low",
  highLaborPrice: "cache_labor_cost_high",
  lowEquipmentPrice: "cache_equipment_cost_low",
  highEquipmentPrice: "cache_equipment_cost_high",
  lowLaborMinimumPrice: "cache_licensed_contractor_cost_low",
  highLaborMinimumPrice: "cache_licensed_contractor_cost_high",
  highMarketPrice: "high_market_price",
  lowMarketPrice: "low_market_price",
};

export default
@connect(mapStateToProps, {
  getJobList,
  updateNewFeature,
  getFeatureRef,
  getUnitTypes,
})
@withFetch((props) => ({
  getJobEstimate: (job_id) => ({
    jobEstimate: {
      url: `/domoreport/uploader/estimateForProperty/${props.property.property_log_id}/job/${job_id}`,
      force: true,
      refresh: true,
      then: (res) => {
        if (res) {
          const planningText = "This estimate is for planning purposes. ";
          props.handleChange("image", res.image);
          props.updateProject("repair_wise_job_id", job_id);
          props.updateProject("repair_wise_estimate_id", res.id);
          props.updateProject("xactware_project_id", res.projectId);

          if (!props.project.xactware_quantity) {
            props.updateProject("xactware_quantity", res.quantity);
          }

          if (props.project.xactware_quality) {
            const qualityLevel = res.qualityLevels.find((level) => {
              return level.name === props.project.xactware_quality;
            });

            for (let key in qualityLevel) {
              if (qualityLevel.hasOwnProperty(key)) {
                if (key === "description") {
                  if (
                    props.project &&
                    props.project.job_details_override &&
                    props.project.job_details_override.includes(planningText)
                  ) {
                    props.updateProject(
                      "job_details_override",
                      `${planningText}${res.description} - ${qualityLevel[key]}`
                    );
                  } else {
                    props.updateProject("job_details_override", null);
                  }

                  props.updateProject(
                    "cache_job_details",
                    `${res.description} - ${qualityLevel[key]}`
                  );
                } else {
                  props.updateProject(
                    repairwiseDBKeyMap[key],
                    qualityLevel[key]
                  );
                }
              } else {
                if (
                  props.project &&
                  props.project.job_details_override &&
                  props.project.job_details_override.includes(planningText)
                ) {
                  props.updateProject(
                    "job_details_override",
                    `${planningText}${res.description}`
                  );
                } else {
                  props.updateProject("job_details_override", null);
                }

                props.updateProject("cache_job_details", `${res.description}`);
              }
            }

            const unitType = res.unitOfMeasure.slice(-2);
            if (props.unitTypes) {
              if (unitType in props.unitTypes) {
                props.updateProject(
                  "cache_unit_type_ref_id",
                  props.unitTypes[unitType].unit_type_ref_id
                );
              } else {
                this.props.updateProject("cache_unit_type_ref_id", 35);
              }
            }
          }
        }
      },
    },
  }),
  getOneProjectFeatureMap: (job_id) => ({
    projectFeatureMap: {
      url: `/domoreport/uploader/projectFeatureMap/${job_id}`,
      force: true,
      refresh: true,
      then: (res) => {
        if (res) {
          // Get property_fact_id if it exists
          let featureID = null;
          props.sortedRooms.map((section) => {
            section.rooms.map((area) => {
              area.features.map((feature) => {
                if (feature.feature_ref_id === res.feature_ref_id) {
                  featureID = feature.property_fact_id;
                }
              });
            });
          });

          if (featureID) {
            props.updateProject("property_fact_id", featureID);
          } else {
            props.updateProject("property_fact_id", "");
          }

          props.updateProject("trade_id", res.trade_id);
          props.updateProject("feature_id", res.feature_ref_id);
          props.updateProject(
            "project_priority_ref_id",
            res.project_priority_ref_id
          );

          props.updateNewFeature("feature_ref_id", res.feature_ref_id);
          props.updateNewFeature("feature_condition", 3);
          props.updateNewFeature("feature_age", -1);

          // Get FeatureRef for create/edit project
          props.getFeatureRef(res.feature_ref_id);
        }
      },
    },
  }),
  updateJobEstimate: (estimate_id, job_id, quantity) => ({
    updatedJobEstimate: {
      url: `/domoreport/uploader/estimateForProperty/${props.property.property_log_id}/estimate/${estimate_id}`,
      method: "PUT",
      force: true,
      refresh: true,
      body: JSON.stringify({ quantity: quantity }),
      andThen: (response) => ({
        jobEstimate: {
          url: `/domoreport/uploader/estimateForProperty/${props.property.property_log_id}/job/${job_id}`,
          force: true,
          refresh: true,
          then: (res) => {
            if (res) {
              props.handleChange("image", res.image);
              props.updateProject("xactware_quantity", res.quantity);
              props.updateProject("repair_wise_job_id", res.jobId);
              props.updateProject("xactware_project_id", res.projectId);
            }
          },
        },
      }),
    },
  }),
}))
class DiyRepairTypeaheadField extends Component {
  constructor(props) {
    super(props);
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
    this._onQuantityChange = this._onQuantityChange.bind(this);
    this._getNewEstimate = this._getNewEstimate.bind(this);
  }

  state = {
    quantity: null,
    useDefaultCosts: false,
    showPredictions: true,
    showImage: false,
  };

  componentDidMount() {
    this.props.getUnitTypes();
  }

  componentDidUpdate(prevProps) {
    const { defaultValue, jobEstimate, project, src, editUpdates } = this.props;

    if (project && project.repair_wise_job_id && !jobEstimate) {
      this.props.getJobEstimate(project.repair_wise_job_id);

      if (src !== "edit" || editUpdates) {
        this.props.getOneProjectFeatureMap(defaultValue.repair_wise_job_id);
      } else {
        this.props.handleChange("edit_updates", true);
      }
    }

    if (
      ((!project.cache_job_details && jobEstimate) ||
        (project.cache_job_details &&
          prevProps.jobEstimate &&
          !!prevProps.jobEstimate.pending &&
          prevProps.jobEstimate.value &&
          prevProps.jobEstimate !== jobEstimate)) &&
      !jobEstimate.pending &&
      jobEstimate.value
    ) {
      this.props.updateProject(
        "cache_job_details",
        jobEstimate.value.description
      );
    }

    if (
      prevProps.project &&
      prevProps.project.repair_wise_job_id !== project.repair_wise_job_id
    ) {
      this.props.getJobEstimate(project.repair_wise_job_id);
    }
  }

  toggleDefaultCosts = () => {
    this.setState({ useDefaultCosts: !this.state.useDefaultCosts });
  };

  togglePredictions = () => {
    this.setState({ showPredictions: !this.state.showPredictions });
  };

  toggleShowImage = () => {
    this.setState({ showImage: !this.state.showImage });
  };

  handleOnInputChange(e) {
    const { project } = this.props;

    if (project.cache_project_name) {
      this.setState({ useDefaultCosts: true });
    }

    if (e.length) {
      this.setState({ job_id: e[0]["id"] });
    }

    this.props.updateProject("job_details_override", null);
    this.props.updateProject("xactware_quality", null);
    this.props.updateProject("xactware_quantity", null);
    this.props.updateProject(
      "cache_project_name",
      e.length > 0 ? e[0]["name"] : null
    );
    this.props.getJobEstimate(e.length > 0 ? e[0]["id"] : null);
    this.props.getOneProjectFeatureMap(e.length > 0 ? e[0]["id"] : null);
  }

  handlePrediction = async (prediction) => {
    const { project, property } = this.props;
    this.props.handleChange("loading", true);

    try {
      const { status } = await axios.get(
        `/domoreport/uploader/estimateForProperty/${property.property_log_id}/job/${prediction.id}`
      );

      if (status === 200) {
        if (project.cache_project_name) {
          this.setState({ useDefaultCosts: true });
        }

        if (prediction.id) {
          this.setState({ job_id: prediction.id });

          let keys = [
            "cache_material_cost_high",
            "cache_material_cost_low",
            "cache_labor_cost_high",
            "cache_labor_cost_low",
            "cache_equipment_cost_high",
            "cache_equipment_cost_low",
            "high_market_price",
            "low_market_price",
            "cache_licensed_contractor_cost_high",
            "cache_licensed_contractor_cost_low",
            "cache_project_cost_high",
            "cache_project_cost_low",
            "xactware_quantity",
          ];

          keys.map((key) => {
            this.props.updateProject(key, null);
          });

          this.props.updateProject("cache_project_name", prediction.name);
          this.props.getJobEstimate(prediction.id);
          this.props.getOneProjectFeatureMap(prediction.id);
          this.props.handleChange("errors", false);
          this.props.handleChange("loading", false);
        }
      } else {
        this.props.handleChange("loading", false);
      }
    } catch (error) {
      this.props.handleChange("loading", false);
    }
  };

  handleSearch = (query) => {
    this.props.getJobList(query);
  };

  _handleQualityClick = (quality, selected) => {
    const planningText = "This estimate is for planning purposes. ";

    if (!this.props.disabled) {
      const { jobEstimate, unitTypes, project } = this.props;
      if (!selected) {
        this.setState({ selectedQualityLevel: quality.selectedQualityLevel });

        for (let key in quality) {
          if (quality.hasOwnProperty(key)) {
            if (key === "description") {
              if (
                project &&
                project.job_details_override &&
                project.job_details_override.includes(planningText)
              ) {
                this.props.updateProject(
                  "job_details_override",
                  `${planningText}${jobEstimate.value.description} - ${quality[key]}`
                );
              } else {
                this.props.updateProject(
                  "job_details_override",
                  `${jobEstimate.value.description} - ${quality[key]}`
                );
              }
              this.props.updateProject(
                "cache_job_details",
                `${jobEstimate.value.description} - ${quality[key]}`
              );
            } else {
              this.props.updateProject(repairwiseDBKeyMap[key], quality[key]);
            }
          }
        }

        const unitType = jobEstimate.value.unitOfMeasure.slice(-2);
        if (unitType in unitTypes) {
          this.props.updateProject(
            "cache_unit_type_ref_id",
            unitTypes[unitType].unit_type_ref_id
          );
        } else {
          //if the unit doesn't exist in the memo, set it to "unit"
          this.props.updateProject("cache_unit_type_ref_id", 35);
        }
      }
    }
  };

  _onQuantityChange(quantity) {
    this.props.updateProject("xactware_quantity", quantity);
    this.setState({ quantity: quantity });
  }

  _getNewEstimate() {
    const { jobEstimate, project } = this.props;
    const { quantity, job_id } = this.state;

    if (quantity || project.xactware_quantity) {
      let jobID = job_id || jobEstimate.value.jobId;

      let keys = [
        "cache_material_cost_high",
        "cache_material_cost_low",
        "cache_labor_cost_high",
        "cache_labor_cost_low",
        "cache_equipment_cost_high",
        "cache_equipment_cost_low",
        "high_market_price",
        "low_market_price",
        "cache_licensed_contractor_cost_high",
        "cache_licensed_contractor_cost_low",
        "cache_project_cost_high",
        "cache_project_cost_low",
      ];

      if (project.xactware_quality) {
        this.props.updateProject("xactware_quality", null);
        keys.map((key) => {
          this.props.updateProject(key, null);
        });
      }

      this.props.updateJobEstimate(
        jobEstimate.value.id,
        jobID,
        parseInt(quantity || project.xactware_quantity)
      );
      this.setState({ useDefaultCosts: true });
    }
  }

  render() {
    const { keyAsIndex, project, jobEstimate, defaultValue, disabled, errors } =
      this.props;
    const { useDefaultCosts, showPredictions, showImage } = this.state;

    return (
      <Container key={keyAsIndex} style={{ padding: 0 }}>
        {project.defect &&
          !!Object.keys(project.defect).length &&
          !!Object.keys(project.defect.predictions).length && (
            <div className="my-3 border-bottom">
              <div
                className="d-flex align-items-center border-bottom"
                onClick={this.togglePredictions}
              >
                <h4 className="mb-1 cursor-pointer">Project Predictions</h4>
                {showPredictions ? (
                  <span className="fa fa-caret-down fa-lg m-2 cursor-pointer" />
                ) : (
                  <span className="fa fa-caret-up fa-lg m-2 cursor-pointer" />
                )}
              </div>
              {showPredictions &&
                project.defect.predictions.repair.map((prediction) => {
                  return (
                    <div
                      key={prediction.name}
                      className={`ml-suggestion col-12 ${
                        project.cache_project_name === prediction.name
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        this.handlePrediction(prediction);
                      }}
                    >
                      <span>
                        {Math.round(prediction.score.toFixed(2) * 100)}% -{" "}
                        {prediction.name}
                      </span>
                      <p>{prediction.description}</p>
                    </div>
                  );
                })}
            </div>
          )}

        <label>Search For Project</label>
        <div className="md-diy-input-wrapper">
          <img src="/assets/icons/Project.svg" className="md-diy-input-icon" />
          <AsyncTypeahead
            id="searchBar"
            type="text"
            placeholder="Search for a repairwise project"
            selectHintOnEnter={true}
            onChange={this.handleOnInputChange}
            allowNew={false}
            multiple={false}
            onSearch={this.handleSearch}
            options={this.props.jobList}
            isLoading={this.props.jobListFetching}
            minLength={1}
            labelKey="name"
            disabled={disabled}
            filterBy={() => {
              return true;
            }}
            selected={
              project && project.cache_project_name
                ? [project.cache_project_name]
                : []
            }
            className={`${
              errors && errors.xactware_project_id ? "invalid" : ""
            }`}
            renderMenuItemChildren={(option) => (
              <div id={option.id} key={option.id}>
                <span>{option.name}</span>
              </div>
            )}
          />
        </div>

        {jobEstimate && jobEstimate.pending && (
          <Card className="md-preloader-wrapper">
            <Card.Img src={preloader} className="md-preloader" />
          </Card>
        )}

        {jobEstimate &&
          !jobEstimate.pending &&
          jobEstimate.value &&
          jobEstimate.value.id && (
            <div>
              <Form.Group
                controlId="fillerControlId"
                key={keyAsIndex}
                style={{ marginTop: "15px", marginBottom: "15px" }}
              >
                <p className="job-details">{project.cache_job_details} </p>

                <p className="job-details-image">
                  {showImage ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleShowImage();
                      }}
                    >
                      Hide Image <i className="fa fa-caret-up" />
                    </a>
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleShowImage();
                      }}
                    >
                      Show Image <i className="fa fa-caret-down" />
                    </a>
                  )}
                </p>

                {showImage && (
                  <img
                    src={jobEstimate.value.image}
                    className="md-diy-sidebar-image-wrapper"
                  />
                )}

                <label>Material Quantity</label>
                <div className="md-diy-input-wrapper d-flex">
                  <img
                    src="/assets/icons/Material Quantity.svg"
                    className="md-diy-input-icon"
                  />
                  <input
                    type="number"
                    placeholder="quantity"
                    defaultValue={
                      project.xactware_quantity || jobEstimate.value.quantity
                    }
                    onChange={(evt) => {
                      this._onQuantityChange(evt.target.value);
                    }}
                    style={{ marginBottom: 0 }}
                    disabled={disabled}
                  />
                  <button
                    className="btn btn-primary get-estimate-btn"
                    onClick={this._getNewEstimate}
                  >
                    Update Quantity
                  </button>
                </div>
                <Form.Text className="text-muted">
                  in units of{" "}
                  {
                    unitOfMeasureMap.unitsOfMeasure.short[
                      jobEstimate.value.unitOfMeasure
                    ]
                  }
                </Form.Text>
              </Form.Group>

              {jobEstimate.value.qualityLevels.map((level, index) => {
                return (
                  <ProjectQualityCard
                    key={index}
                    index={index}
                    level={level}
                    project={project}
                    errors={errors}
                    updateProject={this.props.updateProject}
                    handleQualityClick={this._handleQualityClick}
                    defaultValue={defaultValue}
                    useDefaultCosts={useDefaultCosts}
                    toggleDefaultCosts={this.toggleDefaultCosts}
                    repairwiseDBKeyMap={repairwiseDBKeyMap}
                    disabled={disabled}
                  />
                );
              })}
            </div>
          )}
      </Container>
    );
  }
}

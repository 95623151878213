import "./index.scss";
import React, { useRef } from "react";

export function FileInput({ name, setFile }) {
  const fileInput = useRef(null);

  return (
    <div className="md-file-input-wrapper">
      <input
        onChange={(event) => {
          setFile(event.currentTarget.files);
        }}
        ref={fileInput}
        className="md-file-input"
        type="file"
        name={name}
        id={name}
        accept=".jpg,.jpeg,.png"
      />

      <i className="fa fa-upload" onClick={() => fileInput.current.click()} />
      <p>
        <label htmlFor={name}>Upload File</label>
      </p>
    </div>
  );
}

import React, { Component } from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';

export default class OrderDetailsModal extends Component {

    render() {
        const { user, charge } = this.props;

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={this.props.hideModal}
                className="md-order-modal"
            >
                <Modal.Header closeButton>
                    <ModalTitle>
                        Order Details
						<span className="d-none d-sm-inline">|
							<a href="javascript:window.print()" className="md-print-receipt">
                                <i className="fa fa-print" />
                            </a>
                        </span>
                    </ModalTitle>
                </Modal.Header>

                <Modal.Body>
                    <div className="col">
                        <div className="row">
                            <div className="col-6 col-md-4">
                                <h4>Name</h4>
                                <p>{user.user_first_name + ' ' + user.user_last_name}</p>
                            </div>

                            <div className="col-6 col-md-4">
                                <h4>Payment Method</h4>
                                <p>{charge.brand} {charge.payment_method}</p>
                            </div>

                            <div className="col-6 col-md-4">
                                <h4>Transaction Date</h4>
                                <p>{charge.transactionDate}</p>
                            </div>

                            <div className="col-6 col-md-4">
                                <h4>Transaction Amount</h4>
                                <p>{charge.amount}</p>
                            </div>

                            <div className="col-6 col-md-4">
                                <h4>Majordomo Reference #</h4>
                                <p>{charge.majordomo_refrence_number}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

import React, { useContext, useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import SharingModal from '../../ui/SharingModal';

const ReportNav = ({ reportId, propertyId, token, report }) => {

	const [showSharingModal, setShowSharingModal] = useState(false)

	function toggleSharing() {
		setShowSharingModal(!showSharingModal)
	}

	function hideModal() {
		setShowSharingModal(false)
	}

	return (
		<Nav>
			{showSharingModal &&
				<SharingModal
					reportID={reportId}
					hideModal={hideModal}
					address={(report && report.PropertyAddressFull) ? report.PropertyAddressFull + ', ' + report.PropertyAddressCity + ', ' + report.PropertyAddressState + ' ' + report.PropertyAddressZIP : null}
					token={token}
				/>
			}
			<Nav.Item>
				<NavLink to={`/report/${reportId}`} className="nav-link">
					Report
				</NavLink>
			</Nav.Item>
			<Nav.Item>
				<NavLink to={`/report/${reportId}/home/${propertyId}`} className="nav-link">
					Property
				</NavLink>
			</Nav.Item>
			<Nav.Item>
				<NavLink to={`/projectsForReport/${reportId}/property/${propertyId}`} className="nav-link">
					Projects
				</NavLink>
			</Nav.Item>
			<Nav.Item>
				<NavLink to={`/report/${reportId}/photos/${propertyId}`} className="nav-link">
					Photos
				</NavLink>
			</Nav.Item>
			<Nav.Item>
				<a href={`#`} onClick={(e) => { e.preventDefault(); toggleSharing() }} className="nav-link">
					Sharing
				</a>
			</Nav.Item>
			<Nav.Item>
				<NavLink to={`/diy/report/${reportId}/property/${propertyId}`} className="nav-link">
					DIY
				</NavLink>
			</Nav.Item>
		</Nav>
	)
}

export default ReportNav 
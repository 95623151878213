import React from 'react';
import { Form, Badge } from 'react-bootstrap';

const makeLabel = (labelStr, key, keysToIgnore) => {

	let label = 
		(<Form.Label> {labelStr} <Badge variant="danger"> required </Badge> </Form.Label>);
	if((key in keysToIgnore)){
		label = (<Form.Label> {labelStr} <Badge variant="primary"> not required </Badge> </Form.Label>);
	}
	return label
}

const styleBorder = (obj, key, keysToIgnore) => {
	let borderStyle = {};
	if (!(key in keysToIgnore)){
		if ((obj ? (`${obj[key]}` === "null" ? "" :  `${obj[key]}`): "") === ""){
			borderStyle = {borderColor: "red", backgroundColor: "rgba(255, 0, 0, 0.075)"};
		} else {
			borderStyle = {borderColor: "green", formControl:{focus: {borderColor: "red"}}}
		}
	}

	return borderStyle;
}

const makePlaceholder = (obj, field) => {
	return (obj ? (`${obj[field.Field]}` === "null" ? "" :  `${obj[field.Field]}`): "")
}

export const _returnFields = (fields, obj, onChange, boolChange, handleSpecialChange, keysToIgnore) =>{
	keysToIgnore = (keysToIgnore==null ? {} : keysToIgnore) 
	console.log(keysToIgnore)
	return fields.map((field,index) => {
		let control = null;
		let label = null;
		let keyAsIndex = index;
		switch(true){
			case field.Field.indexOf('id') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore);
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="number" placeholder={`${field.Field}`} defaultValue={makePlaceholder(obj, field)} readOnly/>)
				break;
			case field.Type.includes('tinyint'):
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Check style={styleBorder(obj, field.Field, keysToIgnore)} type="checkbox" label={`${field.Field}`} defaultChecked={obj[field.Field] === "0" ? false: true} onChange={(evt)=>{
					console.log('checkbox changed', evt.target.checked)
					boolChange(field.Field, evt.target.checked);
				}}/>)
				break;
			case field.Type.indexOf('int') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="number" placeholder={`${field.Field}`} defaultValue={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Field.indexOf('email') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="email" placeholder={`${field.Field}`} defaultValue={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Field.indexOf('phone') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="tel" placeholder={`${field.Field}`} defaultValue={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Type.indexOf('varchar') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="text" placeholder={`${field.Field}`} defaultValue={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Type.indexOf('text') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="text" placeholder={`${field.Field}`} defaultValue={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Type.indexOf('varchar') !== -1:
				label = (<Form.Label>{field.Field.replace(/_/g,' ' + " test")}</Form.Label>)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="text" placeholder={`${field.Field}`} defaultValue={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Type.indexOf('time') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)                    
				control = (<Form.Text style={styleBorder(obj, field.Field, keysToIgnore)} >{obj ? `${obj[field.Field]}` : ""}</Form.Text>)
				break;
			default:
				break;
		}
		return (
			<Form.Group controlId="fillerControlId" key={keyAsIndex}>
				{label}
				{control}
			</Form.Group>
		)
	})
}
import axios from "../services/axios";
import { store } from "../ProviderApp";

/*=========== action names ===========*/

export const GET_PROJECT_FIELDS_ATTEMPT = "GET_PROJECT_FIELDS_ATTEMPT";
export const GET_PROJECT_FIELDS_SUCCESS = "GET_PROJECT_FIELDS_SUCCESS";
export const GET_PROJECT_FIELDS_FAIL = "GET_PROJECT_FIELDS_FAIL";

export const GET_UNIT_TYPES_ATTEMPT = "GET_UNIT_TYPES_ATTEMPT";
export const GET_UNIT_TYPES_SUCCESS = "GET_UNIT_TYPES_SUCCESS";
export const GET_UNIT_TYPES_FAIL = "GET_UNIT_TYPES_FAIL";

export const GET_PRIORITIES_ATTEMPT = "GET_PRIORITIES_ATTEMPT";
export const GET_PRIORITIES_SUCCESS = "GET_PRIORITIES_SUCCESS";
export const GET_PRIORITIES_FAIL = "GET_PRIORITIES_FAIL";

export const GET_PROJECT_ATTEMPT = "GET_PROJECT_ATTEMPT";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_ATTEMPT = "UPDATE_PROJECT_ATTEMPT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const GET_PROJECTS_ATTEMPT = "GET_PROJECTS_ATTEMPT";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

export const CREATE_PROJECT_ATTEMPT = "CREATE_PROJECT_ATTEMPT";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";

export const CREATE_EMPTY_PROJECT = "CREATE_EMPTY_PROJECT";

export const GET_JOB_LIST_ATTEMPT = "GET_JOB_LIST_ATTEMPT";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const GET_JOB_LIST_FAIL = "GET_JOB_LIST_FAIL";

export const GET_JOB_DETAILS_ATTEMPT = "GET_JOB_DETAILS_ATTEMPT";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_FAIL = "GET_JOB_DETAILS_FAIL";

export const GET_JOB_ESTIMATE_ATTEMPT = "GET_JOB_ESTIMATE_ATTEMPT";
export const GET_JOB_ESTIMATE_SUCCESS = "GET_JOB_ESTIMATE_SUCCESS";
export const GET_JOB_ESTIMATE_FAIL = "GET_JOB_ESTIMATE_FAIL";

export const UPDATE_JOB_ESTIMATE = "UPDATE_JOB_ESTIMATE";
export const UPDATE_JOB_ESTIMATE_ATTEMPT = "UPDATE_JOB_ESTIMATE_ATTEMPT";
export const UPDATE_JOB_ESTIMATE_SUCCESS = "UPDATE_JOB_ESTIMATE_SUCCESS";
export const UPDATE_JOB_ESTIMATE_FAIL = "UPDATE_JOB_ESTIMATE_FAIL";

/*=========== reducer actions ===========*/

const getProjectFieldsAttempt = () => {
  return {
    type: GET_PROJECT_FIELDS_ATTEMPT,
  };
};

const getProjectFieldsSuccess = (fields, emptyValues, fieldsHash) => {
  return {
    type: GET_PROJECT_FIELDS_SUCCESS,
    fields: fields,
    emptyValues: emptyValues,
    fieldsHash: fieldsHash,
  };
};
const getProjectFieldsFail = (err) => {
  return {
    type: GET_PROJECT_FIELDS_FAIL,
    err: err,
  };
};

const getUnitTypesAttempt = () => {
  return {
    type: GET_UNIT_TYPES_ATTEMPT,
  };
};

const getUnitTypesSuccess = (unitTypes) => {
  return {
    type: GET_UNIT_TYPES_SUCCESS,
    unitTypes: unitTypes,
  };
};
const getUnitTypesFail = (err) => {
  return {
    type: GET_UNIT_TYPES_FAIL,
    err: err,
  };
};

const getPrioritiesAttempt = () => {
  return {
    type: GET_PRIORITIES_ATTEMPT,
  };
};

const getPrioritiesSuccess = (priorities) => {
  return {
    type: GET_PRIORITIES_SUCCESS,
    priorities: priorities,
  };
};

const getPrioritiesFail = (err) => {
  return {
    type: GET_PRIORITIES_FAIL,
    err: err,
  };
};

const getProjectAttempt = () => {
  return {
    type: GET_PROJECT_ATTEMPT,
  };
};

const getProjectSuccess = (project) => {
  return {
    type: GET_PROJECT_SUCCESS,
    project: project,
  };
};

const getProjectFail = (err) => {
  return {
    type: GET_PROJECT_FAIL,
    err: err,
  };
};

export const updateProject = (key, value) => {
  return {
    type: UPDATE_PROJECT,
    key: key,
    value: value,
  };
};

const updateProjectAttempt = () => {
  return {
    type: UPDATE_PROJECT_ATTEMPT,
  };
};

const updateProjectSuccess = () => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
  };
};

const updateProjectFail = (err) => {
  return {
    type: UPDATE_PROJECT_FAIL,
    err: err,
  };
};

const getProjectsAttempt = () => {
  return {
    type: GET_PROJECTS_ATTEMPT,
  };
};

const getProjectsSuccess = (projects) => {
  return {
    type: GET_PROJECTS_SUCCESS,
    projects: projects,
  };
};

const getProjectsFail = (err) => {
  return {
    type: GET_PROJECTS_FAIL,
    err: err,
  };
};

const createProjectAttempt = () => {
  return {
    type: CREATE_PROJECT_ATTEMPT,
  };
};

const createProjectSuccess = () => {
  return {
    type: CREATE_PROJECT_SUCCESS,
  };
};

const createProjectFail = (err) => {
  return {
    type: CREATE_PROJECT_FAIL,
    err: err,
  };
};

export const createEmptyProject = (report_id, property_log_id) => {
  return {
    type: CREATE_EMPTY_PROJECT,
    domoreport_log_id: report_id,
    property_log_id: property_log_id,
  };
};

const getJobListAttempt = () => {
  return {
    type: GET_JOB_LIST_ATTEMPT,
  };
};

const getJobListSucces = (jobs) => {
  return {
    type: GET_JOB_LIST_SUCCESS,
    jobs: jobs,
  };
};

const getJobListFail = (err) => {
  return {
    type: GET_JOB_LIST_FAIL,
    err: err,
  };
};

export const updateJob = (key, value) => {
  return {
    type: UPDATE_JOB_ESTIMATE,
    key: key,
    value: value,
  };
};

const getJobDetailsAttempt = () => {
  return {
    type: GET_JOB_DETAILS_ATTEMPT,
  };
};

const getJobDetailsSuccess = (details) => {
  return {
    type: GET_JOB_DETAILS_SUCCESS,
    details: details,
  };
};

const getJobDetailsFail = (err) => {
  return {
    type: GET_JOB_DETAILS_FAIL,
    err: err,
  };
};

const getJobEstimateAttempt = () => {
  return {
    type: GET_JOB_ESTIMATE_ATTEMPT,
  };
};

const getJobEstimateSuccess = (estimate) => {
  return {
    type: GET_JOB_ESTIMATE_SUCCESS,
    estimate: estimate,
  };
};

const getJobEstimateFail = (err) => {
  return {
    type: GET_JOB_ESTIMATE_FAIL,
    err: { estimateError: true, err },
  };
};

const updateJobEstimateAttempt = () => {
  return {
    type: UPDATE_JOB_ESTIMATE_ATTEMPT,
  };
};

const updateJobEstimateSuccess = (estimate) => {
  return {
    type: UPDATE_JOB_ESTIMATE_SUCCESS,
    estimate: estimate,
  };
};

const updateJobEstimateFail = (err) => {
  return {
    type: UPDATE_JOB_ESTIMATE_FAIL,
    err: { estimateError: true, err },
  };
};

/*=========== actions ===========*/

const fetcProjectFields = (dispatch) => {
  dispatch(getProjectFieldsAttempt());
  axios
    .get(`/domoreport/uploader/project/fields`)
    .then((res) => {
      let emptyValues = {};

      for (let i = 0; i < res.data.length; i++) {
        emptyValues[res.data[i].Field] = null;
      }
      let fieldsHash = {};
      fieldsHash = res.data.reduce((map, obj) => {
        fieldsHash[obj.Field] = obj.Field;
        return fieldsHash;
      });
      dispatch(getProjectFieldsSuccess(res.data, emptyValues, fieldsHash));
    })
    .catch((err) => {
      dispatch(getProjectFieldsFail(err));
    });
};

export const getProjectFields = () => (dispatch) => {
  if (
    !store.getState().projects.fieldsFetched &&
    !store.getState().projects.fieldsFetching
  ) {
    fetcProjectFields(dispatch);
  }
};

const fetchUnitTypes = (dispatch) => {
  dispatch(getUnitTypesAttempt());
  axios
    .get(`/domoreport/uploader/allUnitTypes`)
    .then((res) => {
      let unitTypeMemo = {};
      for (let i = 0; i < res.data.length; i++) {
        if (!(res.data[i].unit_abbreviation in unitTypeMemo)) {
          unitTypeMemo[res.data[i].unit_abbreviation] = {
            ...res.data[i],
            arrayIndex: i,
          };
        }
      }
      dispatch(getUnitTypesSuccess(res.data, unitTypeMemo));
    })
    .catch((err) => {
      dispatch(getUnitTypesFail(err));
    });
};

export const getUnitTypes = () => (dispatch) => {
  if (!store.getState().projects.unitTypesFetching) {
    fetchUnitTypes(dispatch);
  }
};

const fetchPriorities = (dispatch) => {
  dispatch(getPrioritiesAttempt());
  axios
    .get(`/domoreport/uploader/allProjectpriorities`)
    .then((res) => {
      dispatch(getPrioritiesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPrioritiesFail(err));
    });
};

export const getPriorities = () => (dispatch) => {
  if (
    !store.getState().projects.prioritiesFetched &&
    !store.getState().projects.prioritiesFetching
  ) {
    fetchPriorities(dispatch);
  }
};

const fetchOneProject = (project_id) => (dispatch) => {
  dispatch(getProjectAttempt());
  axios
    .get(`/domoreport/uploader/project/${project_id}`)
    .then((res) => {
      dispatch(getProjectSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getProjectFail(err));
    });
};

export const getOneProject = (project_id) => (dispatch) => {
  fetchOneProject(project_id)(dispatch);
};

export const updateOneProject = (project_id, data) => (dispatch) => {
  dispatch(updateProjectAttempt());
  axios
    .put(`/domoreport/uploader/project/${project_id}`, data)
    .then((res) => {
      dispatch(updateProjectSuccess());
      fetchOneProject(project_id)(dispatch);
    })
    .catch((err) => {
      dispatch(updateProjectFail(err));
    });
};

const fetchProjects = (report_id) => (dispatch) => {
  dispatch(getProjectsAttempt());
  axios
    .get(`/domoreport/uploader/allProjectsFor/${report_id}`)
    .then((res) => {
      dispatch(getProjectsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getProjectsFail(err));
    });
};

export const getProjects = (report_id) => (dispatch) => {
  fetchProjects(report_id)(dispatch);
};

export const createProject = (report_id, data) => (dispatch) => {
  axios
    .post(`/domoreport/uploader/project`, data)
    .then((res) => {
      dispatch(createProjectSuccess());
      fetchProjects(report_id)(dispatch);
    })
    .catch((err) => {
      dispatch(createProjectFail(err));
    });
};

const fetchJobList = (query) => (dispatch) => {
  dispatch(getJobListAttempt());
  axios
    .get(`/domoreport/uploader/projectEstimates/job?search=${query}`)
    .then((res) => {
      dispatch(getJobListSucces(res.data));
    })
    .catch((err) => {
      dispatch(getJobListFail(err));
    });
};

export const getJobList = (query) => (dispatch) => {
  fetchJobList(query)(dispatch);
};

const fetchJobDetails = (job_id) => (dispatch) => {
  dispatch(getJobDetailsAttempt());
  axios
    .get(`/domoreport/uploader/projectEstimates/job/${job_id}`)
    .then((res) => {
      dispatch(getJobDetailsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getJobDetailsFail(err));
    });
};

export const getJobDetails = (job_id) => (dispatch) => {
  fetchJobDetails(job_id)(dispatch);
};

const fetchJobEstimate = (job_id, property_id) => (dispatch) => {
  dispatch(getJobEstimateAttempt());
  let property_log_id = null;
  if (property_id != null) {
    property_log_id = property_id;
  } else if (store.getState().properties.property.property_log_id) {
    property_log_id = store.getState().properties.property.property_log_id;
  }
  if (property_log_id) {
    axios
      .get(
        `/domoreport/uploader/estimateForProperty/${property_log_id}/job/${job_id}`
      )
      .then((res) => {
        dispatch(getJobEstimateSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getJobEstimateFail(err.message));
      });
  } else {
    dispatch(getJobEstimateFail(new Error("the property is not set yet")));
  }
};

export const getJobEstimate = (job_id, property_id) => (dispatch) => {
  fetchJobEstimate(job_id, property_id)(dispatch);
};

const putJobEstimate =
  (estimate_id, property_id, job_id, quantity) => (dispatch) => {
    dispatch(updateJobEstimateAttempt());
    let property_log_id = null;
    if (property_id != null) {
      property_log_id = property_id;
    } else if (store.getState().properties.property.property_log_id) {
      property_log_id = store.getState().properties.property.property_log_id;
    }
    if (property_log_id) {
      axios
        .put(
          `/domoreport/uploader/estimateForProperty/${property_log_id}/estimate/${estimate_id}`,
          { quantity: quantity }
        )
        .then((res) => {
          dispatch(updateJobEstimateSuccess(res.data));
          fetchJobEstimate(job_id, property_id)(dispatch);
        })
        .catch((err) => {
          dispatch(updateJobEstimateFail(err));
        });
    } else {
      dispatch(updateJobEstimateFail(new Error("the property is not set yet")));
    }
  };

export const updateJobEstimate =
  (estimate_id, property_id, job_id, quantity) => (dispatch) => {
    putJobEstimate(estimate_id, property_id, job_id, quantity)(dispatch);
  };

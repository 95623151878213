import React from 'react';
import { Container, } from 'react-bootstrap';
import { keysToIgnore } from '../../components/FeatureRefs/';
import * as _ from 'lodash';
import {
	BoolField,
	IdNumberField,
	IntNumberField,
	IntDropdownField,
	VarcharTextField,
	TextQuilField,
} from '../FormFields/';
import TradesTypeaheadField from '../FormFields/trades.typeahead';
/*
feature_ref_id
area_ref_id -> dropdown area_ref_name
*/

export const _returnFields = (fields, obj, onChange, boolChange, handleSpecialChange) => {
	console.log('OB', obj);
	const defaultActionOptions = [
		{
			defaultActionName: "maintain",
			feature_action_ref_id: 1
		},
		{
			defaultActionName: "repair",
			feature_action_ref_id: 2
		},
		{
			defaultActionName: "replace",
			feature_action_ref_id: 3
		},
	]
	let elementIndex = 0

	return (
		<Container>
			<IdNumberField
				formName={"feature_ref_id"}
				fieldKey={"feature_ref_id"}
				keysToIgnore={keysToIgnore}
				obj={obj}
				keyAsIndex={elementIndex++}
			/>
			<VarcharTextField
				formName={"feature_name"}
				fieldKey={"feature_name"}
				keysToIgnore={keysToIgnore}
				obj={obj}
				keyAsIndex={elementIndex++}
				onChange={onChange}
			/>
			<IntDropdownField
				formName={"area_ref_id"}
				fieldKey={"area_ref_id"}
				displayKey={"area_name"}
				keysToIgnore={keysToIgnore}
				obj={obj}
				keyAsIndex={elementIndex++}
				onChange={onChange}
				options={obj.areaOptions}
			/>
			<Container>
				{_.range(5, -1, -1).map((number, index) => {
					return (
						<>
							<div>
								<h5>score of {number}:</h5>
							</div>
							<div style={{ borderWidth: 1, borderStyle: "solid", borderRadius: 5 }}>
								<VarcharTextField
									formName={`description for a score of ${number}`}
									fieldKey={`score_${number}_description`}
									keysToIgnore={keysToIgnore}
									obj={obj}
									keyAsIndex={elementIndex++}
									onChange={onChange}
								/>

								{!obj.hidefields && <IntDropdownField
									formName={`default recommendation for score or ${number}`}
									fieldKey={`default_score_${number}_feature_action_ref_id`}
									displayKey={"defaultActionName"}
									valueKey={"feature_action_ref_id"}
									keysToIgnore={keysToIgnore}
									obj={obj}
									keyAsIndex={elementIndex++}
									onChange={(key, value) => { onChange(key, parseInt(value)) }}
									options={defaultActionOptions}
								/>}
							</div>
						</>
					)
				})
				}
			</Container>
			<Container>
				{
					!obj.hidefields && defaultActionOptions.map((item) => {
						return (
							<>
								<div>
									<h5>fields for {item.defaultActionName}:</h5>
								</div>
								<div style={{ borderWidth: 1, borderStyle: "solid", borderRadius: 5 }}>
									<VarcharTextField
										formName={`video link`}
										fieldKey={`${item.defaultActionName}_video_link`}
										keysToIgnore={keysToIgnore}
										obj={obj}
										keyAsIndex={elementIndex++}
										onChange={onChange}
									/>
									<VarcharTextField
										formName={`website link`}
										fieldKey={`${item.defaultActionName}_website_link`}
										keysToIgnore={keysToIgnore}
										obj={obj}
										keyAsIndex={elementIndex++}
										onChange={onChange}
									/>
									<TradesTypeaheadField
										formName={`trade_id`}
										fieldKey={`${item.defaultActionName}_trade_id`}
										keysToIgnore={keysToIgnore}
										obj={obj}
										keyAsIndex={elementIndex++}
										onChange={onChange}
										value={obj[`${item.defaultActionName}_trade_id`]}
									/>
									<TextQuilField
										formName={`detailed description for a score of ${item.defaultActionName}`}
										fieldKey={`${item.defaultActionName}_detailed_description`}
										keysToIgnore={keysToIgnore}
										obj={obj}
										keyAsIndex={elementIndex++}
										onChange={onChange}
									/>
								</div>
							</>
						)
					})
				}
			</Container>
			<IntNumberField
				formName={"feature_importance (out of 10)"}
				fieldKey={"feature_importance"}
				keysToIgnore={keysToIgnore}
				obj={obj}
				keyAsIndex={elementIndex++}
				onChange={onChange}
			/>
			<IntNumberField
				formName={"feature_life (in years)"}
				fieldKey={"feature_life"}
				keysToIgnore={keysToIgnore}
				obj={obj}
				keyAsIndex={elementIndex++}
				onChange={onChange}
			/>
			<IntNumberField
				formName={"feature_priority"}
				fieldKey={"feature_priority"}
				keysToIgnore={keysToIgnore}
				obj={obj}
				keyAsIndex={elementIndex++}
				onChange={onChange}
			/>
			<BoolField
				formName={"feature preselect (Part of Wizard)"}
				fieldKey={"feature_preselect"}
				keysToIgnore={keysToIgnore}
				obj={obj}
				keyAsIndex={elementIndex++}
				onBoolChange={boolChange}
			/>
		</Container>
	)
}
import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { v2 } from "../../services/axios";

export default function Typeahead({ getJobEstimate, project, setProject }) {
  const [jobs, setJobs] = useState();
  const [loading, setLoading] = useState(false);

  async function handleSearch(query) {
    setProject({ ...project, cache_project_name: null });
    setLoading(true);
    try {
      const { data, status } = await v2.get(`/admin/jobs?search=${query}`);
      if (status === 200) {
        setJobs(data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  function handleChange(jobs) {
    if (jobs[0] && jobs[0].id) {
      getJobEstimate(jobs[0].id, null, true);
    }
  }

  function handleScroll(e) {
    e.preventDefault();
    const inputLabel = document.getElementById("projectSearchLabel");
    inputLabel.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <AsyncTypeahead
      id="projectSearch"
      type="text"
      labelKey="name"
      filterBy={() => {
        return true;
      }}
      minLength={0}
      placeholder="Search for a project"
      useCache={false}
      onChange={handleChange}
      onSearch={handleSearch}
      onFocus={handleScroll}
      options={jobs}
      selected={project.cache_project_name ? [project.cache_project_name] : []}
      isLoading={loading}
      renderMenuItemChildren={(job) => (
        <div id={job.id} key={job.id}>
          <span>{job.name}</span>
        </div>
      )}
    />
  );
}

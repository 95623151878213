import React, { Component } from "react";
import axios from "axios";

export default class MDImage extends Component {
  state = {
    img: "./preloader.gif",
  };

  componentDidMount() {
    if (this.props.loadedPhotoData === null) {
      axios
        .get(`${this.props.sourceURL}`, {
          headers: { Authorization: this.props.token },
          responseType: "arraybuffer",
        })
        .then((res) => {
          let data = new Uint8Array(res.data);
          let raw = ""; //String.fromCharCode.apply(null, data);
          for (let i = 0; i < data.length; i++) {
            raw = raw + String.fromCharCode(data[i]);
          }
          let base64 = btoa(raw);
          let src = "data:image;base64," + base64;
          if ("onLoaded" in this.props) {
            this.props.onLoaded(this.props.docId, src);
          }
          this.setState({ img: src });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({ img: this.props.loadedPhotoData });
    }
  }

  render() {
    return (
      <img
        width={"100%"}
        src={this.state.img}
        alt={"Loading..."}
        ref={(e) => {}}
      />
    );
  }
}

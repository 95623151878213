import React, { Component } from 'react';
const styles = {position: "fixed", top: "2px", left: "2px", right: "2px", zIndex: 100};
export const WithAlert = AlertComponent => {
  return class extends Component{
      state= {
          show: true,
      }
      
      componentWillReceiveProps(){
          this.setState({
            show: true
          });
          const rI = setTimeout(()=> {
                this.setState({
                  show: false
                }, ()=>{
                   clearTimeout(rI);
                   if(this.props.resetMessage != null){
                    this.props.resetMessage();
                   }
                });
          }, 4000);
      }
      render(){
          const {variant, message } = this.props;
          return  this.state.show ?<AlertComponent style={styles} variant={variant} >{message}</AlertComponent>: null;                
      }
  }
};
import React from 'react';
import { Form, Container} from 'react-bootstrap';
import { makeLabel, styleBorder, makePlaceholder } from './';

export const IntNumberField = function({formName, fieldKey, keysToIgnore, obj, keyAsIndex, onChange, readOnly}){
	formName = formName || "";
	fieldKey = fieldKey || "";
	keysToIgnore = keysToIgnore || {};
	keyAsIndex = keyAsIndex || "";
	obj = obj || {};
	onChange = onChange || (() => {});
	return(
		<Container key={keyAsIndex}>
			<Form.Group controlId="fillerControlId" key={keyAsIndex}>
				{makeLabel(formName.replace(/_/g,' '), fieldKey, keysToIgnore)}
				<Form.Control 
					style={styleBorder(obj, fieldKey, keysToIgnore)} 
					type="number" placeholder={`${fieldKey}`} 
					value={makePlaceholder(obj, fieldKey)} 
					onChange={(evt)=>{onChange(fieldKey, evt.target.value);}}
					readOnly={readOnly}
				/>
			</Form.Group>
		</Container>
	)
}
import {
	GET_ALL_MDPEOPLE_ATTEMPT,
	GET_ALL_MDPEOPLE_SUCCESS,
	GET_ALL_MDPEOPLE_FAIL,

	GET_MDPEOPLE_FIELDS_ATTEMPT,
	GET_MDPEOPLE_FIELDS_FAIL,
	GET_MDPEOPLE_FIELDS_SUCCESS,

	UPDATE_NEW_MDPERSON,

	TOGGLE_MDPERSON_MODAL,

	CREATE_NEW_MDPERSON_ATTEMPT,
	CREATE_NEW_MDPERSON_SUCCESS,
	CREATE_NEW_MDPERSON_FAIL,

	DELETE_MDPERSON_ATTEMPT,
	DELETE_MDPERSON_FAIL,
	DELETE_MDPERSON_SUCCESS,

	GET_MDPERSON_ATTEMPT,
	GET_MDPERSON_FAIL,
	GET_MDPERSON_SUCCESS,

	UPDATE_PERSON,

	UPDATE_MDPERSON_ATTEMPT,
	UPDATE_MDPERSON_FAIL,
	UPDATE_MDPERSON_SUCCESS,

	CLEAR_ERROR_SUCCESS,
	CLEAR_ERROR_ATTEMPT,
} from '../actions';

const initialState = {
	people: [],
	peopleFetching: false,
	peopleFetched: false,
	gettingPerson: false,
	updatingPerson: false,
	error: null,
	clearingError: false,
	fields: [],
	fieldsHash: {},
	emptyValues: {},
	person: {},
	fieldsFetched: false,
	fieldsFetching: false,
	newMDPerson: {},
	mdPersonSubmitting: false,
	mdPersonDeleting: false,
	createModalOpen: false,
};

export function mdpeople(state = initialState, action) {
	switch (action.type) {
		case GET_ALL_MDPEOPLE_ATTEMPT:
			return {
				...state,
				peopleFetching: true,
				peopleFetched: false,
			}
		case GET_ALL_MDPEOPLE_SUCCESS:
			return {
				...state,
				people: action.people,
				peopleFetching: false,
				peopleFetched: true,
			}
		case GET_ALL_MDPEOPLE_FAIL:
			return {
				...state,
				peopleFetching: false,
				peopleFetched: false,
				error: action.err,
			}
		case GET_MDPEOPLE_FIELDS_ATTEMPT:
			return {
				...state,
				fieldsFetched: false,
				fieldsFetching: true,
			}
		case GET_MDPEOPLE_FIELDS_SUCCESS:
			return {
				...state,
				fields: action.fields,
				emptyValues: action.emptyValues,
				fieldsHash: action.fieldsHash,
				fieldsFetched: true,
				fieldsFetching: false,
			}
		case GET_MDPEOPLE_FIELDS_FAIL:
			return {
				...state,
				fieldsFetched: false,
				fieldsFetching: false,
				error: action.err,
			}
		case UPDATE_NEW_MDPERSON:
			return {
				...state,
				newMDPerson: {
					...state.newMDPerson,
					[action.key]: action.value,
				}
			}
		case CREATE_NEW_MDPERSON_ATTEMPT:
			return {
				...state,
				mdPersonSubmitting: true,
			}
		case CREATE_NEW_MDPERSON_SUCCESS:
			return {
				...state,
				mdPersonSubmitting: false,
				createModalOpen: false,
				newMDPerson: {},
			}
		case CREATE_NEW_MDPERSON_FAIL:
			return {
				...state,
				mdPersonSubmitting: false,
				error: action.err,
			}
		case TOGGLE_MDPERSON_MODAL:
			return {
				...state,
				createModalOpen: !state.createModalOpen,
			}

		case DELETE_MDPERSON_ATTEMPT:
			return {
				...state,
				mdPersonDeleting: true,
			}
		case DELETE_MDPERSON_SUCCESS:
			return {
				...state,
				mdPersonDeleting: false,
			}
		case DELETE_MDPERSON_FAIL:
			return {
				...state,
				mdPersonDeleting: false,
				error: action.err
			}
		case GET_MDPERSON_SUCCESS:
			return {
				...state,
				gettingPerson: false,
				person: { ...action.personData }
			}
		case GET_MDPERSON_ATTEMPT:
			return {
				...state,
				gettingPerson: true,
			}

		case GET_MDPERSON_FAIL:
			return {
				...state,
				error: action.err,
				gettingPerson: false,
			}
		case UPDATE_PERSON:
			return {
				...state,
				person: {
					...state.person,
					[action.key]: action.value,
				}
			}
		case UPDATE_MDPERSON_ATTEMPT:
			return {
				...state,
				updatingPerson: true,
			}
		case UPDATE_MDPERSON_FAIL:
			return {
				...state,
				error: action.err,
				updatingPerson: false,
			}
		case UPDATE_MDPERSON_SUCCESS:
			return {
				...state,
				updatingPerson: false,
			}
		case CLEAR_ERROR_ATTEMPT:
			return {
				...state,
				clearingError: true,
			}
		case CLEAR_ERROR_SUCCESS:
			return {
				...state,
				error: null,
				clearingError: false,
			}
		default:
			return {
				...state,
			}
	}
}
import '../index.scss'
import React, { Component } from 'react';
import withFetch from '../../../hocs/withFetch';
import ImageCrop from '../../../ui/ImageCrop';
import { templates } from './templates';
import { connect } from 'react-redux';
import { getExploreContent, updateContentState } from '../../../actions'

const mapStateToProps = state => ({
    exploreContent: state.explore.content,
    gettingContent: state.explore.gettingContent,
});

export default 
@connect(mapStateToProps, { getExploreContent, updateContentState })
@withFetch((props) => ({
    updateExploreContent: (data) => ({
        updatedExplore: {
            url: `/admin/explore/${props.resource.explore_content_log_id}`,
            method: 'PUT',
            headers: {
                'Content-Type': null,
            },
            body: data,
            then: (res) => {
                if (res) {
                    props.updateStep(6);
                }
            }
        }
    }),

    deleteExploreContent: () => ({
        deletedExploreContent: {
            url: `/admin/explore/${props.resource.explore_content_log_id}`,
            method: 'DELETE',

            then: (res) => {
                if (res) {
                    props.updateStep(6);
                }
            }
        }
    }),
}))
class EditExplore extends Component {

    state = {
        explore: {},
        is_utilities: false,
        is_mapping: false,
        is_permits: false,
        image: null,
        loading: false,
    }

    componentDidMount() {
        const { resource } = this.props;
        if (resource) {
            this.props.getExploreContent(resource.explore_content_log_id)
        }
        document.getElementsByClassName('md-diy-sidebar')[0].scrollTo(0, 0)
    }

    componentDidUpdate(prevState) {
        const { exploreContent } = this.props;
        const { explore } = this.state;

        if (!Object.keys(explore).length && Object.keys(exploreContent).length && prevState.exploreContent !== exploreContent) {
            const data = {
                body: exploreContent.body,
                city: exploreContent.city,
                county: exploreContent.county,
                is_mapping: exploreContent.is_mapping,
                is_permits: exploreContent.is_permits,
                is_utilities: exploreContent.is_utilities,
                state: exploreContent.state,
                summary_text: exploreContent.summary_text,
                title: exploreContent.title,
                url: exploreContent.url,
            }
            this.handleChange('explore', data)
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    updateExplore = (key, value) => {
        const { explore } = this.state;
        let updatedExplore = explore;

        updatedExplore[key] = value;
        this.setState({ project: updatedExplore })
    }

    getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
            if (object[key] !== null) {
                formData.append(key, object[key])
            }
        });
        return formData;
    }

    submitExplore = () => {
        const { explore, image } = this.state;

        let data = {
            body: explore.body,
            city: explore.city,
            county: explore.county,
            country: "US",
            is_mapping: explore.is_mapping ? 1 : 0,
            is_permits: explore.is_permits ? 1 : 0,
            is_utilities: explore.is_utilities ? 1 : 0,
            primary_image: image,
            state: explore.state,
            summary_text: explore.summary_text,
            title: explore.title,
            url: explore.url,
            zipcode: null,
        }

        let formData = this.getFormData(data)

        this.props.updateExploreContent(formData)
        // console.log(data, formData)
    }

    handleTemplate = (key) => {
        const { title, exploreContent } = this.props;
        const { explore } = this.state;
        const template = templates[title].find((t) => t.key === key) || {}
        let data = { ...explore }

        if (Object.keys(template).length) {
            data.body = template.description || null
            data.is_mapping = !!template.tags.includes('mapping')
            data.is_permits = !!template.tags.includes('permits')
            data.is_utilities = !!template.tags.includes('utilities')
            data.summary_text = template.summary || null
            data.title = template.title
            this.handleChange('explore', data)
        } else {
            data.body = exploreContent.body
            data.is_mapping = exploreContent.is_mapping
            data.is_permits = exploreContent.is_permits
            data.is_utilities = exploreContent.is_utilities
            data.summary_text = exploreContent.summary_text
            data.title = exploreContent.title
            this.handleChange('explore', data)
        }
    }

    render() {
        const { property, title, exploreContent, userCanEdit } = this.props;
        const { image, explore } = this.state;

        if (!explore || explore === {}) {
            return <div>Loading...</div>
        }

        return (
            <div className="md-diy-sidebar-content">
                <div className="md-diy-sidebar-info">
                    <h1 className="md-diy-sidebar-header">Edit {title ? title.toUpperCase() : ""} Resource</h1>
                </div>

                <div className="md-diy-sidebar-form" style={{ paddingBottom: explore && explore.repair_wise_job_id ? '0px' : '300px' }}>
                    <div className="md-diy-input-wrapper row">
                        {explore.county && <div className="col-6">
                            <label>County</label>
                            <div className="md-diy-input-wrapper">
                                <i className="fa fa-map-marker" />
                                <input
                                    disabled={true}
                                    type="text"
                                    value={explore.county || ""}
                                    onChange={(e) => this.updateExplore('county', e.target.value)}
                                />
                            </div>
                        </div>}

                        {explore.city &&
                            <div className="col-6">
                                <label>City</label>
                                <div className="md-diy-input-wrapper">
                                    <i className="fa fa-map-marker" />
                                    <input
                                        disabled={true}
                                        type="text"
                                        value={explore.city || property.city || ""}
                                        onChange={(e) => this.updateExplore('city', e.target.value)}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <label>Template (optional)</label>
                    <div className="md-diy-input-wrapper">
                        <i className="fa fa-clipboard" />
                        <select
                            id="template"
                            onChange={(e) => this.handleTemplate(e.target.value)}
                        >
                            <option key={0} value={null} selected>None</option>
                            {title && templates[title].map((template) => {
                                return (
                                    <option key={template.key} value={template.key}>{template.key}</option>
                                )
                            })}
                        </select>
                        <i className="fa fa-caret-down right" />
                    </div>

                    {!image && exploreContent && exploreContent.primary_image ?
                        <img src={exploreContent.primary_image} width='100%' />
                        :
                        <div />
                    }
                    <ImageCrop
                        handleChange={this.handleChange}
                        title={"Explore"}
                    />

                    <label>URL for primary button</label>
                    <div className="md-diy-input-wrapper">
                        <i className="fa fa-link" />
                        <input
                            type="text"
                            placeholder="URL"
                            value={explore.url || ""}
                            onChange={(e) => this.updateExplore('url', e.target.value)}
                        />
                    </div>

                    <label>Tags</label>
                    <div className="md-diy-input-wrapper row mb-3">
                        <div className="col checkbox-wrapper">
                            <input
                                type="checkbox"
                                id="utilities"
                                name="utilities"
                                onChange={(e) => this.updateExplore('is_utilities', !explore.is_utilities)}
                                checked={!!explore.is_utilities}
                            />
                            <label htmlFor="utilities"> Utilites</label>
                        </div>

                        <div className="col checkbox-wrapper">
                            <input
                                type="checkbox"
                                id="mapping"
                                name="mapping"
                                onChange={(e) => this.updateExplore('is_mapping', !explore.is_mapping)}
                                checked={!!explore.is_mapping}
                            />
                            <label htmlFor="mapping"> Mapping</label>
                        </div>

                        <div className="col checkbox-wrapper">
                            <input
                                type="checkbox"
                                id="permits"
                                name="permits"
                                onChange={(e) => this.updateExplore('is_permits', !explore.is_permits)}
                                checked={!!explore.is_permits}
                            />
                            <label htmlFor="permits"> Permits</label>
                        </div>
                    </div>

                    <label>Title</label>
                    <div className="md-diy-input-wrapper">
                        <i className="fa fa-pencil" />
                        <input
                            type="text"
                            placeholder="Resource Title"
                            value={explore.title || ""}
                            onChange={(e) => this.updateExplore('title', e.target.value)}
                        />
                    </div>

                    <label>Summary</label>
                    <div className="md-diy-input-wrapper">
                        <i className="fa fa-pencil" />
                        <input
                            type="text"
                            placeholder="Summary"
                            value={explore.summary_text || ""}
                            onChange={(e) => this.updateExplore('summary_text', e.target.value)}
                        />
                    </div>

                    <label>Description</label>
                    <div className="md-diy-input-wrapper">
                        <i className="fa fa-pencil" />
                        <textarea
                            rows={4}
                            placeholder="Description"
                            value={explore.body || ''}
                            onChange={(e) => this.updateExplore('body', e.target.value)}
                        />
                    </div>

                    {!!userCanEdit && <a href="#" className="btn btn-primary" onClick={() => this.submitExplore()}>Update</a>}
                    {!!userCanEdit && <a href="#" className="btn btn-danger" onClick={() => this.props.deleteExploreContent()}>Delete</a>}
                    <a href="#" className="btn btn-mdgray" onClick={() => { this.props.updateStep(6); this.props.setResourceTitle(null); }}>Cancel</a>
                </div>
            </div>
        );
    }
}
import "./index.scss";
import React, { Component } from "react";

export default class ProjectQualityCard extends Component {
  state = {
    editMaterial: false,
    editLabor: false,
    editEquipment: false,
    editLaborMinimum: false,
    editMarketPrice: false,
    project_costs: {},
    showDetails: [],
  };

  componentDidMount() {
    const { defaultValue, level } = this.props;

    if (defaultValue && defaultValue.xactware_quality === level.name) {
      let project_costs = {
        highMaterialPrice: defaultValue.cache_material_cost_high,
        lowMaterialPrice: defaultValue.cache_material_cost_low,
        highLaborPrice: defaultValue.cache_labor_cost_high,
        lowLaborPrice: defaultValue.cache_labor_cost_low,
        highEquipmentPrice: defaultValue.cache_equipment_cost_high,
        lowEquipmentPrice: defaultValue.cache_equipment_cost_low,
        highMarketPrice: defaultValue.high_market_price,
        lowMarketPrice: defaultValue.low_market_price,
        highLaborMinimumPrice: defaultValue.cache_licensed_contractor_cost_high,
        lowLaborMinimumPrice: defaultValue.cache_licensed_contractor_cost_low,
        highTotalPrice: defaultValue.cache_project_cost_high,
        lowTotalPrice: defaultValue.cache_project_cost_low,
      };

      this.setState({ project_costs: project_costs });
    } else {
      this.setState({ project_costs: level });
    }
  }

  componentDidUpdate() {
    const { project, defaultValue, useDefaultCosts, level } = this.props;
    const { project_costs } = this.state;

    if (
      defaultValue &&
      defaultValue.cache_project_name !== project.cache_project_name &&
      Object.keys(project_costs).length > 0
    ) {
      this.setState({ project_costs: level });
    }

    if (useDefaultCosts && Object.keys(project_costs).length > 0 && level) {
      this.setState({ project_costs: level });
      this.props.toggleDefaultCosts();
    }
  }

  toggleEdit = (e, key, value) => {
    e.preventDefault();
    this.setState({ [key]: value });
  };

  toggleDetails(index) {
    let details = [...this.state.showDetails];

    if (details[index]) {
      details[index] = false;
    } else if (!details[index]) {
      details[index] = true;
    }

    this.setState({ showDetails: details });
  }

  handleCostsUpdate = (key, value) => {
    const { project_costs } = this.state;
    const { repairwiseDBKeyMap } = this.props;
    let costs = project_costs;
    let highKeys = [
      "highMaterialPrice",
      "highLaborPrice",
      "highEquipmentPrice",
      "highMarketPrice",
      "highLaborMinimumPrice",
    ];
    let lowKeys = [
      "lowMaterialPrice",
      "lowLaborPrice",
      "lowEquipmentPrice",
      "lowMarketPrice",
      "lowLaborMinimumPrice",
    ];

    // So when you clear an input the totals go to NaN. figure out trades first.
    if (!key) {
      key = 0;
    }

    let highTotal = 0;
    highKeys.map((highKey) => {
      if (highKey !== key) {
        highTotal += costs[highKey];
      } else {
        highTotal += Number.parseInt(value);
      }
    });

    let lowTotal = 0;
    lowKeys.map((lowKey) => {
      if (lowKey !== key) {
        lowTotal += costs[lowKey];
      } else {
        lowTotal += Number.parseInt(value);
      }
    });

    costs.highTotalPrice = highTotal;
    costs.lowTotalPrice = lowTotal;
    costs[key] = value.length > 0 ? Number.parseInt(value) : 0;

    this.props.updateProject("cache_project_cost_high", costs.highTotalPrice);
    this.props.updateProject("cache_project_cost_low", costs.lowTotalPrice);
    this.props.updateProject(repairwiseDBKeyMap[key], costs[key]);
    this.setState({ project_costs: costs });
  };

  render() {
    const {
      editMaterial,
      editLabor,
      editEquipment,
      editLaborMinimum,
      editMarketPrice,
      project_costs,
      showDetails,
    } = this.state;
    const { index, level, project, disabled, errors } = this.props;
    let selected = project && project.xactware_quality === level.name;

    return (
      <div
        className={`${errors.xactware_quality ? "invalid" : ""} ${
          selected ? "md-diy-quality-card selected" : "md-diy-quality-card"
        }`}
        key={index}
      >
        <div className="md-diy-quality-header">
          <p
            onClick={(e) => {
              e.preventDefault();
              this.props.handleQualityClick(level, selected);
            }}
          >
            <span
              className={
                selected
                  ? "md-diy-quality-radio selected"
                  : "md-diy-quality-radio"
              }
            />{" "}
            {level.name} - {level.subheading}
          </p>
          <p className="md-diy-quality-cost">
            ${project_costs.lowTotalPrice || level.lowTotalPrice}-$
            {project_costs.highTotalPrice || level.highTotalPrice}
          </p>
        </div>

        <div className="md-diy-quality-body">
          <div className="md-diy-quality-details">
            <h4>Details</h4>
            <p>{level.description}</p>
          </div>

          <div className="md-quality-details-toggle">
            {showDetails[index] ? (
              <a
                href="#"
                className={`${disabled ? "d-none" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleDetails(index);
                }}
              >
                <i className="fa fa-caret-up" />
              </a>
            ) : (
              <a
                href="#"
                className={`${disabled ? "d-none" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleDetails(index);
                }}
              >
                <i className="fa fa-caret-down" />
              </a>
            )}
          </div>

          {showDetails[index] && (
            <div
              className="md-diy-quality-details"
              style={{ marginTop: "15px", maxWidth: "100%", width: "100%" }}
            >
              <hr />

              <h4 className="md-subheader">Cost Breakdown</h4>

              <table>
                <tbody>
                  <tr>
                    <td>Total Cost</td>
                    <td className="text-right">
                      <b>
                        $
                        {project_costs.lowTotalPrice ||
                          level.lowTotalPrice ||
                          project.lowTotalPrice}
                        -$
                        {project_costs.highTotalPrice ||
                          level.highTotalPrice ||
                          project.highTotalPrice}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Materials</td>

                    <td className="text-right">
                      {editMaterial && !disabled ? (
                        <div>
                          $
                          <input
                            type="number"
                            value={`${
                              project_costs.lowMaterialPrice != null
                                ? project_costs.lowMaterialPrice
                                : level.lowMaterialPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "lowMaterialPrice",
                                e.target.value
                              )
                            }
                          />
                          - $
                          <input
                            type="number"
                            value={`${
                              project_costs.highMaterialPrice != null
                                ? project_costs.highMaterialPrice
                                : level.highMaterialPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "highMaterialPrice",
                                e.target.value
                              )
                            }
                          />
                          &nbsp;
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editMaterial", false)
                            }
                          >
                            <i className="fa fa-floppy-o" />
                          </a>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <p>
                            $
                            {project_costs.lowMaterialPrice ||
                              level.lowMaterialPrice}
                            -$
                            {project_costs.highMaterialPrice ||
                              level.highMaterialPrice}
                          </p>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editMaterial", true)
                            }
                          >
                            &nbsp;
                            <i className="fa fa-pencil" />
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Labor</td>
                    <td className="text-right">
                      {editLabor && !disabled ? (
                        <div>
                          $
                          <input
                            type="number"
                            value={`${
                              project_costs.lowLaborPrice != null
                                ? project_costs.lowLaborPrice
                                : level.lowLaborPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "lowLaborPrice",
                                e.target.value
                              )
                            }
                          />
                          - $
                          <input
                            type="number"
                            value={`${
                              project_costs.highLaborPrice != null
                                ? project_costs.highLaborPrice
                                : level.highLaborPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "highLaborPrice",
                                e.target.value
                              )
                            }
                          />
                          &nbsp;
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editLabor", false)
                            }
                          >
                            <i className="fa fa-floppy-o" />
                          </a>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <p>
                            $
                            {project_costs.lowLaborPrice || level.lowLaborPrice}
                            -$
                            {project_costs.highLaborPrice ||
                              level.highLaborPrice}
                          </p>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editLabor", true)
                            }
                          >
                            &nbsp;
                            <i className="fa fa-pencil" />
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Equipment</td>
                    <td className="text-right">
                      {editEquipment && !disabled ? (
                        <div>
                          $
                          <input
                            type="number"
                            value={`${
                              project_costs.lowEquipmentPrice != null
                                ? project_costs.lowEquipmentPrice
                                : level.lowEquipmentPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "lowEquipmentPrice",
                                e.target.value
                              )
                            }
                          />
                          - $
                          <input
                            type="number"
                            value={`${
                              project_costs.highEquipmentPrice != null
                                ? project_costs.highEquipmentPrice
                                : level.highEquipmentPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "highEquipmentPrice",
                                e.target.value
                              )
                            }
                          />
                          &nbsp;
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editEquipment", false)
                            }
                          >
                            <i className="fa fa-floppy-o" />
                          </a>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <p>
                            $
                            {project_costs.lowEquipmentPrice ||
                              level.lowEquipmentPrice}
                            -$
                            {project_costs.highEquipmentPrice ||
                              level.highEquipmentPrice}
                          </p>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editEquipment", true)
                            }
                          >
                            &nbsp;
                            <i className="fa fa-pencil" />
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Market Price</td>
                    <td className="text-right">
                      {editMarketPrice && !disabled ? (
                        <div>
                          $
                          <input
                            type="number"
                            value={`${
                              project_costs.lowMarketPrice != null
                                ? project_costs.lowMarketPrice
                                : level.lowMarketPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "lowMarketPrice",
                                e.target.value
                              )
                            }
                          />
                          - $
                          <input
                            type="number"
                            value={`${
                              project_costs.highMarketPrice != null
                                ? project_costs.highMarketPrice
                                : level.highMarketPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "highMarketPrice",
                                e.target.value
                              )
                            }
                          />
                          &nbsp;
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editMarketPrice", false)
                            }
                          >
                            <i className="fa fa-floppy-o" />
                          </a>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <p>
                            $
                            {project_costs.lowMarketPrice ||
                              level.lowMarketPrice}
                            -$
                            {project_costs.highMarketPrice ||
                              level.highMarketPrice}
                          </p>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editMarketPrice", true)
                            }
                          >
                            &nbsp;
                            <i className="fa fa-pencil" />
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Contractor Fees</td>
                    <td className="text-right">
                      {editLaborMinimum && !disabled ? (
                        <div className="d-flex justify-content-end">
                          $
                          <input
                            type="number"
                            value={`${
                              project_costs.lowLaborMinimumPrice != null
                                ? project_costs.lowLaborMinimumPrice
                                : level.lowLaborMinimumPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "lowLaborMinimumPrice",
                                e.target.value
                              )
                            }
                          />
                          - $
                          <input
                            type="number"
                            value={`${
                              project_costs.highLaborMinimumPrice != null
                                ? project_costs.highLaborMinimumPrice
                                : level.highLaborMinimumPrice
                            }`}
                            onChange={(e) =>
                              this.handleCostsUpdate(
                                "highLaborMinimumPrice",
                                e.target.value
                              )
                            }
                          />
                          &nbsp;
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editLaborMinimum", false)
                            }
                          >
                            <i className="fa fa-floppy-o" />
                          </a>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <p>
                            $
                            {project_costs.lowLaborMinimumPrice ||
                              level.lowLaborMinimumPrice}
                            -$
                            {project_costs.highLaborMinimumPrice ||
                              level.highLaborMinimumPrice}
                            &nbsp;
                          </p>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.toggleEdit(e, "editLaborMinimum", true)
                            }
                          >
                            <i className="fa fa-pencil" />
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

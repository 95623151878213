import axios from '../services/axios';
import { store } from '../ProviderApp';

/*=========== action names ===========*/

export const GET_ALL_FEATURE_REFS_ATTEMPT = 'GET_ALL_FEATURE_REFS_ATTEMPT';
export const GET_ALL_FEATURE_REFS_SUCCESS = 'GET_ALL_FEATURE_REFS_SUCCESS';
export const GET_ALL_FEATURE_REFS_FAIL = 'GET_ALL_FEATURE_REFS_FAIL';

export const GET_FEATURE_REF_ATTEMPT = 'GET_FEATURE_REF_ATTEMPT';
export const GET_FEATURE_REF_SUCCESS = 'GET_FEATURE_REF_SUCCESS';
export const GET_FEATURE_REF_FAIL = 'GET_FEATURE_REF_FAIL';

export const UPDATE_FEATURE_REF = 'UPDATE_FEATURE_REF';
export const UPDATE_FEATURE_REF_ATTEMPT = "UPDATE_FEATURE_REF_ATTEMPT";
export const UPDATE_FEATURE_REF_SUCCESS = "UPDATE_FEATURE_REF_SUCCESS";
export const UPDATE_FEATURE_REF_FAIL = "UPDATE_FEATURE_REF_FAIL";

export const DELETE_FEATURE_REF_ATTEMPT = "DELETE_FEATURE_REF_ATTEMPT";
export const DELETE_FEATURE_REF_SUCCESS = "DELETE_FEATURE_REF_SUCCESS";
export const DELETE_FEATURE_REF_FAIL = "DELETE_FEATURE_REF_FAIL";

export const CREATE_FEATURE_REF_ATTEMPT = "CREATE_FEATURE_REF_ATTEMPT";
export const CREATE_FEATURE_REF_SUCCESS = "CREATE_FEATURE_REF_SUCCESS";
export const CREATE_FEATURE_REF_FAIL = "CREATE_FEATURE_REF_FAIL";

export const SORT_FEATURES = "SORT_FEATURES";

export const GET_FEATURE_REF_SCORES_ATTEMPT = 'GET_FEATURE_REF_SCORES_ATTEMPT';
export const GET_FEATURE_REF_SCORES_SUCCESS = 'GET_FEATURE_REF_SCORES_SUCCESS';
export const GET_FEATURE_REF_SCORES_FAIL = 'GET_FEATURE_REF_SCORES_FAIL';

/*=========== reducer actions ===========*/

const getFeatureRefAttempt = () => {
	return {
		type: GET_FEATURE_REF_ATTEMPT,
	};
};

const getFeatureRefSuccess = (featureRef) => {
	return {
		type: GET_FEATURE_REF_SUCCESS,
		featureRef: featureRef
	};
};

const getFeatureRefFail = (err) => {
	return {
		type: GET_FEATURE_REF_FAIL,
		err: err
	};
};

export const updateFeatureRefState = (key, value) => {
	return {
		type: UPDATE_FEATURE_REF,
		key: key,
		value: value,
	};
};

const updateFeatureRefAttempt = () => {
	return {
		type: UPDATE_FEATURE_REF_ATTEMPT,
	};
};

const updateFeatureRefSuccess = () => {
	return {
		type: UPDATE_FEATURE_REF_SUCCESS,
	};
};

const updateFeatureRefFail = (err) => {
	return {
		type: UPDATE_FEATURE_REF_FAIL,
		err: err
	};
};

const deleteFeatureRefAttempt = () => {
	return {
		type: DELETE_FEATURE_REF_ATTEMPT,
	};
};

const deleteFeatureRefSuccess = () => {
	return {
		type: DELETE_FEATURE_REF_SUCCESS,
	};
};

const deleteFeatureRefFail = (err) => {
	return {
		type: DELETE_FEATURE_REF_FAIL,
		err: err
	};
};

const getAllFeatureRefsAttempt = () => {
	return {
		type: GET_ALL_FEATURE_REFS_ATTEMPT,
	};
};

const getAllFeatureRefsSuccess = (allFeatureRefs, allAreas) => {
	return {
		type: GET_ALL_FEATURE_REFS_SUCCESS,
		allFeatureRefs: allFeatureRefs,
		allAreas: allAreas,
	};
};

const getAllFeatureRefsFail = (err) => {
	return {
		type: GET_ALL_FEATURE_REFS_FAIL,
		err: err
	};
};

const createFeatureRefAttempt = () => {
	return {
		type: CREATE_FEATURE_REF_ATTEMPT,
	};
};

const createFeatureRefSuccess = () => {
	return {
		type: CREATE_FEATURE_REF_SUCCESS,
	};
};

const createFeatureRefFail = (err) => {
	return {
		type: CREATE_FEATURE_REF_FAIL,
		err: err
	};
};

const getFeatureRefScoresAttempt = () => {
	return {
		type: GET_FEATURE_REF_SCORES_ATTEMPT,
	};
};

const getFeatureRefScoresSuccess = (featureRef) => {
	return {
		type: GET_FEATURE_REF_SCORES_SUCCESS,
		featureRef: featureRef
	};
};

const getFeatureRefScoresFail = (err) => {
	return {
		type: GET_FEATURE_REF_SCORES_FAIL,
		err: err
	};
};

export const sortFeaturesBy = (column) => {
	let direction = ""
	let filter = ""
	if (column === store.getState().featureRef.currentSort.sort) {
		filter = column
		direction = store.getState().featureRef.currentSort.direction === "asc" ? "desc" : "asc"
	} else {
		filter = column
		direction = "asc"
	}

	return {
		type: SORT_FEATURES,
		currentSort: {
			sort: filter,
			direction: direction
		},
	};
};

/*=========== actions ===========*/

const fetchFeatureRef = (dispatch, id) => {
	dispatch(getFeatureRefAttempt());
	axios.get(`/admin/property/feature/${id}`)
		.then((res) => {
			let value = {};
			if (res.data.length > 0) {
				value = res.data[0];
			}
			dispatch(getFeatureRefSuccess(value));
		}).catch(err => {
			dispatch(getFeatureRefFail(err));
		});
};

export const getFeatureRef = (id) => dispatch => {
	fetchFeatureRef(dispatch, id);
};

export const updateFeatureRef = (id) => dispatch => {
	dispatch(updateFeatureRefAttempt());
	axios.put(`/admin/property/feature/${id}`, store.getState().featureRef.featureRef)
		.then((res) => {
			dispatch(updateFeatureRefSuccess());
			fetchFeatureRef(dispatch, id);
		}).catch(err => {
			dispatch(updateFeatureRefFail(err));
		});
}

export const deleteFeatureRef = (id, callback) => dispatch => {
	dispatch(deleteFeatureRefAttempt());
	axios.delete(`/admin/property/feature/${id}`)
		.then((res) => {
			dispatch(deleteFeatureRefSuccess());
			callback(true);
		}).catch(err => {
			dispatch(deleteFeatureRefFail(err));
			callback(false);
		});
}

const fetchAllFeatureRefs = () => (dispatch) => {
	dispatch(getAllFeatureRefsAttempt());
	axios.get(`/admin/property/feature/allFeatures`)
		.then((res) => {
			dispatch(getAllFeatureRefsSuccess(res.data.features, res.data.areas));
		}).catch(err => {
			dispatch(getAllFeatureRefsFail(err));
		});
};

export const getAllFeatureRefs = () => dispatch => {
	if (store.getState().featureRef.allFeatureRefs.length === 0) {
		fetchAllFeatureRefs()(dispatch);
	}
};

export const ForceetAllFeatureRefs = () => dispatch => {
	fetchAllFeatureRefs()(dispatch);
};

export const createFeatureRef = (newFeatureRef, callback) => dispatch => {
	dispatch(createFeatureRefAttempt());
	axios.post(`/admin/property/feature`, { features: [newFeatureRef] })
		.then((res) => {
			if (res.error) {
				dispatch(createFeatureRefFail(new Error(res.error)));
				callback(false, null)
			} else {
				dispatch(createFeatureRefSuccess());
				fetchAllFeatureRefs()(dispatch);
				callback(true, res.data.successes[0].insertId);
			}
		}).catch(err => {
			dispatch(createFeatureRefFail(err));
			callback(false, null);
		});
}

const fetchScoreOptionsForFeatureRef = (dispatch, id) => {
	dispatch(getFeatureRefScoresAttempt());
	axios.get(`/admin/property/feature/${id}`)
		.then((res) => {
			let value = {
				feature_ref_id: id,
				options: [],
			};
			if (res.data.length > 0) {
				value.options.push(res.data[0].score_0_description);
				value.options.push(res.data[0].score_1_description);
				value.options.push(res.data[0].score_2_description);
				value.options.push(res.data[0].score_3_description);
				value.options.push(res.data[0].score_4_description);
				value.options.push(res.data[0].score_5_description);
			}
			dispatch(getFeatureRefScoresSuccess(value));
		}).catch(err => {
			dispatch(getFeatureRefScoresFail(err));
		});
};

export const getScoreOptionsForFeatureRef = (feature_ref_id) => dispatch => {
	//If the feature is already in state, don't fetch it again. Otherwise fetch the new feature.
	if (!(`FRId_${feature_ref_id}` in store.getState().featureRef.dropDownOptions)) {
		fetchScoreOptionsForFeatureRef(dispatch, feature_ref_id);
	}
};

export const forceGetScoreOptionsForFeatureRef = (feature_ref_id) => dispatch => {
	fetchScoreOptionsForFeatureRef(dispatch, feature_ref_id);
};
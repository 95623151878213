import './index.scss'
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';

export default class EditImageModal extends Component {

    state = {
        croppedImageUrl: null,
        blob: null,
        crop: {
            width: 300,
            height: 200,
            aspect: 3 / 2,
        }
    }

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                this.setState({ blob: blob });
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { reportID, propertyID } = this.props;
        const { croppedImageUrl } = this.state;
        let self = this;

        this.urltoFile(croppedImageUrl, `primary_photo_${reportID}_${propertyID}.jpg`, 'image/jpeg')
            .then(function (file) {
                let formData = new FormData();
                formData.append("doc_file", file);
                formData.append("doc_title", file.name);
                formData.append("doc_description", 'Primary Photo');
                formData.append("property_photo", true);
                self.props.uploadPhoto(formData)

                // Close modal and change to photos tab (step 5)
                self.props.setPdfImage(null)
                self.props.updateStep(5)
            });
    }

    render() {
        const { crop } = this.state;
        const { src } = this.props;

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                className="md-edit-image-modal"
                onHide={() => this.props.setPdfImage(null)}
            >
                <Modal.Body className="d-flex justify-content-around">
                    {src && (
                        <ReactCrop
                            src={src}
                            crop={crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleSubmit}>Crop and Save</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
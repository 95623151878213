import {
	GET_ALL_INSPECTORS_ATTEMPT,
	GET_ALL_INSPECTORS_FAIL,
	GET_ALL_INSPECTORS_SUCCESS,
} from '../actions';

const initialState = {
	inspectors: [],
	inspectorsFetching: false,
	inspectorsFetched: false,
	error: null,
}

export function inspectors(state = initialState, action){
	switch (action.type){
		case GET_ALL_INSPECTORS_ATTEMPT:
			return {
				...state,
				inspectorsFetching: true,
				inspectorsFetched: false,
			}
		case GET_ALL_INSPECTORS_SUCCESS:
			return {
				...state,
				inspectors: action.inspectors,
				inspectorsFetching: false,
				inspectorsFetched: true,
			}
		case GET_ALL_INSPECTORS_FAIL:
			return {
				...state,
				inspectorsFetching: false,
				inspectorsFetched: false,
				error: action.err,
			}
		default:
			return {
				...state,
			}
	}
}
import "../index.scss";
import React, { useEffect, useState } from "react";

export default function EditQueuedProject({
  report,
  queuedProject,
  updateStep,
  updatePageNumber,
}) {
  const [project, setProject] = useState({});
  const [title, setTitle] = useState();

  useEffect(() => {
    setProject({ ...queuedProject });
    setTitle(queuedProject.text.split(".")[0]);
  }, []);

  function updateProject(key, value) {
    if (key === "text") {
      setTitle(value.split(".")[0]);
    }

    setProject({ ...project, [key]: value });
  }

  function handleSubmit() {
    let queuedProjects = JSON.parse(
      localStorage.getItem("queued-projects")
    ) || { [report.unique_id]: [] };

    if (queuedProjects[report.unique_id]) {
      const index = queuedProjects[report.unique_id].findIndex((queued) => {
        return queued.text === queuedProject.text;
      });

      queuedProjects[report.unique_id][index] = project;
      localStorage.setItem("queued-projects", JSON.stringify(queuedProjects));
    }

    updateStep(1);
  }

  function deleteProject() {
    let queuedProjects =
      JSON.parse(localStorage.getItem("queued-projects")) || [];

    if (queuedProjects[report.unique_id]) {
      const index = queuedProjects[report.unique_id].findIndex((queued) => {
        return queued.text === queuedProject.text;
      });

      queuedProjects[report.unique_id].splice(index, 1);
      localStorage.setItem("queued-projects", JSON.stringify(queuedProjects));
    }

    updateStep(1);
  }

  function scrollToPage(pageNum) {
    if (pageNum < window.PDFViewerApplication.pdfViewer._pages.length) {
      updatePageNumber(pageNum);
    } else {
      updatePageNumber(window.PDFViewerApplication.pdfViewer._pages.length);
    }
  }

  if (!Object.keys(project).length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="md-diy-sidebar-content">
      <div className="md-diy-sidebar-info">
        <h1 className="md-diy-sidebar-header">Create Project</h1>
      </div>

      <div className="md-diy-sidebar-form">
        <label>Defect Description</label>
        <div className="md-diy-input-wrapper">
          <img
            src="/assets/icons/Description.svg"
            className="md-diy-input-icon"
          />
          <textarea
            rows="5"
            placeholder="Add defect description here"
            value={project.text || ""}
            onChange={(e) => updateProject("text", e.target.value)}
          />
        </div>

        <label>Defect Title</label>
        <div className="md-diy-input-wrapper">
          <img src="/assets/icons/Project.svg" className="md-diy-input-icon" />
          <input
            type="text"
            placeholder="One or more unsealed holes"
            value={title || ""}
            disabled
            readOnly
          />
        </div>

        <div className="md-diy-input-wrapper row">
          <div className="col-6">
            <label>PDF Page Start</label>
          </div>
          <div className="col-6">
            <label>PDF Page End</label>
          </div>
        </div>
        <div className="md-diy-input-wrapper row">
          <div className="col-6">
            <img
              src="/assets/icons/Page.svg"
              className="md-diy-input-icon cursor-pointer"
              onClick={() => updatePageNumber(project.pageStart)}
            />
            <input
              type="text"
              placeholder="Page Start #"
              value={project.pageStart || ""}
              onChange={(e) =>
                updateProject("pageStart", parseInt(e.target.value))
              }
            />
          </div>
          <div className="col-6">
            <img
              src="/assets/icons/Page.svg"
              className="md-diy-input-icon cursor-pointer"
              onClick={() => updatePageNumber(project.pageEnd)}
            />
            <input
              type="text"
              placeholder="Page End #"
              value={project.pageEnd || ""}
              onChange={(e) =>
                updateProject("pageEnd", parseInt(e.target.value))
              }
            />
          </div>
        </div>

        <button className="btn btn-primary" onClick={handleSubmit}>
          Update Queued Project
        </button>
        <a href="#" className="btn btn-danger" onClick={deleteProject}>
          Delete
        </a>
        <a href="#" className="btn btn-uigray500" onClick={() => updateStep(1)}>
          Cancel
        </a>
      </div>
    </div>
  );
}

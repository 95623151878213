import {
	GET_ALL_PFMS_ATTEMPT,
	GET_ALL_PFMS_FAIL,
	GET_ALL_PFMS_SUCCESS,
	CHANGE_SORT,

	GET_ONE_PFM_ATTEMPT,
	GET_ONE_PFM_SUCCESS,
	GET_ONE_PFM_FAIL,

	CREATE_EMPTY_PFM,
	UPDATE_PFM,

	CREATE_PFM_ATTEMPT,
	CREATE_PFM_SUCCESS,
	CREATE_PFM_FAIL,

	UPDATE_PFM_ATTEMPT,
	UPDATE_PFM_SUCCESS,
	UPDATE_PFM_FAIL,

	DELETE_PFM_ATTEMPT,
	DELETE_PFM_SUCCESS,
	DELETE_PFM_FAIL,

	VERIFY_PFM_ATTEMPT,
	VERIFY_PFM_SUCCESS,
	VERIFY_PFM_FAIL,


} from '../actions'

const initialState = {
	total: 0,
	limit: 50,
	sort: "id",
	asc: true,
	page: 0,
	maps: [],
	fetchingPFMs: false,
	fetchingOnePFM: false,
	disableButtons: false,
	disableVerifyButton: false,
	currentPFM:{
		id: null,
		name: null,
		description: null,
		uuid: null,
		unitOfMeassure: null,
		trade_id: null,
		feature_action_ref_id: null,
		feature_ref_id: null,
		adminVerifiedAt:null,
	},
	error: null
}

export function projectFeatureMap(state = initialState, action){
	switch(action.type){
		case GET_ALL_PFMS_ATTEMPT:{
			return {
				...state,
				fetchingPFMs: true,
				error: null,
				page: action.page
			}
		}
		case GET_ALL_PFMS_FAIL:{
			return {
				...state,
				maps: [],
				total: 0,
				error: action.err,
				fetchingPFMs: false,
			}
		}
		case GET_ALL_PFMS_SUCCESS:{
			return {
				...state,
				maps: action.pfms.projectFeatureMaps,
				total: action.pfms.total,
				error: null,
				fetchingPFMs: false,
			}
		}
		case CHANGE_SORT: {
			return {
				...state,
				sort: action.sort,
				asc: action.asc,
			}
		}
		case GET_ONE_PFM_ATTEMPT:{
			return {
				...state,
				fetchingOnePFM: true,
				currentPFM:{
					id: action.id,
					name: null,
					description: null,
					uuid: null,
					unitOfMeassure: null,
					trade_id: null,
					feature_action_ref_id: null,
					feature_ref_id: null,
					adminVerifiedAt:null,
				},
			}
		}
		case GET_ONE_PFM_SUCCESS:{
			return {
				...state,
				fetchingOnePFM: true,
				currentPFM:{
					...action.pfm
				}
			}
		}
		case GET_ONE_PFM_FAIL:{
			return {
				...state,
				fetchingOnePFM: true,
				error: action.err
			}
		}
		case CREATE_EMPTY_PFM: {
			return {
				...state,
				currentPFM:{
					id: null,
					name: null,
					description: null,
					uuid: null,
					unitOfMeassure: null,
					trade_id: null,
					feature_action_ref_id: null,
					feature_ref_id: null,
					adminVerifiedAt:null,
				},
			}
		}
		case UPDATE_PFM: {
			return {
				...state,
				currentPFM:{
					...state.currentPFM,
					[action.key]:action.value 
				}
			}
		}
		case CREATE_PFM_ATTEMPT: {
			return {
				...state,
				disableButtons: true,
			}
		}
		case CREATE_PFM_SUCCESS: {
			return {
				...state,
				disableButtons: false,
				currentPFM: {
					...state.currentPFM,
					id: action.newId
				}
			}
		}
		case CREATE_PFM_FAIL: {
			return {
				...state,
				disableButtons: false,
			}
		}
		case UPDATE_PFM_ATTEMPT: {
			return {
				...state,
				disableButtons: true,
			}
		}
		case UPDATE_PFM_SUCCESS: {
			return {
				...state,
				disableButtons: false,
			}
		}
		case UPDATE_PFM_FAIL: {
			return {
				...state,
				disableButtons: false,
			}
		}
		case DELETE_PFM_ATTEMPT: {
			return {
				...state,
				disableButtons: true,
			}
		}
		case DELETE_PFM_SUCCESS: {
			return {
				...state,
				disableButtons: false,
			}
		}
		case DELETE_PFM_FAIL: {
			return {
				...state,
				disableButtons: false,
			}
		}
		case VERIFY_PFM_ATTEMPT: {
			return {
				...state,
				disableVerifyButton: true,
			}
		}
		case VERIFY_PFM_SUCCESS: {
			return {
				...state,
				disableVerifyButton: false,
			}
		}
		case VERIFY_PFM_FAIL: {
			return {
				...state,
				disableVerifyButton: false,
			}
		}
		default: {
			return {
				...state,
			}
		}
	}
}
import React, { Component } from "react";
import {
  Container,
  Alert,
  Button,
  Row,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import withFetch from "../../hocs/withFetch";
import withUser from "../../hocs/withUser";
import CreateModal from "../../ui/CreateModal/photoModal";
import { _returnFields } from "../../ui/FormByKey/projectFields";
import { WithAlert } from "../../hocs/WithAlert";
import MDImage from "../MDImage";
import ImageModal from "../../ui/ImageModal";
const AlertMessage = WithAlert(Alert);

export default
@withRouter
@withUser()
@withFetch((props) => ({
  documents: {
    url: `/domoreport/documentsForDomoreport/${props.match.params.report_id}`,
    force: true,
    refreshing: true,
  },
  allPhotos: {
    url: `/domoreport/propertyPhotosForDomoreport/${props.match.params.report_id}`,
    force: true,
    refreshing: true,
  },
  deletePhoto: (doc_log_id) => ({
    deletedPhoto: {
      url: `domoreport/${props.match.params.report_id}/domoreportDocument/${doc_log_id}`,
      force: true,
      method: "DELETE",
    },
  }),
  refetchProperty: () => ({
    documents: {
      url: `/domoreport/documentsForDomoreport/${props.match.params.report_id}`,
      force: true,
      refreshing: true,
    },
  }),
  uploadPhoto: (data) => ({
    uploadedInspector: {
      url: `/domoreport/documentForDomoreport/${props.match.params.report_id}`,
      method: "POST",
      headers: {
        "Content-Type": null,
      },
      body: data,
    },
  }),
  updatePhoto: (doc_log_id, data) => ({
    updateInspector: {
      url: `domoreport/${props.match.params.report_id}/domoreportDocument/${doc_log_id}`,
      method: "PUT",
      body: JSON.stringify(data),
    },
  }),
}))
class ReportPhotosComponent extends Component {
  state = {
    createModalOpen: false,
    newPhoto: {},
    alertMessage: "",
    alertBG: "",
    formSubmission: false,
    photoSubmitting: false,
    upload: false,
    photo: null,
    photo_report_id: null,
    photo_doc_log_id: null,
    photo_user_token: null,
    display: "all_documents",
    image: null,
    height: null,
    showImageModal: false,
  };
  componentDidMount() {
    const initializeNewPhotoObj = {
      domoreport_log_id: this.props.match.params.report_id,
    };
    this.setState({
      newPhoto: initializeNewPhotoObj,
      display: "all_documents",
    });
  }

  componentDidUpdate() {
    const { documents } = this.props;
    if (this.state.formSubmission && !documents.refresh) {
      if (documents.value && documents.settled) {
        this.setState({
          formSubmission: false,
          alertMessage: "Successfully uploaded photo",
          alertBG: "success",
        });
      } else if (!documents.value && documents.settled) {
        this.setState({
          formSubmission: false,
          alertMessage: "Photo upload failed!!",
          alertBG: "danger",
        });
      }
    }
  }

  _createNewPhoto = async (cond, modalState) => {
    if (modalState.photos && modalState.photos.length === 1) {
      let prepared = new FormData();
      prepared.append("doc_file", modalState.photos[0]);
      prepared.append("doc_title", modalState.title);
      prepared.append("doc_description", modalState.description);
      prepared.append("property_photo", modalState.photoType);
      await this.props.uploadPhoto(prepared);
    } else {
      const uploads = await modalState.photos.map(async (photo) => {
        let prepared = new FormData();

        prepared.append("doc_file", photo);
        prepared.append("doc_title", photo.name);
        prepared.append("doc_description", "Default description");
        prepared.append("property_photo", modalState.photoType);

        await this.props.uploadPhoto(prepared);
        return prepared;
      });
    }
    this.setState(
      {
        createModalOpen: false,
        formSubmission: true,
      },
      this.props.refetchProperty
    );
  };

  _deletePhoto = async (doc_log_id) => {
    await this.props.deletePhoto(doc_log_id);
    this.setState(
      {
        createModalOpen: false,
        alertMessage: "Photo was deleted",
        alertBG: "warning",
        upload: false,
        photo: null,
        photo_report_id: null,
        photo_doc_log_id: null,
        photo_user_token: null,
      },
      this.props.refetchProperty
    );
  };

  _updatePhoto = async (cond, modalState) => {
    const data = {
      doc_title: modalState.title,
      doc_description: modalState.description,
    };
    await this.props.updatePhoto(modalState.doc_log_id, data);
    this.setState(
      {
        createModalOpen: false,
        alertMessage: "Photo was updated",
        alertBG: "success",
        upload: false,
        photo: null,
        photo_report_id: null,
        photo_doc_log_id: null,
        photo_user_token: null,
      },
      this.props.refetchProperty
    );
  };

  _resetMessage = () => {
    this.setState({
      alertMessage: "",
      alertBG: "",
    });
  };

  _toggleModal = (
    upload = false,
    photo = null,
    photo_report_id = null,
    photo_doc_log_id = null,
    photo_user_token = null,
    doc_description = null,
    doc_title = null
  ) => {
    const createModalOpen = this.state.createModalOpen;
    this.setState({
      createModalOpen: !createModalOpen,
      upload,
      photo,
      photo_report_id,
      photo_doc_log_id,
      photo_user_token,
      doc_description,
      doc_title,
    });
  };

  _setImage = (image, height) => {
    this.setState({
      image: image,
      height: height,
    });
  };

  displayChanged = (e) => {
    this.setState({
      display: e.target.value,
    });
  };
  _savePhotoToState = (docId, image) => {
    this.setState({ [`doc_${docId}`]: image });
  };

  render() {
    const { createModalOpen, photoSubmitting } = this.state;
    const { documents, allPhotos, photoFields, userCanEdit } = this.props;

    const docPhotos =
      documents && documents.value && documents.value.documents
        ? documents.value.documents
        : [];
    const allDomoPhotos =
      allPhotos && allPhotos.value && allPhotos.value.documents
        ? allPhotos.value.documents
        : [];
    let { value: fields } = photoFields ? photoFields : {};
    let emptyValues = {};

    if (fields) {
      for (let i = 0; i < fields.length; i++) {
        emptyValues[fields[i].Field] = null;
      }
    }

    return (
      <div className="md-scroll-wrapper">
        <Container>
          {this.state.alertMessage ? (
            <AlertMessage
              resetMessage={this._resetMessage}
              variant={this.state.alertBG}
              message={this.state.alertMessage}
            />
          ) : null}
          <h2>Photos for report {this.props.match.params.report_id}</h2>
          {userCanEdit ? (
            <Button onClick={() => this._toggleModal(true)}>
              Upload Photo{" "}
            </Button>
          ) : (
            <div />
          )}
          {createModalOpen && (
            <CreateModal
              submitting={photoSubmitting}
              keys={fields}
              close={this._toggleModal}
              onChange={this._handleChange}
              submit={this._createNewPhoto}
              headerTitle={"Upload New Photos"}
              returnFields={_returnFields}
              dropdownValues={{ ...emptyValues }}
              delete={this._deletePhoto}
              update={this._updatePhoto}
              upload={this.state.upload}
              photo={this.state[`doc_${this.state.photo_doc_log_id}`]}
              photo_report_id={this.state.photo_report_id}
              photo_info={this.state.photo}
              photo_doc_log_id={this.state.photo_doc_log_id}
              photo_user_token={this.state.photo_user_token}
            />
          )}
          {(this.state.display === "property_photos" ||
            this.state.display === "all_documents") &&
          allDomoPhotos.filter((photo) => photo.is_image).length ? (
            <React.Fragment>
              <Row className="">
                <Col xs="12">
                  <h3>Property Photos</h3>
                </Col>
              </Row>
              <Row className="my-2">
                {allDomoPhotos
                  .filter((photo) => photo.is_image)
                  .map((photo) => (
                    <Col xs="6" key={photo.doc_log_id}>
                      <Card
                        className="mr-1"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this._toggleModal(
                            false,
                            photo,
                            this.props.match.params.report_id,
                            photo.doc_log_id,
                            this.props.user.token
                          )
                        }
                      >
                        <MDImage
                          className="thumbnail-img"
                          key={photo.doc_log_id}
                          sourceURL={`http://majordomostagingapi.herokuapp.com/api/1/domoreport/${this.props.match.params.report_id}/domoreportDocument/${photo.doc_log_id}`}
                          token={this.props.user.token}
                          alt={photo.doc_title}
                          onLoaded={this._savePhotoToState}
                          docId={photo.doc_log_id}
                          loadedPhotoData={null}
                        />
                      </Card>
                    </Col>
                  ))}
              </Row>
            </React.Fragment>
          ) : null}
          {(this.state.display === "all_documents" ||
            this.state.display === "featured_photos") &&
          docPhotos.filter((photo) => photo.is_image).length ? (
            <React.Fragment>
              <Row className="mt-5 ">
                <Col xs="12">
                  <h3>User photos (displayed on docs page)</h3>
                </Col>
              </Row>

              <Row className="my-2">
                {docPhotos
                  .filter((photo) => photo.is_image)
                  .map((photo) => (
                    <Col xs="6" key={photo.doc_log_id}>
                      <Card
                        className="mr-1"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this._toggleModal(
                            false,
                            photo,
                            this.props.match.params.report_id,
                            photo.doc_log_id,
                            this.props.user.token
                          )
                        }
                      >
                        <MDImage
                          className="thumbnail-img"
                          key={photo.doc_log_id}
                          sourceURL={`http://majordomostagingapi.herokuapp.com/api/1/domoreport/${this.props.match.params.report_id}/domoreportDocument/${photo.doc_log_id}`}
                          token={this.props.user.token}
                          alt={photo.doc_title}
                          onLoaded={this._savePhotoToState}
                          docId={photo.doc_log_id}
                        />
                      </Card>
                    </Col>
                  ))}
              </Row>
            </React.Fragment>
          ) : null}

          {(this.state.display === "all_documents" ||
            this.state.display === "not_image_document") &&
          docPhotos.filter((doc) => doc.is_image === 0).length ? (
            <React.Fragment>
              <Row className="mt-5 ">
                <Col xs="12">
                  <h3>Documents (displayed on docs page)</h3>
                </Col>
              </Row>

              <Row className="my-2">
                <Col>
                  <Card>
                    <Table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {docPhotos
                          .filter((doc) => doc.is_image === 0)
                          .map((doc, index) => (
                            <tr key={doc.doc_log_id}>
                              <th scope="row"> {index + 1} </th>
                              <td>{doc.doc_title}</td>
                              <td>{doc.doc_description}</td>
                              <td>{doc.extension}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          ) : null}

          {this.state.image ? (
            <ImageModal
              image={this.state.image}
              height={this.state.height}
              setImage={this._setImage}
              reportID={this.props.match.params.report_id}
              token={this.props.user.token}
            />
          ) : null}
        </Container>
      </div>
    );
  }
}

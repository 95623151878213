import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ConfirmModal({
  title,
  subtext,
  action,
  variant,
  show,
  close,
  handleSubmit,
}) {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {subtext ? <p className="text-center">{subtext}</p> : <div />}
        <div className="d-flex justify-content-around">
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
          <Button variant={variant} onClick={handleSubmit}>
            {action}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

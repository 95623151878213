module.exports = {
	SAVE_USER: 'save-user',
	SAVED_USER: 'user',
	SAVE_USER_RESPONSE: 'save-user-reply',
	ENVIRONMENT: process.env.REACT_APP_BUILD_ENV,
	STYLES: {
		CENTER: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	storage: {
		get: (key, callback) => {
			let data = localStorage.getItem(key);
			data = JSON.parse(data)
			callback(null, data);
		},
		set: (key, json, callback) => {
			json = JSON.stringify(json)
			localStorage.setItem(key, json);
			callback(null);
		},
	}
};

import React, { Component } from 'react';
import { IntDropdownField } from '../FormFields/';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {

} from '../../actions';

const mapStateToProps = (state) => ({
	sortedFeatures: state.properties.property.sortedFeatures,
	features: state.properties.property.features,
	propertyFetched: state.properties.propertyFetched,
});

class FeaturesDropdownField extends Component {

	constructor(props) {
		super(props);
		this._onChange = this._onChange.bind(this);
	}

	_onChange(key, value) {
		if (value <= 0) {
			this.props.onChange(key, null);
		} else {
			this.props.onChange(key, value);
		}

	}

	render() {
		let selectedFeature = {};
		if (this.props.propertyFetched) {
			selectedFeature = this.props.features.find(feature => {
				return feature.property_fact_id == this.props.value;
			});
		}
		return (
			<Container>
				<Row>
					<Col xs={8}>
						<IntDropdownField
							formName={"Feature"}
							fieldKey={"property_fact_id"}
							keysToIgnore={this.props.keysToIgnore}
							obj={{ property_fact_id: this.props.value }}
							keyAsIndex={this.props.keyAsIndex}
							onChange={this._onChange}
							options={this.props.sortedFeatures}
							displayKey="feature_name"

						/>
					</Col>
					<Col xs={2}>
						<Card>
							<Card.Header>Score</Card.Header>
							<Card.Body>
								<Card.Text>
									{selectedFeature && "feature_condition" in selectedFeature ? selectedFeature.feature_condition : "N/A"} out of 5
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={2}>
						<Card>
							<Card.Header>Year</Card.Header>
							<Card.Body>
								<Card.Text>
									{selectedFeature && "feature_age" in selectedFeature ? selectedFeature.feature_age : "N/A"}
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		)
	}
}


export default connect(
	mapStateToProps,
	{}
)(FeaturesDropdownField);
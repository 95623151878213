import React from 'react';
import { Form, Container, Badge} from 'react-bootstrap';
import { keysToIgnore } from '../../components/MDPerson';
import { 
	makeLabel, 
	BoolField, 
	IdNumberField, 
	IntNumberField, 
	UserNumberField,
	VarcharTextField,
	TextQuilField,
} from '../FormFields/';
import form from './form';

/*const makeLabel = (labelStr, key, keysToIgnore) => {
	let label = 
		(<Form.Label> {labelStr} <Badge variant="danger"> required </Badge> </Form.Label>);
	if((key in keysToIgnore)){
		label = (<Form.Label> {labelStr} <Badge variant="primary"> not required </Badge> </Form.Label>);
	}
	return label
}*/

const styleBorder = (obj, key, keysToIgnore) => {
	let borderStyle = {};
	if (!(key in keysToIgnore)){
		if ((obj ? (`${obj[key]}` === "null" ? "" :  `${obj[key]}`): "") === ""){
			borderStyle = {borderColor: "red", backgroundColor: "rgba(255, 0, 0, 0.075)"};
		} else {
			borderStyle = {borderColor: "green", formControl:{focus: {borderColor: "red"}}}
		}
	}

	return borderStyle;
}

const makePlaceholder = (obj, field) => {
	return (obj ? (`${obj[field.Field]}` === "null" ? "" :  `${obj[field.Field]}`): "")
}

export const _returnFields = (fields, obj, onChange, boolChange) =>{
	let indexShift = 0;
	return fields.map((field,index) => {
		let control = null;
		let label = null;
		let extra = null;
		let keyAsIndex = index + indexShift;
		let formGroup = null;
		switch(true){
			case field.Field.indexOf('user_log_id') !== -1:
				formGroup=(
					<UserNumberField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						keyAsIndex={keyAsIndex}
						obj ={obj}
					/>);
				indexShift += 1;
				break;
			case field.Field.indexOf('id') !== -1:
				formGroup = (
					<IdNumberField 
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						obj ={obj}
					/>);
				break;
			case field.Type.includes('tinyint'):
				formGroup = (
					<BoolField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onBoolChange={boolChange}
						obj ={obj}
					/>);
				break;
			case field.Type.indexOf('int') !== -1:
				formGroup = (
					<IntNumberField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj ={obj}
					/>
				)
				break;
			case field.Field.indexOf('email') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="email" placeholder={`${field.Field}`} value={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Field.indexOf('phone') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)
				control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore)} type="tel" placeholder={`${field.Field}`} value={makePlaceholder(obj, field)} onChange={(evt)=>{
					onChange(field.Field, evt.target.value);
				}}/>)
				break;
			case field.Type.indexOf('varchar') !== -1:
				formGroup = (
					<VarcharTextField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj ={obj}
					/>
				)
				break;
			case field.Type.indexOf('text') !== -1:
				formGroup = (
					<TextQuilField 
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj ={obj}
					/>
				)
				break;
			case field.Type.indexOf('time') !== -1:
				label = makeLabel(field.Field.replace(/_/g,' '), field.Field, keysToIgnore)                    
				control = (<Form.Text style={styleBorder(obj, field.Field, keysToIgnore)} >{makePlaceholder(obj, field)}</Form.Text>)
				break;
			default:
				
		}
		if(formGroup === null){
			return (
				<Container key={keyAsIndex}>
					<Form.Group controlId="fillerControlId" key={keyAsIndex}>
						{label}
						{control}
					</Form.Group>
					{extra}
				</Container>
			)
		} else {
			return formGroup;
		}
	})
}
import axios from '../services/axios';
import { store } from '../ProviderApp';
import { sortFeatures } from '../services/helpers';

/*=========== action names ===========*/

export const GET_PROPERTY_ATTEMPT = 'GET_PROPERTY_ATTEMPT';
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS';
export const GET_PROPERTY_FAIL = 'GET_PROPERTY_FAIL';

export const GET_WIZARD_FEATURES_ATTEMPT = 'GET_WIZARD_FEATURES_ATTEMPT';
export const GET_WIZARD_FEATURES_SUCCESS = 'GET_WIZARD_FEATURES_SUCCESS';
export const GET_WIZARD_FEATURES_FAIL = 'GET_WIZARD_FEATURES_FAIL';

export const PROPERTY_SORTED_BY_AREA = 'PROPERTY_SORTED_BY_AREA';

export const GET_AVAILABLE_FEATURES_ATTEMPT = 'GET_AVAILABLE_FEATURES_ATTEMPT';
export const GET_AVAILABLE_FEATURES_SUCCESS = 'GET_AVAILABLE_FEATURES_SUCCESS';
export const GET_AVAILABLE_FEATURES_FAIL = 'GET_AVAILABLE_FEATURES_FAIL';

export const CREATE_FEATURE_ATTEMPT = 'CREATE_FEATURE_ATTEMPT';
export const CREATE_FEATURE_SUCCESS = 'CREATE_FEATURE_SUCCESS';
export const CREATE_FEATURE_FAIL = 'CREATE_FEATURE_FAIL';

export const TOGGLE_CREATE_FEATURE_MODAL = 'TOGGLE_CREATE_FEATURE_MODAL';

export const UPDATE_NEW_FEATURE = 'UPDATE_NEW_FEATURE';

export const UPDATE_CREATED_FEATURE = 'UPDATE_CREATED_FEATURE';

/*=========== reducer actions ===========*/

const getPropertyAttempt = () => {
	return {
		type: GET_PROPERTY_ATTEMPT,
	};
};

const getPropertySuccess = (property, sortedFeatures) => {
	return {
		type: GET_PROPERTY_SUCCESS,
		property: property,
		sortedFeatures: sortedFeatures
	};
};

const getPropertyFail = (err) => {
	return {
		type: GET_PROPERTY_FAIL,
		err: err
	};
};

const getWizardFeaturesAttempt = () => {
	return {
		type: GET_WIZARD_FEATURES_ATTEMPT,
	};
};

const getWizardFeaturesSuccess = (wizardFeatures) => {
	return {
		type: GET_WIZARD_FEATURES_SUCCESS,
		wizardFeatures: wizardFeatures,
	};
};

const getWizardFeaturesFail = (err) => {
	return {
		type: GET_WIZARD_FEATURES_FAIL,
		err: err
	};
};

const propertySortedByArea = (sortedRooms) => {
	return {
		type: PROPERTY_SORTED_BY_AREA,
		sortedRooms: sortedRooms,
	};
};

const getAvailableFeaturesAttempt = () => {
	return {
		type: GET_AVAILABLE_FEATURES_ATTEMPT,
	};
};

const getAvailableFeaturesSuccess = (availableFeatures, availableFeaturesByArea) => {
	return {
		type: GET_AVAILABLE_FEATURES_SUCCESS,
		availableFeatures: availableFeatures,
		availableFeaturesByArea: availableFeaturesByArea,
	};
};

const getAvailableFeaturesFail = (err) => {
	return {
		type: GET_AVAILABLE_FEATURES_FAIL,
		err: err,
	};
};

const createFeatureAttempt = () => {
	return {
		type: CREATE_FEATURE_ATTEMPT,
	};
};

const createFeatureSuccess = () => {
	return {
		type: CREATE_FEATURE_SUCCESS,
	};
};

const createFeatureFail = () => {
	return {
		type: CREATE_FEATURE_FAIL,
	};
};

export const toggleCreateFeatureModal = (state, area_ref_id, area_id) => {
	return {
		type: TOGGLE_CREATE_FEATURE_MODAL,
		state: !!state,
		area_ref_id: area_ref_id,
		area_id: area_id,
	};
};

export const updateNewFeature = (key, value) => {
	return {
		type: UPDATE_NEW_FEATURE,
		key: key,
		value: value,
	};
};

export const updateCreatedFeature = (key, value) => {
	return {
		type: UPDATE_CREATED_FEATURE,
		key: key,
		value: value,
	};
};


/*=========== actions ===========*/


const createHeader = (str, totalLength) => {
	let dashLength = Math.floor((totalLength - Number(str.length)) / 2)
	let headerStr = "";
	for (let j = 0; j < dashLength; j++) {
		headerStr = headerStr + "-";
	}

	headerStr = headerStr + str;

	for (let j = 0; j < dashLength; j++) {
		headerStr = headerStr + "-";
	}

	if (headerStr.length < totalLength) {
		headerStr = headerStr + "-";
	}

	return headerStr;
};



const sortFeaturesForDropdown = (featuresByRoom) => {
	let tempFeatures = [];
	for (let i = 0; i < featuresByRoom.length; i++) {

		tempFeatures.push({
			feature_name: createHeader(`-${featuresByRoom[i].area_name}${featuresByRoom[i].area_log_name ? `, ${featuresByRoom[i].area_log_name}` : ""}-`, 50),
			property_fact_id: -1,
			disabled: true
		});
		tempFeatures = tempFeatures.concat(featuresByRoom[i].features)
	}
	return tempFeatures;
}


const fetchProperty = (prop_id) => (dispatch) => {
	dispatch(getPropertyAttempt());
	return axios.get(`/property/${prop_id}`)
		.then((res) => {
			res.data.property_log_id = prop_id;
			dispatch(getPropertySuccess(res.data, sortFeaturesForDropdown(res.data.rooms)));
		}).catch(err => {
			dispatch(getPropertyFail(err));
		});
};

export const getProperty = (prop_id) => dispatch => {
	if (!store.getState().properties.property.fetched && !store.getState().properties.property.fetching) {
		fetchProperty(prop_id)(dispatch);
	}
};

export const forceGetProperty = (prop_id) => dispatch => {
	fetchProperty(prop_id)(dispatch);
};

const fetchWizardFeatures = (prop_id) => dispatch => {
	dispatch(getWizardFeaturesAttempt());
	return axios.get(`/domoreport/uploader/allUnscoredWizardFeatures/${prop_id}`)
		.then((res) => {
			dispatch(getWizardFeaturesSuccess(res.data));
		}).catch(err => {
			dispatch(getWizardFeaturesFail(err));
		});
};

export const getWizardFeatures = (prop_id) => dispatch => {
	if (!store.getState().properties.wizardFeatures.fetched && !store.getState().properties.wizardFeatures.fetching) {
		fetchWizardFeatures(prop_id)(dispatch);
	}
};

export const forceGetWizardFeatures = (prop_id) => dispatch => {
	fetchWizardFeatures(prop_id)(dispatch);
};

export const sortByArea = (prop_id) => dispatch => {
	let sections = []
	fetchProperty(prop_id)(dispatch).then(() => {
		return fetchWizardFeatures(prop_id)(dispatch);
	}).then(() => {
		if (store.getState().properties.wizardFeatures.fetched && store.getState().properties.property.fetched) {
			let roomsArray = store.getState().properties.property.rooms;
			let wizardFeatures = store.getState().properties.wizardFeatures.features;

			let WFAdded = false;
			for (let i = 0; i < roomsArray.length; i++) {
				if (!WFAdded) {
					for (let j = 0; j < wizardFeatures.length; j++) {
						if (roomsArray[i].area_id === wizardFeatures[j].area_id && !roomsArray[i].features.includes(wizardFeatures[j])) {
							wizardFeatures[j].unscored = true;
							roomsArray[i].features.push(wizardFeatures[j]);

						} else if (roomsArray[i].area_id === wizardFeatures[j].area_id && !roomsArray[i].features.includes(wizardFeatures[j])) {
							WFAdded = true;
						}
					}
				}

				if (roomsArray[i].section_order in sections) {
					sections[roomsArray[i].section_order].rooms.push(roomsArray[i]);
				} else {
					sections[roomsArray[i].section_order] = {
						section_ref_id: roomsArray[i].section_ref_id,
						section_order: roomsArray[i].section_order,
						section_name: roomsArray[i].section_name,
						rooms: [],
					}
					sections[roomsArray[i].section_order].rooms.push(roomsArray[i]);
				}
			}

			for (var i = 0; i < sections.length; i++) {
				sections[i].rooms.sort(sortFeatures)

				for (var j = 0; j < sections[i].rooms.length; j++) {
					sections[i].rooms[j].features.sort(sortFeatures)
				}
			}
		}
		dispatch(propertySortedByArea(sections));
	}).catch(err => {
		dispatch(propertySortedByArea(sections));
	});

};

const fetchAvailableFeatures = () => dispatch => {
	dispatch(getAvailableFeaturesAttempt());
	axios.get(`/property/categorizedFeatures`)
		.then((res) => {
			let availableFeaturesByArea = {};
			res.data.forEach(section => {
				section.areas.forEach(area => {
					availableFeaturesByArea[`${area.area_ref_id}`] = area;
				});
			});
			dispatch(getAvailableFeaturesSuccess(res.data, availableFeaturesByArea));
		}).catch(err => {
			dispatch(getAvailableFeaturesFail(err.message));
		});
};

export const getAvailableFeatures = () => dispatch => {
	if (!store.getState().properties.availableFeatures.fetched && !store.getState().properties.availableFeatures.fetching) {
		fetchAvailableFeatures()(dispatch);
	}
};

export const forceGetAvailableFeatures = () => dispatch => {
	fetchAvailableFeatures()(dispatch);
};

export const updateFeature = (featureID, feature, areaID, allFeatureAges) => dispatch => {
	axios.put(
		`/property/${store.getState().properties.property.property_log_id}/features/${featureID}`,
		{
			area_id: areaID,
			is_exists: feature.is_exists,
			feature_condition: feature.feature_condition,
			feature_age: feature.feature_age,
		},
	).then((res) => {
		dispatch(createFeatureSuccess());

		if (!allFeatureAges) {
			sortByArea(store.getState().properties.property.property_log_id)(dispatch);
		}
	})
		.catch(err => {
			dispatch(createFeatureFail(err));

			if (!allFeatureAges) {
				sortByArea(store.getState().properties.property.property_log_id)(dispatch);
			}
		});
}

export const createFeature = (areaID) => dispatch => {
	dispatch(createFeatureAttempt());
	axios.post(
		`/property/features/${store.getState().properties.property.property_log_id}`,
		{
			features: [store.getState().properties.newFeature]
		},
	).then((res) => {
		let propertyFactId = res.data.successes[0].property_fact_id;
		let newFeature = store.getState().properties.newFeature;
		dispatch(updateCreatedFeature('property_fact_id', propertyFactId))

		return axios.put(
			`/property/${store.getState().properties.property.property_log_id}/features/${propertyFactId}`,
			{
				area_id: store.getState().properties.createFeatureModal.area_id || areaID,
				is_exists: true,
				feature_condition: parseInt(newFeature.feature_condition),
				feature_age: parseInt(newFeature.feature_age),
			},
		);
	}).then((res) => {
		dispatch(createFeatureSuccess());
		sortByArea(store.getState().properties.property.property_log_id)(dispatch);
		dispatch(toggleCreateFeatureModal(false, null));
	}).catch(err => {
		dispatch(createFeatureFail(err));
		sortByArea(store.getState().properties.property.property_log_id)(dispatch);
		dispatch(toggleCreateFeatureModal(false, null));
	});
};
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap'

export default class CreateAreaModal extends React.Component {
    constructor(props) {
      super(props);
      if(this.props.buttonDisabled === null){
        this.props.buttonDisabled = true;
      }
    }

    render() {
      return (
          <Modal 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={ this.props.showModal === undefined ? true : this.props.showModal }
            onHide={this.props.close}
          >
            <Modal.Header closeButton>
              <Modal.Title> {this.props.titleLable ? this.props.titleLable : "Please confirm deletion"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.props.bodyLable ? this.props.bodyLable : "This action is permanent."}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.close}>
                Close
              </Button>
              <Button variant="danger" onClick={this.props.delete} disabled={(this.props.buttonDisabled === undefined) ? false : this.props.buttonDisabled }>
                {this.props.buttonLable ? this.props.buttonLable : "Delete"}
              </Button>
            </Modal.Footer>
          </Modal>
      );
    }
  }
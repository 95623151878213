import './index.scss';
import React, { Component } from 'react';
import { Container, Row, Col, Button, Card, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import FeatureCards from './featureCards';
import { connect } from 'react-redux';
import FeatureModal from '../../ui/FeatureModal';
import {
    createFeature,
    toggleCreateFeatureModal,
} from '../../actions';

const mapStateToProps = state => ({
    sortedRooms: state.properties.sortedRooms,
    sections: state.properties.availableFeatures.sections,
    updating: state.properties.property.fetching || state.properties.wizardFeatures.fetching
});

class AreaCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createFeatureModalOpen: false,
        };
        this._toggleFeatureModal = this._toggleFeatureModal.bind(this);
        this._handleFeatureCreate = this._handleFeatureCreate.bind(this);
        this._handleFeatureChange = this._handleFeatureChange.bind(this);
    }

    _toggleFeatureModal(room, possibleFeatures) {
        this.setState({ createFeatureModalOpen: !this.state.createFeatureModalOpen });
    }

    _handleFeatureCreate(roomId) {
        const { createFeature } = this.props;
        const { features } = this.state;
        createFeature(this.props.prop_id, features.newFeature.feature_ref_id, roomId);
    }

    _handleFeatureChange() {

    }

    render() {
        let { rooms, editAreaModal, featuresByArea, deleteArea, featureFunctions } = this.props
        let roomRows = [];
        for (let i = 0; i < rooms.length; i++) {
            if (i % 2 === 0) {
                roomRows.push([rooms[i]]);
            } else {
                // console.log(roomRows[Math.floor(i/2)]);
                roomRows[Math.floor(i / 2)].push(rooms[i]);
            }
        }

        return (
            <Container >

                {
                    roomRows.map((rooms, index) => {
                        return (
                            <Row style={{ marginBottom: 10 }} key={index}>
                                {rooms.map((room) => {
                                    return (
                                        <Col xs={12} lg={12}>
                                            <Card key={`${room.area_id}`}>
                                                <Card.Header>
                                                    {room.area_name}{("area_log_name" in room && room.area_log_name) ? (", " + room.area_log_name) : ""}, room instance: {room.area_instance}
                                                </Card.Header>
                                                <Card.Body>
                                                    <FeatureCards
                                                        features={room.features}
                                                        deleteFeature={featureFunctions.deleteFeature}
                                                        deleteingFeature={featureFunctions.deleteingFeature}
                                                        submitFeature={featureFunctions.submitFeature}
                                                        submittingFeature={featureFunctions.submittingFeature}
                                                        onChange={featureFunctions.onChange}
                                                    />
                                                </Card.Body>
                                                <Card.Footer >
                                                    <ButtonToolbar>
                                                        <ButtonGroup>
                                                            <Button variant="primary" onClick={() => this.props.toggleCreateFeatureModal(true, room.area_ref_id, room.area_id)}>Add Feature</Button>
                                                            <Button variant="secondary" onClick={() => editAreaModal(room, featuresByArea)}>Edit Area</Button>
                                                            <Button variant="danger" onClick={() => deleteArea(room.area_id)}>Delete Area</Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>
                        )
                    })
                }
            </Container>
        )
    }
}

export default connect(
    mapStateToProps,
    {
        createFeature,
        toggleCreateFeatureModal
    }
)(AreaCards);
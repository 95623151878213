import './index.scss';
import React, { Component } from 'react';
import { Button, Container, Form, Alert } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';

export default
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        couponData: {
            url: `/domoreport/uploader/coupon/${props.match.params.id}`,
            andThen: (res) => ({
                userData: {
                    url: `/admin/user/${res.coupon.user_log_id}`,
                    headers: {
                        authorization: props.user.token
                    },
                }
            })
        },
        updateCoupon: (data) => ({
            updatedCoupon: {
                url: `/domoreport/uploader/coupon/${props.match.params.id}`,
                method: 'PUT',
                headers: {
                    authorization: props.user.token,
                },
                body: JSON.stringify(data),
            }
        }),
        deleteCoupon: () => ({
            deletedCoupon: {
                url: `/domoreport/uploader/coupon/${props.match.params.id}`,
                method: 'DELETE',
                headers: {
                    authorization: props.user.token,
                },
                then: () => {
                    props.history.push('/coupons');
                }
            }
        }),
    }) : null;
})

class EditCouponComponent extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        body: null,
        coupon_code_string: null,
        discount_total: null,
        coupon_description: null,
        start_date: null,
        end_date: null,
        multi_use_per_user_with_end_date: null,
        max_number_of_uses_per_customer: null,
        error: null,
    }

    render() {
        const { couponData, userData } = this.props;
        const { multi_use_per_user_with_end_date } = this.state;

        if (multi_use_per_user_with_end_date == null && couponData && !couponData.pending) {
            this.setState({
                multi_use_per_user_with_end_date: !!couponData.value.coupon.multi_use_per_user_with_end_date,
            })
        }

        return (
            (couponData && !couponData.pending && couponData.value && userData && !userData.pending && userData.value) ?
                this._renderAvailableState(couponData.value.coupon, userData.value)
                :
                this._renderEmptyState()
        );
    }

    _renderEmptyState = () => {
        return (
            <Container>
                <Link className="mt-3" to="/coupons">Go Back</Link>
                <h2 className="mt-3">Edit Coupon</h2>
                <p>empty state</p>
            </Container>
        )
    }

    _renderAvailableState = (content, userData) => {
        const { updatedCoupon } = this.props;
        let formattedStartDate = Intl.DateTimeFormat('en-US').format(new Date(content.start_date));
        let formattedEndDate = Intl.DateTimeFormat('en-US').format(new Date(content.end_date));

        return (
            <div className="md-scroll-wrapper">
                <Container className="md-edit-coupon-page">
                    {updatedCoupon && !updatedCoupon.pending && updatedCoupon.value && updatedCoupon.value.error && this._renderAlert('danger', updatedCoupon.value.error)}
                    {this.state.error && this._renderAlert('danger', this.state.error)}
                    <Container>
                        <Link className="mt-3" to="/coupons">Go Back</Link>

                        <h2 className="mt-3">Edit Coupon</h2>
                        <hr width="100%" />

                        <h4>Coupon Info</h4>
                        <div className="row">
                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>Coupon Code</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('coupon_code_string', e.target.value)} defaultValue={content.coupon_code_string} className="md-coupon-code-input" />
                            </Form.Group>

                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>Coupon Discount</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('discount_total', e.target.value)} defaultValue={'$' + (content.discount_total / 100)} />
                            </Form.Group>

                            <Form.Group className={"col-12 mx-auto"}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('coupon_description', e.target.value)} defaultValue={content.coupon_description} />
                            </Form.Group>

                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('start_date', e.target.value)} defaultValue={formattedStartDate} placeholder="MM/DD/YYYY" />
                            </Form.Group>

                            <Form.Group className={"col-6 mx-auto"}>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="text" onChange={(e) => this._handleFormChange('end_date', e.target.value)} defaultValue={formattedEndDate} placeholder="MM/DD/YYYY" />
                            </Form.Group>

                            <Form.Group className={"col-12 mx-auto"}>
                                <Form.Check
                                    type="checkbox"
                                    className="mx-2"
                                    onChange={(e) => this._handleFormChange('multi_use_per_user_with_end_date', !this.state.multi_use_per_user_with_end_date)}
                                    checked={this.state.multi_use_per_user_with_end_date}
                                    label="Allow multiple uses per customer"
                                />
                            </Form.Group>

                            {this.state.multi_use_per_user_with_end_date ?
                                <Form.Group className={"col-12 mx-auto"}>
                                    <Form.Label># of uses per customer</Form.Label>
                                    <Form.Control type="text" onChange={(e) => this._handleFormChange('max_number_of_uses_per_customer', e.target.value)} defaultValue={content.max_number_of_uses_per_customer} />
                                </Form.Group>
                                :
                                <div></div>
                            }

                            <Form.Group className={"col-12 mx-auto"}>
                                <Form.Label>Created by</Form.Label>
                                <Form.Control type="text" value={userData.user_first_name + ' ' + userData.user_last_name} disabled />
                            </Form.Group>

                            <div className="d-flex col">
                                <Button
                                    variant="primary"
                                    size="md"
                                    block
                                    style={{ marginTop: 20 }}
                                    onClick={(e) => this._updateContent(content)}
                                    className="col-2 mx-1"
                                > Update
                            </Button>
                                <Button
                                    variant="danger"
                                    size="md"
                                    block
                                    style={{ marginTop: 20 }}
                                    onClick={() => this._callAlert('delete', () => this.props.deleteCoupon())}
                                    className="col-2 mx-1"
                                > Delete
                            </Button>
                                <Button
                                    variant="secondary"
                                    size="md"
                                    block
                                    style={{ marginTop: 20 }}
                                    onClick={this._goBack}
                                    className="col-2 mx-1"
                                > Cancel
                            </Button>
                            </div>
                        </div>

                    </Container>
                </Container>
            </div>
        )
    }

    _updateContent = (content) => {
        let { coupon_code_string, discount_total, coupon_description, start_date, end_date, multi_use_per_user_with_end_date, max_number_of_uses_per_customer, } = this.state;
        let start = new Date(start_date);
        let end = new Date(end_date);

        // Allow state to be empty string. If null, use prefilled content
        let data = {
            coupon_code_string: (coupon_code_string != null) ? coupon_code_string.toUpperCase() : content.coupon_code_string,
            discount_total: (discount_total != null) ? (discount_total.replace('$', '') * 100) : content.discount_total,
            coupon_description: (coupon_description != null) ? coupon_description : content.coupon_description,
            start_date: (start_date != null) ? start.toISOString() : content.start_date,
            end_date: (end_date != null) ? end.toISOString() : content.end_date,
            one_use_per_user_with_end_date: !multi_use_per_user_with_end_date,
            multi_use_per_user_with_end_date: multi_use_per_user_with_end_date,
            max_number_of_uses_per_customer: (max_number_of_uses_per_customer != null) ? max_number_of_uses_per_customer : content.max_number_of_uses_per_customer,
        }

        if (data.start_date && data.end_date && (new Date(data.start_date) < new Date(data.end_date))) {
            this._callAlert('update', () => this.props.updateCoupon(data));
            this.setState({
                multi_use_per_user_with_end_date: null,
            });
            this._goBack();
        } else {
            this.setState({
                error: 'Start date must be greater than end date'
            })
        }
    }

    _handleFormChange(key, value) {
        this.setState({ [key]: this._validateValue(value) });
    }

    _validateValue = (value) => {
        if (value === '' || value === ' ' || value === 0) {
            return null
        } else {
            return value
        }
    }

    _callAlert = (type, cb) => {
        if (type === "create") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Do you want to create this content?')
            if (res === true) {
                cb()
            }
        }

        if (type === "update") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Are you sure you want to update this content?')
            if (res === true) {
                cb()
            }
        }

        if (type === "delete") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Are you sure you want to delete this content?')
            if (res === true) {
                cb()
            }
        }
    }

    _renderAlert(variant, message) {
        return <Alert variant={variant} onClose={() => { this.setState({ showMessage: false }) }} className="md-alert"><p>{message}</p></Alert>
    }

    _goBack = () => {
        this.props.history.push('/coupons');
    }
}
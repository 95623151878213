import {
	GET_ALL_TRADES_ATTEMPT,
	GET_ALL_TRADES_FAIL,
	GET_ALL_TRADES_SUCCESS,
} from '../actions';

const initialState = {
	trades: {categories: []},
	sortedTrades: [],
	tradesFetching: false,
	tradesFetched: false,
	error: null,
};

export function trades(state = initialState, action){
	switch (action.type){
		case GET_ALL_TRADES_ATTEMPT:
			return {
				...state,
				tradesFetching: true,
				tradesFetched: false,
			}
		case GET_ALL_TRADES_FAIL:
			return {
				...state,
				tradesFetching: false,
				tradesFetched: false,
				error: action.err,
			}
		case GET_ALL_TRADES_SUCCESS:
			return {
				...state,
				tradesFetching: false,
				tradesFetched: true,
				trades: action.trades,
				sortedTrades: action.sortedTrades
			}
		default:
			return {
				...state,
			}
	}
}
import "./index.css";
import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import FormByKey from '../../ui/FormByKey/form';
import { _returnFields } from '../../ui/FormByKey/defaultFields';
import { keysToIgnore } from './';
import WarningModal from '../../ui/WarningModal/';

export default 
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        broker: {
            url: `/domoreport/uploader/broker/${props.match.params.id}`,

        },
        brokerFields: {
            url: '/domoreport/uploader/broker/fields',

        },
        updateBroker: (data) => ({
            updatedBroker: {
                url: `/domoreport/uploader/broker/${props.match.params.id}`,
                method: 'PUT',

                body: JSON.stringify(data)
            }
        }),
        refetchBroker: () => ({
            Broker: {
                url: `/domoreport/uploader/Broker/${props.match.params.id}`,
                force: true,
                refreshing: true,
                headers: {
                    authorization: props.user.token
                }
            }
        }),
        deleteBroker: () => ({
            deletedBroker: {
                url: `/domoreport/uploader/Broker/${props.match.params.id}`,
                method: 'DELETE',
                headers: {
                    authorization: props.user.token
                }
            }
        })
    }) : null;
})
class EditBrokerComponent extends Component {


    state = {
        createWarningOpen: false,
    }

    constructor(props) {
        super(props)
        this._goBack = this._goBack.bind(this);
    }

    _handleChange = (key, value) => {

        this.setState({ [key]: value });
    }

    _toggleWarningModal = () => {
        const { createWarningOpen } = this.state;
        this.setState({ createWarningOpen: !createWarningOpen }, () => {
            console.log(this.state);
        });
    }

    _onSubmit = () => {
        const { updateBroker, refetchBroker } = this.props;

        updateBroker(this.state)
        refetchBroker();
    }

    _onDelete = () => {
        const { deleteBroker } = this.props;
        deleteBroker();
        this._goBack();
    }

    _goBack() {
        this.props.history.goBack();
    }

    render() {
        const { broker, brokerFields, updatedBroker } = this.props;
        const { createWarningOpen } = this.state;
        console.log('updatedBroker', updatedBroker);
        if (!broker) {
            return null;
        }
        if (broker.pending || brokerFields.pending) {
            return <Container>
                <h2>Broker:</h2>
                <Link to="/brokers">Go Back</Link>
            </Container>
        }

        let { value } = broker;
        let { value: fields } = brokerFields;
        let fieldsHash = {}
        fieldsHash = fields.reduce((map, obj) => {
            fieldsHash[obj.Field] = obj.Field
            return fieldsHash;
        });

        if (!this.state.fromServer) {
            this.setState({ ...value, fromServer: !this.state.fromServer })
        }

        let buttonDisabled = false
        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                value[key] = (key in this.state ? this.state[key] : value[key])
                if (!(key in keysToIgnore) && key in fieldsHash) {
                    buttonDisabled = buttonDisabled || value[key] === "" || value[key] === "null" || value[key] === null
                }
            }
        }

        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <Row>
                        <Col>
                            <h2>Broker: {value.broker_person_name}</h2>
                            <Link to="/brokers">Go Back</Link>
                            <FormByKey
                                fields={fields}
                                obj={value}
                                onChange={this._handleChange}
                                submit={this._onSubmit}
                                showDelete={true}
                                delete={this._onDelete}
                                returnFields={_returnFields}
                                keysToIgnore={keysToIgnore}
                                disabled={buttonDisabled}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                            < Button variant="danger" onClick={this._toggleWarningModal}>DELETE</Button>
                        </Col>
                    </Row>
                    <Row style={{ height: 25 }}></Row>
                    {createWarningOpen && <WarningModal
                        close={this._toggleWarningModal}
                        delete={this._onDelete}
                        titleLable={"Yeah... but seriously?"}
                        bodyLable={"This is, like, forever man."}
                        buttonLable={"Bin it, mate."}
                    />}
                </Container>
            </div>
        )
    }
}
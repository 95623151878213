import './index.scss'
import React, { Component } from 'react';
import { Container, Button, Alert, Card, Row, Col, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import AreaCards from './areaCards';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import ReportNav from '../Reports/reportNav';
import CreateFeatureModal from '../../ui/FeatureModal';
import CreateAreaModal from '../../ui/CreateAreaModal';
import { WithAlert } from "../../hocs/WithAlert";
import { connect } from 'react-redux';
import {
    getProperty,
    getWizardFeatures,
    getAvailableFeatures,
    sortByArea,
    updateFeature
} from '../../actions';
const AlertMessage = WithAlert(Alert);

const mapStateToProps = state => ({
    sortedRooms: state.properties.sortedRooms,
    sections: state.properties.availableFeatures.sections,
    updating: state.properties.property.fetching || state.properties.wizardFeatures.fetching,
    report: state.reports.report,
});

export default
@withRouter
@withUser()
@connect(mapStateToProps,
    {
        getProperty,
        getWizardFeatures,
        getAvailableFeatures,
        sortByArea,
        updateFeature
    }
)
@withFetch((props) => {
    return props.user && props.user ? ({

        availableFeatures: {
            url: '/property/categorizedFeatures',
        },
        updateArea: (data) => ({
            area: {
                url: `/property/${props.match.params.id}/room/${data.area_id}`,
                method: 'PUT',

                body: JSON.stringify(data),
            }
        }),
        createArea: (data) => ({
            newArea: {
                url: `/property/${props.match.params.id}/room`,
                method: 'POST',
                force: true,
                refreshing: true,

                body: JSON.stringify(data),
            }
        }),
        deleteArea: (areaId) => ({
            area: {
                url: `/property/${props.match.params.id}/room/${areaId}`,
                method: 'DELETE',
                headers: {
                    authorization: props.user.token
                }
            }
        }),
        featureFields: {
            url: `/domoreport/uploader/feature/fields`,

        },
        updateProperty: (data) => ({
            report: {
                url: `/property/${props.match.params.id}`,
                method: 'PUT',
                force: true,
                refreshing: true,

                body: JSON.stringify(data)
            }
        })
    }) : null;
})
class EditPropertyComponent extends Component {
    state = {
        createModalOpen: false,
        createAreaModalOpen: false,
        createFeatureModalOpen: false,
        editAreaModalOpen: false,
        currentAreaSelected: null,
        wizardFeaturesAdded: false,
        availableFeaturesByRoom: [],
        newArea: {},
        features: { newFeature: {} },
        formSubmission: false,
        alertMessage: "",
        alertBG: "",
        action: {
            areaCreate: '',
            areaUpdate: '',
            areaDelete: '',
            featureCreate: '',
            featureDelete: '',
            featureSubmit: ''
        },
        showSharingModal: false,
    }

    componentDidMount() {
        this.props.sortByArea(this.props.match.params.id)
        this.props.getAvailableFeatures()
    }

    _toggleModal = () => {
        const { createModalOpen } = this.state;
        this.setState({ createModalOpen: !createModalOpen });
    }

    _toggleAreaModel = (section_ref_id = null, defaultRefId = null) => {
        const { createAreaModalOpen } = this.state;
        this.setState({
            createAreaModalOpen: !createAreaModalOpen,
            currentSectionRefID: section_ref_id,
            newArea: {
                area_log_name: null,
                area_ref_id: defaultRefId,
                property_log_id: this.props.match.params.id
            }
        });
    }

    _toggleEditArea = (area = null, areas) => {
        const { editAreaModalOpen } = this.state;
        this.setState({
            editAreaModalOpen: !editAreaModalOpen,
            currentAreaSelected: area ? area : null,
            possibleAreas: areas,
            newArea: {
                area_id: area ? area.area_id : null,
                area_log_name: area ? area.area_log_name : null,
                property_log_id: this.props.match.params.id, property_log_id: this.props.match.params.id,
                area_instance: area ? area.area_instance : null,
                area_ref_id: area ? area.area_ref_id : null,
            }
        });
    }

    _handleFeatureChange = (key, feature_id, value) => {
        let { features } = this.state;

        let f_id = ""

        if (feature_id != null) {
            f_id = `${feature_id}`;
        } else {
            f_id = "newFeature";
        }

        if (key === "feature_age" || key === "feature_condition") {
            value = Number(value)
        }

        if (f_id in features) {
            features[f_id][key] = value;
        } else {
            features[f_id] = { [key]: value }
        }
        this.setState({ features });
    }

    _handleFeatureSubmit = (feature) => {
        const { updateFeature, sortByArea } = this.props;
        const { features } = this.state;

        if (String(feature.property_fact_id) in features) {
            updateFeature(feature.property_fact_id, features[String(feature.property_fact_id)], feature.area_id)
        }

        const action = this.state.action;
        action.featureSubmit = "Feature was successfully Submitted.";
        this.setState({
            formSubmission: true,
            action
        })
    }

    _resetMessage = () => {
        this.setState({
            alertMessage: '',
            alertBG: '',
            action: {
                areaCreate: '',
                areaUpdate: '',
                areaDelete: '',
                featureCreate: '',
                featureDelete: '',
                featureSubmit: ''
            }
        });
    }

    _handleFeatureDelete = (feature_id) => {
        const { updateFeature, sortByArea } = this.props;
        const { features } = this.state
        updateFeature(feature_id, { "is_exists": false, feature_condition: -1, feature_age: -1 })
        sortByArea(this.props.match.params.id);
        const action = this.state.action;
        action.featureDelete = 'Feature was successfully Deleted.';
        this.setState({ formSubmission: true, action })
    }

    _handleAreaChange = (key, value) => {
        let { newArea } = this.state;
        newArea[key] = value;
        this.setState({ newArea });
    }

    _handleAreaUpdate = () => {
        const { updateArea, sortByArea } = this.props;
        const { currentAreaSelected, newArea } = this.state;
        let saveModel = { ...newArea };
        updateArea(saveModel);
        sortByArea(this.props.match.params.id);
        const action = this.state.action;
        action.areaUpdate = "Area was successfully updated.";
        this.setState({ editAreaModalOpen: false, newArea: {}, currentAreaSelected: null, formSubmission: true, action });
    }

    _handleAreaCreate = () => {
        const { createArea, sortByArea } = this.props;
        const { currentAreaSelected, newArea } = this.state;
        let saveModel = { ...newArea };
        createArea(saveModel);
        sortByArea(this.props.match.params.id);
        const action = this.state.action;
        action.areaCreate = "Area was successfully created.";
        this.setState({ createAreaModalOpen: false, newArea: {}, currentAreaSelected: null, formSubmission: true, action });
    }

    _handleAreaDelete = (area_id) => {
        const { deleteArea, sortByArea } = this.props;
        const { currentAreaSelected, newArea } = this.state;
        deleteArea(area_id);
        sortByArea(this.props.match.params.id);
        const action = this.state.action;
        action.areaDelete = 'Area was successfully deleted.';
        this.setState({ newArea: {}, currentAreaSelected: null, formSubmission: true, action });
    }

    _handleChange = (key, value) => {
        let { newInspector } = this.state;
        newInspector[key] = value;
        this.setState({ newInspector });
    }

    _toggleSharing = () => {
        this.setState({
            showSharingModal: !this.state.showSharingModal,
        })
    }

    render() {
        const { createModalOpen, createAreaModalOpen, createFeatureModalOpen, editAreaModalOpen, availableFeaturesByRoom } = this.state;
        const { user, report } = this.props;

        return (
            <div className="md-scroll-wrapper">
                <Container>
                    {(this.props.updating)
                        && <div style={
                            {
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                                zIndex: 999,
                                position: "fixed",
                                left: 0,
                                top: 0,
                                width: "100vw",
                                height: "100vh",
                            }
                        }></div>}
                    {this.state.alertMessage ? <AlertMessage variant={this.state.alertBG} resetMessage={this._resetMessage} message={this.state.alertMessage} /> : null}

                    <h2>Property : {this.props.match.params.id}</h2>

                    <Link to="/reports">Go Back</Link>

                    <ReportNav
                        reportId={this.props.match.params.report_id}
                        propertyId={this.props.match.params.id}
                        token={(user && user.value && user.value.token) || null}
                        report={report}
                    />
                    {/* <Button onClick={this._toggleModal}> Complete Wizard </Button>
            {createModalOpen && <WizardModal close={this._toggleModal}/>} */}
                    {createAreaModalOpen && <CreateAreaModal
                        create={true}
                        submitting={false}
                        allAreas={this.props.sections[this.state.currentSectionRefID - 1].areas}
                        close={this._toggleAreaModel}
                        onChange={this._handleAreaChange}
                        submit={this._handleAreaCreate}
                        obj={this.state.newArea}
                    />}

                    {editAreaModalOpen && <CreateAreaModal
                        allAreas={this.state.possibleAreas}
                        obj={this.state.newArea}
                        close={this._toggleEditArea}
                        onChange={this._handleAreaChange}
                        submit={this._handleAreaUpdate}
                    />}

                    <CreateFeatureModal
                        create={true}
                        submitting={false}
                        close={this._toggleFeatureModal}
                        onChange={this._handleFeatureChange}
                        submit={this._handleFeatureCreate}
                        headerTitle={'Add a Feature'}
                        src="property_table"
                    />
                    {this.props.sortedRooms.map(section => (
                        <Row style={{ marginBottom: 10 }} key={section.section_ref_id}>
                            <Col>
                                <Card key={`${section.section_ref_id}`}>
                                    <Card.Header>
                                        {section.section_name}
                                    </Card.Header>

                                    <Card.Body>
                                        <Card.Text>
                                            Areas in this section:
                                </Card.Text>
                                        <AreaCards
                                            featuresByArea={this.props.sections[section.section_ref_id - 1].areas}
                                            rooms={section.rooms}
                                            addFeatureModal={this._toggleFeatureModal}
                                            editAreaModal={this._toggleEditArea}
                                            deleteArea={this._handleAreaDelete}
                                            prop_id={this.props.match.params.id}
                                            featureFunctions={{
                                                deleteFeature: this._handleFeatureDelete,
                                                deleteingFeature: false,
                                                submitFeature: this._handleFeatureSubmit,
                                                submittingFeature: false,
                                                onChange: this._handleFeatureChange,
                                            }}
                                        />
                                    </Card.Body>
                                    <Card.Footer >
                                        <ButtonToolbar>
                                            <ButtonGroup>
                                                <Button variant="primary" onClick={() => this._toggleAreaModel(section.section_ref_id, this.props.sections[section.section_ref_id - 1].areas[0].area_ref_id)}>Add Area</Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    ))}
                </Container>
            </div >
        )
    }
}
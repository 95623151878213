import "./index.scss";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../services/axios";

export default function UpdateInspectionModal({ reportId, close }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleFileChange(e) {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  }

  async function updateFile() {
    let formData = new FormData();
    formData.append("inspection", file);
    setLoading(true);

    try {
      const { status } = await axios.put(
        `/domoreport/${reportId}/inspection`,
        formData
      );
      if (status === 200) {
        window.location.reload();
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={close}
      className="update-inspection-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Inspection PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            WARNING: This will delete the current Inspection PDF and replace it
            with the one you are uploading. Only click "Update File" if you are
            certain that you want to permanently delete the current inspection.
          </p>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={updateFile} variant="danger">
          {loading ? "Processing..." : "Update File"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

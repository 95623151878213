import { connect } from 'react-refetch';
import urlJoin from 'url-join';

export const baseUrl = process.env.REACT_APP_BASE_URL;
export default connect.defaults({
  buildRequest: function (mapping) {
    const options = {
      method: mapping.method,
      headers: {
        ...mapping.headers,
        authorization: !!JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : null
      },
      credentials: mapping.credentials,
      redirect: mapping.redirect,
      mode: mapping.mode,
      body: mapping.body
    }

    return new Request(urlJoin(baseUrl, mapping.url), options)
  }
})

import React from 'react';
import { Form, Container} from 'react-bootstrap';
import { makeLabel, styleBorder, makePlaceholder } from './';

export const UserNumberField = function({formName, fieldKey, keysToIgnore, obj, keyAsIndex, onChange}){
	formName = formName || "";
	fieldKey = fieldKey || "";
	keysToIgnore = keysToIgnore || {};
	keyAsIndex = keyAsIndex || "";
	obj = obj || {};
	onChange = onChange || (() => {});
	return(
		<Container key={keyAsIndex}>
			<Form.Group controlId="fillerControlId" key={keyAsIndex}>
				{makeLabel(formName.replace(/_/g,' '), fieldKey, keysToIgnore)}
				<Form.Control 
					style={styleBorder(obj, fieldKey, keysToIgnore)} 
					type="number" placeholder={`${fieldKey}`} 
					value={makePlaceholder(obj, fieldKey)} 
					onChange={(evt)=>{onChange(fieldKey, evt.target.value);}}
				/>
			</Form.Group>
			<Form.Group controlId="fillerControlId" key={keyAsIndex + 1}>
				{makeLabel("User Name","user_first_name", keysToIgnore)}
				<Form.Control 
					style={styleBorder(obj, "user_first_name", keysToIgnore)} 
					type="text" placeholder="User Name" 
					value={obj ? ("user_first_name" in obj ? `${obj.user_first_name} ${obj.user_last_name}` : "") : ""} 
					readOnly
				/>
			</Form.Group>
		</Container>
	)
}
import { storage } from '../utils';

/*=========== action names ===========*/

export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

/*=========== reducer actions ===========*/

export const attemptLogin = () => {
	return {
		type: ATTEMPT_LOGIN,
	};
};

export const loginSuccess = (token) => {
	return {
		type: LOGIN_SUCCESS,
		token: token
	};
};

export const loginFail = err => {
	return {
		type: LOGIN_FAIL,
		error: err
	};
};

/*=========== actions ===========*/

export const getLoginFromHoc = (data) => dispatch => {
	// dispatch(loginSuccess(data));
};

export const checkCurrentLoggin = () => dispatch => {
	storage.get("user", (err, data) => {
		if (err) {
			dispatch(loginFail(err));
		} else if (data) {
			dispatch(loginSuccess(data.value.token));
		} else {
			dispatch(loginFail({ error: "there is no user data" }));
		}
	});
};
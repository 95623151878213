import {
	GET_FEATURE_REF_ATTEMPT,
	GET_FEATURE_REF_FAIL,
	GET_FEATURE_REF_SUCCESS,

	UPDATE_FEATURE_REF,

	UPDATE_FEATURE_REF_ATTEMPT,
	UPDATE_FEATURE_REF_SUCCESS,
	UPDATE_FEATURE_REF_FAIL,

	DELETE_FEATURE_REF_ATTEMPT,
	DELETE_FEATURE_REF_FAIL,
	DELETE_FEATURE_REF_SUCCESS,

	GET_ALL_FEATURE_REFS_ATTEMPT,
	GET_ALL_FEATURE_REFS_SUCCESS,
	GET_ALL_FEATURE_REFS_FAIL,

	CREATE_FEATURE_REF_ATTEMPT,
	CREATE_FEATURE_REF_SUCCESS,
	CREATE_FEATURE_REF_FAIL,

	SORT_FEATURES,

	GET_FEATURE_REF_SCORES_ATTEMPT,
	GET_FEATURE_REF_SCORES_FAIL,
	GET_FEATURE_REF_SCORES_SUCCESS,
} from '../actions';

const initialState = {
	lockFeatureRef: false,
	lockAllFeatureRefs: false,
	featureRef: {},
	error: null,
	allFeatureRefs: [],
	allAreas: [],
	dropDownOptions: {},
	currentSort: {
		sort: 'Area',
		direction: "asc"
	},
};

export function featureRef(state = initialState, action) {
	switch (action.type) {
		case GET_FEATURE_REF_ATTEMPT: {
			return {
				...state,
				lockFeatureRef: true
			};
		}
		case GET_FEATURE_REF_FAIL: {
			return {
				...state,
				lockFeatureRef: false,
				error: action.err
			};
		}
		case GET_FEATURE_REF_SUCCESS: {
			return {
				...state,
				lockFeatureRef: false,
				featureRef: action.featureRef
			};
		}
		case UPDATE_FEATURE_REF: {
			return {
				...state,
				featureRef: {
					...state.featureRef,
					[action.key]: action.value,
				}
			};
		}
		case UPDATE_FEATURE_REF_ATTEMPT: {
			return {
				...state,
				lockFeatureRef: true
			};
		}
		case UPDATE_FEATURE_REF_SUCCESS: {
			return {
				...state,
				lockFeatureRef: false
			};
		}
		case UPDATE_FEATURE_REF_FAIL: {
			return {
				...state,
				lockFeatureRef: false,
				error: action.err,
			};
		}
		case DELETE_FEATURE_REF_ATTEMPT: {
			return {
				...state,
				lockFeatureRef: true
			};
		}
		case DELETE_FEATURE_REF_FAIL: {
			return {
				...state,
				lockFeatureRef: false,
				error: action.err,
			};
		}
		case DELETE_FEATURE_REF_SUCCESS: {
			return {
				...state,
				lockFeatureRef: false,
			};
		}
		case GET_ALL_FEATURE_REFS_ATTEMPT: {
			return {
				...state,
				lockAllFeatureRefs: true,
			};
		}
		case GET_ALL_FEATURE_REFS_SUCCESS: {
			return {
				...state,
				lockAllFeatureRefs: false,
				allFeatureRefs: action.allFeatureRefs,
				allAreas: action.allAreas,
			};
		}
		case GET_ALL_FEATURE_REFS_FAIL: {
			return {
				...state,
				lockAllFeatureRefs: false,
				error: action.err
			};
		}
		case CREATE_FEATURE_REF_ATTEMPT: {
			return {
				...state,
				lockAllFeatureRefs: true,
			};
		}
		case CREATE_FEATURE_REF_SUCCESS: {
			return {
				...state,
				lockAllFeatureRefs: false,
			};
		}
		case CREATE_FEATURE_REF_FAIL: {
			return {
				...state,
				lockAllFeatureRefs: false,
				error: action.err,
			};
		}
		case SORT_FEATURES: {
			return {
				...state,
				currentSort: action.currentSort,
			}
		}
		case GET_FEATURE_REF_SCORES_ATTEMPT: {
			return {
				...state,
			};
		}
		case GET_FEATURE_REF_SCORES_SUCCESS: {
			return {
				...state,
				dropDownOptions: {
					...state.dropDownOptions,
					[`FRId_${action.featureRef.feature_ref_id}`]: action.featureRef.options
				}
			};
		}
		case GET_FEATURE_REF_SCORES_FAIL: {
			return {
				...state,
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
}
import {

	GET_PROJECT_FIELDS_ATTEMPT,
	GET_PROJECT_FIELDS_SUCCESS,
	GET_PROJECT_FIELDS_FAIL,

	GET_UNIT_TYPES_ATTEMPT,
	GET_UNIT_TYPES_FAIL,
	GET_UNIT_TYPES_SUCCESS,

	GET_PRIORITIES_ATTEMPT,
	GET_PRIORITIES_FAIL,
	GET_PRIORITIES_SUCCESS,

	GET_PROJECT_ATTEMPT,
	GET_PROJECT_FAIL,
	GET_PROJECT_SUCCESS,

	UPDATE_PROJECT,
	UPDATE_PROJECT_ATTEMPT,
	UPDATE_PROJECT_FAIL,
	UPDATE_PROJECT_SUCCESS,

	GET_PROJECTS_ATTEMPT,
	GET_PROJECTS_FAIL,
	GET_PROJECTS_SUCCESS,

	CREATE_EMPTY_PROJECT,

	GET_JOB_LIST_ATTEMPT,
	GET_JOB_LIST_SUCCESS,
	GET_JOB_LIST_FAIL,

	GET_JOB_DETAILS_ATTEMPT,
	GET_JOB_DETAILS_SUCCESS,
	GET_JOB_DETAILS_FAIL,

	GET_JOB_ESTIMATE_ATTEMPT,
	GET_JOB_ESTIMATE_SUCCESS,
	GET_JOB_ESTIMATE_FAIL,

	UPDATE_JOB_ESTIMATE,

	CREATE_PROJECT_ATTEMPT,
	CREATE_PROJECT_FAIL,
	CREATE_PROJECT_SUCCESS,

} from '../actions';

const initialState = {
	project: {},
	oneFetching: false,
	oneFetched: false,
	updatingProject: false,
	fields: [],
	fieldsHash: {},
	emptyValues: {},
	fieldsFetched: false,
	fieldsFetching: false,
	unitTypes: { unitTypes: [] },
	unitTypesMemo: {},
	unitTypesFetched: false,
	unitTypesFetching: false,
	priorities: { priorities: [] },
	prioritiesFetched: false,
	prioritiesFetching: false,
	projects: { Projects: [] },
	projectsFetching: false,
	projectsFetched: false,
	jobList: [],
	jobListFetching: false,
	jobDetails: {},
	jobDetailFetching: false,
	jobEstimate: {},
	jobEstimateFetching: false,
	creatingProject: false,
	createdProject: false,
};

export function projects(state = initialState, action) {
	switch (action.type) {
		case GET_PROJECT_FIELDS_ATTEMPT:
			return {
				...state,
				fieldsFetched: false,
				fieldsFetching: true,
			}
		case GET_PROJECT_FIELDS_SUCCESS:
			return {
				...state,
				fields: action.fields,
				emptyValues: action.emptyValues,
				fieldsHash: action.fieldsHash,
				fieldsFetched: true,
				fieldsFetching: false,
			}
		case GET_PROJECT_FIELDS_FAIL:
			return {
				...state,
				fieldsFetched: false,
				fieldsFetching: false,
				error: action.err,
			}
		case GET_UNIT_TYPES_ATTEMPT:
			return {
				...state,
				unitTypesFetched: false,
				unitTypesFetching: true,
			}
		case GET_UNIT_TYPES_FAIL:
			return {
				...state,
				unitTypesFetched: false,
				unitTypesFetching: false,
				error: action.err,
			}
		case GET_UNIT_TYPES_SUCCESS:
			let tempUnitMemo = {};
			for (let i = 0; i < action.unitTypes.unitTypes.length; i++) {
				if (action.unitTypes.unitTypes[i].unit_abbreviation === "M²") {
					tempUnitMemo["M2"] = action.unitTypes.unitTypes[i];
				}
				tempUnitMemo[action.unitTypes.unitTypes[i].unit_abbreviation] = action.unitTypes.unitTypes[i];
			}
			return {
				...state,
				unitTypesFetched: true,
				unitTypesFetching: false,
				unitTypes: action.unitTypes,
				unitTypesMemo: tempUnitMemo
			}
		case GET_PRIORITIES_ATTEMPT:
			return {
				...state,
				prioritiesFetched: false,
				prioritiesFetching: true,
			}
		case GET_PRIORITIES_FAIL:
			return {
				...state,
				prioritiesFetched: false,
				prioritiesFetching: false,
				error: action.err,
			}
		case GET_PRIORITIES_SUCCESS:
			return {
				...state,
				prioritiesFetched: true,
				prioritiesFetching: false,
				priorities: action.priorities,
			}
		case GET_PROJECT_ATTEMPT:
			return {
				...state,
				oneFetching: true,
				oneFetched: false,
			}
		case GET_PROJECT_FAIL:
			return {
				...state,
				oneFetching: false,
				oneFetched: false,
				error: action.err,
			}
		case GET_PROJECT_SUCCESS:
			return {
				...state,
				project: action.project,
				oneFetching: false,
				oneFetched: true,
			}
		case UPDATE_PROJECT:
			return {
				...state,
				project: {
					...state.project,
					[action.key]: action.value,
				}
			}
		case UPDATE_PROJECT_ATTEMPT:
			return {
				...state,
				updatingProject: true,
			}
		case UPDATE_PROJECT_FAIL:
			return {
				...state,
				error: action.err,
				updatingProject: false,
			}
		case UPDATE_PROJECT_SUCCESS:
			return {
				...state,
				updatingProject: false,
			}
		case GET_PROJECTS_ATTEMPT:
			return {
				...state,
				projectsFetching: true,
				projectsFetched: false,
			}
		case GET_PROJECTS_FAIL:
			return {
				...state,
				projectsFetching: false,
				projectsFetched: false,
				error: action.err,
			}
		case GET_PROJECTS_SUCCESS:
			return {
				...state,
				projects: action.projects,
				projectsFetching: false,
				projectsFetched: true,
			}
		case CREATE_EMPTY_PROJECT:
			return {
				...state,
				jobEstimate: {},
				project: {
					...state.emptyValues,
					domoreport_log_id: Number(action.domoreport_log_id),
					property_log_id: Number(action.property_log_id),
					is_visible: true,
				}
			}
		case GET_JOB_LIST_ATTEMPT:
			return {
				...state,
				jobListFetching: true,
			}
		case GET_JOB_LIST_SUCCESS:
			return {
				...state,
				jobList: action.jobs,
				jobListFetching: false,
			}
		case GET_JOB_LIST_FAIL:
			return {
				...state,
				error: action.err,
				jobListFetching: false,
			}
		case GET_JOB_DETAILS_ATTEMPT:
			return {
				...state,
				jobDetailFetching: true,
			}
		case GET_JOB_DETAILS_SUCCESS:
			return {
				...state,
				jobDetails: action.details,
				jobDetailFetching: false,
			}
		case GET_JOB_DETAILS_FAIL:
			return {
				...state,
				error: action.err,
				jobDetailFetching: false,
			}
		case GET_JOB_ESTIMATE_ATTEMPT:
			return {
				...state,
				jobEstimateFetching: true,
			}
		case GET_JOB_ESTIMATE_SUCCESS:
			return {
				...state,
				project: {
					...state.project,
					repair_wise_job_id: action.estimate.postEstimate.jobId,
					repair_wise_estimate_id: action.estimate.postEstimate.id,
					xactware_project_id: action.estimate.postEstimate.projectId,
				},
				jobEstimate: action.estimate,
				jobEstimateFetching: false,
			}
		case GET_JOB_ESTIMATE_FAIL:
			return {
				...state,
				jobEstimateFetching: false,
				error: action.err,
			}
		case UPDATE_JOB_ESTIMATE:
			return {
				...state,
				jobEstimate: {
					...state.jobEstimate,
					[action.key]: action.value,
				}
			}
		case CREATE_PROJECT_ATTEMPT:
			return {
				...state,
				creatingProject: true,
				createdProject: false,
			}
		case CREATE_PROJECT_FAIL:
			return {
				...state,
				creatingProject: false,
				createdProject: false,
				error: action.err,
			}
		case CREATE_PROJECT_SUCCESS:
			return {
				...state,
				creatingProject: false,
				createdProject: true,
			}
		default:
			return {
				...state,
			}
	}
}
import { combineReducers } from 'redux';
import { mdpeople } from './mdpeople.reducer';
import { user } from './user.reducer';
import { explore } from './explore.reducer';
import { brokers } from './brokers.reducer';
import { reports } from './reports.reducers';
import { inspectors } from './inspectors.reducer';
import { projects } from './projects.reducer';
import { trades } from './trades.reducer';
import { properties } from './properties.reducer';
import { featureRef } from './featureRef.reducer';
import { projectFeatureMap } from './projectFeatureMap.reducer'



const rootReducer = combineReducers({
	user,
	mdpeople,
	explore,
	brokers,
	reports,
	inspectors,
	projects,
	trades,
	properties,
	featureRef,
	projectFeatureMap,
});

export default rootReducer;

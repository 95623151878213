import React from 'react';
import { Form, Container} from 'react-bootstrap';
import { makeLabel, styleBorder, makePlaceholder } from './';

export const BoolField = function({formName, fieldKey, keysToIgnore, obj, keyAsIndex, onBoolChange}){
	formName = formName || "";
	fieldKey = fieldKey || "";
	keysToIgnore = keysToIgnore || {};
	keyAsIndex = keyAsIndex || "";
	onBoolChange = onBoolChange || (() => {});
	obj = obj || {};
	//console.log("boolean", obj[fieldKey], !!Number(obj[fieldKey]));
	return(
		<Container key={keyAsIndex}>
			<Form.Group controlId="fillerControlId" key={keyAsIndex}>
				{makeLabel(formName.replace(/_/g,' '), fieldKey, keysToIgnore)}
				<Form.Check 
					style={styleBorder(obj, fieldKey, keysToIgnore)} 
					type="checkbox" 
					label={`${fieldKey}`}
					checked={!!Number(obj[fieldKey])}
					onChange={(evt)=>{ onBoolChange(fieldKey, evt.target.checked);}}
				/>
			</Form.Group>
		</Container>
	)
}
import axios from '../services/axios';
import { store } from '../ProviderApp';

/*=========== action names ===========*/

export const GET_ALL_MDPEOPLE_ATTEMPT = 'GET_ALL_MDPEOPLE_ATTEMPT';
export const GET_ALL_MDPEOPLE_SUCCESS = 'GET_ALL_MDPEOPLE_SUCCESS';
export const GET_ALL_MDPEOPLE_FAIL = 'GET_ALL_MDPEOPLE_FAIL';

export const GET_MDPEOPLE_FIELDS_ATTEMPT = 'GET_MDPEOPLE_FIELDS_ATTEMPT';
export const GET_MDPEOPLE_FIELDS_SUCCESS = 'GET_MDPEOPLE_FIELDS_SUCCESS';
export const GET_MDPEOPLE_FIELDS_FAIL = 'GET_MDPEOPLE_FIELDS_FAIL';

export const UPDATE_NEW_MDPERSON = 'UPDATE_NEW_MDPERSON';
export const CLEAR_NEW_MDPERSON = 'CLEAR_NEW_MDPERSON';
export const CREATE_NEW_MDPERSON_ATTEMPT = 'CREATE_NEW_MDPERSON_ATTEMPT';
export const CREATE_NEW_MDPERSON_SUCCESS = 'CREATE_NEW_MDPERSON_SUCCESS';
export const CREATE_NEW_MDPERSON_FAIL = 'CREATE_NEW_MDPERSON_FAIL';

export const TOGGLE_MDPERSON_MODAL = 'TOGGLE_MDPERSON_MODAL';

export const DELETE_MDPERSON_ATTEMPT = 'DELETE_MDPERSON_ATTEMPT';
export const DELETE_MDPERSON_SUCCESS = 'DELETE_MDPERSON_SUCCESS';
export const DELETE_MDPERSON_FAIL = 'DELETE_MDPERSON_FAIL';

export const GET_MDPERSON_ATTEMPT = 'GET_MDPERSON_ATTEMPT';
export const GET_MDPERSON_SUCCESS = 'GET_MDPERSON_SUCCESS';
export const GET_MDPERSON_FAIL = 'GET_MDPERSON_FAIL';

export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_MDPERSON_ATTEMPT = 'UPDATE_MDPERSON_ATTEMPT';
export const UPDATE_MDPERSON_SUCCESS = 'UPDATE_MDPERSON_SUCCESS';
export const UPDATE_MDPERSON_FAIL = 'UPDATE_MDPERSON_FAIL';

export const CLEAR_ERROR_ATTEMPT = 'CLEAR_ERROR_ATTEMPT';
export const CLEAR_ERROR_SUCCESS = 'CLEAR_ERROR_SUCCESS';

/*=========== reducer actions ===========*/

export const getAllMDPeopleAttempt = () => {
	return {
		type: GET_ALL_MDPEOPLE_ATTEMPT,
	};
};

export const getAllMDPeopleSuccess = (people) => {
	return {
		type: GET_ALL_MDPEOPLE_SUCCESS,
		people: people
	};
};
export const getAllMDPeopleFail = (err) => {
	return {
		type: GET_ALL_MDPEOPLE_FAIL,
		err: err
	};
};

export const getMDPeopleFieldsAttempt = () => {
	return {
		type: GET_MDPEOPLE_FIELDS_ATTEMPT,
	};
};

export const getMDPeopleFieldsSuccess = (fields, emptyValues, fieldsHash) => {
	return {
		type: GET_MDPEOPLE_FIELDS_SUCCESS,
		fields: fields,
		emptyValues: emptyValues,
		fieldsHash: fieldsHash,
	};
};
export const getMDPeopleFieldsFail = (err) => {
	return {
		type: GET_MDPEOPLE_FIELDS_FAIL,
		err: err
	};
};

export const updateNewMDPerson = (key, value) => {
	return {
		type: UPDATE_NEW_MDPERSON,
		key: key,
		value: value,
	};
};

export const toggleMDPersonModal = () => {
	return {
		type: TOGGLE_MDPERSON_MODAL,
	};
};

export const clearNewMDPerson = () => {
	return {
		type: CLEAR_NEW_MDPERSON,
	};
};

export const createNewMDPersonAttempt = () => {
	return {
		type: CREATE_NEW_MDPERSON_ATTEMPT,
	};
};

export const createNewMDPersonSuccess = () => {
	return {
		type: CREATE_NEW_MDPERSON_SUCCESS,
	};
};
export const createNewMDPersonFail = (err) => {
	return {
		type: CREATE_NEW_MDPERSON_FAIL,
		err: err
	};
};

export const deleteMDPersonAttempt = () => {
	return {
		type: DELETE_MDPERSON_ATTEMPT
	};
};

export const deleteMDPersonSuccess = () => {
	return {
		type: DELETE_MDPERSON_SUCCESS
	};
};

export const deleteMDPersonFail = (err) => {
	return {
		type: DELETE_MDPERSON_FAIL,
		err: err
	};
};

export const getMDPersonAttempt = () => {
	return {
		type: GET_MDPERSON_ATTEMPT
	};
};

export const getMDPersonSuccess = (personData) => {
	return ({
		type: GET_MDPERSON_SUCCESS,
		personData: { ...personData },
	});
};

export const getMDPersonFail = (err) => {
	return {
		type: GET_MDPERSON_FAIL,
		err: err
	};
};

export const updatePerson = (key, value) => {
	return {
		type: UPDATE_PERSON,
		key: key,
		value: value,
	};
};

export const updateMDPersonAttempt = () => {
	return {
		type: UPDATE_MDPERSON_ATTEMPT
	};
};

export const updateMDPersonSuccess = () => {
	return {
		type: UPDATE_MDPERSON_SUCCESS
	};
};

export const updateMDPersonFail = (err) => {
	return {
		type: UPDATE_MDPERSON_FAIL,
		err: err
	};
};

export const clearErrorAttempt = () => {
	return {
		type: CLEAR_ERROR_ATTEMPT,
	}
}

export const clearErrorSuccess = () => {
	return {
		type: CLEAR_ERROR_SUCCESS,
	}
}

/*=========== actions ===========*/

const fetchAllMDPeople = (dispatch) => {
	dispatch(getAllMDPeopleAttempt());
	axios.get(`/domoreport/uploader/allMDPersons`)
		.then((res) => {
			for (let i = 0; i < res.data.people.length; i++) {
				let person = res.data.people[i]
				res.data.people[i].displayKey = `${person.user_first_name} ${person.user_last_name}, ${person.mdp_title}`
			}
			dispatch(getAllMDPeopleSuccess(res.data.people));
		}).catch(err => {
			dispatch(getAllMDPeopleFail(err));
		});
};

export const getAllMDPeople = () => dispatch => {
	if (!store.getState().mdpeople.peopleFetched && !store.getState().mdpeople.peopleFetching) {
		fetchAllMDPeople(dispatch);
	}
};

export const forceGetAllMDPeople = () => dispatch => {
	fetchAllMDPeople(dispatch);
};


const fetchMDPeopleFields = (dispatch) => {
	dispatch(getMDPeopleFieldsAttempt());
	axios.get(`/domoreport/uploader/MDPerson/fields`)
		.then((res) => {
			let emptyValues = {};

			for (let i = 0; i < res.data.length; i++) {
				emptyValues[res.data[i].Field] = null;
			}
			let fieldsHash = {};
			fieldsHash = res.data.reduce((map, obj) => {
				fieldsHash[obj.Field] = obj.Field
				return fieldsHash;
			});
			dispatch(getMDPeopleFieldsSuccess(res.data, emptyValues, fieldsHash));
		}).catch(err => {
			dispatch(getMDPeopleFieldsFail(err));
		});
};

export const getMDPeopleFields = () => dispatch => {
	if (!store.getState().mdpeople.fieldsFetched && !store.getState().mdpeople.fieldsFetching) {
		fetchMDPeopleFields(dispatch);
	}
};

export const forceGetMDPeopleFields = () => dispatch => {
	fetchMDPeopleFields(dispatch);
};

export const createNewMDPerson = () => dispatch => {
	dispatch(createNewMDPersonAttempt())
	axios.post(`/domoreport/uploader/MDPerson`, store.getState().mdpeople.newMDPerson)
		.then((res) => {
			dispatch(createNewMDPersonSuccess());
			dispatch(clearNewMDPerson());
			fetchAllMDPeople(dispatch);
		}).catch(err => {
			dispatch(createNewMDPersonFail(err));
		});
};

export const deleteMDPerson = (id, goback) => dispatch => {
	dispatch(deleteMDPersonAttempt());
	axios.delete(`/domoreport/uploader/MDPerson/${id}`)
		.then((res) => {
			dispatch(deleteMDPersonSuccess());
			fetchAllMDPeople(dispatch);
			//pass in the goback function. This is not a great way to do it, honestly.
			if (goback != null) {
				goback();
			}
		}).catch(err => {
			dispatch(deleteMDPersonFail(err));
		});
};

const getOneMDPerson = (dispatch, id) => {
	dispatch(getMDPersonAttempt());
	axios.get(`/domoreport/uploader/MDPerson/${id}`)
		.then((res) => {
			dispatch(getMDPersonSuccess(res.data));
		}).catch(err => {
			dispatch(getMDPersonFail(err));
		});
};

export const fetchMDPerson = (id) => dispatch => {
	getOneMDPerson(dispatch, id);
};

export const updateMdPerson = (id) => dispatch => {
	dispatch(updateMDPersonAttempt());
	axios.put(`/domoreport/uploader/MDPerson/${id}`, store.getState().mdpeople.person, {})
		.then((res) => {
			dispatch(updateMDPersonSuccess());
			fetchAllMDPeople(dispatch);
		}).catch(err => {
			dispatch(updateMDPersonFail(err));
		});
}

export const clearErrorWait = () => dispatch => {
	if (!store.getState().mdpeople.clearingError) {
		dispatch(clearErrorAttempt());
		setTimeout(() => {
			dispatch(clearErrorSuccess());
		}, 4000);
	}
}
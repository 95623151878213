import React from 'react';
import { Form, Badge, } from 'react-bootstrap';

export const makeLabel = (labelStr, fieldKey, keysToIgnore) => {
	keysToIgnore = keysToIgnore || {};
	let label = 
		(<Form.Label> {labelStr} <Badge variant="danger"> required </Badge> </Form.Label>);
	if((fieldKey in keysToIgnore)){
		label = (<Form.Label> {labelStr} <Badge variant="primary"> not required </Badge> </Form.Label>);
	}
	return label
}

export const styleBorder = (obj, fieldKey, keysToIgnore, nullValues) => {
	keysToIgnore = keysToIgnore || {};
	let borderStyle = {};
	if (!(fieldKey in keysToIgnore)){
		let value = (obj ? (`${obj[fieldKey]}` === "null" ? "" :  `${obj[fieldKey]}`): "")
		if ( value === "" || value === nullValues){
			borderStyle = {borderColor: "red", backgroundColor: "rgba(255, 0, 0, 0.075)"};
		} else {
			borderStyle = {borderColor: "green", formControl:{focus: {borderColor: "red"}}}
		}
	}

	return borderStyle;
}

export const makePlaceholder = (obj, fieldKey) => {
	return (obj ? (`${obj[fieldKey]}` === "null" ? "" :  `${obj[fieldKey]}`): "")
}

export const TitleCase = (str) => {
	if (!str){
		str = ""
	}
	return str.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}
import React, { Component } from 'react';
import { Modal, Button, Form} from 'react-bootstrap';


export default class CreateModal extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			let obj = this.props.obj ? this.props.obj : {};
			obj = {...obj, ...this.props.dropdownValues};
			let buttonDisabled = false
			for (let key in obj) {
				if (obj.hasOwnProperty(key)) {
					obj[key] = ( key in this.props.state ? this.props.state[key] : obj[key])
					if(this.props.keysToIgnore){
						if(!(key in this.props.keysToIgnore)){
							buttonDisabled = buttonDisabled || obj[key] === "" || obj[key] === "null" || obj[key] === null
							if(obj[key] === "" || obj[key] === "null" || obj[key] === null){
								console.log(key, obj[key]);
							}
						}
					}
				}
			} 

			return (
					<Modal 
						dialogClassName={this.props.dialogClassName}
						size="lg"
						aria-labelledby="contained-modal-title-vcenter"
						centered
						show={true}
						onHide={this.props.close}
						
					>
						<Modal.Header closeButton>
							<Modal.Title>{this.props.headerTitle}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
						<Form>
								{this.props.keys.length >0 && this.props.returnFields(this.props.keys, obj, this.props.onChange, this.props.boolChange, this.props.handleSpecialChange ,this.props.keysToIgnore)}
						</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.props.close}>
								Close
							</Button>
							<Button variant="primary" onClick={() => {this.props.submit(("defaults" in this.props) ? this.props.defaults : null)}} disabled={this.props.submitting || buttonDisabled}>
								Save Changes
							</Button>
						</Modal.Footer>
					</Modal>
			);
		}
	}
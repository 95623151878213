import React, { Component } from 'react';
import { Container, Button, Row, Col, Alert } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import FormByKey from '../../ui/FormByKey/form';
import { _returnFields } from '../../ui/FormByKey/mdPersonFields';
import { keysToIgnore } from "./";
import { connect } from 'react-redux'
import WarningModal from "../../ui/WarningModal";
import { deleteMDPerson, getMDPeopleFields, fetchMDPerson, updatePerson, updateMdPerson, clearErrorWait } from '../../actions';

const mapStateToProps = state => ({
    person: state.mdpeople.person,
    mdPersonDeleting: state.mdpeople.mdPersonDeleting,
    fields: state.mdpeople.fields,
    fieldsHash: state.mdpeople.fieldsHash,
    gettingPerson: state.mdpeople.gettingPerson,
    error: state.mdpeople.error,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, { deleteMDPerson, getMDPeopleFields, fetchMDPerson, updatePerson, updateMdPerson, clearErrorWait })
class EditMDPersonComponent extends Component {
    state = {
        fromServer: false,
        createWarningOpen: false,
    }

    constructor(props) {
        super(props);
        this.props.getMDPeopleFields()
        this._goBack = this._goBack.bind(this);
    }

    componentDidMount() {
        this.props.fetchMDPerson(this.props.match.params.id);
    }

    _handleChange = (key, value) => {
        this.props.updatePerson(key, value);
    }

    _toggleWarningModal = () => {
        const { createWarningOpen } = this.state;
        this.setState({ createWarningOpen: !createWarningOpen }, () => {
            console.log(this.state);
        });
    }

    _onSubmit = () => {
        this.props.updateMdPerson(this.props.match.params.id);
    }

    _onDelete = () => {
        const { deleteMDPerson, match } = this.props;
        deleteMDPerson(match.params.id, this._goBack);
    }

    _goBack() {
        this.props.history.goBack();
    }

    render() {
        const { person, fields, fieldsHash } = this.props;
        const { createWarningOpen } = this.state;

        let buttonDisabled = false
        for (let key in person) {
            if (person.hasOwnProperty(key)) {
                if (!(key in keysToIgnore) && key in fieldsHash) {
                    console.log(key, person[key]);
                    buttonDisabled = buttonDisabled || person[key] === "" || person[key] === "null" || person[key] === null
                }
            }
        }

        if (this.props.error != null) {
            this.props.clearErrorWait();
        }

        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <Row>
                        <Col>
                            <h2>mdPerson: {this.props.gettingPerson && "loading..."}{!this.props.gettingPerson && `${person.user_first_name} ${person.user_last_name}, ${person.mdp_title}`}</h2>
                            <Link to="/mdPeople">Go Back</Link>
                            <FormByKey
                                fields={fields}
                                obj={person}
                                onChange={this._handleChange}
                                submit={this._onSubmit}
                                returnFields={_returnFields}
                                disabled={buttonDisabled}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {this.props.error != null && <Alert variant="danger">There was an error saving</Alert>}
                    </Row>
                    <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                            < Button variant="danger" onClick={this._toggleWarningModal}>DELETE</Button>
                        </Col>
                    </Row>
                    <Row style={{ height: 25 }}></Row>
                    {createWarningOpen && <WarningModal
                        close={this._toggleWarningModal}
                        delete={this._onDelete}
                        titleLable={"Are you firing me?"}
                        bodyLable={"You can't do this to me? My child is sick! I have debt! I need this job..."}
                        buttonLable={"You're fired"}
                        buttonDisabled={this.props.mdPersonDeleting}
                    />}
                </Container>
            </div>
        )
    }
}
import './index.scss';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

export default
@withRouter
class RepairTable extends Component {

    state = {
        details: null,
    }

    setDetailsButton = (repairID) => {
        this.setState({ details: repairID });
    }

    render() {
        const { repairs, filter, priority, reportID, source, invert } = this.props;
        let sortedRepairs = [];
        let headerType = 'contractor_type';
        let filterName = 'area';

        const compare = (a, b) => {
            let sortA = ''
            let sortB = ''

            if (filter == 2) {
                sortA = a.inspection_page_num_start;
                sortB = b.inspection_page_num_start;
                headerType = "inspection_page_num_start";
                filterName = "Page #";
            } else if (filter == 3) {
                sortA = a.contractor_type;
                sortB = b.contractor_type;
                headerType = 'contractor_type';
                filterName = "Contractor Type"
            } else if (filter == 4) {
                sortA = a.cost_estimate.low;
                sortB = b.cost_estimate.low;
                headerType = 'cost_estimate';
                filterName = "Cost Estimate";
            } else if (filter == 5) {
                //sort high to low
                sortA = b.priority
                sortB = a.priority
                headerType = "priority"
                filterName = "Priority"
            } else {
                sortA = a.section_name;
                sortB = b.section_name;
                headerType = "section_name";
                filterName = "Area";
            }

            //If invert is set to true, sort high to low. Otherwise sort low to high.
            let comparison = 0;
            if (sortA > sortB) {
                comparison = invert ? -1 : 1;
            } else if (sortA < sortB) {
                comparison = invert ? 1 : -1;
            }
            return comparison;
        }

        if (priority == 'Cosmetic') {
            sortedRepairs = repairs.filter((repair) => {
                headerType = "priority";
                return repair.priority === 'Cosmetic'
            })
        } else if (priority == 'Pressing') {
            sortedRepairs = repairs.filter((repair) => {
                headerType = "priority";
                return repair.priority === 'Pressing'
            })
        } else if (priority == 'Urgent') {
            sortedRepairs = repairs.filter((repair) => {
                headerType = "priority";
                return repair.priority === 'Urgent'
            })
        } else {
            sortedRepairs = repairs;
        }

        sortedRepairs = sortedRepairs.sort(compare)

        let headersMemo = {};
        let headers = [];
        let mobileHeaders = [];

        sortedRepairs.map((repair) => {
            if (repair[headerType] in headersMemo) {
                ++headersMemo[repair[headerType]].total
                headersMemo[repair[headerType]].low += repair.cost_estimate.low;
                headersMemo[repair[headerType]].high += repair.cost_estimate.high;
            } else {
                headersMemo[repair[headerType]] = {
                    total: 1,
                    low: repair.cost_estimate.low,
                    high: repair.cost_estimate.high,
                }
            }
        })

        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12 d-flex repairs-details-header">
                        <h2 className="px-md-4">REPAIRS DETAILS</h2>
                        <div className="dropdown-filter icon d-inline d-md-none" onClick={this.showModal}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">{filterName}</Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { this.props.updateFilters(reportID, 1, priority) }}>Area </Dropdown.Item>
                                    <Dropdown.Item onClick={() => { this.props.updateFilters(reportID, 2, priority) }}>Page #</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { this.props.updateFilters(reportID, 3, priority) }}>Contractor Type</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { this.props.updateFilters(reportID, 4, priority) }}>Cost Estimate</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { this.props.updateFilters(reportID, 5, priority) }}>Priority</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <table cellPadding="0" className="details-table d-none d-md-table" cellSpacing="0" border="0" width="100%">
                    <thead>
                        <tr>
                            <td>
                                <Link
                                    to="#"
                                    onClick={(e) => { e.preventDefault(); this.props.updateFilters(reportID, 1, priority) }}
                                >AREA - PROJECT&nbsp;
                                    <i className={(invert && filter == 1) ? `fa fa-caret-up` : `fa fa-caret-down`} />
                                </Link>
                            </td>
                            <td className="text-center">
                                <Link
                                    to="#"
                                    onClick={(e) => { e.preventDefault(); this.props.updateFilters(reportID, 4, priority) }}
                                >COST ESTIMATE&nbsp;
                                    <i className={(invert && filter == 4) ? `fa fa-caret-up` : `fa fa-caret-down`} />
                                </Link>
                            </td>
                            <td>
                                <Link
                                    to="#"
                                    onClick={(e) => { e.preventDefault(); this.props.updateFilters(reportID, 3, priority) }}
                                >CONTRACTOR TYPE&nbsp;
                                    <i className={(invert && filter == 3) ? `fa fa-caret-up` : `fa fa-caret-down`} />
                                </Link>
                            </td>
                            <td className='text-center'>
                                <Link
                                    to="#"
                                    onClick={(e) => { e.preventDefault(); this.props.updateFilters(reportID, 2, priority) }}
                                >PAGE #&nbsp;
                                    <i className={(invert && filter == 2) ? `fa fa-caret-up` : `fa fa-caret-down`} />
                                </Link>
                            </td>
                            <td className="text-center">
                                <Link
                                    to="#"
                                    onClick={(e) => { e.preventDefault(); this.props.updateFilters(reportID, 5, priority) }}
                                >PRIORITY&nbsp;
                                    <i className={(invert && filter == 5) ? `fa fa-caret-up` : `fa fa-caret-down`} />
                                </Link>
                            </td>
                        </tr>
                    </thead>
                    {sortedRepairs.map((repair) => {
                        return (
                            <tbody key={repair.repair_id}>
                                {(!headers.includes(repair[headerType]) && headerType != 'cost_estimate' && headerType != 'inspection_page_num_start') ?
                                    <tr className={headerType === 'priority' ? `heading md-${repair.priority}` : `heading md-${priority || 'cosmetic'}`}>
                                        <td colSpan="1">{repair[headerType]} - {headersMemo[repair[headerType]].total} {headersMemo[repair[headerType]].total == 1 ? 'repair needed' : 'repairs needed'}</td>
                                        <td colSpan="1" className="text-center">${headersMemo[repair[headerType]].low} - {headersMemo[repair[headerType]].high}</td>
                                        <td colSpan="3"></td>
                                        <td className="d-none">{headers.push(repair[headerType])}</td>
                                    </tr>
                                    : <tr></tr>
                                }
                                {(!headers.includes(headerType) && headerType == 'cost_estimate') ?
                                    <tr className={`heading md-${priority || 'cosmetic'}`}>
                                        <td colSpan="1">Cost Estimate</td>
                                        <td colSpan="1" className="text-center">${headersMemo[repair[headerType]].low} - {headersMemo[repair[headerType]].high}</td>
                                        <td colSpan="3"></td>
                                        <td className="d-none">{headers.push(headerType)}</td>
                                    </tr>
                                    : <tr></tr>
                                }
                                {(!headers.includes(headerType) && headerType == 'inspection_page_num_start') ?
                                    <tr className={`heading md-${priority || 'cosmetic'}`}>
                                        <td colSpan="1">Page Number</td>
                                        <td colSpan="1" className="text-center">${headersMemo[repair[headerType]].low} - {headersMemo[repair[headerType]].high}</td>
                                        <td colSpan="3"></td>
                                        <td className="d-none">{headers.push(headerType)}</td>
                                    </tr>
                                    : <tr></tr>
                                }

                                <tr
                                    key={repair.repair_id}
                                    className="table-row"
                                    onMouseOver={() => { this.setDetailsButton(repair.repair_id) }}
                                    onMouseLeave={() => this.setDetailsButton(null)}
                                >
                                    <td
                                        className="md-clickable"
                                        onClick={(e) => this.props.updateUrl(e, repair.repair_id, reportID, filter, priority, source, repair)}
                                    ><b>{repair.feature_name}</b> - {repair.project_name}
                                    </td>
                                    <td className="text-center">
                                        ${repair.cost_estimate.low} - {repair.cost_estimate.high}
                                    </td>
                                    <td>
                                        {repair.contractor_type}
                                    </td>
                                    <td className="text-center">
                                        <Link to="#" onClick={(e) => { e.preventDefault(); }}>
                                            {repair.inspection_page_num_start !== repair.inspection_page_num_end ?
                                                (repair.inspection_page_num_start + ' - ' + repair.inspection_page_num_end)
                                                :
                                                repair.inspection_page_num_start
                                            }
                                        </Link>
                                    </td>
                                    <td>
                                        {this.state.details == repair.repair_id ?
                                            <span
                                                className={`md-view-details md-priority-tag md-${repair.priority}`}
                                                onClick={(e) => this.props.updateUrl(e, repair.repair_id, reportID, filter, priority, source, repair)}
                                            >View Details
                                            </span>
                                            :
                                            <span className={`md-priority-tag md-${repair.priority}`}>{repair.priority}</span>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        )
                    })}
                </table>

                <div className="repair-card-wrapper d-inline-block d-md-none">
                    {sortedRepairs.map((repair) => {
                        return (
                            <div key={repair.repair_id}>
                                {(!mobileHeaders.includes(repair[headerType]) && headerType != 'cost_estimate' && headerType != 'inspection_page_num_start') ?
                                    <div className={headerType == 'priority' ? `row heading-mobile md-${repair.priority}` : `row heading-mobile md-${priority || 'cosmetic'}`}>
                                        <p>{repair[headerType]}</p>
                                        <p className="text-center">${headersMemo[repair[headerType]].low} - {headersMemo[repair[headerType]].high}</p>
                                        <p className="d-none">{mobileHeaders.push(repair[headerType])}</p>
                                    </div>
                                    : <div />
                                }
                                {(!headers.includes(headerType) && headerType == 'cost_estimate') ?
                                    <div className={`row heading-mobile md-${priority || 'cosmetic'}`}>
                                        <p>Cost Estimate</p>
                                        <p className="text-center">${headersMemo[repair[headerType]].low} - {headersMemo[repair[headerType]].high}</p>
                                        <p className="d-none">{mobileHeaders.push(repair[headerType])}</p>
                                    </div>
                                    : <div />
                                }
                                {(!headers.includes(headerType) && headerType == 'inspection_page_num_start') ?
                                    <div className={`row heading-mobile md-${priority || 'cosmetic'}`}>
                                        <p>Page Number</p>
                                        <p className="text-center">${headersMemo[repair[headerType]].low} - {headersMemo[repair[headerType]].high}</p>
                                        <p className="d-none">{mobileHeaders.push(repair[headerType])}</p>
                                    </div>
                                    : <div />
                                }
                                <div className="repair-card" key={repair.repair_id}>
                                    <div className="row">
                                        <div className="col-8 col-sm-9 repair-name">
                                            <p>{repair.project_name}</p>
                                        </div>
                                        <div className="col-4 col-sm-3 repair-priority">
                                            <span className={`md-priority-tag md-${repair.priority}`}>{repair.priority}</span>
                                        </div>

                                        <div className="col-10 offset-1 repair-details">
                                            <p>${repair.cost_estimate.low} - {repair.cost_estimate.high}</p>

                                            <h4 className="md-card-heading">Job Details</h4>
                                            <p dangerouslySetInnerHTML={{ __html: repair.job_details }}></p>

                                            <h4 className="md-card-heading">Contractor Type</h4>
                                            <p>{repair.contractor_type}</p>
                                            <p>
                                                More details on inspection page(s):&nbsp;
                                                <Link
                                                    to="#"
                                                    onClick={(e) => { e.preventDefault() }}
                                                >
                                                    {repair.inspection_page_num_start !== repair.inspection_page_num_end ?
                                                        (repair.inspection_page_num_start + ' - ' + repair.inspection_page_num_end)
                                                        :
                                                        repair.inspection_page_num_start
                                                    }
                                                </Link>
                                            </p>

                                            <Link to="#" onClick={(e) => { e.preventDefault(); this.props.updateUrl(e, repair.repair_id, reportID, filter, priority, source, repair) }}>SHOW PROJECT DETAILS</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
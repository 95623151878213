import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typeahead,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import { getAllTrades } from "../../actions";

const mapStateToProps = (state) => ({
  trades: state.trades.sortedTrades,
  tradesFetched: state.trades.tradesFetched,
});
class TradesTypeaheadField extends Component {
  componentDidMount() {
    this.props.getAllTrades();
  }

  _renderMenu(trades, menuProps) {
    let items = [];
    let idx = 0;
    for (let i = 0; i < trades.length; i++) {
      if (!!i && trades[i].trade_id < 0) {
        items.push(<Menu.Divider key={`${trades[i].trade_name}-divider`} />);
      }
      if (trades[i].trade_id < 0) {
        items.push(
          <Menu.Header key={`${trades[i].trade_name}-header`}>
            {trades[i].trade_name}
          </Menu.Header>
        );
      }
      if (trades[i].trade_id >= 0) {
        items.push(
          <MenuItem key={trades[i].trade_id} option={trades[i]} position={idx}>
            <Highlighter search={menuProps.text || ""}>
              {trades[i].trade_name}
            </Highlighter>
          </MenuItem>
        );
        idx++;
      }
    }

    return <Menu {...menuProps}>{items}</Menu>;
  }

  handleChange = (e) => {
    const { src } = this.props;

    if (src === "reviewCard") {
      this.props.updateProject(e.length > 0 ? e[0]["trade_id"] : null);
    } else {
      this.props.updateProject(
        "trade_id",
        e.length > 0 ? e[0]["trade_id"] : null
      );
    }
  };

  render() {
    let { keysToIgnore, keyAsIndex, value, disabled } = this.props;

    keysToIgnore = keysToIgnore || {};
    keyAsIndex = keyAsIndex || "";
    let selectedArray = [];

    if (value && this.props.trades.length) {
      selectedArray = [
        this.props.trades.find((trade) => {
          return trade.trade_id == value;
        }),
      ];
    }

    return (
      <Typeahead
        onChange={this.handleChange}
        allowNew={false}
        multiple={false}
        placeholder="Search for trade"
        options={this.props.trades}
        labelKey="trade_name"
        id={"trade search"}
        selected={selectedArray}
        renderMenu={this._renderMenu}
        disabled={false}
        key={keyAsIndex}
      />
    );
  }
}

export default connect(mapStateToProps, { getAllTrades })(TradesTypeaheadField);

import { 
    GET_EXPLORE_CONTENT_ATTEMPT,
    GET_EXPLORE_CONTENT_FAIL,
    GET_EXPLORE_CONTENT_SUCCESS,
    UPDATE_CONTENT_STATE,
} from '../actions'

const initialState = {
    content:{},
    gettingContent: false,
}

export function explore(state = initialState, action){
	switch (action.type){
        case GET_EXPLORE_CONTENT_ATTEMPT:
            return {
                ...state,
                gettingContent: true,
            }
        case GET_EXPLORE_CONTENT_SUCCESS:
            return {
                ...state,
                gettingContent: false,
                content: action.content,
            }
        case GET_EXPLORE_CONTENT_FAIL:
            return {
                ...state,
                gettingContent: false,
            } 
        case UPDATE_CONTENT_STATE:
                return {
					...state,
					content: {
						...state.content,
						[action.key]: action.value,
					}
				}
        default:
            return {...state}
    }
}
import React, { Component } from 'react';
import { Form, Container, Row, Col, Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { makeLabel, styleBorder, makePlaceholder } from './';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import preloader from '../../preloader.gif';
import {
	getJobList,
	getJobDetails,
	getJobEstimate,
	updateProject,
	updateJobEstimate,
} from '../../actions';

const unitOfMeasureMap = require('./unit-of-measure-mappings.json');

const mapStateToProps = (state) => ({
	jobList: state.projects.jobList,
	jobListFetching: state.projects.jobListFetching,
	jobDetails: state.projects.jobDetails,
	jobDetailFetching: state.projects.jobDetailFetching,
	jobEstimate: state.projects.jobEstimate,
	jobEstimateFetching: state.projects.jobEstimateFetching,
	project: state.projects.project,
	fetchingProject: state.projects.oneFetching,
	fetchedProject: state.projects.oneFetched,
	property: state.properties.property.property,
	propertyFetching: state.properties.propertyFetching,
	propertyFetched: state.properties.propertyFetched,
	unitTypes: state.projects.unitTypesMemo
});

// name(pin):"Low"
// subheading(pin):""
// description(pin):"Paint (1 coat) - 1 color"


const repairwiseDBKeyMap = {
	"description": "cache_job_details",
	"name": "xactware_quality",
	"lowTotalPrice": "cache_project_cost_low",
	"highTotalPrice": "cache_project_cost_high",
	"lowMaterialPrice": "cache_material_cost_low",
	"highMaterialPrice": "cache_material_cost_high",
	"lowLaborPrice": "cache_labor_cost_low",
	"highLaborPrice": "cache_labor_cost_high",
	"highEquipmentPrice": "cache_equipment_cost_low",
	"lowEquipmentPrice": "cache_equipment_cost_high",
	"lowLaborMinimumPrice": "cache_licensed_contractor_cost_low",
	"highLaborMinimumPrice": "cache_licensed_contractor_cost_high",
	"highMarketPrice": "high_market_price",
	"lowMarketPrice": "low_market_price",
};

class RepairTypeaheadField extends Component {

	constructor(props) {
		super(props)
		this.handleSearch = this.handleSearch.bind(this);
		this.handleOnInputChange = this.handleOnInputChange.bind(this);
		this._onQuantityChange = this._onQuantityChange.bind(this);
		this._getNewEstimate = this._getNewEstimate.bind(this);
		this._handleUseThisImage = this._handleUseThisImage.bind(this);
		this._handleRemoveThisImage = this._handleRemoveThisImage.bind(this);
	}

	componentDidMount() {
		if ("repair_wise_job_id" in this.props.project && this.props.project.repair_wise_job_id != null) {
			this.props.getJobEstimate(this.props.project.repair_wise_job_id);
		}
	}

	componentDidUpdate(lastProps) {
		console.log("componentDidUpdate", this.props.fetchedProject, this.props.propertyFetched, lastProps.fetchingProject, lastProps.propertyFetching)
		if (this.props.fetchedProject && this.props.propertyFetched && (lastProps.fetchingProject || lastProps.propertyFetching)) {
			if ("repair_wise_job_id" in this.props.project && this.props.project.repair_wise_job_id != null) {
				this.props.getJobEstimate(this.props.project.repair_wise_job_id);
			}
		}

		if (lastProps.jobEstimateFetching && !this.props.jobEstimateFetching) {
			this.props.updateProject("xactware_quantity", this.props.jobEstimate.quantity);
			this.props.updateProject("cache_project_name", this.props.jobEstimate.name);
		}
	}

	handleOnInputChange(e) {
		this.props.onChange("repair_wise_job_id", (e.length > 0 ? e[0]["id"] : null))
		this.props.getJobEstimate(e.length > 0 ? e[0]["id"] : null);
	}

	handleSearch(query) {
		this.props.getJobList(query);
	}

	_handleQulaityClick(quality) {
		this.setState({ selectedQualityLevel: quality.selectedQualityLevel })
		for (let key in quality) {
			if (quality.hasOwnProperty(key)) {
				if (key === "description") {
					this.props.updateProject(repairwiseDBKeyMap[key], `${this.props.jobEstimate.description} - ${quality[key]}`);
				} else {
					this.props.updateProject(repairwiseDBKeyMap[key], quality[key]);
				}
			}
		}
		const unitType = this.props.jobEstimate.unitOfMeasure.slice(-2);
		if (unitType in this.props.unitTypes) {
			this.props.updateProject("cache_unit_type_ref_id", this.props.unitTypes[unitType].unit_type_ref_id);
		} else {
			//if the unit doesn't exist in the memo, set it to "unit"
			this.props.updateProject("cache_unit_type_ref_id", 35);
		}

	}

	_onQuantityChange(quantity) {
		this.setState({ quantity: quantity });
	}

	_getNewEstimate() {
		if ("quantity" in this.state) {
			let { id, jobId } = this.props.jobEstimate
			this.props.updateJobEstimate(id, null, jobId, this.state.quantity)
		}
	}

	_handleUseThisImage() {
		this.props.updateProject("doc_log_id", this.props.jobEstimate.doc_log_id);
	}

	_handleRemoveThisImage() {
		this.props.updateProject("doc_log_id", null);
	}

	render() {
		let { keysToIgnore, obj, keyAsIndex, value } = this.props

		keysToIgnore = keysToIgnore || {};
		keyAsIndex = keyAsIndex || "";
		let selectedArray = [];

		if (value && this.props.tradesFetched) {
			selectedArray = [this.props.trades.find(trade => { return trade.trade_id == value })];
		}

		return (
			<Container key={keyAsIndex}>
				<Row>
					<Col>
						<Form.Group controlId="fillerControlId" key={keyAsIndex}>
							{makeLabel("Search For Projects", "xactware_project_id", keysToIgnore)}

							<AsyncTypeahead
								id="searchBar"
								type="text"
								placeholder="Search for a repairwise project"
								inputProps={{
									style: styleBorder({ trade_id: value }, "trade_id", keysToIgnore, "")
								}}
								selectHintOnEnter={true}
								onChange={this.handleOnInputChange}
								allowNew={false}
								multiple={false}
								onSearch={this.handleSearch}
								options={this.props.jobList}
								isLoading={this.props.jobListFetching}
								minLength={1}
								labelKey="name"
								filterBy={() => {
									return true;
								}}

								renderMenuItemChildren={(option, props) => (
									<div id={option.id} key={option.id}>
										<span>{option.name}</span>
									</div>
								)}
							/>
						</Form.Group>
					</Col>
				</Row>
				{this.props.jobEstimateFetching &&
					<Card>
						<Card.Img src={preloader} />
					</Card>
				}
				{("id" in this.props.jobEstimate) && !this.props.jobEstimateFetching &&
					<Card>

						<Card.Header>
							{this.props.jobEstimate.name}
						</Card.Header>
						<Card.Body>
							<Card.Text>
								{this.props.jobEstimate.description}
							</Card.Text>
							<Card.Img style={{ width: "50%" }} src={this.props.jobEstimate.image} />
						</Card.Body>
						<Card.Body>
							{!this.props.project.doc_log_id &&
								<Button variant="outline-primary" onClick={this._handleUseThisImage}>
									Use this image?
								</Button>
							}
							{this.props.project.doc_log_id &&

								<Button variant="danger" onClick={this._handleRemoveThisImage}>
									remove image
								</Button>
							}
						</Card.Body>
						<Card.Body>

							<Form.Group controlId="fillerControlId" key={keyAsIndex}>
								<Form.Label>{unitOfMeasureMap.unitsOfMeasure.long[this.props.jobEstimate.unitOfMeasure]}</Form.Label>
								<Form.Row>
									<Col>
										<Form.Control
											type="number" placeholder={`${unitOfMeasureMap.unitsOfMeasure.short[this.props.jobEstimate.unitOfMeasure]}`}
											defaultValue={this.props.jobEstimate.quantity}
											onChange={(evt) => { this._onQuantityChange(evt.target.value) }}
										/>
									</Col>
									<Col xs="auto">
										<Button onClick={this._getNewEstimate}>
											Get New Estimate
										</Button>
									</Col>
								</Form.Row>
								<Form.Text className="text-muted">
									in units of {unitOfMeasureMap.unitsOfMeasure.short[this.props.jobEstimate.unitOfMeasure]}
								</Form.Text>
							</Form.Group>


						</Card.Body>
						<Card.Body>
							<Container>
								<Row className="justify-content-md-center">
									{
										this.props.jobEstimate.qualityLevels.map(level => {
											return (
												<Col xs={4}>
													<Card>
														<Link onClick={(e) => { e.preventDefault(); this._handleQulaityClick(level); }}>
															<Card.Header>
																{level.name}
															</Card.Header>
															<Card.Body>
																<Card.Text>
																	${level.lowTotalPrice}-${level.highTotalPrice}
																</Card.Text>
															</Card.Body>
															<Card.Body>
																<Card.Title>
																	{level.subheading}
																</Card.Title>
																<Card.Text>
																	{level.description}
																</Card.Text>
															</Card.Body>
															<ListGroup className="list-group-flush">
																<ListGroupItem>Material: ${level.lowMaterialPrice}-${level.highMaterialPrice}</ListGroupItem>
																<ListGroupItem>Labor: ${level.lowLaborPrice}-${level.highLaborPrice}</ListGroupItem>
																<ListGroupItem>Equipment: ${level.lowEquipmentPrice}-${level.highEquipmentPrice}</ListGroupItem>
																<ListGroupItem>Market: ${level.lowMarketPrice}-${level.highMarketPrice}</ListGroupItem>
																<ListGroupItem>Labor Minimum: ${level.lowLaborMinimumPrice}-${level.highLaborMinimumPrice}</ListGroupItem>
															</ListGroup>
														</Link>
													</Card>
												</Col>
											)
										})
									}
								</Row>
							</Container>
						</Card.Body>
					</Card>
				}

			</Container>
		)
	}

}

export default connect(
	mapStateToProps,
	{ getJobList, getJobDetails, getJobEstimate, updateProject, updateJobEstimate }
)(RepairTypeaheadField);
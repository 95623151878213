import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import withUser from '../../hocs/withUser';
import { connect } from 'react-redux';
import ProjectFeatureMapForm from './form'
import {
	createProjectFeatureMap,
	updateProjectFeatureMap,
	deleteProjectFeatureMap,
} from '../../actions'

const mapStateToProps = state => ({
	pfm: state.projectFeatureMap.currentPFM,
	disableButtons: state.projectFeatureMap.disableButtons
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, {
	createProjectFeatureMap,
	updateProjectFeatureMap,
	deleteProjectFeatureMap,
})
class ProjectFeatureMapModal extends Component {

	state = {
		attemptDelete: false
	}

	_onClose = () => {
		this.setState({attemptDelete: false}); 
		this.props.close();
	}

	_onDelete = (pfm) => {
		if(this.state.attemptDelete){
			this.props.deleteProjectFeatureMap(pfm.id, this._onClose)
		} else {
			this.setState({attemptDelete: true})
		}
	}

	render() {
		let { pfm } = this.props
		console.log(pfm)
		return (
			<Modal 
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.isVisible}
				onHide={this._onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>Project-Feature Map id: {pfm.id || "ID not set yet"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<Form>
					{ !this.state.attemptDelete && <ProjectFeatureMapForm /> }
					{ this.state.attemptDelete && 
						"This action is permanent. Please confirm deleting "
					}
				</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this._onClose}>
						Close
					</Button>
					{ !this.state.attemptDelete && 
						<Button variant="primary" onClick={() => { if(pfm.id){this.props.updateProjectFeatureMap(pfm.id)} else {this.props.createProjectFeatureMap()} }} disabled={this.props.submitting || this.props.disableButtons}>
							{pfm.id  ? "Save Changes" : "Save New"}
						</Button> 
					}
					{ this.state.attemptDelete && 
						<Button variant="primary" disabled={this.props.disableButtons} onClick={() => { this.setState({attemptDelete: false}) }}>
							{"cancel delete"}
						</Button> 
					}
					{ pfm.id && 
						<Button variant="danger" onClick={() => {this._onDelete(pfm)}} disabled={this.props.disableButtons}>
							{ !this.state.attemptDelete ? "Delete" : "Confirm delete" }
						</Button>
					}
				</Modal.Footer>
			</Modal>
		);
	  }
}
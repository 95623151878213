import React, { Component } from 'react';
import { Container, Button, Form, Col, ListGroup, Table } from 'react-bootstrap';
import withFetch from '../../hocs/withFetch';
import { withRouter } from 'react-router-dom';
import withUser from '../../hocs/withUser';
import CreateModal from '../../ui/CreateModal/modal';
import { _returnFields } from '../../ui/FormByKey/defaultFields';
import Fuse from 'fuse.js';

export const keysToIgnore = {
    "broker_last_modified": "broker_last_modified",
    "broker_added": "broker_added",
    "brokerage_profile_pic": "brokerage_profile_pic",
    "broker_ref_id": "broker_ref_id",
};

export default 
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        allBrokers: {
            url: `/domoreport/uploader/allBrokers`,
            force: true,

        },
        refreshBrokers: () => ({
            allBrokers: {
                url: `/domoreport/uploader/allBrokers`,
                force: true,
                refreshing: true,
                headers: {
                    authorization: props.user.token
                }
            }
        }),
        brokerFields: {
            url: '/domoreport/uploader/broker/fields',

        },
        createBroker: (data) => ({
            createdBroker: {
                url: `/domoreport/uploader/broker`,
                method: 'POST',

                body: JSON.stringify(data)
            }
        })
    }) : null;
})
class BrokersComponent extends Component {

    state = {
        createModalOpen: false,
        newBroker: {},
        brokerSubmitting: false,
        searchValue: null
    }

    _toggleModal = () => {
        const { createModalOpen } = this.state;
        this.setState({ createModalOpen: !createModalOpen });
    }


    _handleChange = (key, value) => {
        let { newBroker } = this.state;
        newBroker[key] = value;
        this.setState({ newBroker });
    }

    _createNewBroker = () => {
        const { createBroker, refreshBrokers } = this.props;
        const { newBroker } = this.state;
        this.setState({ brokerSubmitting: true });
        if (newBroker.broker_person_name) {
            createBroker({ ...newBroker });
            setTimeout(() => {
                refreshBrokers();
                this.setState({ newBroker: {}, createModalOpen: false, brokerSubmitting: false })
            }, 1000)

        }
    }

    _createEmptyValues = (fields) => {
        let emptyValues = {}
        for (let i = 0; i < fields.length; i++) {
            emptyValues[fields[i].Field] = null
        }
        return emptyValues;
    }
    _handleSearch = (text) => {
        if (!text) {
            this.setState({ searchValue: null });
        }
        this.setState({ searchValue: text });
    }


    _renderBrokerRows = (brokers) => {
        const { searchValue } = this.state;
        const { history } = this.props;
        let options = {
            keys: [
                {
                    name: 'broker_person_name',
                    weight: 0.4
                },
                {
                    name: 'brokerage_phone',
                    weight: 0.1
                },
                {
                    name: 'brokerage',
                    weight: 0.1
                }, {
                    name: 'brokerage_email',
                    weight: 0.4
                }]
        }

        let brokersFuse = new Fuse(brokers, options)
        let arr = brokersFuse.list;
        if (searchValue) {
            arr = brokersFuse.search(searchValue)
        }
        return arr.map(broker => {
            return (
                <tr key={broker.broker_ref_id} onClick={() => history.push(`/brokers/${broker.broker_ref_id}`)}>
                    <td>{broker.broker_ref_id}</td>
                    <td>{broker.broker_person_name}</td>
                    <td>{broker.brokerage}</td>
                    <td>{broker.brokerage_phone}</td>
                    <td>{broker.brokerage_email}</td>
                </tr>
            )
        })
    }

    render() {
        const { createModalOpen, brokerSubmitting } = this.state;
        const { allBrokers, brokerFields } = this.props;
        if (!allBrokers) {
            return null;
        }
        if (allBrokers.pending || brokerFields.pending) {


            return <Container>
                <h2>Brokers</h2>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Brokerage</th>
                            <th>Phone</th>
                            <th>Email</th>
                        </tr>
                    </thead>

                </Table>
            </Container>
        }
        let { brokers } = allBrokers.value;
        let { value: fields } = brokerFields;

        let emptyValues = this._createEmptyValues(fields);

        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <h2>Brokers</h2>
                    <Button onClick={this._toggleModal} >Create New Broker</Button>
                    {createModalOpen && <CreateModal
                        submitting={brokerSubmitting}
                        keys={fields}
                        close={this._toggleModal}
                        onChange={this._handleChange}
                        submit={this._createNewBroker}
                        headerTitle={'Create a Broker'}
                        returnFields={_returnFields}
                        dropdownValues={{ ...emptyValues }}
                        state={this.state.newBroker}
                        keysToIgnore={keysToIgnore}
                    />}
                    <Form.Group>
                        <Form.Label>Filter Results</Form.Label>
                        <Form.Control type="text" onChange={(e) => this._handleSearch(e.target.value)} />
                    </Form.Group>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Brokerage</th>
                                <th>Phone</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {brokers.length > 0 && this._renderBrokerRows(brokers)}
                        </tbody>
                    </Table>
                </Container>
            </div>
        )
    }
}
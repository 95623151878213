import './index.scss';
import React from "react";
import * as SmartyStreetsSDK from "smartystreets-javascript-sdk";
import InputForm from "./InputForm";
import Suggestions from "./Suggestions";
import withFetch from '../../hocs/withFetch';
import { withRouter } from "react-router-dom";
import { geolocated } from "react-geolocated";

@withRouter
@withFetch((props) => ({
	getAddressId: (data) => ({
		addressData: {
			url: `/property/addressId`,
			method: 'POST',
			body: JSON.stringify(data),
			force: true,
			refresh: true,
			andThen: (res) => ({
				createdProperty: {
					url: "/property/register",
					method: 'POST',
					body: JSON.stringify(res),
					force: true,
					refresh: true,
					then: (createdProperty) => {
						props.handlePropertyChange(createdProperty.property_log_id, res.domo_address_id);
					}
				}
			})
		}
	}),
}))
class PropertyAddressSearch extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			address1: "",
			address2: "",
			city: "",
			state: "",
			zipCode: "",
			country: "US",
			suggestions: [],
			error: "",
			showSuggestions: false,
			loading: false,
		};

		const SmartyStreetsCore = SmartyStreetsSDK.core;

		const websiteKey = process.env.REACT_APP_SMARTYSTREETS_API_KEY;
		const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
		let client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);

		this.SmartyStreetsCore = SmartyStreetsCore;
		this.client = client;
	}

	toggleSuggestions = () => {
		this.setState({ showSuggestions: !this.state.showSuggestions })
	}

	updateStateFromForm = (key, value) => {
		const newState = {};
		newState[key] = value;

		this.setState(newState);
	}

	updateField = (e) => {
		this.updateStateFromForm(e.target.id, e.target.value);
	}

	queryAutocompleteForSuggestions = (query) => {
		const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(query);

		this.client.send(lookup)
			.then(response => {
				this.setState({ suggestions: response.result });
			})
			.catch(console.warn);
	}

	selectSuggestion = (suggestion) => {
		const newState = {
			address1: suggestion.streetLine + ', ' + (suggestion.secondary ? suggestion.secondary + ', ' : '') + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zipcode,
			error: "",
			loading: true,
			suggestions: [],
		};

		if (suggestion.streetLine) {
			let data = {
				street: suggestion.streetLine,
				street2: suggestion.secondary,
				city: suggestion.city,
				state: suggestion.state,
				zipCode: suggestion.zipcode,
			}

			let fullAddress = data.street + ', ' + data.city + ', ' + data.state + ' ' + data.zipCode;
			let urlAddress = fullAddress.replace(/\s+/g, '+');
			let streetURL = `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${urlAddress}&pitch=-0.76&key=AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU`;
			newState.address1 = fullAddress;

			localStorage.setItem('wizard-address-url', streetURL);
			this.props.getAddressId(data)
		} else {
			this.setState({ error: 'Sorry, we cannot find this address. ' })
		}

		this.setState(newState);
	}

	render() {
		return (
			<div className="row md-address-search-wrapper" style={{ position: "relative" }}>
				<div style={{ width: "100%" }}>
					<div>
						<i className="fa fa-search" />
						<InputForm
							state={this.state}
							updateField={this.updateField}
							queryAutocompleteForSuggestions={this.queryAutocompleteForSuggestions}
							toggleSuggestions={this.toggleSuggestions}
						/>
						<Suggestions
							state={this.state}
							suggestions={this.state.suggestions}
							selectSuggestion={this.selectSuggestion}
						/>
					</div>
				</div>
			</div>
		);
	}
}
export default geolocated({
	positionOptions: {
		enableHighAccuracy: true,
	},
	userDecisionTimeout: null,
})(PropertyAddressSearch)
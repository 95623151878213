import React from 'react';
import { Form, Container} from 'react-bootstrap';
import { makeLabel, styleBorder, makePlaceholder } from './';
import ReactQuill from 'react-quill';

const setEmptyValueToNull = (emptyValue, string) => {
	if(string === emptyValue){
		return null;
	} else {
		return string;
	}
};

export const TextQuilField = function({formName, fieldKey, keysToIgnore, obj, keyAsIndex, onChange}){
	formName = formName || "";
	fieldKey = fieldKey || "";
	keysToIgnore = keysToIgnore || {};
	keyAsIndex = keyAsIndex || "";
	obj = obj || {};
	onChange = onChange || (() => {});

	return(
		<Container key={keyAsIndex}>
			<Form.Group controlId="fillerControlId" key={keyAsIndex}>
				{makeLabel(formName.replace(/_/g,' '), fieldKey, keysToIgnore)}
				<ReactQuill
					style={styleBorder(obj, fieldKey, keysToIgnore, "<p><br></p>")} 
					value = {makePlaceholder(obj, fieldKey)} 
					onChange={(evt)=>{onChange(fieldKey, setEmptyValueToNull("<p><br></p>", evt));}}
				/>
			</Form.Group>
		</Container>
	)
}
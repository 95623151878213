import axios from "../services/axios";
import { store } from "../ProviderApp";

/*=========== action names ===========*/

export const GET_ALL_TRADES_ATTEMPT = "GET_ALL_TRADES_ATTEMPT";
export const GET_ALL_TRADES_SUCCESS = "GET_ALL_TRADES_SUCCESS";
export const GET_ALL_TRADES_FAIL = "GET_ALL_TRADES_FAIL";

/*=========== reducer actions ===========*/

export const getAllTradesAttempt = () => {
  return {
    type: GET_ALL_TRADES_ATTEMPT,
  };
};

export const getAllTradesSuccess = (trades, sortedTrades) => {
  return {
    type: GET_ALL_TRADES_SUCCESS,
    trades: trades,
    sortedTrades: sortedTrades,
  };
};
export const getAllTradesFail = (err) => {
  return {
    type: GET_ALL_TRADES_FAIL,
    err: err,
  };
};

/*=========== actions ===========*/

const sortTradesForDropdown = (allTrades) => {
  let tempTrades = [];

  for (let i = 0; i < allTrades.categories.length; i++) {
    tempTrades.push({
      trade_name: allTrades.categories[i].category,
      trade_id: -1,
    });
    tempTrades = tempTrades.concat(
      allTrades.categories[i].trades.sort((a, b) => {
        let textA = a.trade_name.toUpperCase();
        let textB = b.trade_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
    );
  }

  tempTrades.sort((a, b) => {
    if (a.trade_name > b.trade_name) {
      return 1;
    } else if (a.trade_name < b.trade_name) {
      return -1;
    } else {
      return 0;
    }
  });

  return tempTrades;
};

const fetchAllTrades = (dispatch) => {
  dispatch(getAllTradesAttempt());
  axios
    .get(`/contacts/trade/allTrades`)
    .then((res) => {
      dispatch(getAllTradesSuccess(res.data, sortTradesForDropdown(res.data)));
    })
    .catch((err) => {
      dispatch(getAllTradesFail(err));
    });
};

export const getAllTrades = () => (dispatch) => {
  if (
    !store.getState().trades.tradesFetched &&
    !store.getState().trades.tradesFetched
  ) {
    fetchAllTrades(dispatch);
  }
};

export const forceGetAllTrades = () => (dispatch) => {
  fetchAllTrades(dispatch);
};

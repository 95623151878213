import './index.scss';
import React, { Component } from 'react';
import { Container, Button, Row, Table, ListGroup, Form, Pagination } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import _ from 'lodash'
import Fuse from 'fuse.js'

export default 
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        couponsData: {
            url: `/domoreport/uploader/coupons`,
        },
    }) : null;
})
class CouponsComponent extends Component {

    state = {
        searchValue: null,
        currentSort: 'coupon_log_id',
        direction: 'asc',
        activePage: 1,
        showModal: false,
        user_email: null,
        user_password: null,
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    render() {
        const { couponsData } = this.props;

        if (!couponsData) {
            return <div>
                <h1>Pending domoreports</h1>
                <Row>
                    <ListGroup>
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                        <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                </Row>
            </div>
        }

        return !couponsData.fulfilled ? this._emptyState() : !couponsData.value ? this._emptyState() : this._availableState(couponsData.value.coupons);
    }

    _renderPagination = (length) => {
        const { activePage } = this.state;

        let items = [];
        for (let number = 1; number <= length; number++) {
            items.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => this._changePage(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        return <Pagination style={{ flexWrap: 'wrap' }}>{items}</Pagination>
    }

    _changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber })
    }

    _handleSearch = (text) => {
        if (!text) {
            this.setState({ searchValue: null });
        }
        this.setState({ searchValue: text });
    }

    _sortBy = (column) => {
        if (column == this.state.currentSort) {
            let newDirection = this.state.direction == "asc" ? "desc" : "asc"
            this.setState({ direction: newDirection })
        } else {
            this.setState({ currentSort: column, direction: "asc" })
        }

    }

    _returnCaret = (direction) => {
        return direction === "asc" ? <span>&#9660;</span> : <span>&#9650;</span>
    }


    _availableState = (couponContent) => {
        const { searchValue, currentSort, direction, activePage } = this.state;

        let options = {
            keys: [
                {
                    name: 'user_first_name',
                    weight: 0.4
                },
                {
                    name: 'user_email',
                    weight: 0.1
                },
                {
                    name: 'user_timestamp',
                    weight: 0.1
                },
            ]
        }

        let contentFuse = new Fuse(couponContent, options)
        let searchedCouponContent = contentFuse.list;
        if (searchValue) {
            searchedCouponContent = contentFuse.search(searchValue)
        }

        let skip = activePage === 1 ? 0 : (parseInt(activePage - 1) * 10)

        let currentContent = searchedCouponContent.slice(skip, skip + 10)

        return (
            <div className="md-scroll-wrapper">
                <Container className="md-coupon-page">
                    <h1 className="mt-4">Coupons</h1>
                    <div className="row d-flex" style={{ alignItems: 'center' }}>
                        <Form.Group className="col-6">
                            <Link
                                to="/coupon-create"
                                className={`btn btn-primary btn-large`}
                                style={{ maxHeight: 60, marginTop: 15 }}
                            > Create New Coupon
                        </Link>
                        </Form.Group>
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th onClick={() => this._sortBy('coupon_log_id')}>ID {currentSort === "coupon_log_id" ? this._returnCaret(direction) : null}</th>
                                <th onClick={() => this._sortBy('coupon_code_string')}>Coupon Code {currentSort === "coupon_code_string" ? this._returnCaret(direction) : null}</th>
                                <th onClick={() => this._sortBy('discount_total')}>Discount Total {currentSort === "discount_total" ? this._returnCaret(direction) : null}</th>
                                <th onClick={() => this._sortBy('start_date')}>Start Date {currentSort === "start_date" ? this._returnCaret(direction) : null}</th>
                                <th onClick={() => this._sortBy('end_date')}>Expiration Date {currentSort === "end_date" ? this._returnCaret(direction) : null}</th>
                            </tr>

                        </thead>
                        <tbody>
                            {_.orderBy(currentContent, [currentSort], [`${direction}`])
                                .map(content => {
                                    return <tr onClick={() => this.props.history.push(`/coupon/${content.coupon_log_id}`)} key={content.coupon_log_id}>
                                        <td>{content.coupon_log_id}</td>
                                        <td>{content.coupon_code_string}</td>
                                        <td>${content.discount_total / 100}</td>
                                        <td>{Intl.DateTimeFormat('en-US').format(new Date(content.start_date))}</td>
                                        <td>{Intl.DateTimeFormat('en-US').format(new Date(content.end_date))}</td>
                                    </tr>
                                })}
                        </tbody>
                    </Table>
                    {this._renderPagination(Math.ceil(searchedCouponContent.length / 10))}
                </Container>
            </div>
        )
    }

    _emptyState = () => {
        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <h1 className="mt-4">Coupons </h1>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Coupon Code</th>
                                <th>Discount Total</th>
                                <th>Expiration Date</th>
                            </tr>
                        </thead>
                    </Table>
                </Container>
            </div>
        )
    }
}
import React from 'react';
import { Form, Container, Row, Col, Badge, Card } from 'react-bootstrap';
import { keysToIgnore } from '../../components/Reports/editReport';
import ReactQuill from 'react-quill';
import MetaMeasureList from '../FormFields/metaMeasure.list';

const makeLabel = (labelStr, key, keysToIgnore) => {
	let label =
		(<Form.Label> {labelStr} <Badge variant="danger"> required </Badge> </Form.Label>);
	if ((key in keysToIgnore)) {
		label = (<Form.Label> {labelStr} <Badge variant="primary"> not required </Badge> </Form.Label>);
	}
	return label
}

const styleBorder = (obj, key, keysToIgnore, nullValues) => {
	let borderStyle = {};
	if (!(key in keysToIgnore)) {
		let value = (obj ? (`${obj[key]}` === "null" ? "" : `${obj[key]}`) : "")
		if (value === "" || value === nullValues) {
			borderStyle = { borderColor: "red", backgroundColor: "rgba(255, 0, 0, 0.075)" };
		} else {
			borderStyle = { borderColor: "green", formControl: { focus: { borderColor: "red" } } }
		}
	}

	return borderStyle;
}

const makePlaceholder = (obj, field) => {
	return (obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : "")
}

const TitleCase = (str) => {
	if (!str) {
		str = ""
	}
	return str.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export const _returnFields = (fields, obj, onChange, boolChange, handleSpecialChange) => {
	let indexShift = 0;
	return (
		<>
			{fields.map((field, index) => {
				let control = null;
				let label = null;
				let extra = null;
				let keyAsIndex = index + indexShift;
				let returnType = "";
				let controllId = "fillerControlId"

				switch (true) {
					case field.Field === 'inspection_file_name':
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="text" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} readOnly />)
						break;
					case field.Field === 'domoscore_notes':
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="text" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} readOnly />)
						break;
					case field.Field.indexOf('inspector_ref_id') !== -1:
						label = makeLabel("inspector", 'inspector_ref_id', keysToIgnore)
						controllId = `${field.Field}_field`
						control = (
							<Form.Control
								as="select"
								style={styleBorder(obj, field.Field, keysToIgnore, "")}
								onChange={(e) => { onChange(field.Field, e.target.value) }}
								value={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""}
							>
								{obj.inspectors.map(inspector => {
									return (<option value={inspector.inspector_ref_id} key={inspector.inspector_ref_id}>
										{inspector.inspector_person_name}
									</option>)
								})}
							</Form.Control>)
						break;
					case field.Field.indexOf('broker_ref_id') !== -1:
						controllId = `${field.Field}_field`
						label = makeLabel("broker", 'broker_ref_id', keysToIgnore)
						control = (
							<Form.Control
								as="select"
								style={styleBorder(obj, field.Field, keysToIgnore, "")}
								onChange={(e) => { onChange(field.Field, e.target.value) }}
								value={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""}
							>
								{obj.brokers.map(broker => {
									return (<option value={broker.broker_ref_id} key={broker.broker_ref_id}>
										{broker.broker_person_name}
									</option>)
								})}
							</Form.Control>)
						break;
					case field.Field === "is_archived":
						break;
					case field.Field === "explore_content_published":
						controllId = `${field.Field}_field`
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						control = (<Form.Check style={styleBorder(obj, field.Field, keysToIgnore, "")} type="checkbox" label={`${field.Field}`} defaultChecked={obj[field.Field] === "0" ? false : true} onChange={(evt) => {
							boolChange(field.Field, evt.target.checked);
						}} disabled />)
						break;
					case field.Field.indexOf('majordomo_people_ref_id') !== -1:
						label = makeLabel("Majordomo person", 'majordomo_people_ref_id', keysToIgnore)
						controllId = `${field.Field}_field`
						control = (
							<Form.Control
								as="select"
								style={styleBorder(obj, field.Field, keysToIgnore, "")}
								onChange={(e) => { onChange(field.Field, e.target.value) }}
								value={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""}
							>
								{obj.people.map(person => {
									return (<option value={person.majordomo_people_ref_id} key={person.majordomo_people_ref_id}>
										{`${person.user_first_name} ${person.user_last_name}, ${person.mdp_title}`}
									</option>)
								})}
							</Form.Control>)
						break;
					case field.Field === "creator_user_id":
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)//(<Form.Label>{field.Field.replace(/_/g,' ')}</Form.Label>);
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="number" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} readOnly />)
						extra = (
							<Row>
								<Col>
									<Card>
										<Card.Header>Name</Card.Header>
										<Card.Body>
											<Card.Text>
												{TitleCase(obj.user_first_name)} {TitleCase(obj.user_last_name)}
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>

								<Col>
									<Card>
										<Card.Header>Email</Card.Header>
										<Card.Body>
											<Card.Text>
												{(obj.user_email != null) ? obj.user_email : "email not available"}
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card>
										<Card.Header>Phone number</Card.Header>
										<Card.Body>
											<Card.Text>
												{(obj.phone_number != null) ? obj.phone_number : "phone not available"}
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						);
						break;
					case field.Field === "domo_address_id":
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)//(<Form.Label>{field.Field.replace(/_/g,' ')}</Form.Label>);
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="number" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} readOnly />)
						extra = (
							<Row>
								<Col>
									<Card>
										<Card.Header>Address</Card.Header>
										<Card.Body>
											<Card.Text>
												{TitleCase(obj.PropertyAddressFull)}, {TitleCase(obj.PropertyAddressCity)}, {obj.PropertyAddressState} {obj.PropertyAddressZIP}
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						);
						break;
					case field.Field.indexOf('id') !== -1:
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)//(<Form.Label>{field.Field.replace(/_/g,' ')}</Form.Label>);
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="number" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} readOnly />)
						break;
					case field.Field === "metaMeasures":
						returnType = "list"
						break;
					case field.Type.includes('tinyint'):
						if (field.Field === 'domoreport_published') {
							if (!obj.domoreport_published) {

								label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
								controllId = `${field.Field}_field`
								control = (<Form.Check style={styleBorder(obj, field.Field, keysToIgnore, "")} type="checkbox" label={`${field.Field}`} value={obj[field.Field]} onChange={(evt) => {
									boolChange(field.Field, evt.target.checked);
								}} />)
							}
						} else {
							label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
							controllId = `${field.Field}_field`
							control = (<Form.Check style={styleBorder(obj, field.Field, keysToIgnore, "")} type="checkbox" label={`${field.Field}`} value={obj[field.Field]} onChange={(evt) => {
								boolChange(field.Field, evt.target.checked);
							}} />)
						}
						break;
					case field.Type.indexOf('int') !== -1:
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="number" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} onChange={(evt) => {
							onChange(field.Field, evt.target.value);
						}} />)
						break;
					case field.Field.indexOf('email') !== -1:
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="email" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} onChange={(evt) => {
							onChange(field.Field, evt.target.value);
						}} />)
						break;
					case field.Field.indexOf('phone') !== -1:
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="tel" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} onChange={(evt) => {
							onChange(field.Field, evt.target.value);
						}} />)
						break;
					case field.Type.indexOf('varchar') !== -1:
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="text" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""} onChange={(evt) => {
							onChange(field.Field, evt.target.value);
						}} />)
						break;
					case field.Type.indexOf('text') !== -1:
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						/*control = (<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} as="textarea" placeholder={`${field.Field}`} defaultValue={obj ? (`${obj[field.Field]}` === "null" ? "" :  `${obj[field.Field]}`): ""} onChange={(evt)=>{
							onChange(field.Field, evt.target.value);
						}} required />)*/
						control = (
							<ReactQuill
								style={styleBorder(obj, field.Field, keysToIgnore, "<p><br></p>")}
								value={makePlaceholder(obj, field)}
								onChange={(evt) => { onChange(field.Field, evt); }}
							/>
						)

						if (field.Field === 'majordomo_notes') {
							extra = (
								<Row style={{ marginBottom: '15px' }}>
									<Col>
										<Card>
											<Card.Header>Domoscore Notes</Card.Header>
											<Card.Body>
												<Card.Text>
													{TitleCase(obj.domoscore_notes)}
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							);
						}
						break;
					case field.Type.indexOf('time') !== -1:
						label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
						controllId = `${field.Field}_field`
						if (field.Default === "CURRENT_TIMESTAMP") {
							control = (<Form.Text>{obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""}</Form.Text>)
						} else if (field.Field === "explore_published_at" || field.Field === "report_published_at") {
							control = (<Form.Text>{obj ? (`${obj[field.Field]}` === "null" ? "" : `${obj[field.Field]}`) : ""}</Form.Text>)
						} else {
							returnType = "date"
							label = makeLabel(field.Field.replace(/_/g, ' '), field.Field, keysToIgnore)
							let today = new Date();
							let year = today.getFullYear();
							let month = String(today.getMonth() + 1).padStart(2, '0');
							let day = String(today.getDate()).padStart(2, '0');
							if (obj && field.Field in obj && obj[field.Field] != null) {
								year = obj[field.Field].substr(0, 4)
								month = obj[field.Field].substr(5, 2)
								day = obj[field.Field].substr(8, 2)
							}
							let months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "Rocktober", "November", "December"]
							extra = (
								<Container>
									<Form.Row>
										<Col>
											<Form.Label>Month</Form.Label>
											{/*<Form.Control type="number" placeholder={`${field.Field}`} defaultValue={month ? `${month}` : ""} onChange={(evt)=>{
											handleSpecialChange.dateChange(field.Field, evt.target.value, "mm", obj[field.Field]);
										}}/>*/}
											<Form.Control
												as="select"
												style={styleBorder(obj, field.Field, keysToIgnore, "")}
												onChange={(evt) => { handleSpecialChange.dateChange(field.Field, evt.target.value, "mm", obj[field.Field] ? obj[field.Field] : today.toISOString()); }}
												value={month ? `${Number(month)}` : ""}
											>
												{months.map((month_name, index) => {
													return (<option value={index + 1} key={index}>
														{month_name}
													</option>)
												})}
											</Form.Control>
										</Col>
										<Col>
											<Form.Label>Day</Form.Label>
											<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="number" placeholder={`${field.Field}`} value={day ? `${day}` : ""} onChange={(evt) => {
												handleSpecialChange.dateChange(field.Field, evt.target.value, "dd", obj[field.Field] ? obj[field.Field] : today.toISOString());
											}} />
										</Col>
										<Col>
											<Form.Label>Year</Form.Label>
											<Form.Control style={styleBorder(obj, field.Field, keysToIgnore, "")} type="number" placeholder={`${field.Field}`} value={year ? `${year}` : ""} onChange={(evt) => {
												handleSpecialChange.dateChange(field.Field, evt.target.value, "yyyy", obj[field.Field] ? obj[field.Field] : today.toISOString());
											}} />
										</Col>
									</Form.Row>
								</Container>
							)
						}
						break;
					default:
						break;
				}
				switch (true) {
					case returnType === "date":
						return (
							<Container>
								<Form.Group as={Row} controlId={controllId} key={keyAsIndex}>
									{label}
									{extra}
								</Form.Group>
							</Container>
						)
					case returnType === "list":
						return (
							<Container>
								<Form.Group as={Row} controlId={controllId} key={keyAsIndex}>
									{label}
									{extra}
								</Form.Group>
							</Container>
						)
					default:
						return (
							<Container>
								<Form.Group controlId={controllId} key={keyAsIndex}>
									{label}
									{control}
								</Form.Group>
								{extra}
							</Container>
						)
				}
			})}
			<MetaMeasureList />
		</>
	)
}



import React, { Component } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { _returnFields } from "../FormByKey/defaultFields";

export default class CustomerModal extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        title: null,
        file: null,
    }

    render() {
        const { postedUser } = this.props;

        console.log(postedUser);

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={this.props.close}
                className="md-new-customer-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.headerTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {postedUser && !postedUser.pending && postedUser.value && postedUser.value.success && this._renderAlert('success', postedUser.value.success)}
                    {postedUser && !postedUser.pending && !postedUser.value && this._renderAlert('danger', 'There was an error saving a new user!')}
                    <Form>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <label htmlFor="user_email">Email</label>
                                <input type="text" id="user_email" name="user_email" onChange={(e) => {
                                    this.props.handleChange('user_email', e.target.value);
                                }} />
                            </div>

                            <div className="col-12 col-md-6">
                                <label htmlFor="user_password">Password</label>
                                <input type="text" id="user_password" name="user_password" onChange={(e) => {
                                    this.props.handleChange('user_password', e.target.value);
                                }} />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.close}>Close</Button>
                    <Button variant="primary" onClick={() => this.props.submit(this.state.file)} disabled={this.props.submitting}>Create User</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    _renderAlert(variant, message) {
        return <Alert variant={variant} onClose={() => { this.setState({ showMessage: false }) }} className="md-alert"><p>{message}</p></Alert>
    }
}
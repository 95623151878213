import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../services/axios";
import { sortFeatures } from "../../services/helpers";
import { sortByArea } from "../../actions";
import { useDispatch } from "react-redux";

function CreateFeatureModal({
  areaRef,
  create,
  handleChange,
  propertyId,
  showCreateFeature,
  src,
  recommendedFeature,
  updateProject,
}) {
  const [areas, setAreas] = useState([]);
  const [areaRefId, setAreaRefId] = useState(null);
  const [features, setFeatures] = useState([]);
  const [featureRefId, setFeatureRefId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (areaRef) {
      setAreaRefId(areaRef);
    }

    getAvailableFeatures();
  }, []);

  useEffect(() => {
    if (areas.length) {
      const currentArea = areas.find((area) => {
        return area.area_ref_id === areaRefId;
      });

      if (
        Object.keys(currentArea).length &&
        currentArea.features &&
        currentArea.features[0]
      ) {
        setFeatureRefId(currentArea.features[0].feature_ref_id);
      }
    }
  }, [areaRefId]);

  async function getAvailableFeatures() {
    try {
      const availableFeaturesResponse = await axios.get(
        "/property/categorizedFeatures"
      );
      if (availableFeaturesResponse.data) {
        let featuresList = {};
        let allAreas = [];

        availableFeaturesResponse.data.map((section) => {
          section.areas.map((area) => {
            allAreas.push(area);
            featuresList[area.area_ref_id] = area.features.sort(sortFeatures);

            if (recommendedFeature) {
              area.features.map((feature) => {
                if (feature.feature_ref_id === recommendedFeature) {
                  setAreaRefId(area.area_ref_id);
                  setFeatureRefId(feature.feature_ref_id);
                }
              });
            }
          });
        });

        allAreas.sort(sortFeatures);
        setAreas(allAreas);
        setFeatures(featuresList);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function createFeature() {
    setLoading(true);
    const data = [
      {
        area_ref_id: parseInt(areaRefId),
        feature_ref_id: parseInt(featureRefId),
      },
    ];

    try {
      const createdFeatureResponse = await axios.post(
        `/property/features/${propertyId}`,
        { features: data }
      );
      if (createdFeatureResponse.data) {
        const { property_fact_id } = createdFeatureResponse.data.successes[0];

        if (src !== "property_table") {
          updateProject("property_fact_id", property_fact_id);
        }

        handleChange("features", []);
        handleChange("showCreateFeature", false);
        dispatch(sortByArea(propertyId));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    const form = document.getElementById("createFeatureForm");
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else if (form.checkValidity() === true) {
      createFeature();
    }

    setValidated(true);
  }

  function minMaxYear(year) {
    const maxYear = new Date().getFullYear();
    const minYear = -1;
    if (year > maxYear) {
      return maxYear;
    } else if (year < minYear) {
      return minYear;
    } else {
      return parseInt(year);
    }
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showCreateFeature}
      onHide={() => handleChange("showCreateFeature", false)}
    >
      <Modal.Header closeButton>
        <Modal.Title> {create ? "Create" : "Edit"} a new feature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          id="createFeatureForm"
        >
          <Form.Label>Area</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setAreaRefId(parseInt(e.target.value));
            }}
            value={areaRefId || ""}
            disabled={!!areaRef}
            required
          >
            <option key={""} value={""} disabled={true}>
              -----------------------
            </option>
            {areas.map((area) => {
              return (
                <option key={area.area_ref_id} value={area.area_ref_id}>
                  {area.area_name}
                </option>
              );
            })}
          </Form.Control>

          <Form.Label>Feature Type</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setFeatureRefId(parseInt(e.target.value));
            }}
            value={featureRefId || ""}
            required
          >
            <option key={""} value={""} disabled={true}>
              -----------------------
            </option>
            {features[areaRefId] &&
              features[areaRefId].map((feature, index) => {
                return (
                  <option key={index} value={feature.feature_ref_id}>
                    {feature.feature_name}
                  </option>
                );
              })}
          </Form.Control>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => handleChange("showCreateFeature", false)}
        >
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {loading ? "Saving..." : "Save New"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateFeatureModal;

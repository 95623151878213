import React, { Component } from 'react';
import {Row, Col} from 'react-bootstrap'
import { IntDropdownField } from '../FormFields/';
import { connect } from 'react-redux';
import { 
	getAllFeatureRefs
 } from '../../actions';

const mapStateToProps = (state) => ({
	allFeatureRefs: state.featureRef.allFeatureRefs,
	allAreas: state.featureRef.allAreas
});


class AreaFeatureDropdownField extends Component {
	state = {
		selectedArea: null,
		featuresToDisplay: []
	}

	componentDidMount(){
		this.props.getAllFeatureRefs()
	}



	componentDidUpdate(lastProps){
		if(this.props.allFeatureRefs.length !== lastProps.allFeatureRefs.length){
			const featureRef = this.props.allFeatureRefs.find(e => e.feature_ref_id === this.props.value)
			if(featureRef){
				this._updateArea(featureRef.area_ref_id)
			}
			
			
		}
		if(this.props.value !== lastProps.value){
			const featureRef = this.props.allFeatureRefs.find(e => e.feature_ref_id === this.props.value)
			if(featureRef){
				this._updateArea(featureRef.area_ref_id)
			}
		}
	}

	_updateArea = (areaId) => {
		let featuresToDisplay = [];
		if(areaId){
			areaId = parseInt(areaId)
			featuresToDisplay = this.props.allFeatureRefs.filter(e => e.area_ref_id === areaId)
		}
		this.setState({selectedArea: areaId, featuresToDisplay: featuresToDisplay}, () => {
			console.log("stuff to display:", this.state.featuresToDisplay)
		})
	}

	render(){
		return(
			<Row>
				<Col>
					<IntDropdownField 
						formName={"Area"}
						fieldKey={"area_ref_id"}
						displayKey={"area_name"}
						keysToIgnore={this.props.keysToIgnore}
						obj={{area_ref_id: this.state.selectedArea}}
						keyAsIndex={this.props.keyAsIndex}
						onChange={(key, value) => {this._updateArea(value)}}
						options={this.props.allAreas}
					/>
				</Col>
				<Col>
					<IntDropdownField 
						formName={"Feature"}
						fieldKey={"feature_ref_id"}
						displayKey={"feature_name"}
						keysToIgnore={this.props.keysToIgnore}
						obj={{feature_ref_id: this.props.value}}
						keyAsIndex={this.props.keyAsIndex}
						onChange={this.props.onChange}
						options={this.state.featuresToDisplay}
					/>
				</Col>
			</Row>
			
			
		)
	}
}


export default connect(
	mapStateToProps,
	{ getAllFeatureRefs }
)(AreaFeatureDropdownField);
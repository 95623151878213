import "./index.scss";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../services/axios";

export default function EditAffiliationModal({
  affiliation,
  close,
  getAffiliationsData,
  handleChange,
}) {
  const [errors, setErrors] = useState("");

  async function deleteAffiliation() {
    try {
      const deletedAffiliationResponse = await axios.delete(
        `/affiliations/${affiliation.id}`
      );
      if (deletedAffiliationResponse) {
        handleChange("affiliation", { id: null, name: "" });
        await getAffiliationsData();
        close();
      }
    } catch (error) {
      console.error(error);
      setErrors("There was an issue trying to delete this affiliation");
    }
  }

  async function updateAffiliation() {
    try {
      const updatedAffiliationResponse = await axios.patch(
        `/affiliations/${affiliation.id}`,
        { name: affiliation.name }
      );
      if (updatedAffiliationResponse) {
        close();
      }
    } catch (error) {
      setErrors("There was an issue trying to update this affiliation");
    }
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className="md-edit-affiliation-modal"
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Affiliation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors && <p>{errors}</p>}
        <input
          type="text"
          placeholder="Edit Affiliation"
          onChange={(e) => {
            handleChange("affiliation", {
              id: affiliation.id,
              name: e.target.value,
            });
          }}
          value={affiliation ? affiliation.name : ""}
        />
        <div className="d-flex justify-content-between mt-3">
          <Button variant="danger" onClick={() => deleteAffiliation()}>
            Delete
          </Button>
          <Button variant={"success"} onClick={() => updateAffiliation()}>
            Update
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

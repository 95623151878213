import React, { Component } from 'react';
import { Container, Button, Row, Col, ListGroup, Table } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import FormByKey from '../../ui/FormByKey/form';
import { _returnFields } from '../../ui/FormByKey/projectFields';
import { keysToIgnore } from './';
import WarningModal from "../../ui/WarningModal";
import {
    getAllMDPeople,
    getProjectFields,
    getUnitTypes,
    getOneProject,
    updateOneProject,
    updateProject,
    getProperty,
} from '../../actions';


const mapStateToProps = state => ({
    projectFields: state.projects.fields,
    fieldsHash: state.projects.fieldsHash,
    unitTypes: state.projects.unitTypes,
    project: state.projects.project,
    updatingProject: state.projects.updatingProject,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps,
    {
        getProjectFields,
        getAllMDPeople,
        getUnitTypes,
        getOneProject,
        updateOneProject,
        updateProject,
        getProperty,
    }
)
@withFetch((props) => {
    return props.user && props.user ? ({
        deleteProject: () => ({
            deletedProject: {
                url: `/domoreport/uploader/project/${props.match.params.id}`,
                method: 'DELETE',
                headers: {
                    authorization: props.user.token
                }
            }
        })

    }) : null;
})
class EditProjectComponent extends Component {

    state = {
        fromServer: false,
        formSubmission: false,
        alertMessage: '',
        alertBG: '',
        createWarningOpen: false,
    }

    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.props.getProjectFields()
        this.props.getUnitTypes()
        this.props.getOneProject(this.props.match.params.id)
        this.props.getProperty(this.props.match.params.prop_id)
    }

    _handleChange = (key, value) => {
        this.props.updateProject(key, value)
    }

    _handleBoolChange = (key, value) => {
        this.props.updateProject(key, value)
    }

    _toggleWarningModal = () => {
        const { createWarningOpen } = this.state;
        this.setState({ createWarningOpen: !createWarningOpen }, () => {
            console.log(this.state);
        });
    }


    _onSubmit = () => {
        this.props.updateOneProject(this.props.match.params.id)
    }

    _onDelete = () => {
        const { deleteProject } = this.props;
        deleteProject();
        this.goBack();
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const { project, trades, property } = this.props;
        const { createWarningOpen } = this.state;

        let value = project;
        let fields = this.props.projectFields;
        let allUnitTypes = this.props.unitTypes;
        //let { rooms: featuresByRoom } = property.value;

        //featuresByRoom = _sortFeaturesForDropdown(featuresByRoom);

        let buttonDisabled = false
        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                value[key] = (key in this.state ? this.state[key] : value[key])
                if (!(key in keysToIgnore)) {
                    buttonDisabled = buttonDisabled || value[key] === "" || value[key] === "null" || value[key] === null
                    // if(value[key] === "" || value[key] === "null" || value[key] === null)
                    // console.log(key, value[key])
                }
            }
        }
        buttonDisabled = false


        return (
            <div className="md-scroll-wrapper">
                <Container>
                    {(this.props.updatingProject)
                        && <div style={
                            {
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                                zIndex: 999,
                                position: "fixed",
                                left: 0,
                                top: 0,
                                width: "100vw",
                                height: "100vh",
                            }
                        }></div>}
                    <Row>
                        <Col>
                            <h2>project</h2>
                            < Link
                                to="#"
                                onClick={this.goBack}
                            >Go Back</Link>
                            <FormByKey
                                fields={fields}
                                obj={{ ...value, ...allUnitTypes }}
                                onChange={this._handleChange}
                                submit={this._onSubmit}
                                boolChange={this._handleBoolChange}
                                returnFields={_returnFields}
                                disabled={buttonDisabled}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                            < Button variant="danger" onClick={this._toggleWarningModal}>DELETE</Button>
                        </Col>
                    </Row>
                    <Row style={{ height: 25 }}></Row>
                    {createWarningOpen && <WarningModal
                        close={this._toggleWarningModal}
                        delete={this._onDelete}
                    />}
                </Container>
            </div>
        )
    }
}
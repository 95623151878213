import React, { Component } from 'react';
import { Container, Button, Table, Pagination } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import withUser from '../../hocs/withUser';
import { connect } from 'react-redux';
import ProjectFeatureMapModal from './Modal'
import {
	getAllProjectFeatureMaps,
	getOneProjectFeatureMap,
	changeFetchSort,
} from '../../actions'

const mapStateToProps = state => ({
	PFMS: state.projectFeatureMap.maps,
	total: state.projectFeatureMap.total,
	limit: state.projectFeatureMap.limit,
	page: state.projectFeatureMap.page,
	sort: state.projectFeatureMap.sort,
	asc: state.projectFeatureMap.asc,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, {
	getAllProjectFeatureMaps,
	getOneProjectFeatureMap,
	changeFetchSort,
})
class ProjectFeatureMapComponent extends Component {

	state = {
		modalVisible: false
	}

	componentDidMount() {
		this.props.getAllProjectFeatureMaps(0)
	}

	_hideShowModal = (id) => {
		this.props.getOneProjectFeatureMap(id)
		this.setState({ modalVisible: !this.state.modalVisible })
	}

	_renderPagination = (total, page, limit) => {
		// const { activePage } = this.state;

		let items = [];
		for (let number = 1; number <= Math.ceil(total / limit); number++) {
			items.push(
				<Pagination.Item key={number} active={number === (page + 1)} onClick={() => this._changePage(number)}>
					{number}
				</Pagination.Item>
			);
		}

		return (
			<div>
				{total} total items, {limit} per page
				<Pagination>{items}</Pagination>
			</div>
		)

	}

	_changePage = (pageNumber) => {
		this.props.getAllProjectFeatureMaps(pageNumber - 1)
	}

	_returnCaret = (sortKey) => {
		let caret = null
		if (sortKey === this.props.sort) {
			caret = this.props.asc ? <span>&#9660;</span> : <span>&#9650;</span>
		}
		return caret
	}

	_renderPFMRows = (projectFeatureMaps) => {
		const { history } = this.props;
		return projectFeatureMaps.map(map => {
			return (
				// <tr key={map.id} onClick={()=> history.push(`/featureRef/${map.id}`)}>
				<tr key={map.id} onClick={() => { this._hideShowModal(map.uuid) }}>
					<td>{map.id}</td>
					<td>{map.name}</td>
					<td>{map.uuid}</td>
					<td style={
						map.adminVerifiedAt == null ?
							{ background: 'red', color: 'white' } : {}
					}>{map.adminVerifiedAt ? map.adminVerifiedAt : "not approved yet"}</td>
				</tr>
			)
		})
	}

	render() {
		let { PFMS, total, page, limit } = this.props

		return (
			<>
				<div className="md-scroll-wrapper">
					<Container>
						<h2>Project-Feature Map</h2>
						<Button onClick={() => { this._hideShowModal() }} >Create a New Project-Feature Map</Button>
						{this._renderPagination(total, page, limit)}
						<Table striped bordered hover>
							<thead>
								<tr>
									<th onClick={() => this.props.changeFetchSort('id')}> ID {this._returnCaret("id")}</th>
									<th onClick={() => this.props.changeFetchSort('name')}> Name {this._returnCaret("name")}</th>
									<th onClick={() => this.props.changeFetchSort('uuid')}> UUID {this._returnCaret("uuid")}</th>
									<th onClick={() => this.props.changeFetchSort('adminVerifiedAt')}> Approved At {this._returnCaret("adminVerifiedAt")}</th>
								</tr>
							</thead>
							<tbody>
								{PFMS.length > 0 && this._renderPFMRows(PFMS)}
							</tbody>
						</Table>
					</Container>
					<ProjectFeatureMapModal
						isVisible={this.state.modalVisible}
						close={() => { this._hideShowModal() }}
					/>
				</div>
			</>
		)
	}
}
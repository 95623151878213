import React, { Component } from 'react';
import './index.css';
import { Container, Button, Table, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReportNav from '../Reports/reportNav';
import CreateModal from '../../ui/CreateModal/modal';
import { _returnFields } from '../../ui/FormByKey/projectFields';
import withUser from '../../hocs/withUser';
import { WithAlert } from "../../hocs/WithAlert";
import {
    getAllMDPeople,
    getProjectFields,
    getUnitTypes,
    getOneProject,
    updateOneProject,
    updateProject,
    getProjects,
    createEmptyProject,
    getProperty,
    createProject,
} from '../../actions';
const AlertMessage = WithAlert(Alert);

export const keysToIgnore = {
    "xactware_project_id": "xactware_project_id",
    "project_added": "project_added",
    "project_last_modified": "project_last_modified",
    "domoreport_project_log_id": "domoreport_project_log_id",
    "domoreport_log_id": "domoreport_log_id",
    "is_visible": "is_visible",
    "job_details_override": "job_details_override",
    "project_name_override": "project_name_override",
    "unit_type_override": "unit_type_override",
    "repair_wise_job_id": "repair_wise_job_id",
    "repair_wise_estimate_id": "repair_wise_estimate_id",
    "doc_log_id": "doc_log_id",
    "cache_unit_type_ref_id": "cache_unit_type_ref_id",
    "cache_project_name": "cache_project_name",
    "cache_job_details": "cache_job_details",
};

const mapStateToProps = state => ({
    projectFields: state.projects.fields,
    fieldsHash: state.projects.fieldsHash,
    unitTypes: state.projects.unitTypes,
    project: state.projects.project,
    updatingProject: state.projects.updatingProject,
    projects: state.projects.projects,
    creatingProject: state.projects.creatingProject,
    createdProject: state.projects.createdProject,
    report: state.reports.report,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps,
    {
        getProjectFields,
        getAllMDPeople,
        getUnitTypes,
        getOneProject,
        updateOneProject,
        updateProject,
        getProjects,
        createEmptyProject,
        getProperty,
        createProject,
    }
)
class ProjectsComponent extends Component {



    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.state = {
            createModalOpen: false,
        };
    }

    componentDidUpdate(lastProps) {
        if (!lastProps.createdProject && this.props.createdProject) {
            this._toggleModal();
        }
    }

    componentDidMount() {
        this.props.getProjectFields()
        this.props.getUnitTypes()
        this.props.getProjects(this.props.match.params.report_id)
        this.props.getProperty(this.props.match.params.prop_id)

        console.log('blah', this.props)
    }

    _toggleModal = () => {
        const { createModalOpen } = this.state;
        this.props.createEmptyProject(this.props.match.params.report_id, this.props.match.params.prop_id)
        this.setState({ createModalOpen: !createModalOpen });
    }

    _resetMessage = () => {
        console.log("It's been 4 seconds");
    }

    _handleChange = (key, value) => {
        this.props.updateProject(key, value);
    }

    _renderProjectRows = (projects) => {
        const { history } = this.props;
        let priority = null;

        return projects.map(project => {

            if (project.project_priority_ref_id == 1) {
                priority = 'Urgent';
            } else if (project.project_priority_ref_id == 2) {
                priority = 'Pressing';
            } else if (project.project_priority_ref_id == 3) {
                priority = 'Cosmetic';
            }

            return (
                <tr key={project.domoreport_project_log_id} onClick={() => history.push(`/project/${project.domoreport_project_log_id}/property/${this.props.match.params.prop_id}`)}>
                    <td>{project.domoreport_project_log_id}</td>
                    <td>{project.project_name_override || project.cache_project_name}</td>
                    <td>{project.cache_project_cost_high}</td>
                    <td>{project.cache_project_cost_low}</td>
                    <td>{project.inspection_page_num}</td>
                    <td>{priority}</td>
                </tr>
            )
        })
    }

    handleKeyDown(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey && charCode === 's') {
            event.preventDefault();
            this.props.createProject(this.props.match.params.report_id);
        }

        // For MAC we can use metaKey to detect cmd key

        if (event.metaKey && charCode === 's') {
            event.preventDefault();
            this.props.createProject(this.props.match.params.report_id);
        }
    }

    render() {
        const { createModalOpen, projectSubmitting } = this.state;
        const { user, report } = this.props;

        let { Projects } = this.props.projects;
        let fields = this.props.projectFields;
        let allUnitTypes = this.props.unitTypes;

        return (
            <div className="md-scroll-wrapper">
                <Container>
                    {(this.props.creatingProject) &&
                        <div className={"lock-screen"}>
                        </div>
                    }
                    <h2>Projects for report {this.props.match.params.report_id}</h2>
                    <ReportNav
                        reportId={this.props.match.params.report_id}
                        propertyId={this.props.match.params.prop_id}
                        token={(user && user.value && user.value.token) || null}
                        report={report}
                    />
                    {/* <Button onClick={this._toggleModal} >Create a Project</Button> */}
                    {createModalOpen && <CreateModal
                        submitting={projectSubmitting}
                        keys={fields}
                        close={this._toggleModal}
                        onChange={this._handleChange}
                        submit={() => { this.props.createProject(this.props.match.params.report_id) }}
                        headerTitle={'Create an New Project'}
                        returnFields={_returnFields}
                        dropdownValues={{ ...this.props.project, ...allUnitTypes }}
                        state={this.props.project}
                        keysToIgnore={keysToIgnore}
                        dialogClassName="modal-90w"
                    />}
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Cost: high</th>
                                <th>Cost: low</th>
                                <th>Report Page</th>
                                <th>Priority</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Projects.length > 0 && this._renderProjectRows(Projects)}
                        </tbody>
                    </Table>
                </Container>
            </div>
        )
    }
}
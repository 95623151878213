import axios from '../services/axios';
import { store } from '../ProviderApp';

/*=========== action names ===========*/
export const GET_ALL_BROKERS_ATTEMPT = 'GET_ALL_BROKERS_ATTEMPT';
export const GET_ALL_BROKERS_SUCCESS = 'GET_ALL_BROKERS_SUCCESS';
export const GET_ALL_BROKERS_FAIL = 'GET_ALL_BROKERS_FAIL';

/*=========== reducer actions ===========*/
export const getAllBrokersAttempt = () => {
	return {
		type: GET_ALL_BROKERS_ATTEMPT,
	};
};

export const getAllBrokersSuccess = (brokers) => {
	return {
		type: GET_ALL_BROKERS_SUCCESS,
		brokers: brokers
	};
};
export const getAllBrokersFail = (err) => {
	return {
		type: GET_ALL_BROKERS_FAIL,
		err: err
	};
};

/*=========== actions ===========*/
const fetchAllBrokers = (dispatch) => {
	dispatch(getAllBrokersAttempt());
	axios.get(`/domoreport/uploader/allBrokers`)
		.then((res) => {
			dispatch(getAllBrokersSuccess(res.data.brokers));
		}).catch(err => {
			dispatch(getAllBrokersFail(err));
		});
};

export const getAllBrokers = () => dispatch => {
	if (!store.getState().brokers.brokersFetched && !store.getState().brokers.brokersFetched) {
		fetchAllBrokers(dispatch);
	}
};

export const forceGetAllBrokers = () => dispatch => {
	fetchAllBrokers(dispatch);
};
import React from 'react';
import { getLoginFromHoc } from '../actions';
import { store } from '../ProviderApp';

const { storage } = require('../utils');

export default () => Component =>
  class WithUserHoc extends React.Component {
    static displayName = `withUserHoc(${Component.displayName || Component.name})`;
    state = {
      user: null
    }

    async componentDidMount() {
      await storage.get('user', (err, data) => {
        if (err) {
          this.setState({ user_error: err });
        } else if (data) {
          store.dispatch(getLoginFromHoc(data.token));
          this.setState({ user: data });
        } else {
          this.setState({ user_error: { error: "there was an error" } });
        }
      })
    }

    async componentWillReceiveProps() {
      await storage.get('user', (err, data) => {
        if (err) {
          this.setState({ user_error: err });
        } else {
          store.dispatch(getLoginFromHoc(data.token));
          this.setState({ user: data });
        }
      })
    }

    render() {
      {/* Get user from localstorage or session or api, etc */ }
      // console.log('this.state inside of withUser', this.state, this.props);
      return <Component {...this.props} {...this.state} />;
    }
  }

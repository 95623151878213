import React from 'react';
import { Form, Container} from 'react-bootstrap';
import { makeLabel, styleBorder, makePlaceholder } from './';

export const IntDropdownField = function({formName, fieldKey, displayKey, keysToIgnore, obj, keyAsIndex, onChange, options, valueKey}){
	formName = formName || "";
	fieldKey = fieldKey || "";
	keysToIgnore = keysToIgnore || {};
	keyAsIndex = keyAsIndex || "";
	obj = obj || {};
	onChange = onChange || (() => {});
	options = options || [];
	displayKey = displayKey || "displayKey";
	valueKey = valueKey || fieldKey;
 
	if(options.length === 0 || (!options[0].nullVal)){
		options.unshift({
			[fieldKey]: "null",
			[displayKey]: "--------------",
			disabled: false,
			nullVal: true
		});
	}
	
	return(
		<Container key={keyAsIndex}>
			<Form.Group controlId="intDropDown" key={keyAsIndex}>
				{makeLabel(formName.replace(/_/g,' '), fieldKey, keysToIgnore)}

				<Form.Control 
					style={styleBorder(obj, fieldKey, keysToIgnore, "")}  
					as="select" 
					onChange={(e) => {onChange(fieldKey, e.target.value)}} 
					value={makePlaceholder(obj, fieldKey)}
				>
					{options.map(option => {
						return (<option value={option[valueKey]} disabled={option.disabled}>
							{`${option[displayKey]}` }
						</option>);
					})}
				</Form.Control>
			</Form.Group>
		</Container>
	)
}
import "./index.scss";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import RoleContext from "../../contexts/RoleContext";
import { useContext } from "react";

function TopNavComponent({ history, location }) {
  const roleContext = useContext(RoleContext);
  const env =
    process.env.REACT_APP_BUILD_ENV === "production"
      ? "production"
      : "development";
  let path = location.pathname;

  function logout() {
    localStorage.removeItem("queued-projects");
    localStorage.removeItem("user");
    history.push("/");
    window.location.reload();
  }

  return (
    <Navbar expand="lg">
      <Link to="/" className="navbar-brand">
        <img src="/assets/images/logo.svg" />
        <Badge variant={env === "production" ? "warning" : "success"}>
          {env}
        </Badge>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="ml-auto">
          <Link
            to="/reports"
            className={`nav-link ${path === "/reports" ? "active" : ""}`}
          >
            Reports
          </Link>
          {roleContext.role === "PUBLISHER" && (
            <>
              <Link
                to="/explore"
                className={`nav-link ${path === "/explore" ? "active" : ""}`}
              >
                Explore Content
              </Link>
              <NavDropdown title="Features" id="basic-nav-dropdown">
                <NavDropdown.Item
                  href="/featureRefs"
                  className={`nav-link ${
                    path === "/featureRefs" ? "active" : ""
                  }`}
                >
                  Feature Refs
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/projectFeatureMaps"
                  className={`nav-link ${
                    path === "/projectFeatureMaps" ? "active" : ""
                  }`}
                >
                  Project-Feature Maps
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Users" id="basic-nav-dropdown">
                <NavDropdown.Item
                  href="/mdPeople"
                  className={`nav-link ${path === "/mdPeople" ? "active" : ""}`}
                >
                  MD People
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/customers"
                  className={`nav-link ${path == "/customers" ? "active" : ""}`}
                >
                  Customers
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/brokers"
                  className={`nav-link ${path === "/brokers" ? "active" : ""}`}
                >
                  Brokers
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/inspectors"
                  className={`nav-link ${
                    path === "/inspectors" ? "active" : ""
                  }`}
                >
                  Inspectors
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}
          <Link to="/" onClick={logout} className={`nav-link`}>
            Logout
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default withRouter(TopNavComponent);

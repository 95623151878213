import React, { Component } from 'react';
import { IntDropdownField } from '../FormFields/';
import { connect } from 'react-redux';
import { 
    getAllMDPeople, 
 } from '../../actions';

const mapStateToProps = (state) => ({
	mdPeople: state.mdpeople.people,
});


class MDPeopleDropdownField extends Component {
	
	componentDidMount(){
		this.props.getAllMDPeople()
	}

	render(){
		return(
			<IntDropdownField 
				formName={"majordomo_people_ref_id"}
				fieldKey={"majordomo_people_ref_id"}
				keysToIgnore={this.props.keysToIgnore}
				obj={{majordomo_people_ref_id: this.props.value}}
				keyAsIndex={this.props.keyAsIndex}
				onChange={this.props.onChange}
				options={this.props.mdPeople}
			/>
		)
	}
}


export default connect(
	mapStateToProps,
	{ getAllMDPeople }
)(MDPeopleDropdownField);
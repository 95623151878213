import React, { Component } from 'react';
import { Card, Button, Row, Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { getExploreContent, updateContentState } from '../../actions'

const mapStateToProps = state => ({
    exploreContent: state.explore.content,
    gettingContent: state.explore.gettingContent,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, { getExploreContent, updateContentState })
@withFetch((props) => {
    return props.user && props.user ? ({

        verifyExploreContent: () => ({
            verifiedExploreContent: {
                url: `/admin/explore/verify/${props.match.params.id}`,
                method: 'PUT',
                headers: {
                    authorization: props.user.token
                }
            }
        }),
        deleteExploreContent: () => ({
            deletedExploreContent: {
                url: `/admin/explore/${props.match.params.id}`,
                method: 'DELETE',
                headers: {
                    authorization: props.user.token
                }
            }
        }),
        updateExploreContent: (data) => ({
            createdContent: {
                url: `/admin/explore/${props.match.params.id}`,
                method: 'PUT',
                headers: {
                    authorization: props.user.token,
                    'Content-Type': null,
                },
                body: data
            }
        }),
    }) : null;
})
class EditExploreComponent extends Component {

    constructor(props) {
        super(props);
        this.props.getExploreContent(this.props.match.params.id);
    }
    state = {
        body: null,
        primary_image: null,
        title: null,
        summary_text: null,
        url: null,
        zipcode: null,
        city: null,
        state: null,
        county: null,
        country: null,
        body: null,
        is_utilities: null,
        is_mapping: null,
        is_permits: null,
        src: null,
        croppedImageUrl: null,
        blob: null,
        crop: {
            width: 600,
            height: 300,
            aspect: 2 / 1,
        }
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ src: reader.result })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                this.setState({ blob: blob });
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }


    render() {
        const { exploreContent, gettingContent } = this.props;
        const { is_utilities, is_mapping, is_permits } = this.state;

        // If exploreContent has been loaded set default values for is_utilities, 
        // is_mapping, and is_permits. (probably a better way to go about this).
        if (is_utilities == null && is_mapping == null && is_permits == null && Object.getOwnPropertyNames(exploreContent).length > 0) {
            this.setState({
                is_utilities: exploreContent.is_utilities,
                is_mapping: exploreContent.is_mapping,
                is_permits: exploreContent.is_permits,
            })
        }

        /*if(!exploreContent){
            return this._renderEmptyState();
        }*/

        return (!("verified_by_user" in exploreContent) || gettingContent) ? this._renderEmptyState() : this._renderAvailableState(exploreContent);

    }

    _renderEmptyState = () => {
        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <Link style={{ marginTop: 20 }} to="/explore">Go Back</Link>

                    <h2>New Explore Content</h2>
                </Container>
            </div>
        )
    }

    _renderAvailableState = (content) => {
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <Container>
                        <Link style={{ marginTop: 20 }} to="/explore">Go Back</Link>

                        <h2>Update Explore Content</h2>
                        {
                            !content.verified_by && <Form.Label>*NOT VERIFIED*</Form.Label>
                        }
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('title', e.target.value)} defaultValue={content.title} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Summary</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('summary_text', e.target.value)} defaultValue={content.summary_text} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Primary Image (600px x 300px)</Form.Label>
                            <Form.Control type="file" onChange={this.onSelectFile} />

                            {src && (
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            )}
                            <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl || content.primary_image} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Url for Primary Button</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('url', e.target.value)} defaultValue={content.url} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('zipcode', e.target.value)} defaultValue={content.zipcode} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('city', e.target.value)} defaultValue={content.city} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>County</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('county', e.target.value)} defaultValue={content.county} />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('state', e.target.value)} defaultValue={content.state} />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('country', e.target.value)} defaultValue={content.country} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Verified By</Form.Label>
                            <Form.Control type="text" disabled value={`${("verified_by_user" in content && content.verified_by_user != null) ? content.verified_by_user.user_first_name : null} ${("verified_by_user" in content && content.verified_by_user != null) ? content.verified_by_user.user_last_name : null}`} />
                            <Form.Control type="text" disabled value={content.date_verified} />

                        </Form.Group>


                        <Form.Group className="d-flex">
                            <Form.Check
                                type="checkbox"
                                className="mx-2"
                                onChange={(e) => this._handleFormChange('is_utilities', !this.state.is_utilities)}
                                checked={this.state.is_utilities}
                                label="Utilities"
                            />

                            <Form.Check
                                type="checkbox"
                                className="mx-2"
                                onChange={(e) => this._handleFormChange('is_mapping', !this.state.is_mapping)}
                                checked={this.state.is_mapping}
                                label="Mapping"
                            />

                            <Form.Check
                                type="checkbox"
                                className="mx-2"
                                onChange={(e) => this._handleFormChange('is_permits', !this.state.is_permits)}
                                checked={this.state.is_permits}
                                label="Permits"
                            />
                        </Form.Group>

                        <ReactQuill value={this.state.body ? this.state.body : content.body}
                            onChange={(evt) => { this._handleFormChange("body", evt); }} />

                        <Button variant="primary" size="lg" block style={{ marginTop: 20 }} onClick={() => this._submitContent(content)}>Update</Button>
                        <Button variant="success" size="lg" block style={{ marginTop: 20 }} onClick={() => this._verifyExploreContent()}>Verify Content</Button>
                        <Button variant="danger" size="lg" block style={{ marginTop: 20 }} onClick={() => this._deleteExploreContent()}>Delete</Button>

                        <Button variant="secondary" size="lg" block style={{ marginTop: 20 }} onClick={this._goBack}>Cancel</Button>
                    </Container>

                </Container>
            </div>
        );
    }


    _deleteExploreContent = () => {
        this._callAlert('delete', () => this.props.deleteExploreContent())
        this._goBack();
    }

    _callAlert = (type, cb) => {
        if (type === "create") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Do you want to create this content?')
            if (res === true) {
                cb()
            }
        }

        if (type === "update") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Are you sure you want to update this content?')
            if (res === true) {
                cb()
            }
        }

        if (type === "delete") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Are you sure you want to delete this content?')
            if (res === true) {
                cb()
            }
        }
    }

    _goBack = () => {
        this.props.history.push('/explore');
    }


    _validateValue = (value) => {
        if (value === '' || value === ' ' || value === 0) {
            return null
        } else {
            return value
        }
    }

    _getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
            if (object[key] != null) {
                formData.append(key, object[key])
            }
        });
        return formData;
    }

    _verifyExploreContent = () => {
        const { verifyExploreContent } = this.props;

        verifyExploreContent();
        this._goBack();
    }

    _submitContent = (prevContent) => {
        let { primary_image, title, summary_text, url, zipcode, city, state, county, country, body, is_utilities, is_mapping, is_permits, blob } = this.state;

        // Allow state to be empty string. If null, use prefilled content (prevContent)
        let data = {
            primary_image: (blob != null) ? blob : prevContent.primary_image,
            title: (title != null) ? title : prevContent.title,
            summary_text: (summary_text != null) ? summary_text : prevContent.summary_text,
            url: (url != null) ? url : prevContent.url,
            zipcode: (zipcode != null) ? zipcode : prevContent.zipcode,
            city: (city != null) ? city : prevContent.city,
            state: (state != null) ? state : prevContent.state,
            county: (county != null) ? county : prevContent.county,
            country: (country != null) ? country : prevContent.country,
            body: (body != null) ? body : prevContent.body,
            is_utilities: is_utilities,
            is_mapping: is_mapping,
            is_permits: is_permits,
        }

        console.log('data', data, county, prevContent.county)

        let formData = this._getFormData(data)
        this._callAlert('create', () => this.props.updateExploreContent(formData));
        this.setState({
            is_utilities: null,
            is_mapping: null,
            is_permits: null,
        });
        this._goBack();
    }

    _handleFormChange(key, value) {
        // if (value === '') {
        //     value = null;
        // }

        if (value == true) {
            value = 1;
        } else if (value == false) {
            value = 0;
        }

        this.setState({ [key]: value })
    }

}
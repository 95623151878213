import React, {Component} from 'react';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import App from './app'

export const store = configureStore();

export default class ProviderApp extends Component {
    render() {
        return (
            <Provider store={store}>
                <App />
            </Provider>
        );
    }
}
import React from 'react';
import { Button, Card, Accordion, ButtonToolbar, ButtonGroup, Form, Alert, Container } from 'react-bootstrap';
import FeatureScoreDropdownField from '../../ui/FormFields/featureScore.dropdown';

const FeatureCards = ({ features, deleteFeature, deleteingFeature, submitFeature, submittingFeature, onChange }) => {

	return (
		<Accordion>
			{features.map(feature => (
				<Card key={`${feature.property_fact_id}`}>
					<Card.Header>
						<Accordion.Toggle as={Button} variant="link" eventKey={`${feature.property_fact_id}`}>
							{feature.feature_name} <span className="md-feature-score">{feature.unscored ? `(unscored)` : `(score: ${feature.feature_condition}/5, year: ${feature.feature_age})`}</span>
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey={`${feature.property_fact_id}`}>
						<Container>
							<Card.Body>
								<div className="md-feature-header-container">
									<p className="md-feature-header">Feature ID: {feature.property_fact_id}</p>
								</div>
								<Form>
									<Form.Group>
										<Form.Label>Feature Condition:</Form.Label>
										<FeatureScoreDropdownField
											onChange={onChange}
											featureRefId={feature.feature_ref_id}
											propertyFactId={feature.property_fact_id}
											defaultValue={feature.feature_condition}
											isNew={false}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Feature Year:</Form.Label>
										<Form.Control
											type="number"
											placeholder={`Feature Year`}
											defaultValue={`${feature.feature_age}`}
											onChange={(evt) => { onChange('feature_age', feature.property_fact_id, evt.target.value) }}
											required
										/>
										<Form.Text className="text-muted">
											Please enter the Year that a feature was installed
											</Form.Text>
									</Form.Group>
									<ButtonToolbar>
										<ButtonGroup>
											<Button variant="secondary" type="submit" onClick={() => { submitFeature(feature) }} disabled={submittingFeature}>
												Update Feature
											</Button>
											<Button variant="danger" onClick={() => deleteFeature(feature.property_fact_id)} disabled={deleteingFeature}>
												Delete Feature
											</Button>
										</ButtonGroup>
									</ButtonToolbar>
								</Form>
							</Card.Body>
						</Container>
					</Accordion.Collapse>
				</Card>
			))}
		</Accordion>

	)
}

export default FeatureCards;
import "../index.scss";
import React, { Component } from "react";
import withFetch from "../../../hocs/withFetch";
import ImageCrop from "../../../ui/ImageCrop";
import { templates } from "./templates";

export default
@withFetch((props) => ({
  createExploreContent: (data) => ({
    createdContent: {
      url: `/admin/explore`,
      method: "POST",
      headers: {
        "Content-Type": null,
      },
      body: data,
      andThen: (res) => ({
        verifiedExploreContent: {
          url: `/admin/explore/verify/${res.explore_content_log_id}`,
          method: "PUT",
          then: (res) => {
            if (res) {
              props.updateStep(6);
            }
          },
        },
      }),
    },
  }),
}))
class CreateExplore extends Component {
  state = {
    explore: {},
    is_utilities: false,
    is_mapping: false,
    is_permits: false,
    image: null,
    loading: false,
    url: "",
  };

  componentDidMount() {
    document.getElementsByClassName("md-diy-sidebar")[0].scrollTo(0, 0);
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  updateExplore = (key, value) => {
    const { explore } = this.state;
    let updatedExplore = explore;

    updatedExplore[key] = value;
    this.setState({ project: updatedExplore });
  };

  getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (object[key]) {
        formData.append(key, object[key]);
      }
    });
    return formData;
  };

  submitExplore = () => {
    const { explore, image } = this.state;
    const { property, report, title } = this.props;

    let city = property.city.toLowerCase();
    city = city.charAt(0).toUpperCase() + city.slice(1);

    let data = {
      body: explore.body,
      city: title === "city" ? city : null,
      county: report.PropertyAddressCounty,
      country: "US",
      is_mapping: explore.is_mapping ? 1 : 0,
      is_permits: explore.is_permits ? 1 : 0,
      is_utilities: explore.is_utilities ? 1 : 0,
      primary_image: image || explore.primary_image,
      state: property.state,
      summary_text: explore.summary_text,
      title: explore.title,
      url: explore.url,
      zipcode: null,
    };

    let formData = this.getFormData(data);
    this.props.createExploreContent(formData);
  };

  handleTemplate = (key) => {
    const { report, title } = this.props;
    const { explore } = this.state;
    const template = templates[title].find((t) => t.key === key) || {};
    let data = { ...explore };
    let url = "";

    if (Object.keys(template).length) {
      data.body = template.description || null;
      data.is_mapping = !!template.tags.includes("mapping");
      data.is_permits = !!template.tags.includes("permits");
      data.is_utilities = !!template.tags.includes("utilities");
      data.summary_text = template.summary || null;
      data.primary_image = template.primary_image;

      if (title === "city") {
        data.title =
          template.title + ` ${explore.city || report.PropertyAddressCity}`;
        url = `http://www.google.com/search?q=${
          template.search + " " + (explore.city || report.PropertyAddressCity)
        }, ${explore.state || report.PropertyAddressState}`;
      } else if (title === "county") {
        data.title =
          template.title + ` ${explore.county || report.PropertyAddressCounty}`;
        url = `http://www.google.com/search?q=${
          template.search +
          " " +
          (explore.county || report.PropertyAddressCounty)
        } County, ${explore.state || report.PropertyAddressState}`;
      }

      this.handleChange("explore", data);
      this.handleChange("url", url);
    }
  };

  render() {
    const { property, report, title } = this.props;
    const { explore, image, url } = this.state;

    return (
      <div className="md-diy-sidebar-content explore">
        <div className="md-diy-sidebar-info">
          <h1 className="md-diy-sidebar-header">
            Create {title ? title.toUpperCase() : ""} Resource
          </h1>
        </div>

        <div
          className="md-diy-sidebar-form"
          // style={{
          //   paddingBottom:
          //     explore && explore.repair_wise_job_id ? "0px" : "300px",
          // }}
        >
          <div className="md-diy-input-wrapper row">
            <div className="col-6">
              <label>County</label>
              <div className="md-diy-input-wrapper">
                <i className="fa fa-map-marker" />
                <input
                  disabled={true}
                  type="text"
                  value={explore.county || report.PropertyAddressCounty || ""}
                  onChange={(e) => this.updateExplore("county", e.target.value)}
                />
              </div>
            </div>

            {title === "city" && (
              <div className="col-6">
                <label>City</label>
                <div className="md-diy-input-wrapper">
                  <i className="fa fa-map-marker" />
                  <input
                    disabled={true}
                    type="text"
                    value={explore.city || property.city || ""}
                    onChange={(e) => this.updateExplore("city", e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <label>Template (optional)</label>
          <div className="md-diy-input-wrapper">
            {/* {console.log('title', templates[title], title)} */}
            <i className="fa fa-clipboard" />
            <select
              id="template"
              onChange={(e) => this.handleTemplate(e.target.value)}
              defaultValue={""}
              className="mb-0"
            >
              <option key={0} value={""}>
                None
              </option>
              {templates &&
                templates[title] &&
                templates[title].map((template) => {
                  return (
                    <option key={template.key} value={template.key}>
                      {template.key}
                    </option>
                  );
                })}
            </select>
            <i className="fa fa-caret-down right" />
          </div>
          {url && (
            <a href={url} target="_blank" rel="noopener noreferrer">
              Search on Google
            </a>
          )}

          <ImageCrop handleChange={this.handleChange} title={"Explore"} />

          {explore.primary_image && !image && (
            <img src={explore.primary_image} width="100%" />
          )}

          <label>URL for primary button</label>
          <div className="md-diy-input-wrapper">
            <i className="fa fa-link" />
            <input
              type="text"
              placeholder="URL"
              value={explore.url || ""}
              onChange={(e) => this.updateExplore("url", e.target.value)}
            />
          </div>

          <label>Tags</label>
          <div className="md-diy-input-wrapper row mb-3">
            <div className="col checkbox-wrapper">
              <input
                type="checkbox"
                id="utilities"
                name="utilities"
                onChange={() =>
                  this.updateExplore("is_utilities", !explore.is_utilities)
                }
                checked={explore.is_utilities}
              />
              <label htmlFor="utilities"> Utilites</label>
            </div>

            <div className="col checkbox-wrapper">
              <input
                type="checkbox"
                id="mapping"
                name="mapping"
                onChange={() =>
                  this.updateExplore("is_mapping", !explore.is_mapping)
                }
                checked={explore.is_mapping}
              />
              <label htmlFor="mapping"> Mapping</label>
            </div>

            <div className="col checkbox-wrapper">
              <input
                type="checkbox"
                id="permits"
                name="permits"
                onChange={() =>
                  this.updateExplore("is_permits", !explore.is_permits)
                }
                checked={explore.is_permits}
              />
              <label htmlFor="permits"> Permits</label>
            </div>
          </div>

          <label>Title</label>
          <div className="md-diy-input-wrapper">
            <i className="fa fa-pencil" />
            <input
              type="text"
              placeholder="Resource Title"
              value={explore.title || ""}
              onChange={(e) => this.updateExplore("title", e.target.value)}
            />
          </div>

          <label>Summary</label>
          <div className="md-diy-input-wrapper">
            <i className="fa fa-pencil" />
            <input
              type="text"
              placeholder="Summary"
              value={explore.summary_text || ""}
              onChange={(e) =>
                this.updateExplore("summary_text", e.target.value)
              }
            />
          </div>

          <label>Description</label>
          <div className="md-diy-input-wrapper">
            <i className="fa fa-pencil" />
            <textarea
              rows={4}
              placeholder="Description"
              value={explore.body || ""}
              onChange={(e) => this.updateExplore("body", e.target.value)}
            />
          </div>

          <a
            href="#"
            className="btn btn-primary create-explore"
            onClick={this.submitExplore}
          >
            +
          </a>
          <a
            href="#"
            className="btn btn-mdgray"
            onClick={() => {
              this.props.updateStep("step", 6);
              this.props.setResourceTitle(null);
            }}
          >
            Cancel
          </a>
        </div>
      </div>
    );
  }
}

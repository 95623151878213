import React, { Component } from 'react';
import { Container, Button, Table } from 'react-bootstrap';
import withFetch from '../../hocs/withFetch';
import { withRouter } from 'react-router-dom';
import withUser from '../../hocs/withUser';
import CreateModal from '../../ui/CreateModal/modal';
import { _returnFields } from '../../ui/FormByKey/mdPersonFields';
import { connect } from 'react-redux';
import { getAllMDPeople, getMDPeopleFields, updateNewMDPerson, createNewMDPerson, toggleMDPersonModal } from '../../actions';

export const keysToIgnore = {
    "majordomo_people_ref_id": "majordomo_people_ref_id",
    "mdp_added": "mdp_added",
    "mdp_last_modified": "mdp_last_modified",
    "mdp_profile_pic": "mdp_profile_pic",
    "user_first_name": "user_first_name",
    "user_last_name": "user_last_name",
};

const mapStateToProps = state => ({
    people: state.mdpeople.people,
    fields: state.mdpeople.fields,
    emptyValues: state.mdpeople.emptyValues,
    newMDPerson: state.mdpeople.newMDPerson,
    mdPersonSubmitting: state.mdpeople.mdPersonSubmitting,
    createModalOpen: state.mdpeople.createModalOpen,
});

export default 
@withRouter
@withUser()
@connect(mapStateToProps, { getAllMDPeople, getMDPeopleFields, updateNewMDPerson, createNewMDPerson, toggleMDPersonModal })
class MDPersonComponent extends Component {


    componentDidMount() {
        this.props.getAllMDPeople();
        this.props.getMDPeopleFields();
    }

    _toggleModal = () => {
        this.props.toggleMDPersonModal();
    }


    _handleChange = (key, value) => {
        this.props.updateNewMDPerson(key, value);
    }

    _createNewMDPerson = () => {
        const { createNewMDPerson } = this.props;
        createNewMDPerson();
        this.setState({ createModalOpen: false })
    }


    _renderMDPersonRows = (mdPersons) => {
        const { history } = this.props;

        return mdPersons.map(mdPerson => {
            return (
                <tr key={mdPerson.majordomo_people_ref_id} onClick={() => history.push(`/mdPeople/${mdPerson.majordomo_people_ref_id}`)}>
                    <td>{mdPerson.majordomo_people_ref_id}</td>
                    <td>{mdPerson.user_first_name + " " + mdPerson.user_last_name}</td>
                    <td>{mdPerson.mdp_title}</td>
                </tr>
            )
        })
    }

    render() {
        const { people, fields, emptyValues, mdPersonSubmitting, createModalOpen } = this.props;


        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <h2>Majordomo People</h2>
                    <Button onClick={this._toggleModal} >Create New Majordomo Person (Someone to fill out forms)</Button>
                    {createModalOpen && <CreateModal
                        submitting={mdPersonSubmitting}
                        keys={fields}
                        close={this._toggleModal}
                        onChange={this._handleChange}
                        submit={this._createNewMDPerson}
                        headerTitle={'Create a Majordomo Person'}
                        returnFields={_returnFields}
                        dropdownValues={{ ...emptyValues }}
                        state={this.props.newMDPerson}
                        keysToIgnore={keysToIgnore}
                    />}
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {people.length > 0 && this._renderMDPersonRows(people)}
                        </tbody>
                    </Table>
                </Container>
            </div>
        )
    }
}
export function formatCost(a, b) {
  let low = 0;
  let high = null;

  if (a) {
    low = Math.round(a)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (b) {
    high = Math.round(b)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return high && high !== low ? "$" + low + " - " + "$" + high : "$" + low;
}

export function sortProjects(sort, projects) {
  const compare = (a, b) => {
    let comparison = 0;
    if (sort === "priceHigh") {
      if (a.cache_project_cost_low > b.cache_project_cost_low) {
        comparison = -1;
      } else if (a.cache_project_cost_low < b.cache_project_cost_low) {
        comparison = 1;
      }
    } else if (sort === "priceLow") {
      if (a.cache_project_cost_low < b.cache_project_cost_low) {
        comparison = -1;
      } else if (a.cache_project_cost_low > b.cache_project_cost_low) {
        comparison = 1;
      }
    } else if (sort === "priority") {
      if (a.project_priority_ref_id < b.project_priority_ref_id) {
        comparison = -1;
      } else if (a.project_priority_ref_id > b.project_priority_ref_id) {
        comparison = 1;
      } else if (a.project_priority_ref_id === b.project_priority_ref_id) {
        if (a.cache_project_cost_low > b.cache_project_cost_low) {
          comparison = -1;
        } else if (a.cache_project_cost_low < b.cache_project_cost_low) {
          comparison = 1;
        }
      }
    } else if (sort === "createdAt") {
      if (a.project_last_modified > b.project_last_modified) {
        comparison = -1;
      } else if (a.project_last_modified < b.project_last_modified) {
        comparison = 1;
      }
    } else if (sort === "alphabetical") {
      if (
        (a.project_name_override || a.cache_project_name) <
        (b.project_name_override || b.cache_project_name)
      ) {
        comparison = -1;
      } else if (
        (a.project_name_override || a.cache_project_name) >
        (b.project_name_override || b.cache_project_name)
      ) {
        comparison = 1;
      }
    } else if (sort === "pageStart") {
      if (a.inspection_page_num_start > b.inspection_page_num_start) {
        comparison = 1;
      } else if (a.inspection_page_num_start < b.inspection_page_num_start) {
        comparison = -1;
      }
    }
    return comparison;
  };
  return [...projects].sort(compare);
}

export const sortFeatures = (a, b) => {
  let result = 0;

  if (a.feature_name) {
    if (a.feature_name > b.feature_name) {
      result = 1;
    } else if (a.feature_name < b.feature_name) {
      result = -1;
    }
  } else if (a.area_name) {
    if (a.area_name > b.area_name) {
      result = 1;
    } else if (a.area_name < b.area_name) {
      result = -1;
    }
  } else if (a.section_name) {
    if (a.section_name > b.section_name) {
      result = 1;
    } else if (a.section_name < b.section_name) {
      result = -1;
    }
  }
  return result;
};

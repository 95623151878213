import {
	GET_ALL_BROKERS_ATTEMPT,
	GET_ALL_BROKERS_FAIL,
	GET_ALL_BROKERS_SUCCESS,
} from '../actions';

const initialState = {
	brokers: [],
	brokersFetching: false,
	brokersFetched: false,
	error: null,
}

export function brokers(state = initialState, action){
	switch (action.type){
		case GET_ALL_BROKERS_ATTEMPT:
			return {
				...state,
				brokersFetching: true,
				brokersFetched: false,
			}
		case GET_ALL_BROKERS_SUCCESS:
			return {
				...state,
				brokers: action.brokers,
				brokersFetching: false,
				brokersFetched: true,
			}
		case GET_ALL_BROKERS_FAIL:
			return {
				...state,
				brokersFetching: false,
				brokersFetched: false,
				error: action.err,
			}
		default:
			return {
				...state,
			}
	}
}
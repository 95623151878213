import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../services/axios";

export default function QueuedProjectsModal({
  show,
  close,
  handleOverwrite,
  handleSubmit,
}) {
  const [queuedReport, setQueuedReport] = useState();

  useEffect(() => {
    getDomoreport();
  }, []);

  async function getDomoreport() {
    try {
      const { data } = await axios.get(
        "/domoreport/uploader/unpublishedDomoreports"
      );
      if (data) {
        const queued =
          JSON.parse(localStorage.getItem("queued-projects")) || {};
        const keys = Object.keys(queued);
        if (keys.length && queued[keys[0]].length) {
          const report = data.reports.filter(
            (report) => report.unique_id === keys[0]
          );
          setQueuedReport(report[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {queuedReport && (
          <p className="text-center">
            You have projects queued for{" "}
            <a
              href={`/diy/report/${queuedReport.domoreport_log_id}/property/${queuedReport.property_log_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Report #{queuedReport.domoreport_log_id}
            </a>
            . To queue a project for this report, either submit your previously
            queued projects or overwrite them.
          </p>
        )}
        <div className="d-flex justify-content-space-between">
          <Button
            variant="danger"
            onClick={handleOverwrite}
            style={{ width: "calc(100% - 10px)" }}
          >
            Overwrite
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            style={{ width: "calc(100% - 10px)", marginLeft: 10 }}
          >
            Submit for classification
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import "./index.scss";
import React, { Component } from "react";
import {
  Container,
  Row,
  Table,
  ListGroup,
  Form,
  Pagination,
} from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import withFetch from "../../hocs/withFetch";
import withUser from "../../hocs/withUser";
import _ from "lodash";
import Fuse from "fuse.js";
import CustomerModal from "../../ui/CreateModal/customerModal";
import moment from "moment";

export default
@withRouter
@withUser()
@withFetch((props) => {
  return props.user && props.user
    ? {
        usersData: {
          url: `/admin/users/1/100000`,
        },
        postUser: (data) => ({
          postedUser: {
            method: "POST",
            url: `/users/register`,
            headers: {
              authorization: props.user.token,
            },
            body: JSON.stringify(data),
            force: true,
            refresh: true,
            andThen: (res) => ({
              usersData: {
                url: `/admin/users/1/100000`,
                headers: {
                  authorization: props.user.token,
                },
                force: true,
                refresh: true,
              },
            }),
          },
        }),
      }
    : null;
})
class CustomersComponent extends Component {
  state = {
    searchValue: null,
    currentSort: "user_log_id",
    direction: "desc",
    activePage: 1,
    showModal: false,
    user_email: null,
    user_password: null,
  };

  createUser = () => {
    const { user_email, user_password } = this.state;

    if (user_email && user_password) {
      let data = {
        user_email,
        user_password,
      };
      this.props.postUser(data);
    } else {
      console.log("Please provide a valid email and password.");
    }
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { usersData } = this.props;

    if (!usersData) {
      return (
        <div>
          <h1>Pending domoreports</h1>
          <Row>
            <ListGroup>
              <ListGroup.Item>Cras justo odio</ListGroup.Item>
              <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
              <ListGroup.Item>Morbi leo risus</ListGroup.Item>
              <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
              <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
            </ListGroup>
          </Row>
        </div>
      );
    }

    return !usersData.fulfilled
      ? this._emptyState()
      : !usersData.value
      ? this._emptyState()
      : this._availableState(usersData.value);
  }

  _renderPagination = (length) => {
    const { activePage } = this.state;

    let items = [];
    for (let number = 1; number <= length; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => this._changePage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return <Pagination style={{ flexWrap: "wrap" }}>{items}</Pagination>;
  };

  _changePage = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  };

  _handleSearch = (text) => {
    if (!text) {
      this.setState({ searchValue: null });
    }
    this.setState({ searchValue: text });
  };

  _sortBy = (column) => {
    if (column == this.state.currentSort) {
      let newDirection = this.state.direction == "asc" ? "desc" : "asc";
      this.setState({ direction: newDirection });
    } else {
      this.setState({ currentSort: column, direction: "asc" });
    }
  };

  _returnCaret = (direction) => {
    return direction === "asc" ? <span>&#9660;</span> : <span>&#9650;</span>;
  };

  _availableState = (customerContent) => {
    const { searchValue, currentSort, direction, activePage } = this.state;
    console.log(customerContent);

    customerContent.map((content) => {
      return (content.user_name =
        content.user_first_name + " " + content.user_last_name);
    });

    let options = {
      keys: [
        {
          name: "user_name",
          weight: 0.25,
        },
        {
          name: "user_email",
          weight: 0.25,
        },
        {
          name: "partner.name",
          weight: 0.25,
        },
        {
          name: "affiliation.name",
          weight: 0.25,
        },
      ],
      threshold: 0.0,
    };

    let contentFuse = new Fuse(customerContent, options);
    let searchedCustomerContent = contentFuse.list;
    if (searchValue) {
      searchedCustomerContent = contentFuse.search(searchValue);
    }

    let skip = activePage === 1 ? 0 : parseInt(activePage - 1) * 10;

    searchedCustomerContent = _.orderBy(
      searchedCustomerContent,
      [currentSort],
      [`${direction}`]
    );
    let currentContent = searchedCustomerContent.slice(skip, skip + 10);

    return (
      <div className="md-scroll-wrapper">
        <Container>
          <div className="md-customer-page">
            {this.state.showModal && (
              <CustomerModal
                headerTitle={"Create User"}
                close={this.toggleModal}
                handleChange={this.handleChange}
                submit={this.createUser}
                postedUser={this.props.postedUser}
              />
            )}
            <h1 className="mt-4">Customers</h1>
            <div className="row d-flex" style={{ alignItems: "center" }}>
              <Form.Group className="col-6">
                <Form.Label>Filter Results</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => this._handleSearch(e.target.value)}
                />
              </Form.Group>

              <Link
                to="#"
                className={`btn btn-primary btn-large col-3`}
                style={{ maxHeight: 60, marginTop: 15 }}
                onClick={this.toggleModal}
              >
                {" "}
                Create New Customer
              </Link>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => this._sortBy("user_log_id")}>
                    ID{" "}
                    {currentSort === "user_log_id"
                      ? this._returnCaret(direction)
                      : null}
                  </th>
                  <th onClick={() => this._sortBy("user_first_name")}>
                    Name{" "}
                    {currentSort === "user_first_name"
                      ? this._returnCaret(direction)
                      : null}
                  </th>
                  <th onClick={() => this._sortBy("user_email")}>
                    Email{" "}
                    {currentSort === "user_email"
                      ? this._returnCaret(direction)
                      : null}
                  </th>
                  <th onClick={() => this._sortBy("affiliation.name")}>
                    Partner{" "}
                    {currentSort === "affiliation.name"
                      ? this._returnCaret(direction)
                      : null}
                  </th>
                  <th onClick={() => this._sortBy("partner.name")}>
                    Affiliation{" "}
                    {currentSort === "partner.name"
                      ? this._returnCaret(direction)
                      : null}
                  </th>
                  <th onClick={() => this._sortBy("user_timestamp")}>
                    User Since{" "}
                    {currentSort === "user_timestamp"
                      ? this._returnCaret(direction)
                      : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {_.orderBy(currentContent, [currentSort], [`${direction}`]).map(
                  (content) => {
                    return (
                      <tr
                        onClick={() =>
                          this.props.history.push(
                            `/customer/${content.user_log_id}`
                          )
                        }
                        key={content.user_log_id}
                      >
                        <td>{content.user_log_id}</td>
                        <td>
                          {content.user_first_name} {content.user_last_name}
                        </td>
                        <td>{content.user_email}</td>
                        <td>
                          {content.affiliation ? content.affiliation.name : ""}
                        </td>
                        <td>{content.partner ? content.partner.name : ""}</td>
                        <td>{moment(content.user_timestamp).format("LLL")}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
            {this._renderPagination(
              Math.ceil(searchedCustomerContent.length / 10)
            )}
          </div>
        </Container>
      </div>
    );
  };

  _emptyState = () => {
    return (
      <Container>
        <h2>Customers </h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name </th>
              <th>Last Name </th>
              <th>Email </th>
            </tr>
          </thead>
        </Table>
      </Container>
    );
  };
}

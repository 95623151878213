import './Suggestions.scss';
import React from "react";
import Suggestion from "./Suggestion";

export default function Suggestions({ state, suggestions, selectSuggestion }) {
	return <div className={suggestions.length ? "autocomplete--suggestions" : 'd-none'}>
		{suggestions.map(suggestion => {
			return (
				<Suggestion
					key={suggestion.text}
					suggestion={suggestion}
					selectSuggestion={() => selectSuggestion(suggestion)}
				/>
			)
		})}
	</div>;
}
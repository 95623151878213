import "./index.scss";
import React, { useCallback, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { _returnFields } from "../FormByKey/defaultFields";
import { useDropzone } from "react-dropzone";
import axios from "../../services/axios";

export default function ReportModal({ getDomoreports, hideModal }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  async function createDomoreport(formData) {
    try {
      const reportResponse = await axios.post(
        "/domoreport/uploader/domoreport",
        formData,
        { headers: { contentType: null } }
      );
      if (reportResponse.data) {
        hideModal();
        getDomoreports();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function submitDomoreport(file) {
    var formData = new FormData();
    formData.append("inspection_file", file);
    setLoading(true);
    createDomoreport(formData);
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={hideModal}
      className="md-create-report-modal"
    >
      <Modal.Header>
        <Modal.Title>Create Domoreport</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <div className="file-input-wrapper" {...getRootProps()}>
            <input {...getInputProps()} accept="application/pdf" />
            {!!(file && file.name) ? (
              <p>{file.name}</p>
            ) : isDragActive ? (
              <p>Drop file here ...</p>
            ) : (
              <p>Drag and drop file here, or click to select a file</p>
            )}
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal} disabled={loading}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => submitDomoreport(file)}
          disabled={loading}
        >
          {loading ? "Loading..." : "Save Changes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const templates = {
    county: [
        {
            key: `Building Permit Search`,
            title: `Building Permit Resources for`,
            summary: `A successful transaction starts with obtaining the necessary permit information. Be sure to research permits on the home before you buy.`,
            description: `If you are purchasing a home you should carefully check if any additions or improvements to the property were made after the original construction. The building permit history on a property can often tell you a lot about the condition of the home and give you clues into what may need further research. Checking on this history is an important part of doing your due diligence on a home purchase as it helps you to verify the information shared with you in the seller’s disclosures. The goal is that with a little extra research, you can have more confidence and appropriate expectations when purchasing a home!`,
            tags: ['permits'],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A27%3A53.038Z.jpeg`,
            search: 'Building Permit Search for',
        },
        {
            key: `GIS and Mapping`,
            title: `GIS and Mapping Resources for`,
            summary: `A collection of important, interactive mapping and GIS data for your home buying research.`,
            description: `This is valuable information to aid in the decision-making process when purchasing a home or property. This information can show how much a property is valued at, where the parcel property is located, property size, can be used to differentiate between developed and developing areas, and can ascertain cost and time of transportation and housing.`,
            tags: ['mapping'],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A28%3A09.784Z.jpeg`,
            search: 'GIS and Mapping for',

        },
        {
            key: `Home Buying Assistant Programs`,
            title: `Home Buying Assistance Resources in`,
            summary: `Special Home Buying Assistance Programs.`,
            description: `Buying a home is a big deal! Exploring local home buyer assistance programs in your county is an important step in the journey of home-ownership. Check to see if there are any home buying assistance programs available to help you when buying a home.`,
            tags: [],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A28%3A25.396Z.jpeg`,
            search: 'Home Buying Assistance in',
        },
        {
            key: `Septic Permit Search`,
            title: `Septic Permit Resources in`,
            summary: `If you home is on a septic system and not city sewer - it is crucial to verify it was installed correctly and it working.`,
            description: `The permit for the septic system will provide you with a detailed description of the system components (e.g. size of septic tank, pump chamber specification, drain-field size) and the design capacity of the system. Receiving copies of the permits on file will also show you whether the system has previously been repaired or upgraded, the proposed location of system components as design, and the design waste strength. A successful transaction starts with obtaining the necessary permit information.`,
            tags: ['permits'],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A28%3A39.898Z.jpeg`,
            search: 'Septic Permit Search in',
        },
        {
            key: `Tax Assessor`,
            title: `County Assessor’s Office Information - `,
            summary: `Research your property at your county’s assessor to find key property information, taxes and more…`,
            description: `The information the county has about your property determines your property taxes.  It is important the information is correct - for you and them.  Be sure to verify all details.  If you find incorrect information, find out why it is not right and work to have it corrected.  Incorrect data could be costly and may be clues to work done incorrectly.`,
            tags: [],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A28%3A52.950Z.jpeg`,
            search: 'County Assessor tax search',
        },
    ],
    city: [
        {
            key: `Building Permit Search`,
            title: `Building Permit Resources for`,
            summary: `A successful transaction starts with obtaining the necessary permit information. Be sure to research permits on the home before you buy!`,
            description: `If you are purchasing a home you should carefully check if any additions or improvements to the property were made after the original construction. The building permit history on a property can often tell you a lot about the condition of the home and give you clues into what may need further research. Checking on this history is an important part of doing your due diligence on a home purchase as it helps you to verify the information shared with you in the seller’s disclosures. The goal is that with a little extra research, you can have more confidence and appropriate expectations when purchasing a home!`,
            tags: ['permits'],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A11%3A40.699Z.jpeg`,
            search: 'Building Permit Search for',

        },
        {
            key: `GIS and Mapping`,
            title: `GIS and Mapping Resources for`,
            summary: `A collection of important, interactive mapping and GIS data for your home buying research.`,
            description: `This is valuable information to aid in the decision-making process when purchasing a home or property. This information can show how much a property is valued at, where the parcel property is located, property size, can be used to differentiate between developed and developing areas, and can ascertain cost and time of transportation and housing.`,
            tags: ['mapping'],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A25%3A49.285Z.jpeg`,
            search: 'GIS and Mapping for',
        },
        {
            key: `Home Buying Assistant Programs`,
            title: `Home Buying Assistance Resources in`,
            summary: `Special Home Buying Assistance Programs`,
            description: `Buying a home is a big deal! Exploring local home buyer assistance programs in your city is an important step in the journey of home-ownership. Check to see if there are any home buying assistance programs available to help you when buying a home.`,
            tags: [],
            primary_image: `https://md-feature-images.s3.us-east-2.amazonaws.com/explore-2020-12-15T22%3A26%3A48.938Z.jpeg`,
            search: 'Home Buying Assistance in',
        }
    ]
}
import "./InputForm.scss";
import React from "react";

function InputForm({ state, updateField, queryAutocompleteForSuggestions, toggleSuggestions }) {
	return (
		<form className="autocomplete--input-form" onSubmit={(e) => e.preventDefault()}>
			<input
				className="autocomplete--input-field"
				type="text"
				id="address1"
				value={state["address1"]}
				onChange={e => {
					updateField(e);
					queryAutocompleteForSuggestions(e.target.value);
				}}
			/>

			{state['loading'] && <p>Loading...</p>}
		</form>
	);
}
export default InputForm;
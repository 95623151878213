import React, { Component } from 'react';
import { Form, Button,Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import {WithAlert } from "../../hocs/WithAlert"
//import {_returnFields} from "./defaultFields";


const AlertMessage = WithAlert(Alert);
class FormByKey extends Component {
    
    render() {
        const { fields, obj, showSubmit = true, returnFields, alertMessage, alertBG, disabled, resetMessage } = this.props;
        const keysToIgnore = (this.props.keysToIgnore==null ? {} : this.props.keysToIgnore);
        
        return ( <Form> 
            {alertMessage ? <AlertMessage resetMessage={this.props.resetMessage} variant={alertBG} message={alertMessage}/>: null }
            {
                fields.length > 0 &&
                obj &&
                returnFields(
                    fields,
                    obj,
                    this.props.onChange,
                    this.props.boolChange,
                    this.props.handleSpecialChange,
                    keysToIgnore,
                )
            } {
                showSubmit && ( <Button variant = "primary"
                    className = "mr-2"
                    onClick = { this.props.submit } 
                    disabled={disabled} > { obj.domoreport_published ? 'Submit' : 'Save' } </Button>
                )
            } </Form>);
        }
    }

export default withRouter(FormByKey);
import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function EmptySelectionModal({ show, close }) {
  return (
    <Modal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Empty Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The project was not queued because there was no text selected.</p>
        <div className="d-flex justify-content-around">
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React, { Component } from 'react';
import { Modal, Button, Form, } from 'react-bootstrap'

export default class CreateAreaModal extends Component {
    constructor(props) {
        super(props);
    }

    onHide = () => {
        const { src } = this.props;

        if (src === 'property_table') {
            this.props.onChange('section_ref_id', null)
        } else {
            this.props.close();
        }
    }

    render() {
        const { onChange, obj, create } = this.props;
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title> {create ? "Create" : "Edit"} a new area instance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {!create &&
                            <div>
                                <Form.Label>Area Id</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={`area_id`}
                                    defaultValue={("area_id" in obj && obj.area_id) ? obj.area_id : ""}
                                    disabled
                                />
                            </div>
                        }

                        {!create &&
                            <div>
                                <Form.Label>Property Log Id</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={`property_log_id`}
                                    defaultValue={("property_log_id" in obj && obj.property_log_id) ? obj.property_log_id : ""}
                                    disabled
                                />
                            </div>
                        }

                        <Form.Label>Area Name (area_log_name)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={`Area Name`}
                            onChange={(evt) => { onChange('area_log_name', evt.target.value) }}
                            defaultValue={("area_log_name" in obj && obj.area_log_name) ? obj.area_log_name : ""}
                        />

                        <Form.Label>Area Name</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) => { onChange('area_ref_id', e.target.value) }}
                            disabled={("area_instance" in obj && obj.area_instance)}
                            value={obj.area_ref_id}
                        >
                            {this.props.allAreas.map((area, index) => {
                                return (<option key={index} value={area.area_ref_id}>
                                    {area.area_name}
                                </option>)
                            })}
                        </Form.Control>

                        {!create &&
                            <div>
                                <Form.Label>Area Instance</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={`area_instance`}
                                    defaultValue={("area_instance" in obj && obj.area_instance) ? obj.area_instance : ""}
                                    disabled
                                />
                            </div>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>Close</Button>
                    <Button variant="primary" onClick={() => this.props.submit(this.props.allAreas)} disabled={this.props.submitting}>Save Changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
import React, { useEffect, useState } from "react";
import "./edit.scss";

const repairwiseDBKeyMap = {
  description: "cache_job_details",
  name: "xactware_quality",
  lowTotalPrice: "cache_project_cost_low",
  highTotalPrice: "cache_project_cost_high",
  lowMaterialPrice: "cache_material_cost_low",
  highMaterialPrice: "cache_material_cost_high",
  lowLaborPrice: "cache_labor_cost_low",
  highLaborPrice: "cache_labor_cost_high",
  lowEquipmentPrice: "cache_equipment_cost_low",
  highEquipmentPrice: "cache_equipment_cost_high",
  lowLaborMinimumPrice: "cache_licensed_contractor_cost_low",
  highLaborMinimumPrice: "cache_licensed_contractor_cost_high",
  highMarketPrice: "high_market_price",
  lowMarketPrice: "low_market_price",
};

export default function ProjectQualityCard({
  setProject,
  project,
  quality,
  errors,
}) {
  const [costs, setCosts] = useState({});
  const [quantity, setQuantity] = useState();
  const [defaultProject, setDefaultProject] = useState();
  const [showCosts, setShowCosts] = useState(false);
  const [editKeys, setEditKeys] = useState({
    Total: false,
    Material: false,
    Labor: false,
    Equipment: false,
    Market: false,
    LaborMinimum: false,
  });
  const keys = [
    "Total",
    "Material",
    "Labor",
    "Equipment",
    "Market",
    "LaborMinimum",
  ];

  useEffect(() => {
    if (
      !Object.keys(costs).length &&
      project.xactware_quality &&
      project.xactware_quality === quality.name
    ) {
      const allCosts = {};
      for (const key in repairwiseDBKeyMap) {
        if (
          repairwiseDBKeyMap[key] !== "xactware_quality" &&
          repairwiseDBKeyMap[key] !== "cache_job_details"
        ) {
          allCosts[key] = project[repairwiseDBKeyMap[key]];
        }
      }
      setCosts(allCosts);
      setQuantity(project.xactware_quantity);
      setDefaultProject(project.repair_wise_job_id);
    }

    if (
      project.repair_wise_job_id &&
      defaultProject &&
      project.repair_wise_job_id !== defaultProject
    ) {
      resetCosts();
      setDefaultProject(project.repair_wise_job_id);
    }

    if (
      project.xactware_quantity &&
      quantity &&
      project.xactware_quantity !== quantity
    ) {
      resetCosts();
      setQuantity(project.xactware_quantity);
    }
  }, [project]);

  function toggleEdit(key) {
    setEditKeys({ ...editKeys, [key]: !editKeys[key] });
  }

  function updateQuality() {
    let qualityCosts = {};
    for (let key in quality) {
      if (repairwiseDBKeyMap[key]) {
        if (repairwiseDBKeyMap[key] === "cache_job_details") {
          qualityCosts = {
            ...qualityCosts,
            job_details_override: `${project.cache_job_details} ${quality[key]}`,
          };
        } else if (repairwiseDBKeyMap[key] === "xactware_quality") {
          qualityCosts = {
            ...qualityCosts,
            [repairwiseDBKeyMap[key]]: quality[key],
          };
        } else {
          const currentCost =
            costs[key] !== undefined ? costs[key] : quality[key];
          qualityCosts = {
            ...qualityCosts,
            [repairwiseDBKeyMap[key]]: currentCost,
          };
        }
      }
    }
    setProject({ ...project, ...qualityCosts });
  }

  function updateCost(key, value, totalKey) {
    const currentCost = costs[key] !== undefined ? costs[key] : quality[key];
    const total =
      costs[totalKey] !== undefined ? costs[totalKey] : quality[totalKey];
    const newCost = value ? value : 0;

    setCosts({
      ...costs,
      [key]: newCost,
      [totalKey]: total + (newCost - currentCost),
    });

    if (project.xactware_quality === quality.name) {
      setProject({
        ...project,
        [repairwiseDBKeyMap[key]]: newCost,
        [repairwiseDBKeyMap[totalKey]]: total + (newCost - currentCost),
      });
    }
  }

  function resetCosts(e) {
    if (e) {
      e.preventDefault();
    }

    let qualityCosts = {};
    let projectCosts = {};

    for (let key in quality) {
      if (
        repairwiseDBKeyMap[key] &&
        repairwiseDBKeyMap[key] !== "xactware_quality" &&
        repairwiseDBKeyMap[key] !== "cache_job_details"
      ) {
        qualityCosts = {
          ...qualityCosts,
          [key]: undefined,
        };

        projectCosts = {
          ...projectCosts,
          [repairwiseDBKeyMap[key]]: quality[key],
        };
      }
    }
    setCosts({ ...qualityCosts });

    if (project.xactware_quality === quality.name) {
      setProject({ ...project, ...projectCosts });
    }
  }

  return (
    <div
      key={quality.name}
      className={`project-quality ${errors.xactware_quality ? "invalid" : ""} ${
        project.xactware_quality === quality.name ? "active" : ""
      } ${showCosts ? "show" : ""}`}
    >
      <div className="quality-header">
        <p onClick={updateQuality}>
          {quality.name} - {quality.subheading}
        </p>
        <span onClick={() => setShowCosts(!showCosts)}>
          $
          {Math.round(
            costs.lowTotalPrice !== undefined
              ? costs.lowTotalPrice
              : quality.lowTotalPrice
          )}{" "}
          - $
          {Math.round(
            costs.highTotalPrice !== undefined
              ? costs.highTotalPrice
              : quality.highTotalPrice
          )}{" "}
          {showCosts ? (
            <i className="fa fa-caret-up" />
          ) : (
            <i className="fa fa-caret-down" />
          )}
        </span>
      </div>

      <div className="quality-body">
        <p className="quality-description">{quality.description}</p>

        <div className="quality-costs">
          <table>
            <tbody>
              {keys.map((key) => {
                const lowKeyName = `low${key}Price`;
                const highKeyName = `high${key}Price`;
                const low = Math.round(
                  costs[lowKeyName] !== undefined
                    ? costs[lowKeyName]
                    : quality[lowKeyName]
                );
                const high = Math.round(
                  costs[highKeyName] !== undefined
                    ? costs[highKeyName]
                    : quality[highKeyName]
                );

                let label = key;
                if (key === "Market") {
                  label = "Market Price";
                } else if (key === "LaborMinimum") {
                  label = "Contractor Fees";
                }

                if (key === "Total") {
                  return (
                    <tr key={key}>
                      <td>
                        <b>{label} Costs</b>{" "}
                        <a href="#" onClick={resetCosts}>
                          Use Default
                        </a>
                      </td>
                      <td className="text-right">
                        <p>
                          <b className="mr-3">
                            ${low} - ${high}
                          </b>
                        </p>
                      </td>
                    </tr>
                  );
                }

                return (
                  <tr key={key}>
                    <td>{label}</td>
                    <td className="text-right">
                      {!editKeys[key] ? (
                        <p>
                          ${low} - ${high}{" "}
                          <i
                            className="fa fa-pencil"
                            onClick={() => toggleEdit(key)}
                          />
                        </p>
                      ) : (
                        <div>
                          $
                          <input
                            type="number"
                            value={low || 0}
                            onChange={(e) =>
                              updateCost(
                                lowKeyName,
                                parseInt(e.target.value),
                                "lowTotalPrice"
                              )
                            }
                          />{" "}
                          - $
                          <input
                            type="number"
                            value={high || 0}
                            onChange={(e) =>
                              updateCost(
                                highKeyName,
                                parseInt(e.target.value),
                                "highTotalPrice"
                              )
                            }
                          />
                          <i
                            className="fa fa-floppy-o"
                            onClick={() => toggleEdit(key)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

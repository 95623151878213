import "./index.scss";
import React, { useState, useEffect, useRef } from "react";
import { ImageCropper } from "../../ImageCropper";
import { FileInput } from "../../../ui/FileInput";
import _ from "lodash";
import { v2 } from "../../../services/axios";

export default function AffiliateDetails({ user }) {
  const logoInput = useRef(null);
  const photoInput = useRef(null);
  const [prevPartner, setPrevPartner] = useState({});
  const [partner, setPartner] = useState({});
  const [logo, setLogo] = useState("");
  const [logoSrc, setLogoSrc] = useState(null);
  const [currentLogoUrl, setCurrentLogoUrl] = useState("");
  const [companyPhoto, setCompanyPhoto] = useState("");
  const [companySrc, setCompanySrc] = useState(null);
  const [currentPhotoUrl, setCurrentPhotoUrl] = useState("");
  const [paletteOptions, setPaletteOptions] = useState([]);
  const [priceOptions, setPriceOptions] = useState([]);
  const [softwareOptions, setSoftwareOptions] = useState([]);
  const [flyerUrl, setFlyerUrl] = useState(`/`);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});

  useEffect(() => {
    hydrate();
  }, []);

  useEffect(() => {
    if (user.partnerId && Object.keys(partner).length) {
      const { slug, defaultOffer } = partner;

      setFlyerUrl(
        `https://www.majordomo.com/affiliate-flyer/${slug}/${defaultOffer}`
      );

      if (
        Object.keys(prevPartner).length &&
        partner !== prevPartner &&
        partner.url === prevPartner.url &&
        partner.name === prevPartner.name
      ) {
        handleSubmit();
        setPrevPartner(partner);
      }
    }
  }, [partner]);

  useEffect(() => {
    if (currentLogoUrl && currentLogoUrl !== prevPartner.logo) {
      handleSubmit();
    }

    if (
      prevPartner.logo &&
      Object.keys(partner).length &&
      !currentLogoUrl &&
      !logo
    ) {
      handleSubmit();
    }
  }, [currentLogoUrl, logo]);

  useEffect(() => {
    if (partner && currentPhotoUrl && currentPhotoUrl !== prevPartner.image) {
      handleSubmit();
    }

    if (
      prevPartner.image &&
      Object.keys(partner).length &&
      !currentPhotoUrl &&
      !companyPhoto
    ) {
      handleSubmit();
    }
  }, [currentPhotoUrl, companyPhoto]);

  async function hydrate() {
    try {
      const partnerOptions = await v2.get("/partners/options");

      partnerOptions.data.prices.sort((a, b) => {
        return a.amount > b.amount ? -1 : 1;
      });
      setPriceOptions(partnerOptions.data.prices);
      setPaletteOptions(partnerOptions.data.palettes);
      setSoftwareOptions(partnerOptions.data.software);

      if (user.partnerId) {
        let partnerData = await v2.get(`/partners/${user.partnerId}`);

        partnerData.data.price = partnerData.data.price.id;
        partnerData.data.flyerPrimaryColor =
          partnerData.data.flyerPalette.primary;
        partnerData.data.flyerSecondaryColor =
          partnerData.data.flyerPalette.secondary;

        setPartner(partnerData.data);
        setPrevPartner(partnerData.data);
        setCurrentLogoUrl(partnerData.data.logo);
        setCurrentPhotoUrl(partnerData.data.image);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function updatePartner(key, value) {
    let updatedPartner = _.cloneDeep(partner);
    if (key === "colors") {
      updatedPartner["flyerPrimaryColor"] = value.primary;
      updatedPartner["flyerSecondaryColor"] = value.secondary;
    } else {
      updatedPartner[key] = value;
    }

    setPartner(updatedPartner);
  }

  async function handleSubmit() {
    setMessage({});
    const formData = new FormData();

    for (const key in partner) {
      if (key !== "flyerPalette") {
        formData.set(key, partner[key]);
      }

      if (logo !== "") {
        formData.set("logo", logo);
      }

      if (companyPhoto !== "") {
        formData.set("image", companyPhoto);
      }
    }

    try {
      if (partner.url) {
        const { status } = await v2.patch(
          `/partners/${user.partnerId}`,
          formData
        );

        if (status === 200) {
          setMessage({
            variant: "success",
            text: "Affiliate details updated successfully",
          });
          setErrors({});
        }
      } else {
        setMessage({
          variant: "danger",
          text: "Company Website Url is required",
        });
        setErrors({
          url: !partner.url,
        });
      }
    } catch (error) {
      setMessage({ variant: "danger", text: `Error: ${error.message}` });
    }
  }

  // async function handleDashboardRedirect(e) {
  //   e.preventDefault();
  //   console.log();
  //   const { data } = await Axios.post(
  //     `/api/partners/${user.partnerId}/dashboard-link`,
  //     {
  //       redirectUrl: `${window.location.origin}/settings`,
  //     },
  //     { withCredentials: true }
  //   );
  //   window.location = data.url;
  // }

  function onLogoSelect(file) {
    const reader = new FileReader();
    reader.onload = (evt) => {
      setLogoSrc(evt.target.result);
    };

    reader.readAsDataURL(file[0]);
    setLogo(file[0]);
    setCurrentLogoUrl(null);
  }

  function onCompanyPhotoSelect(file) {
    const reader = new FileReader();
    reader.onload = (evt) => {
      setCompanySrc(evt.target.result);
    };

    reader.readAsDataURL(file[0]);
    setCompanyPhoto(file[0]);
    setCurrentPhotoUrl(null);
  }

  function getTheme(primaryColor) {
    const primary = primaryColor.toLowerCase();
    let theme = 1;

    if (primary === "#0a3782") {
      theme = 1;
    } else if (primary === "#0b7b3b") {
      theme = 2;
    } else if (primary === "#a11e1c") {
      theme = 3;
    }

    return theme;
  }

  async function handleTheme(e) {
    e.preventDefault();
    switch (e.target.value) {
      case "1":
        updatePartner("colors", {
          primary: "#0a3782",
          secondary: "#ffbf00",
        });
        break;
      case "2":
        updatePartner("colors", {
          primary: "#0b7b3b",
          secondary: "#0a3782",
        });
        break;
      case "3":
        updatePartner("colors", {
          primary: "#a11e1c",
          secondary: "#ffbf00",
        });
        break;
      default:
        updatePartner("colors", {
          primary: "#0a3782",
          secondary: "#ffbf00",
        });
    }
  }

  if (!Object.keys(partner).length) {
    return (
      <div className="col-12">
        <p className="text-danger">
          This user has not joined the Affiliate Program yet
        </p>
      </div>
    );
  }

  return (
    <div className="user-card md-partner-details">
      <div className="card-title">Affiliate Details</div>

      <div className="details-card-body">
        <div className="col-12">
          {partner.status !== "APPROVED" && (
            <div className="d-flex align-items-center justify-content-between alert bg-warning text-white">
              <p>
                <i className="fa fa-exclamation-triangle" />{" "}
                <b>Affiliate has not completed their Stripe details</b>
              </p>
            </div>
          )}
        </div>

        <div className="col-12">
          {!!Object.keys(message).length && (
            <div className={`alert bg-${message.variant} text-white`}>
              <p className="d-flex align-items-center justify-content-between">
                <b>{message.text}</b>
                <i className="fa fa-close p-1" onClick={() => setMessage({})} />
              </p>
            </div>
          )}
        </div>

        <div className="col-12">
          <div className="row">
            <div className="form-group col-12 col-md-6">
              <p style={{ margin: 0, marginBottom: "5px", fontWeight: 700 }}>
                Company Logo (Recommended 144x144)
              </p>
              {!currentLogoUrl && !logo && (
                <FileInput name="logo" setFile={onLogoSelect} />
              )}
              {currentLogoUrl && (
                <div className="cropped-result">
                  <img src={currentLogoUrl} />

                  <input
                    onChange={(event) => {
                      onLogoSelect(event.currentTarget.files);
                    }}
                    ref={logoInput}
                    className="md-file-input d-none"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                  />

                  <div
                    className="cropped-result-overlay"
                    onClick={() => {
                      logoInput.current.click();
                    }}
                  >
                    <i className="fa fa-pencil" />
                  </div>
                </div>
              )}
              {!currentLogoUrl && logo && (
                <ImageCropper
                  imageData={logo}
                  imageSrc={logoSrc}
                  aspect={1 / 1}
                  setImage={setLogo}
                  setImageUrl={setCurrentLogoUrl}
                />
              )}
            </div>

            <div className="form-group col-12 col-md-6">
              <p style={{ margin: 0, marginBottom: "5px", fontWeight: 700 }}>
                Company Team Photo (Recommended 768x768)
              </p>
              {!currentPhotoUrl && !companyPhoto && (
                <FileInput name="companyPhoto" setFile={onCompanyPhotoSelect} />
              )}
              {currentPhotoUrl && (
                <div className="cropped-result">
                  <img src={currentPhotoUrl} />

                  <input
                    onChange={(event) => {
                      onCompanyPhotoSelect(event.currentTarget.files);
                    }}
                    ref={photoInput}
                    className="md-file-input d-none"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                  />

                  <div
                    className="cropped-result-overlay"
                    onClick={() => {
                      photoInput.current.click();
                    }}
                  >
                    <i className="fa fa-pencil" />
                  </div>
                </div>
              )}
              {!currentPhotoUrl && companyPhoto && (
                <ImageCropper
                  imageData={companyPhoto}
                  imageSrc={companySrc}
                  aspect={1 / 1}
                  setImage={setCompanyPhoto}
                  setImageUrl={setCurrentPhotoUrl}
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label htmlFor="company_name">Company Name</label>
              <div className="icon-input-wrapper">
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  className="form-control"
                  value={partner.name}
                  style={{ color: "#555555" }}
                  onBlur={handleSubmit}
                  onChange={(e) => updatePartner("name", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group col-12 col-md-6">
              <label htmlFor="uuid">Majordomo Affiliate ID</label>

              <div className="icon-input-wrapper">
                <input
                  type="text"
                  id="uuid"
                  name="uuid"
                  className="form-control"
                  value={partner.key}
                  style={{ color: "#555555" }}
                  readOnly
                />
                <i
                  className="fa fa-copy"
                  onClick={() => {
                    document.getElementById("uuid").select();
                    document.execCommand("copy");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label htmlFor="theme">
                Flyer Color Theme{" "}
                <div>
                  <a href={flyerUrl} target="_blank" rel="noopener noreferrer">
                    Preview and Download <i className="fa fa-download" />
                  </a>
                </div>
              </label>
              <select
                onChange={handleTheme}
                value={getTheme(partner.flyerPrimaryColor)}
                id="theme"
                name="theme"
              >
                {paletteOptions.map((option, value) => {
                  return (
                    <option value={value + 1} key={option.name}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group col-12 col-md-6">
              <label htmlFor="price">Client Domoreport Price</label>
              <select
                onChange={(e) => updatePartner("price", e.target.value)}
                value={partner.price}
                id="price"
                name="price"
              >
                {priceOptions.map((option) => {
                  return (
                    <option value={option.id} key={option.id}>
                      {option.amount} ({option.commission} commission)
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label htmlFor="url">Company Website URL *</label>
              <input
                id="url"
                name="url"
                type="text"
                className={`form-control ${errors.name ? "invalid" : ""}`}
                onBlur={handleSubmit}
                onChange={(e) => updatePartner("url", e.target.value)}
                placeholder="https://www.majordomo.com"
                value={partner.url}
              />
            </div>

            <div className="form-group col-12 col-md-6">
              <label htmlFor="inspectionSoftware">
                Home Inspection Software
              </label>
              <select
                onChange={(e) => updatePartner("software", e.target.value)}
                value={partner.software}
                id="inspectionSoftware"
                name="inspectionSoftware"
              >
                {softwareOptions.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* <div className="row">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="messageTheme">Flyer Messaging Theme</label>
                <select
                  onChange={(e) => updatePartner("theme", e.target.value)}
                  value={partner.theme}
                  id="messageTheme"
                  name="messageTheme"
                  style={{ textTransform: "capitalize" }}
                >
                  {themeOptions.map((option) => {
                    {
                      return (
                        <option key={option} value={option}>
                          {option.toLowerCase()}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div> */}
        </div>

        <div className="col-12">
          <hr />
          <a
            href={`https://www.majordomo.com/offer/${partner.slug}`}
            className="md-accentblue-text"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: 14 }}
          >
            <i className="fa fa-external-link" /> View Offer Page
          </a>
        </div>
      </div>
    </div>
  );
}

import './index.scss';
import React, { Component } from 'react';
import { parseQueryString } from '../../services/parseQueryString';
import { withRouter } from 'react-router-dom';
import withFetch, { baseUrl } from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RepairTable from './RepairTable';
import Image from '../Image';
import preloader from '../../preloader.gif';

export default
@withRouter
@withFetch((props) => ({
    userData: `/users`,
    getRepair: (repairID) => ({
        repairData: {
            force: true,
            refresh: true,
            url: `/domoreport/${props.reportID}/repair/${repairID}`,
        }
    }),
    getPrimaryPhoto: (reportID) => ({
        primaryPhotoData: {
            force: true,
            refresh: true,
            url: `/domoreport/primaryPropertyPhotoForDomoreport/${reportID}`,
        }
    })
}))
class DomoReport extends Component {
    state = {
        zoom: 16,
        showShares: false,
        checkAll: false,
        repair: '',
        pageStart: null,
        pageEnd: null,
        showInspection: false,
        details: null,
        showEstimateInfo: false,
        url: '',
        invertFilter: false,
        showNotes: false,
    }

    componentDidUpdate() {
        const { reportID, primaryPhotoData } = this.props;

        if (reportID && !primaryPhotoData) {
            this.props.getPrimaryPhoto(reportID)
        }
    }

    toggleInspectionDetails = (e) => {
        e.preventDefault();
        this.setState({ showInspection: !this.state.showInspection })
    }



    //Changes the Priority tag to "View Details" when provided a repairID.
    setDetailsButton = (repairID) => {
        this.setState({ details: repairID });
    }

    updateUrl = (e, index, reportID, filter, priority, repair) => {
        const is_public = parseQueryString(this.props.location.search).public || 0;
        const { propertyID } = this.props;

        this.props.getRepair(index)
        if (e.target.type !== 'checkbox') {
            this.props.history.push(`?${is_public ? 'public=1&' : ''}report=${reportID}` + (propertyID ? ('&property=' + propertyID) : '') + (index ? `&index=${index}` : ``) + (priority ? `&priority=${priority}` : ``) + (filter ? `&filter=${filter}` : ``))
        }
        this.setState({ repair: repair })
    }

    updateFilters = (reportID, filter, priority) => {
        const { propertyID } = this.props;
        const is_public = parseQueryString(this.props.location.search).public || 0;
        let currentFilter = parseQueryString(window.location.search).filter;
        let currentPriority = parseQueryString(window.location.search).priority;

        if (filter == currentFilter && priority == currentPriority) {
            this.setState({ invertFilter: !this.state.invertFilter });
        } else {
            this.setState({ invertFilter: false });
        }

        this.props.history.push(`?${is_public ? 'public=1&' : ''}report=${reportID}` + (propertyID ? ('&property=' + propertyID) : '') + (priority ? `&priority=${priority}` : ``) + (filter ? `&filter=${filter}` : ``));
    }

    showEstimateInfo = () => {
        this.setState({
            showEstimateInfo: true,
        })
    }

    hideEstimateInfo = () => {
        this.setState({
            showEstimateInfo: false,
        })
    }

    toggleNotes = (e) => {
        e.preventDefault();
        if (localStorage.getItem('show-default') == 1) {
            localStorage.setItem('show-default', 0)
            this.setState({ showNotes: false })
        } else {
            this.setState({ showNotes: !this.state.showNotes })
        }
    }

    showNotes = (e) => {
        if (!this.state.showNotes) {
            this.setState({ showNotes: true })
        }
    }

    hideNotes = (e) => {
        if (this.state.showNotes) {
            this.setState({ showNotes: false })
        }
    }

    render() {
        const { reportData, userData } = this.props;
        const index = parseQueryString(this.props.location.search).index || null;
        const filter = parseQueryString(this.props.location.search).filter || null;
        const priority = parseQueryString(this.props.location.search).priority || null;

        let repairs = [];

        if (reportData) {
            reportData.repairs.map((section) => {
                section.repairs.map((repair) => {
                    repairs.push(repair)
                })
            })
        }

        if (userData && !userData.pending) {
            return (
                this._renderAvailable(reportData, userData.value, index, filter, priority, repairs)
            );
        } else {
            return (
                <div className="md-preloader-wrapper domoreport">
                    <img src={preloader} className="md-preloader" />
                </div>
            )
        }
    }

    _renderAvailable = (reportData, userData, index, filter, priority, repairs) => {
        const is_public = parseQueryString(this.props.location.search).public || 0;

        let modalRepair = [];
        if (index && this.props.repairData && !this.props.repairData.pending) {
            modalRepair = repairs.filter((repair) => {
                return repair.repair_id == this.props.repairData.value.repair_id
            })

            modalRepair[0].costs = this.props.repairData.value.costs;
            modalRepair[0].feature_problem = this.props.repairData.value.feature_problem;
            modalRepair[0].updatedAt = this.props.repairData.value.updatedAt;
        }

        return (
            <div className="md-domoreport-page no-print">
                <div className="md-domoreport-body">
                    {/* REPAIR MODAL */}
                    {index && this.props.repairData && !this.props.repairData.pending && this._openModal(modalRepair[0])}

                    {/* PAGE HEADER */}
                    <div className="md-header-wrapper">
                        <h2 className="md-header">DOMOREPORT</h2>
                        <p className="md-subheader">powered by <Link to="/"><img src="/assets/images/logo.svg" width="150px" alt="majordomo logo" /></Link></p>
                    </div>

                    {/* OVERVIEW SECTION */}
                    {this._renderOverviewSection(reportData)}

                    {/* DESKTOP MAP SECTION */}
                    {reportData && this._renderMapSection('md', this.props.primaryPhotoData)}

                    {/* REPAIRS SUMMARY SECTION */}
                    <h2 className="summary-header px-md-4">REPAIRS SUMMARY</h2>
                    {this._renderSummarySection(filter, priority, this.props.reportID, reportData, userData)}

                    {/* NOTES SECTION */}
                    {this._renderNotesSection(reportData)}

                    {/* REPAIR TABLE */}
                    {reportData.repairs && filter != 0 &&
                        <div className="row repair-detail-wrapper">
                            <RepairTable
                                filter={filter}
                                priority={priority}
                                repairs={repairs}
                                reportID={this.props.reportID}
                                updateUrl={this.updateUrl}
                                updateFilters={this.updateFilters}
                                invert={this.state.invertFilter}
                                handleChange={this.props.handleChange}
                            />
                        </div>
                    }
                    <div className="md-disclaimer">
                        <p>
                            Disclaimer: All information deemed reliable, but not guaranteed. Estimates are approximate and not to be considered quotes. Actual repair costs may be higher, or lower. Estimates are considered minimum charges for single repairs. If work is done with other similar jobs, costs may be reduced. Priority level is based on information within inspection. User to verify and prioritize to their satisfaction. Majordomo is not a licensed real estate agent or contractor. Refer to our <a href="http://help.majordomo.com/en/">FAQ</a> and <Link to="/terms">Terms and Conditions</Link> for additional information
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    _renderOverviewSection = (reportData) => {
        return (
            <div className="row overview-wrapper">
                <div className="col-12 col-sm-6 col-md-3 section">
                    <div>
                        <p className="label">Original Inspection</p>
                        <p className="data">{reportData.inspection_date ? reportData.inspection_date : "Unknown"}</p>
                        <p className="label">Conditions</p>
                        <p className="data">{reportData.inspection_condition ? reportData.inspection_condition : "Unknown"}</p>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 section">
                    <div>
                        <p className="label">Performed By</p>
                        {reportData.inspector && reportData.inspector.name ?
                            <p className="data">{reportData.inspector.name}<br />of {reportData.inspector.company}</p>
                            :
                            <p className="data">Unknown</p>
                        }

                        {reportData.inspector && reportData.inspector.email &&
                            <p className="label">
                                <a href={"mailto:" + reportData.inspector.email}><i className="fa fa-envelope" /> {reportData.inspector.email}</a><br />
                                <i className="fa fa-phone" /> {reportData.inspector.phone_number}
                            </p>
                        }
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 section">
                    <div>
                        <p className="label">Real Estate Agent</p>
                        {reportData.real_estate_broker && reportData.real_estate_broker.name &&
                            <p className="data">{reportData.real_estate_broker.name}<br />
                                of {reportData.real_estate_broker.company}
                            </p>
                        }

                        {reportData.real_estate_broker && reportData.real_estate_broker.email &&
                            <p className="label">
                                <a href={"mailto:" + reportData.real_estate_broker.email}><i className="fa fa-envelope" /> {reportData.real_estate_broker.email}</a><br />
                                <i className="fa fa-phone" /> {reportData.real_estate_broker.phone_number}
                            </p>
                        }
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 section section-4">
                    <div>
                        <p className="label">Property Address</p>
                        <p className="data">{reportData.address}, {reportData.city}, {reportData.state}</p>
                    </div>
                </div>
            </div>
        )
    }

    _renderMapSection = (display) => {
        const { primaryPhotoData } = this.props;
        return (
            <div className={display == 'md' ? 'row map-wrapper d-none d-md-flex' : 'row map-wrapper d-flex d-md-none'}>
                <div className="col-12 col-md-6 image">
                    {primaryPhotoData && !primaryPhotoData.pending && primaryPhotoData.meta.response.status === 200 ?
                        <Image
                            sourceURL={`${baseUrl}/domoreport/primaryPropertyPhotoForDomoreport/${this.props.reportID}`}
                            token={JSON.parse(localStorage.getItem('user')).token}
                            pageSource={'/'}
                            toggleConfirmModal={false}
                            toggleImageModal={false}
                            deleteFile={false}
                            customClassName="img"
                        />
                        :
                        <div
                            className="image"
                            style={{ background: 'url(https://maps.googleapis.com/maps/api/streetview?size=600x400&location=' + encodeURI(localStorage.address) + '&pitch=-0.76&key=AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU)' }}
                        />
                    }
                </div>

                <div className="col-12 col-md-6 map" style={{ height: '340px', width: '100%' }}>
                    <div className="image" style={{ background: 'url(https://maps.googleapis.com/maps/api/staticmap?center=' + encodeURI(localStorage.address) + '&zoom=17&size=600x300&maptype=hybrid&markers=size:mid%7Ccolor:0xffbf00%7C&key=AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU)' }} />
                </div>
            </div>
        )
    }

    _renderSummarySection = (filter, priority, report, reportData) => {
        const is_public = parseQueryString(this.props.location.search).public || 0;

        return (
            <div className="summary-wrapper px-md-3">
                <div className={priority == null ? "card" : 'card card-inactive'} onClick={() => { this.updateFilters(report, filter, null) }}>
                    <div className="heading bg-dark">All Repairs</div>
                    <div className="number-repairs-wrapper">
                        <div className="label">Number of Repairs</div>
                        <div className="number">{reportData.repair_summary.all.number}</div>

                        <div className="label">Estimated Cost</div>
                        <div className="number">${Number((reportData.repair_summary.all.cost.low + reportData.repair_summary.all.cost.high) / 2).toFixed(0)}</div>
                    </div>
                </div>
                <div className={(priority === 'Urgent') ? "card" : 'card card-inactive'} onClick={() => { this.updateFilters(report, filter, 'Urgent') }}>
                    <div className="heading bg-danger">Urgent Repairs</div>
                    <div className="number-repairs-wrapper">
                        <div className="label">Number of Repairs</div>
                        <div className="number">{reportData.repair_summary.Urgent.number}</div>

                        <div className="label">Estimated Cost</div>
                        <div className="number">${Number((reportData.repair_summary.Urgent.cost.low + reportData.repair_summary.Urgent.cost.high) / 2).toFixed(0)}</div>
                    </div>
                </div>
                <div className={(priority === 'Pressing') ? "card" : 'card card-inactive'} onClick={() => { this.updateFilters(report, filter, 'Pressing') }}>
                    <div className="heading md-pressing">pressing repairs</div>
                    <div className="number-repairs-wrapper">
                        <div className="label">Number of Repairs</div>
                        <div className="number">{reportData.repair_summary.Pressing.number}</div>

                        <div className="label">Estimated Cost</div>
                        <div className="number">${Number((reportData.repair_summary.Pressing.cost.low + reportData.repair_summary.Pressing.cost.high) / 2).toFixed(0)}</div>
                    </div>
                </div>
                <div className={(priority === 'Cosmetic') ? "card" : 'card card-inactive'} onClick={() => { this.updateFilters(report, filter, 'Cosmetic') }}>
                    <div className="heading md-mdblue">cosmetic repairs</div>
                    <div className="number-repairs-wrapper">
                        <div className="label">Number of Repairs</div>
                        <div className="number">{reportData.repair_summary.Cosmetic.number}</div>

                        <div className="label">Estimated Cost</div>
                        <div className="number">${Number((reportData.repair_summary.Cosmetic.cost.low + reportData.repair_summary.Cosmetic.cost.high) / 2).toFixed(0)}</div>
                    </div>
                </div>

                <div className="card card-inactive">
                    <div className="heading md-mdgray">Request List</div>
                    <div className="number-repairs-wrapper">
                        <div className="label">Number of Requests</div>
                        <div className="number">0</div>

                        <div className="label">Estimated Cost</div>
                        <div className="number">$0</div>
                    </div>
                </div>
            </div>
        )
    }

    _renderNotesSection = (reportData) => {
        const showDefault = localStorage.getItem('show-default');
        const { showNotes } = this.state;

        return (
            <div className={showNotes || (showDefault == 1) ? "notes show" : "notes"}>
                <Link to="#" className="float-right" onClick={this.toggleNotes}>{showNotes || (showDefault == 1) ? "Up" : "Down"}</Link>
                <h2 className={showNotes || (showDefault == 1) ? "heading" : "heading p-0"}>Majordomo Notes</h2>
                <div className={showNotes || (showDefault == 1) ? "notes-footer-wrapper" : "notes-footer-wrapper d-none"}>
                    <div className="footer-content">
                        <p className="desc">{reportData.notes.majordomo.note}</p>
                        <div className="img" style={{ background: `url(${reportData.notes.majordomo.mdp_profile_pic})`, backgroundSize: 'cover' }} />
                        <div className="name-wrapper">
                            <div className="name">
                                {reportData.notes.majordomo.name}
                                <span className="icon icon-6">
                                    <Link to="#" onClick={(e) => { e.preventDefault(); this.props.toggleModal(e) }}><img src="/assets/icons/Contact.svg" width="20" alt="" /></Link>
                                </span>
                            </div>
                            <div className="title">{reportData.notes.majordomo.titel}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _hideModal = () => {
        const { propertyID } = this.props;
        const reportID = parseQueryString(this.props.location.search).report || null;
        const priority = parseQueryString(this.props.location.search).priority || null;
        const filter = parseQueryString(this.props.location.search).filter || null;
        const is_public = parseQueryString(this.props.location.search).public || 0;
        let url = '';

        this.setState({
            showInspection: false,
            showShares: false,
            pageStart: null,
            pageEnd: null,
        })
        this.props.history.push(`?${is_public ? 'public=1&' : ''}report=${reportID}` + (propertyID ? ('&property=' + propertyID) : '') + (priority ? `&priority=${priority}` : ``) + (filter ? `&filter=${filter}` : ``));
    };

    _openModal = (repair) => {
        const { costs } = repair;

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={this._hideModal}
                className="md-domoreport-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="md-domoreport-modal-title">{repair.project_name} |
                        <Link to={`/print-repair?report=${this.props.reportID}&repair=${repair.repair_id}&inspection=${this.state.showInspection ? '1' : '0'}`} target="_blank">
                            <i className="fa fa-print d-none d-sm-inline" />
                        </Link>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="md-domoreport-modal-details">
                        <span className={`md-priority-tag md-${repair.priority}`}>{repair.priority}</span>
                        <h4 className="md-domoreport-modal-subheader" dangerouslySetInnerHTML={{ __html: 'Problem: ' + repair.feature_problem }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: repair.inspection_problem_comment }}></p>

                        <hr />

                        <h4 className="md-domoreport-modal-subheader">Project Details</h4>
                        {repair.job_details && <p dangerouslySetInnerHTML={{ __html: repair.job_details }}></p>}

                        <hr />
                    </div>

                    <table className="modal-table">
                        <tbody>
                            <tr className="table-heading">
                                <td colSpan="3" onMouseOver={this.showEstimateInfo} onMouseLeave={this.hideEstimateInfo}><span>Estimated Project Cost</span>

                                    {this.state.showEstimateInfo &&
                                        <p className="md-estimate-info">
                                            This is the apx. minimum charge for the repair.
                                            If done with other similar jobs, the cost may be reduced.
                                            Repair estimates were pulled on {repair.updatedAt}. Repair estimates
                                            are approximate and not to be considered quotes.
                                            Actual repair costs may be higher, or lower.
                                        </p>
                                    }

                                </td>
                                {costs.total.low ?
                                    <td colSpan="3" className="text-right">${costs.total.low} - {costs.total.high}</td>
                                    :
                                    <td colSpan="3" className="text-right">$0</td>
                                }
                            </tr>
                            <tr className="md-table-light">
                                <td colSpan="3">Materials</td>
                                {costs.material.low ?
                                    <td colSpan="3" className="text-right">${costs.material.low} - {costs.material.high}</td>
                                    :
                                    <td colSpan="3" className="text-right">$0</td>
                                }
                            </tr>
                            <tr className="md-table-dark">
                                <td colSpan="3">Labor ({repair.contractor_type})</td>
                                {costs.labor.low ?
                                    <td colSpan="3" className="text-right">${costs.labor.low} - {costs.labor.high}</td>
                                    :
                                    <td colSpan="3" className="text-right">$0</td>
                                }
                            </tr>
                            <tr className="md-table-light">
                                <td colSpan="3">Equipment</td>
                                {costs.equiptment.low ?
                                    <td colSpan="3" className="text-right">${costs.equiptment.low} - {costs.equiptment.high}</td>
                                    :
                                    <td colSpan="3" className="text-right">$0</td>
                                }
                            </tr>
                            <tr className="md-table-dark">
                                <td colSpan="3">Minimum Trip Charge</td>
                                {costs.contactor_fees.low ?
                                    <td colSpan="3" className="text-right">${costs.contactor_fees.low} - {costs.contactor_fees.high}</td>
                                    :
                                    <td colSpan="3" className="text-right">$0</td>
                                }
                            </tr>
                            <tr>
                                <td colSpan="3"><b>Quantity</b></td>
                                <td colSpan="3" className="text-right">{costs.quantityString ? costs.quantityString : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td colSpan="3"><b>Material Quality Level</b></td>
                                <td colSpan="3" className="text-right">{costs.material_quality_level ? costs.material_quality_level : 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        );
    };
}

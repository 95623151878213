import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeLabel, styleBorder, makePlaceholder } from './';
import { Form, Container } from 'react-bootstrap';
import {
	getScoreOptionsForFeatureRef,
	updateFeatureRefState,
	updateNewFeature,
} from '../../actions';


const mapStateToProps = (state) => ({
	dropDownOptions: state.featureRef.dropDownOptions,
	newFeature: state.properties.newFeature,
	featureRef: state.featureRef,
});

class FeatureScoreDropdownField extends Component {
	componentDidMount() {
		this.props.getScoreOptionsForFeatureRef(this.props.featureRefId)
	}

	componentDidUpdate(oldProps) {
		if (this.props.featureRefId !== oldProps.featureRefId) {
			this.props.getScoreOptionsForFeatureRef(this.props.featureRefId)
		}
	}

	_handleChange = (key, value) => {
		if (this.props.isNew) {
			this.props.updateNewFeature(key, value)
			this.props.onChange(value);
		} else {
			this.props.updateFeatureRefState(key, value)
			this.props.onChange('feature_condition', this.props.propertyFactId, value);
		}
	}

	render() {
		const { featureCondition } = this.props;
		let memoKey = `FRId_${this.props.featureRefId}`;
		const options = this.props.dropDownOptions[memoKey];

		return (
			<Form.Group>
				<Form.Control
					as="select"
					value={featureCondition}
					onChange={(e) => { this._handleChange(memoKey, parseInt(e.target.value)) }}
				>
					<option value={-1}>-1</option>
					{options && options.length > 0 &&
						options.map((option, index) => {
							return (
								option ? <option value={index}>{index}: {option}</option> : null
							);
						})
					}
				</Form.Control>
			</Form.Group>
		)
	}
}

export default connect(
	mapStateToProps,
	{
		getScoreOptionsForFeatureRef,
		updateFeatureRefState,
		updateNewFeature,
	}
)(FeatureScoreDropdownField);
import axios from "axios";

export const baseUrl = process.env.REACT_APP_BASE_URL;

const v2 = axios.create({
  baseURL: "/api/",
  withCredentials: true,
});
v2.interceptors.request.use(
  async function (config) {
    const token = (await !!JSON.parse(localStorage.getItem("user")))
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    if (token) {
      config.headers.authorization = token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { v2 };

let instance = axios.create();
instance.defaults.baseURL = baseUrl;
instance.interceptors.request.use(
  async function (config) {
    const token = (await !!JSON.parse(localStorage.getItem("user")))
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    if (token) {
      config.headers.authorization = token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;

import "./index.scss";
import React, { useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import Axios from "axios";
import { withRouter } from "react-router-dom";

function LoginComponent({ history }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  async function login(e) {
    e.preventDefault();
    try {
      const loginResult = await Axios.post("/api/users/login", {
        email,
        password,
      });
      if (loginResult.data) {
        const data = JSON.stringify(loginResult.data);
        await localStorage.setItem("user", data);

        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (!email || !password) {
        setError("Invalid Log In Credentials.");
      } else if (error && !error.response) {
        setError("Network error. Please try again in a few minutes.");
      }
    }
  }

  return (
    <div className="md-login-page">
      <div>
        <img src="/assets/images/logo.svg" />
      </div>
      <div className="md-login-form-wrapper">
        <h2>Login</h2>
        {error && (
          <Alert key={1} variant={"danger"}>
            {error}
          </Alert>
        )}

        <Form onSubmit={login}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password || ""}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" onClick={login}>
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
}
export default withRouter(LoginComponent);

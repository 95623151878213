import "./index.scss";
import React, { Component } from "react";
import PdfViewer from "../PdfViewer";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  user: state.user,
});

export default
@connect(mapStateToProps, {})
class HomeInspection extends Component {
  render() {
    const { user } = this.props;
    return (
      <div className="md-inspection-page">
        <PdfViewer
          alerts={this.props.alerts}
          report={this.props.report}
          reportID={this.props.reportID}
          token={user.token}
          setPageNumber={this.props.setPageNumber}
          setContextComment={this.props.setContextComment}
          setContextPage={this.props.setContextPage}
          setAlerts={this.props.setAlerts}
          setPdfImage={this.props.setPdfImage}
          updateStep={this.props.updateStep}
          updateReport={this.props.updateReport}
          pageNumber={this.props.pageNumber}
          queuedProjects={this.props.queuedProjects}
          setQueuedProjects={this.props.setQueuedProjects}
          getOneReport={this.props.getOneReport}
          setMessage={this.props.setMessage}
          setProjectId={this.props.setProjectId}
          projectsFetched={this.props.projectsFetched}
        />
      </div>
    );
  }
}

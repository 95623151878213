import './index.scss';
import React, { Component } from 'react';
import { Card, Button, Row, Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';
import ReactQuill from 'react-quill';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

export default 
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        createExploreContent: (data) => ({
            createdContent: {
                url: `/admin/explore`,
                method: 'POST',
                headers: {
                    'Content-Type': null,
                    authorization: props.user.token
                },
                body: data
            }
        }),
        allExploreContent: {
            url: `/admin/explore`,

        }
    }) : null;
})
class CreateExploreComponent extends Component {

    constructor(props) {
        super(props);
    }
    state = {
        body: null,
        primary_image: null,
        is_utilities: null,
        is_mapping: null,
        is_permits: null,
        src: null,
        croppedImageUrl: null,
        blob: null,
        crop: {
            width: 600,
            height: 300,
            aspect: 2 / 1,
        }
    }


    // Pull all of this out and put it into its own component. Need state too!
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ src: reader.result })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                this.setState({ blob: blob });
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }


    render() {
        const { crop, croppedImageUrl, src } = this.state;
        const { allExploreContent } = this.props;
        if (!allExploreContent) {
            return null
        }

        if (allExploreContent.pending) {
            return <Container>
                <Container>
                    <Link style={{ marginTop: 20 }} to="/explore">Go Back</Link>
                </Container>
            </Container>
        }

        let zipcodes = new Set(allExploreContent.value.filter(x => x.zipcode).map(x => x.zipcode))

        let cities = new Set(allExploreContent.value.filter(x => x.city && x.city != "null").map(x => x.city))

        let counties = new Set(allExploreContent.value.filter(x => x.county && x.county != "null").map(x => x.county))

        let countries = new Set(allExploreContent.value.filter(x => x.country && x.country != "null").map(x => x.country))

        let states = new Set(allExploreContent.value.filter(x => x.state && x.state != "null").map(x => x.state))

        return (
            <div className="md-scroll-wrapper">
                <Container className="md-create-explore">
                    <Container>
                        <Link style={{ marginTop: 20 }} to="/explore">Go Back</Link>

                        <h2>New Explore Content</h2>
                        <Form.Group>
                            <Form.Label>Primary Image (600px x 300px)</Form.Label>
                            <Form.Control type="file" onChange={this.onSelectFile} />

                            {src && (
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            )}
                            {croppedImageUrl && (
                                <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('title', e.target.value)} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Summary</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('summary_text', e.target.value)} />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Url for Primary Button</Form.Label>
                            <Form.Control type="text" onChange={(e) => this._handleFormChange('url', e.target.value)} />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Zipcode</Form.Label>
                            <Typeahead
                                labelKey={option => `${option}`}
                                options={[...zipcodes]}
                                placeholder="Zipcode ..."
                                onChange={(e) => this._handleFormChange('zipcode', e[0])}
                                onInputChange={(e) => this._handleFormChange('zipcode', e)}
                                id="zipcode"
                            />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>City</Form.Label>
                            <Typeahead
                                labelKey={option => `${option}`}
                                options={[...cities]}
                                placeholder="City ..."
                                onChange={(e) => this._handleFormChange('city', e[0])}
                                onInputChange={(e) => this._handleFormChange('city', e)}
                                id="city"
                            />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>County</Form.Label>
                            <Typeahead
                                labelKey={option => `${option}`}
                                options={[...counties]}
                                placeholder="County ..."
                                onChange={(e) => this._handleFormChange('county', e[0])}
                                onInputChange={(e) => this._handleFormChange('county', e)}
                                id="county"
                            />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>State</Form.Label>
                            <Typeahead
                                labelKey={option => `${option}`}
                                options={[...states]}
                                placeholder="State ..."
                                onChange={(e) => this._handleFormChange('state', e[0])}
                                onInputChange={(e) => this._handleFormChange('state', e)}
                                id="state"
                            />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Countries</Form.Label>
                            <Typeahead
                                labelKey={option => `${option}`}
                                options={[...countries]}
                                placeholder="Country ..."
                                onChange={(e) => this._handleFormChange('country', e[0])}
                                onInputChange={(e) => this._handleFormChange('country', e)}
                                id="country"
                            />
                        </Form.Group>

                        <Form.Group className="d-flex">
                            <Form.Check
                                type="checkbox"
                                className="mx-2"
                                onChange={(e) => this._handleFormChange('is_utilities', !this.state.is_utilities)}
                                checked={this.state.is_utilities}
                                label="Utilities"
                            />

                            <Form.Check
                                type="checkbox"
                                className="mx-2"
                                onChange={(e) => this._handleFormChange('is_mapping', !this.state.is_mapping)}
                                checked={this.state.is_mapping}
                                label="Mapping"
                            />

                            <Form.Check
                                type="checkbox"
                                className="mx-2"
                                onChange={(e) => this._handleFormChange('is_permits', !this.state.is_permits)}
                                checked={this.state.is_permits}
                                label="Permits"
                            />
                        </Form.Group>

                        <ReactQuill value={this.state.body}
                            onChange={this._handleChange} />

                        <Button variant="primary" size="lg" block style={{ marginTop: 20 }} onClick={this._submitContent}>Submit</Button>
                    </Container>

                </Container>
            </div>
        )
    }

    _goBack = () => {
        this.props.history.push('/explore');
    }

    _addPrimaryImage = (event) => {
        // event.stopPropagation();
        // event.preventDefault();

        this.setState({ primary_image: event.target.files[0] });
        console.log(this.state.primary_image)
    }

    _validateValue = (value) => {
        if (value === '' || value === ' ' || value === 0) {
            return null
        } else {
            return value
        }
    }

    _submitContent = () => {
        let rawData = this.state;

        let data = {
            primary_image: rawData.blob || null,
            title: rawData.title || null,
            summary_text: rawData.summary_text || null,
            url: rawData.url || null,
            zipcode: rawData.zipcode || null,
            city: rawData.city || null,
            state: rawData.state || null,
            county: rawData.county || null,
            country: rawData.country || null,
            body: rawData.body || null,
            is_utilities: rawData.is_utilities || null,
            is_mapping: rawData.is_mapping || null,
            is_permits: rawData.is_permits || null,
        }

        console.log('data', data);

        let formData = this._getFormData(data)
        this._callAlert('create', () => this.props.createExploreContent(formData))

        let newState = {};
        for (let prop in rawData) {
            newState[prop] = null;
        }
        this.setState({ ...newState });
        this._goBack();
    }

    _callAlert = (type, cb) => {
        if (type === "create") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Do you want to create this content?')
            if (res === true) {
                cb()
            }
        }

        if (type === "delete") {
            // eslint-disable-next-line no-restricted-globals
            let res = confirm('Are you sure you want to delete this content?')
            if (res === true) {
                cb()
            }
        }
    }

    _getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
            if (object[key]) {
                formData.append(key, object[key])
            }
        });
        return formData;
    }

    _handleFormChange(key, value) {
        if (value == true) {
            value = 1;
        } else if (value == false) {
            value = 0;
        }

        this.setState({ [key]: this._validateValue(value) || null })
    }

    _handleChange = (value) => {
        if (value === '') {
            value = null;
        }
        this.setState({ body: value })
    }

}
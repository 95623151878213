import {
	GET_REPORT_ATTEMPT,
	GET_REPORT_SUCCESS,
	GET_REPORT_FAIL,

	GET_REPORT_FIELDS_ATTEMPT,
	GET_REPORT_FIELDS_SUCCESS,
	GET_REPORT_FIELDS_FAIL,

	UPDATE_REPORT,
	UPDATE_REPORT_ATTEMPT,
	UPDATE_REPORT_SUCCESS,
	UPDATE_REPORT_FAIL,

	GET_META_MEASURES_ATTEMPT,
	GET_META_MEASURES_FAIL,
	GET_META_MEASURES_SUCCESS,

	UPDATE_META_MEASURE,
	UPDATE_META_MEASURES_ATTEMPT,
	UPDATE_META_MEASURES_FAIL,
	UPDATE_META_MEASURES_SUCCESS,

} from '../actions'

const initialState = {
	reports: [],
	report: {},
	oneFetching: false,
	oneFetched: false,
	updatingReport: false,
	fields: [],
	emptyValues: {},
	fieldsHash: {},
	fieldsFetched: false,
	fieldsFetching: false,
	newMetaMeasure: {
		domoreport_log_id: null,
		measure: "",
		value: ""
	},
	metaMeasures: [],
	measuresFetching: false,
	measuresFetched: false,
	updatingMeasure: false,
	error: null,
}

export function reports(state = initialState, action){
	switch (action.type){
		case GET_REPORT_ATTEMPT:
			return {
				...state,
				oneFetching: true,
				oneFetched: false,
			}
		case GET_REPORT_SUCCESS:
			return {
				...state,
				report: action.report,
				oneFetching: false,
				oneFetched: true,
			}
		case GET_REPORT_FAIL:
			return {
				...state,
				oneFetching: false,
				oneFetched: false,
				error: action.err,
			}
		case GET_REPORT_FIELDS_ATTEMPT:
			return {
				...state,
				fieldsFetched: false,
				fieldsFetching: true,
			}
		case GET_REPORT_FIELDS_SUCCESS:
			return {
				...state,
				fields: action.fields,
				emptyValues: action.emptyValues,
				fieldsHash: action.fieldsHash,
				fieldsFetched: true,
				fieldsFetching: false,
			}
		case GET_REPORT_FIELDS_FAIL:
			return {
				...state,
				fieldsFetched: false,
				fieldsFetching: false,
				error: action.err,
			}
		case UPDATE_REPORT:
			return {
				...state,
				report: {
					...state.report,
					[action.key]: action.value,
				}
			}
		case UPDATE_REPORT_ATTEMPT:
			return {
				...state,
				updatingReport: true,
			}
		case UPDATE_REPORT_FAIL:
			return {
				...state,
				error: action.err,
				updatingReport: false,
			}
		case UPDATE_REPORT_SUCCESS:
			return {
				...state,
				updatingReport: false,
			}
		case GET_META_MEASURES_ATTEMPT:
			return {
				...state,
				measuresFetching: true,
				measuresFetched: false,
			}
		case GET_META_MEASURES_FAIL:
			return {
				...state,
				measuresFetching: false,
				measuresFetched: false,
			}
		case GET_META_MEASURES_SUCCESS:
			return {
				...state,
				measuresFetching: false,
				measuresFetched: true,
				metaMeasures: action.measures,
			}
		case UPDATE_META_MEASURE:
			if(action.index === null){
				return {
					...state,
					newMetaMeasure:{
						...state.newMetaMeasure,
						measure: action.key,
						value: action.value,
					},
				}
			} else {
				//make a copy of the array, don't mutate it.
				let tempArray = state.metaMeasures.slice();
				tempArray[action.index] = { 
					...tempArray[action.index], 
					measure: action.key,
					value: action.value,
				}

				return {
					...state,
					metaMeasures: tempArray,
				}
			}

		case UPDATE_META_MEASURES_ATTEMPT:
			return {
				...state,
			}
		case UPDATE_META_MEASURES_FAIL:
			return {
				...state,
			}
		case UPDATE_META_MEASURES_SUCCESS:
			return {
				...state,
				newMetaMeasure: {
					domoreport_log_id: null,
					measure: "",
					value: ""
				},
			}
		default:
			return {
				...state,
			}
	}
}
import React, { Component } from 'react';
import { IntDropdownField } from '../FormFields/';
import { connect } from 'react-redux';
import { 
    getPriorities, 
 } from '../../actions';

const mapStateToProps = (state) => ({
	projectPriority: state.projects.priorities,
});


class ProjectPriorityDropdownField extends Component {
	
	componentDidMount(){
		this.props.getPriorities()
	}

	render(){
		return(
			<IntDropdownField 
				formName={"project_priority_ref_id"}
				fieldKey={"project_priority_ref_id"}
				keysToIgnore={this.props.keysToIgnore}
				obj={{project_priority_ref_id: this.props.value}}
				keyAsIndex={this.props.keyAsIndex}
				onChange={this.props.onChange}
				options={this.props.projectPriority.priorities}
				displayKey={"project_priority_name"}
			/>
		)
	}
}


export default connect(
	mapStateToProps,
	{ getPriorities }
)(ProjectPriorityDropdownField);
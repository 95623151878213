import "./index.scss";
import React, { Component } from "react";
import _ from "lodash";

export default class CustomContext extends Component {
  state = {
    visible: false,
    x: 0,
    y: 0,
    targetAlert: null,
    pageElement: null,
  };

  componentDidMount() {
    let self = this;
    let viewer = document.getElementById("viewer");

    viewer.addEventListener("contextmenu", function (event) {
      event.preventDefault();
      const clickX = event.clientX;
      const clickY = event.clientY;
      let page = null;
      let targetAlert = null;

      let pages = document.getElementsByClassName("page");
      for (var i = 0; i < pages.length; i++) {
        if (
          parseInt(pages[i].getAttribute("data-page-number")) ===
          window.PDFViewerApplication.pdfViewer._currentPageNumber
        ) {
          page = pages[i];
        }
      }

      if (event.target.classList[0] === "md-inspection-alert") {
        targetAlert = event.target;
      }

      self.setState({
        visible: true,
        x: clickX,
        y: clickY,
        targetAlert: targetAlert,
        pageElement: page,
      });
    });

    // Close context menu when either menu or pdfViewer are clicked.
    const context = document.getElementById("customContext");
    context.addEventListener("click", function () {
      self.setState({ visible: false, x: 0, y: 0 });
    });

    document.addEventListener("click", function (e) {
      /* 
                PDF Service redirects to '/' on 'btn' click,
                this prevents that without preventing file inputs
                or checkboxes.
            */
      if (e && e.path && e.path[0] && e.path[0].classList[0] === "btn") {
        e.preventDefault();
      }
      self.setState({ visible: false, x: 0, y: 0 });
    });
  }

  createProject = (selection) => {
    this.props.setContextComment(selection.replace(/  +/g, " "));
    this.props.setContextPage(
      window.PDFViewerApplication.pdfViewer._currentPageNumber
    );
    this.props.updateStep(3);
  };

  handleQueue = (selection) => {
    if (selection.trim().length) {
      const { uniqueId } = this.props;

      let currentProjects = JSON.parse(
        localStorage.getItem("queued-projects")
      ) || { [uniqueId]: [] };

      if (Object.keys(currentProjects).length) {
        if (
          currentProjects[Object.keys(currentProjects)[0]].length &&
          Object.keys(currentProjects)[0] !== uniqueId
        ) {
          this.props.handleChange("showConfirmModal", true);
        } else {
          if (!currentProjects[uniqueId] || !currentProjects[uniqueId].length) {
            currentProjects = { [uniqueId]: [] };
          }

          this.props.queueProject(
            currentProjects,
            selection.replace(/\0/g, "").replace(/  +/g, " ")
          );
        }
      } else {
        currentProjects = { [uniqueId]: [] };
        this.props.queueProject(
          currentProjects,
          selection.replace(/\0/g, "").replace(/  +/g, " ")
        );
      }

      this.props.handleChange(
        "selection",
        selection.replace(/\0/g, "").replace(/  +/g, " ")
      );
    } else {
      this.props.handleChange("showErrorModal", true);
    }
  };

  addAlert = () => {
    const { pageElement, x, y } = this.state;

    let page = pageElement;
    let current_page = window.PDFViewerApplication.pdfViewer._currentPageNumber;
    let rect = page.getBoundingClientRect();
    let offsetWidth =
      document.getElementsByClassName("md-diy-sidebar")[0].offsetWidth;
    let xPos = x - (offsetWidth + page.offsetLeft) - 10;
    let yPos = y - rect.top - 10;
    let alerts = [...this.props.alerts];
    // let options = [...this.props.alertOptions]

    let newAlert = document.createElement("H4");
    newAlert.innerHTML = "ALERT";
    newAlert.classList = "md-inspection-alert";
    newAlert.style.position = "absolute";
    newAlert.style.top = yPos.toString() + "px";
    newAlert.style.left = xPos.toString() + "px";
    newAlert.style.color = "#ff0000";

    if (!alerts[current_page]) {
      alerts[current_page] = [{ alert: newAlert, page: page }];

      newAlert.id =
        "mdAlert_" + current_page + "_" + (alerts[current_page].length - 1);
      newAlert.setAttribute("data-alert-id", alerts[current_page].length - 1);
      newAlert.setAttribute("data-alert-page", current_page);
    } else {
      alerts[current_page].push({ alert: newAlert, page: page });

      newAlert.id =
        "mdAlert_" + current_page + "_" + (alerts[current_page].length - 1);
      newAlert.setAttribute("data-alert-id", alerts[current_page].length - 1);
      newAlert.setAttribute("data-alert-page", current_page);
    }

    this.props.setAlerts(alerts);
    page.appendChild(newAlert);
  };

  removeAlert = () => {
    const { targetAlert } = this.state;

    let id = targetAlert.getAttribute("data-alert-id");
    let alerts = _.cloneDeep(this.props.alerts);
    let current_page = window.PDFViewerApplication.pdfViewer._currentPageNumber;

    let el = document.getElementById("mdAlert_" + current_page + "_" + id);
    el.remove();

    this.props.alerts[current_page].map((alert, index) => {
      if (alert.alert.getAttribute("data-alert-id") === id) {
        alerts[current_page].splice(index, 1);
      }
    });

    this.props.setAlerts(alerts);
  };

  nextAlert = () => {
    const { targetAlert } = this.state;
    const { alerts } = this.props;
    let page_start = parseInt(targetAlert.getAttribute("data-alert-page"));
    let current_id = parseInt(targetAlert.getAttribute("data-alert-id"));
    let scrolled = false;

    for (var i = page_start; i < alerts.length; i++) {
      if (scrolled) {
        break;
      }

      if (alerts[i]) {
        for (
          var x = i === page_start ? current_id : 0;
          x < alerts[i].length;
          x++
        ) {
          let next = null;

          if (i === page_start && alerts[i][x + 1]) {
            next = alerts[i][x + 1].alert;

            if (parseInt(next.getAttribute("data-alert-id")) === x + 1) {
              next.scrollIntoView();
              scrolled = true;
              break;
            }
          } else if (i !== page_start && alerts[i]) {
            alerts[i][0].alert.scrollIntoView();
            scrolled = true;
            break;
          }
        }
      }
    }
  };

  editPdfImage = () => {
    let current_page = window.PDFViewerApplication.pdfViewer._currentPageNumber;
    let canvas = document.getElementById(`page${current_page}`);

    this.props.setPdfImage(canvas.toDataURL());
  };

  handleIncompleteProject = (selection) => {
    const { report } = this.props;
    const currentPage =
      window.PDFViewerApplication.pdfViewer._currentPageNumber;

    let majordomoNotes = `${
      report.majordomo_notes
    }\nPage ${currentPage}: ${selection
      .replace(/\0/g, "")
      .replace(/  +/g, " ")}`;
    if (!report.majordomo_notes) {
      majordomoNotes = `Thanks for having Majordomo on your team for the second half of your home inspection. We appreciate the opportunity. If you have any questions or need any updates, simply email us or use the instant chat button on this page. We are here for you 24/7/365. \nNotes: \n\n-Any reference to mold and/or bacterial growths should be reviewed by a specialist.\n\n-All structural and foundation issues should be reviewed by a structural specialist. \n\n-When needed, anti-tip devices for kitchen stoves and anti-siphon devices for spigots can be purchased at your local home store for $5-$10 and are easily installed.;\n\nNot enough information to estimate:\n\nPage ${currentPage}: ${selection
        .replace(/\0/g, "")
        .replace(/  +/g, " ")}`;
    } else if (
      report.majordomo_notes &&
      !report.majordomo_notes.includes("Not enough information to estimate:")
    ) {
      majordomoNotes = `${
        report.majordomo_notes
      }\n\nNot enough information to estimate:\n\nPage ${currentPage}: ${selection
        .replace(/\0/g, "")
        .replace(/  +/g, " ")}`;
    }

    this.props.updateReport({ majordomo_notes: majordomoNotes });
    this.props.getOneReport();
    // this.props.updateStep(1);
  };

  render() {
    const { targetAlert } = this.state;
    var myStyle = {
      position: "fixed",
      top: `${this.state.y - 10}px`,
      left: `${this.state.x - 5}px`,
      display: this.state.visible ? "block" : "none",
    };

    return (
      <div className="custom-context" id="customContext" style={myStyle}>
        {window.PDFViewerApplication && window.PDFViewerApplication.pdfViewer && (
          <div
            className="custom-context-item-last text-center"
            style={{ backgroundColor: "#eeeeee" }}
          >
            Page {window.PDFViewerApplication.pdfViewer._currentPageNumber}
          </div>
        )}
        <div
          className={
            !targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            document.execCommand("copy");
          }}
        >
          Copy
        </div>

        <div
          className={
            !targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            this.editPdfImage();
          }}
        >
          Save PDF Image
        </div>

        <div
          className={
            !targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            this.addAlert();
          }}
        >
          + Add Alert
        </div>

        <div
          className={
            !targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            this.createProject(window.getSelection().toString());
          }}
        >
          + Add Project
        </div>

        <div
          className={
            !targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            this.handleQueue(window.getSelection().toString());
          }}
        >
          + Queue Project
        </div>

        <div
          className={
            !targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            this.handleIncompleteProject(window.getSelection().toString());
          }}
        >
          Not Enough Information
        </div>

        {/* If user is targeting an alert. Show different options */}

        <div
          className={
            targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            this.nextAlert(e);
          }}
        >
          Next Alert
        </div>

        <div
          className={
            targetAlert
              ? "custom-context-item-last"
              : "custom-context-item-last d-none"
          }
          onMouseDown={(e) => {
            this.removeAlert(e);
          }}
        >
          - Remove Alert
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Container, Button, Row, Col, ListGroup, Table } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import CreateModal from '../../ui/CreateModal/modal';
import { _returnFields } from '../../ui/FormByKey/defaultFields';
import withFetch from '../../hocs/withFetch';
import withUser from '../../hocs/withUser';

export const keysToIgnore = {
    "inspector_last_modified": "inspector_last_modified",
    "inspector_added": "inspector_added",
    "inspection_profile_pic": "inspection_profile_pic",
    "inspector_ref_id": "inspector_ref_id",
};

export default 
@withRouter
@withUser()
@withFetch((props) => {
    return props.user && props.user ? ({
        allInspectors: {
            url: `/domoreport/uploader/allInspectors`,
            force: true,

        },
        refreshInspectors: () => ({
            allInspectors: {
                url: `/domoreport/uploader/allInspectors`,
                force: true,
                refreshing: true,
                headers: {
                    authorization: props.user.token
                }
            }
        }),
        inspectorFields: {
            url: '/domoreport/uploader/inspector/fields',

        },
        createInspector: (data) => ({
            createdInspector: {
                url: `/domoreport/uploader/inspector`,
                method: 'POST',

                body: JSON.stringify(data)
            }
        })
    }) : null;
})
class InspectorsComponent extends Component {

    state = {
        createModalOpen: false,
        newInspector: {},
        inspectorSubmitting: false
    }

    _toggleModal = () => {
        const { createModalOpen } = this.state;
        this.setState({ createModalOpen: !createModalOpen });
    }

    _handleChange = (key, value) => {
        let { newInspector } = this.state;
        newInspector[key] = value;
        this.setState({ newInspector });
    }

    _createNewInspector = () => {
        const { createInspector, refreshInspectors } = this.props;
        const { newInspector } = this.state;
        this.setState({ inspectorSubmitting: true })
        if (newInspector.inspector_person_name) {
            createInspector({ ...newInspector });
            setTimeout(() => {
                refreshInspectors()
                this.setState({ newInspector: {}, createModalOpen: false, inspectorSubmitting: false })
            }, 1000)

        }
    }

    _createEmptyValues = (fields) => {
        let emptyValues = {}
        for (let i = 0; i < fields.length; i++) {
            emptyValues[fields[i].Field] = null
        }
        return emptyValues;
    }

    _renderInspectorRows = (inspectors) => {
        const { history } = this.props;
        return inspectors.map(inspector => {
            return (
                <tr key={inspector.inspector_ref_id} onClick={() => history.push(`/inspectors/${inspector.inspector_ref_id}`)}>
                    <td>{inspector.inspector_ref_id}</td>
                    <td>{inspector.inspector_person_name}</td>
                    <td>{inspector.inspection}</td>
                    <td>{inspector.inspection_phone}</td>
                    <td>{inspector.inspection_email}</td>
                </tr>
            )
        })
    }

    render() {
        const { createModalOpen, inspectorSubmitting } = this.state;
        const { allInspectors, inspectorFields, createdInspector } = this.props;
        if (!allInspectors) {
            return null;
        }
        if (allInspectors.pending || inspectorFields.pending) {
            return <Container>
                <h2>Inspectors</h2>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Inspection</th>
                            <th>Phone</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                </Table>
            </Container>
        }

        let { inspectors } = allInspectors.value;
        let { value: fields } = inspectorFields;

        let emptyValues = this._createEmptyValues(fields);

        return (
            <div className="md-scroll-wrapper">
                <Container>
                    <h2>Inspectors</h2>
                    <Button onClick={this._toggleModal} >Create an Inspector</Button>
                    {createModalOpen && <CreateModal
                        submitting={inspectorSubmitting}
                        keys={fields}
                        close={this._toggleModal}
                        onChange={this._handleChange}
                        submit={this._createNewInspector}
                        headerTitle={'Create an Inspector'}
                        returnFields={_returnFields}
                        dropdownValues={{ ...emptyValues }}
                        state={this.state.newInspector}
                        keysToIgnore={keysToIgnore}
                    />}
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Inspection</th>
                                <th>Phone</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inspectors.length > 0 && this._renderInspectorRows(inspectors)}
                        </tbody>
                    </Table>
                </Container>
            </div>
        )
    }
}
import "./index.scss";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../services/axios";

export default function UpdateAddressModal({ reportId, close }) {
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState(false);

  async function updateAddress() {
    setLoading(true);
    const data = {
      street,
      city,
      state,
      zipCode,
    };

    try {
      const { status } = await axios.patch(
        `/domoreport/${reportId}/address`,
        data
      );
      if (status === 200) {
        window.location.reload();
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={close}
      className="update-address-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_0d8c6654c6ad1fe275a334234_c8f1438cad"
              tabIndex="-1"
              value=""
              onChange={() => {}}
            />
          </div>

          <div className="col-12">
            <p>
              Warning: This will update all instances of this address for this
              property even if the address is not verified by USPS or
              Smartystreets. Fields can be updated individually or all at once.
            </p>
            <div className="row">
              <div className="col-6 file-input-wrapper">
                <label htmlFor="street">Street Address</label>
                <input
                  id="street"
                  type="text"
                  onChange={(e) => setStreet(e.target.value)}
                  value={street}
                  placeholder="123 Street Address"
                />
              </div>

              <div className="col-6 file-input-wrapper">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  placeholder="Corvallis"
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-6 file-input-wrapper">
                <label htmlFor="state">State</label>
                <input
                  id="state"
                  type="text"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                  placeholder="OR"
                />
              </div>

              <div className="col-6 file-input-wrapper">
                <label htmlFor="zip">Zip</label>
                <input
                  id="zip"
                  type="text"
                  onChange={(e) => setZipCode(e.target.value)}
                  value={zipCode}
                  placeholder="97333"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={updateAddress} variant="danger">
          {loading ? "Processing..." : "Update Address"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import './index.scss';
import 'font-awesome/css/font-awesome.min.css';
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import TopNavComponent from '../components/TopNavComponent';
import ReportsComponent from '../components/Reports';
import BrokersComponent from '../components/Brokers';
import InspectorsComponent from '../components/Inspectors';
import EditInspectorComponent from '../components/Inspectors/editInspector';
import EditBrokerComponent from '../components/Brokers/editBroker';
import MDPersonComponent from '../components/MDPerson';
import EditMDPersonComponent from '../components/MDPerson/editMDPerson';
import EditReportComponent from '../components/Reports/editReport';
import EditPropertyComponent from '../components/Homes/editProperty';
import ProjectsComponent from '../components/Projects';
import PhotosComponent from '../components/Photos';
import EditProjectComponent from '../components/Projects/editProject';
import ExploreComponent from '../components/Explore';
import CreateExploreComponent from '../components/Explore/createExplore';
import EditExploreComponent from '../components/Explore/editExplore';
import FeatureRefComponent from '../components/FeatureRefs';
import EditFeatureRefComponent from '../components/FeatureRefs/editFeatureRef';
import CustomersComponent from '../components/Customers';
import EditCustomerComponent from '../components/Customers/editCustomer';
import CouponsComponent from '../components/Coupons';
import EditCouponComponent from '../components/Coupons/editCoupon';
import CreateCouponComponent from '../components/Coupons/createCoupon';
import ProjectFeatureMapComponent from '../components/ProjectFeatureMap';
import DiyComponent from '../components/Diy';
import RoleContext from '../contexts/RoleContext';

export const MainLayout = () => {
    const roleContext = useContext(RoleContext);
    return (
        <div className="md-admin-inspection-uploader">
            <TopNavComponent />
            <div className="md-admin-inspection-uploader-body">
                <Switch>
                    <Route path={`/`} exact component={ReportsComponent} />
                    <Route path={`/report/:report_id/home/:id`} exact component={EditPropertyComponent} />
                    <Route path={`/reports`} exact component={ReportsComponent} />
                    <Route path={`/report/:report_id`} exact component={EditReportComponent} />
                    <Route path={`/report/:report_id/photos/:prop_id`} exact component={PhotosComponent} />
                    <Route path={`/diy/report/:report_id/property/:property_id`} exact component={DiyComponent} />

                    {roleContext.role === 'PUBLISHER' &&
                        <>
                            <Route path={`/brokers`} exact component={BrokersComponent} />
                            <Route path={`/brokers/:id`} exact component={EditBrokerComponent} />

                            <Route path={`/inspectors`} exact component={InspectorsComponent} />
                            <Route path={`/inspectors/:id`} exact component={EditInspectorComponent} />

                            <Route path={`/explore/:id`} exact component={ExploreComponent} />
                            <Route path={`/explore`} exact component={ExploreComponent} />
                            <Route path={`/explore-content/:id`} exact component={EditExploreComponent} />
                            <Route path={`/explore-create`} exact component={CreateExploreComponent} />

                            <Route path={'/mdPeople'} exact component={MDPersonComponent} />
                            <Route path={'/mdPeople/:id'} exact component={EditMDPersonComponent} />

                            <Route path={`/projectsForReport/:report_id/property/:prop_id`} exact component={ProjectsComponent} />
                            <Route path={'/project/:id/property/:prop_id'} exact component={EditProjectComponent} />

                            <Route path={'/featureRefs/'} exact component={FeatureRefComponent} />
                            <Route path={'/featureRef/:id'} exact component={EditFeatureRefComponent} />

                            <Route path={'/customers/'} exact component={CustomersComponent} />
                            <Route path={'/customer/:id'} exact component={EditCustomerComponent} />

                            <Route path={'/coupons/'} exact component={CouponsComponent} />
                            <Route path={'/coupon/:id'} exact component={EditCouponComponent} />
                            <Route path={'/coupon-create'} exact component={CreateCouponComponent} />

                            <Route path={'/projectFeatureMaps'} exact component={ProjectFeatureMapComponent} />
                        </>
                    }

                    <Route path={'/'} render={() => { return <h4>Not Found</h4> }} />
                </Switch>
            </div>
        </div>
    );
};

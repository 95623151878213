import "./index.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { MainLayout } from "./layouts/mainLayout";
import LoginComponent from "./components/LoginComponent";
import decode from "jwt-decode";
import RoleContext from "../src/contexts/RoleContext";

export default function App() {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    hydrate();
  }, []);

  async function hydrate() {
    const user = await JSON.parse(localStorage.getItem("user"));
    let expDate = null;

    if (user && user.token) {
      expDate = new Date(decode(user.token).exp * 1000);
    }

    if (expDate != null && expDate < new Date()) {
      localStorage.removeItem("user");
      setToken(null);
    } else if (user && user.token) {
      setToken(user.token);

      const { role } = decode(user.token);
      setRole(role);
    } else {
      setToken(null);
      setRole(null);
    }
  }

  if (token !== null) {
    return (
      <RoleContext.Provider value={{ token: token, role: role }}>
        <Router>
          <MainLayout />
        </Router>
      </RoleContext.Provider>
    );
  } else {
    return (
      <Router>
        <Route path={`/`} component={LoginComponent} />
      </Router>
    );
  }
}

import "./index.scss";
import React from "react";
import { Button } from "react-bootstrap";

export default function InlineConfirmModal({
  title,
  subtext,
  action,
  variant,
  show,
  close,
  handleSubmit,
}) {
  function handleClick(e) {
    if (e.target.id && e.target.id === "modalOverlay") {
      close();
    }
  }

  return (
    <div
      id="modalOverlay"
      className={`confirm-modal-overlay ${!show ? "hidden" : ""}`}
      onClick={handleClick}
    >
      <div className="confirm-modal">
        <div className="modal-header">
          <h4>{title}</h4>
        </div>
        <div className="modal-body">
          {subtext ? <p className="text-center">{subtext}</p> : <div />}
        </div>
        <div className="modal-footer">
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
          <Button variant={variant} onClick={handleSubmit}>
            {action}
          </Button>
        </div>
      </div>
    </div>
  );
}

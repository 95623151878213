import "./index.scss";
import React, { useState, useCallback, useEffect } from "react";

import Cropper from "react-easy-crop";
import { getCroppedImg } from "./utils.js";

export function ImageCropper({
  imageData,
  imageSrc,
  aspect,
  setImage,
  setImageUrl,
}) {
  const [result, setResult] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedPixels, setCroppedPixels] = useState(null);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setResult(null);
  }, [imageData]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedPixels,
        setImage,
        imageData
      );
      setResult(croppedImage);
      setImageUrl(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedPixels]);

  function handleZoomIn(value) {
    if (zoom >= 4) {
      setZoom(4);
    } else {
      setZoom(value);
    }
  }

  function handleZoomOut(value) {
    if (zoom <= 0.5) {
      setZoom(0.5);
    } else {
      setZoom(value);
    }
  }

  return (
    <div className="md-image-cropper-wrapper">
      {result ? (
        <div className="cropped-result">
          <img src={result} />

          <div
            className="cropped-result-overlay"
            onClick={() => setResult(null)}
          >
            <i className="fa fa-pencil" />
          </div>
        </div>
      ) : (
        <div className="md-image-cropper">
          <Cropper
            minZoom={0.5}
            maxZoom={4}
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            restrictPosition={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      )}

      {!result && (
        <div className="actions-wrapper">
          <a
            href="#"
            className="text-center"
            style={{ color: "#2174a5" }}
            onClick={(e) => {
              e.preventDefault();
              handleZoomOut(zoom - 0.5);
            }}
          >
            <i className="fa fa-minus" /> Zoom Out
          </a>

          <a
            href="#"
            className=" text-center"
            style={{ color: "#2174a5" }}
            onClick={(e) => {
              e.preventDefault();
              handleZoomIn(zoom + 0.5);
            }}
          >
            <i className="fa fa-plus" /> Zoom In
          </a>

          <a
            href="#"
            className="text-red-500 text-center"
            style={{ color: "#c42527" }}
            onClick={(e) => {
              e.preventDefault();
              setImage(null);
              setImageUrl(null);
            }}
          >
            <i className="fa fa-trash" /> Delete
          </a>

          <a
            href="#"
            className="text-accentBlue text-center"
            style={{ color: "#2174a5" }}
            onClick={(e) => {
              e.preventDefault();
              showCroppedImage();
            }}
          >
            <i className="fa fa-crop" /> Crop and Save
          </a>
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { Form, Container, Col, Badge } from 'react-bootstrap';
import { Typeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { keysToIgnore } from '../../components/Projects';
import ReactQuill from 'react-quill';
import { makeLabel, styleBorder, makePlaceholder } from '../FormFields';
import {
	BoolField,
	IdNumberField,
	IntNumberField,
	VarcharTextField,
	TextQuilField,
} from '../FormFields/';
import MDPeopleDropdownField from '../FormFields/mdPeople.dropdown';
import ProjectPriorityDropdownField from '../FormFields/projectPriority.dropdown';
import TradesTypeaheadField from '../FormFields/trades.typeahead';
import FeaturesDropdownField from '../FormFields/features.dropdown';
import RepairTypeaheadField from '../FormFields/repair.typeahead';



export const _returnFields = (fields, obj, onChange, boolChange, handleSpecialChange) => {
	let indexShift = 0;
	return fields.map((field, index) => {
		let control = null;
		let label = null;
		let extra = null;
		let keyAsIndex = index + indexShift;
		let returnType = "";
		let formGroup = null;
		switch (true) {

			case field.Field.indexOf('majordomo_people_ref_id') !== -1:
				formGroup = (
					<MDPeopleDropdownField
						keysToIgnore={keysToIgnore}
						value={obj[field.Field]}
						keyAsIndex={keyAsIndex}
						onChange={onChange}
					/>
				)
				break;
			case field.Field.indexOf('project_priority_ref') !== -1:
				formGroup = (
					<ProjectPriorityDropdownField
						keysToIgnore={keysToIgnore}
						value={obj[field.Field]}
						keyAsIndex={keyAsIndex}
						onChange={onChange}
					/>
				)
				break;
			case field.Field.indexOf('xactware_quality') !== -1:
				formGroup = (
					<VarcharTextField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj={obj}
						readOnly={false}
					/>
				)
				break;
			case field.Field === 'cache_project_name':
				formGroup = (
					<VarcharTextField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={() => { }}
						obj={obj}
						readOnly={false}
					/>
				)
				break;
			case field.Field === 'cache_job_details':
				formGroup = (
					<TextQuilField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={() => { }}
						obj={obj}
					/>
				)
				break;
			case field.Field.indexOf('trade_id') !== -1:
				formGroup = (
					<TradesTypeaheadField
						keysToIgnore={keysToIgnore}
						value={obj[field.Field]}
						keyAsIndex={keyAsIndex}
						onChange={onChange}
					/>
				)

				break;
			case field.Field.indexOf('property_fact_id') !== -1:
				formGroup = (
					<FeaturesDropdownField
						keysToIgnore={keysToIgnore}
						value={obj[field.Field]}
						keyAsIndex={keyAsIndex}
						onChange={onChange}
					/>
				)
				break;
			case field.Field === 'xactware_project_id':
				formGroup = (
					<RepairTypeaheadField
						keysToIgnore={keysToIgnore}
						value={obj[field.Field]}
						keyAsIndex={keyAsIndex}
						onChange={onChange}
					/>
				)
				break;
			case field.Field === 'xactware_quantity':
				formGroup = (
					<IntNumberField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj={obj}
					/>
				)
				break;
			case field.Field === 'cache_unit_type_ref_id':
				formGroup = (
					<VarcharTextField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj={obj}
						readOnly={true}
					/>
				)
				break;
			case field.Field.indexOf('_id') !== -1:
				formGroup = (
					<IdNumberField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						obj={obj}
					/>);
				break;
			case field.Type.includes('tinyint'):
				formGroup = (
					<BoolField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onBoolChange={onChange}
						obj={obj}
					/>);
				break;
			case field.Type.indexOf('int') !== -1:
				formGroup = (
					<IntNumberField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj={obj}
					/>
				)
				break;
			case field.Field.indexOf('email') !== -1:
				break;
			case field.Field.indexOf('phone') !== -1:
				break;
			case field.Type.indexOf('varchar') !== -1:
				formGroup = (
					<VarcharTextField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj={obj}
					/>
				)
				break;
			case field.Type.indexOf('text') !== -1:
				formGroup = (
					<TextQuilField
						formName={field.Field}
						fieldKey={field.Field}
						keysToIgnore={keysToIgnore}
						onChange={onChange}
						obj={obj}
					/>
				)
				break;
			case field.Type.indexOf('time') !== -1:
				break;
			default:
				break;
		}
		return formGroup
	})
}


